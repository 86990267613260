<template>
  <div>
    <drag
      v-for="(widget, i) in dragWidget"
      :key="i"
      class="cursor-pointer m-b-10"
      :class="{ [widget.style]: true }"
      :transfer-data="{ item: widget, list: dragWidget }"
    >
      <i
        class="m-r-5 p-l-10"
        :class="[widget.icon, widget.color]"
      ></i>
      <b
        style="text-transform: uppercase"
        class="p-l-10"
      >{{ $t(widget.title) }}</b>
      <small class="hide"> {{ widget.type }} </small>
    </drag>
  </div>
</template>

<script>
import { Drag } from "vue-drag-drop";

export default {
  name: "WidgetList",
  components: {
    Drag,
  },
  data() {
    return {
      dragWidget: [
        /*
        {
          type: "xeChartLine",
          title: "Line",
          icon: "fa fa-chart-line",
          color: "text-primary",
          effect: "new",
          panel: { display: true },
        },
        {
          type: "xeChartPie",
          title: "Pie",
          icon: "fa fa-chart-pie",
          color: "text-indigo",
          effect: "new",
          panel: { display: true },
        },
        {
          type: "xeChartGauge",
          title: "Gauge",
          icon: "fa fa-tachometer-alt",
          color: "text-aqua",
          effect: "new",
          panel: { display: true },
          defaultHeigth: 40,
        },

        {
          type: "xeChartDonut",
          title: "Donut",
          icon: "fa fa-tachometer-alt",
          color: "text-aqua",
          effect: "new",
          panel: { display: true },
        },

        {
          type: "xeChartHorizontalBar",
          title: "HorizontalBar",
          icon: "fa fa-align-left",
          color: "text-indigo",
          effect: "new",
          panel: { display: true },
        },
        {
          type: "xeChartRadar",
          title: "Radar",
          icon: "fa fa-compass",
          color: "text-teal",
          effect: "new",
          panel: { display: true },
        },
        {
          type: "xeChartScatter",
          title: "Scatter",
          icon: "fa fa-braille",
          color: "text-green",
          effect: "new",
          panel: { display: true },
        },

        {
          type: "xeRankCard",
          title: "Rank",
          icon: "fas fa-star",
          color: "text-orange-darker",
          effect: "new",
          panel: { display: true },
        },

        
        {
          type: "xePivotTable",
          title: "Pivot",
          icon: "fa fa-table",
          color: "text-red",
          effect: "new",
          panel: { display: true },
        },
        {
          type: "xeHtml",
          title: "Html",
          icon: "fab fa-html5",
          color: "text-black",
          effect: "new",
          panel: { display: true },
        },

        {
          type: "xeEnergyCard",
          title: "Card",
          icon: "fa fa-address-card",
          color: "text-lime",
          effect: "new",
          panel: { display: false },
          defaultHeigth: 14,
        },
        {
          type: "xeSimpleCard",
          title: "Simple",
          icon: "fa fa-id-card",
          color: "text-purple",
          effect: "new",
          panel: { display: false },
          defaultHeigth: 7,
        },
        {
          type: "xeGoalCard",
          title: "Goal",
          icon: "fa fa-newspaper",
          color: "text-yellow-darker",
          effect: "new",
          panel: { display: false },
          defaultHeigth: 20,
        },

        {
          type: "xeImage",
          title: "Image",
          icon: "fas fa-image",
          color: "text-orange-darker",
          effect: "new",
          panel: { display: true },
        },

        {
          type: "xeRealtime",
          title: "RealTime",
          fileName: "",
          icon: "fa fa-desktop",
          color: "text-orange-darker",
          effect: "new",
          panel: { display: true },
        },

        {
          type: "xeAlarmHistory",
          title: "Alarm",
          icon: "fa fa-bell",
          color: "text-aqua",
          effect: "new",
          panel: { display: true },
        },
        {
          type: "xeTodo",
          title: "Calendar",
          icon: "fa fa-calendar-alt",
          color: "text-green-darker",
          effect: "new",
          panel: { display: true },
        },
        {
          type: "xeWeather",
          title: "Weather",
          icon: "fa fa-sun",
          color: "text-red",
          effect: "new",
          panel: { display: true },
          defaultHeigth: 36,
        },
*/
        // new Widget
        {
          type: "xeBizInfo",
          title: global.xe.$t("건물정보"),
          icon: "fa fa-building",
          color: "text-primary",
          effect: "new",
          panel: { display: false },
        },
        {
          type: "xeIndoorEnvInfo",
          title: global.xe.$t("실내환경정보"),
          icon: "fa fa-building",
          color: "text-primary",
          effect: "new",
          panel: { display: false },
        },
        {
          type: "xeForecast",
          title: global.xe.$t("기상 예보"),
          icon: "fa fa-building",
          color: "text-primary",
          effect: "new",
          panel: { display: false },
        },
        // {
        //   type: "xeSelfRate",
        //   title: "Energy Self Rate",
        //   icon: "fa fa-building",
        //   color: "text-primary",
        //   effect: "new",
        //   panel: { display: false },
        // },
        {
          type: "xeSelfRateNew",
          title: global.xe.$t("실시간 자립률"),
          icon: "fa fa-building",
          color: "text-primary",
          effect: "new",
          panel: { display: false },
        },
        {
          type: "xeSelfRateToc",
          title: global.xe.$t("전국현황 자립률"),
          icon: "fa fa-building",
          color: "text-primary",
          effect: "new",
          panel: { display: false },
        },
        {
          type: "xeSelfRateCard",
          title: global.xe.$t("에너지 자립률 카드"),
          icon: "fa fa-address-card",
          color: "text-primary",
          effect: "new",
          panel: { display: false },
        },
        {
          type: "xeCompareCard",
          title: global.xe.$t("데이터 카드"),
          icon: "fa fa-address-card",
          color: "text-primary",
          effect: "new",
          panel: { display: false },
        },
        {
          type: "xeProgressCard",
          title: global.xe.$t("작업진행상태 카드"),
          icon: "fa fa-id-card",
          color: "text-primary",
          effect: "new",
          panel: { display: false },
        },
        {
          type: "xeLine",
          title: `Line ${global.xe.$t('차트')}`,
          icon: "fa fa-chart-line",
          color: "text-primary",
          effect: "new",
          panel: {
            display: true,
            backgroundColor: "#1d2226",
            fontColor: "#FFFFFF",
            colorOpens: false,
          },
        },
        {
          type: "xeArea",
          title: `Area ${global.xe.$t('차트')}`,
          icon: "fa fa-chart-line",
          color: "text-primary",
          effect: "new",
          panel: {
            display: true,
            backgroundColor: "#1d2226",
            // backgroundColor: '#ff0000',
            fontColor: "#FFFFFF",
            colorOpens: false,
          },
        },
        {
          type: "xeBar",
          title: `Bar ${global.xe.$t('차트')}`,
          icon: "fa fa-chart-bar",
          color: "text-primary",
          effect: "new",
          panel: {
            display: true,
            backgroundColor: "#1d2226",
            fontColor: "#FFFFFF",
            colorOpens: false,
          },
        },
        {
          type: "xeHorizontalBar",
          title: `Horizontal Bar ${global.xe.$t('차트')}`,
          icon: "fa fa-chart-bar",
          color: "text-primary",
          effect: "new",
          panel: {
            display: true,
            backgroundColor: "#1d2226",
            fontColor: "#FFFFFF",
            colorOpens: false,
          },
        },
        {
          type: "xePie",
          title: `Pie ${global.xe.$t('차트')}`,
          icon: "fa fa-chart-pie",
          color: "text-indigo",
          effect: "new",
          panel: {
            display: true,
            backgroundColor: "#1d2226",
            fontColor: "#FFFFFF",
            colorOpens: false,
          },
        },
        {
          type: "xeDoughnut",
          title: `Doughnut ${global.xe.$t('차트')}`,
          icon: "fa fa-chart-pie",
          color: "text-indigo",
          effect: "new",
          panel: {
            display: true,
            backgroundColor: "#1d2226",
            fontColor: "#FFFFFF",
            colorOpens: false,
          },
        },
        {
          type: "xePeekCard",
          title: global.xe.$t("실시간 전력현황"),
          icon: "fa fa-anchor",
          color: "text-primary",
          effect: "new",
          panel: {
            display: true,
            backgroundColor: "#1d2226",
            fontColor: "#FFFFFF",
            colorOpens: false,
          },
        },
        {
          type: "xeGoalUsageChart",
          title: global.xe.$t("목표대비 에너지 사용량"),
          icon: "fa fa-anchor",
          color: "text-primary",
          effect: "new",
          panel: {
            display: true,
            backgroundColor: "#1d2226",
            fontColor: "#FFFFFF",
            colorOpens: false,
          },
        },
        {
          type: "xeUsageChart",
          title: global.xe.$t("오늘의 에너지 사용량"),
          icon: "fa fa-anchor",
          color: "text-primary",
          effect: "new",
          panel: {
            display: true,
            backgroundColor: "#1d2226",
            fontColor: "#FFFFFF",
            colorOpens: false,
          },
        },
        {
          type: "xeCarbonSaving",
          title: global.xe.$t("탄소절감량"),
          icon: "fa fa-globe",
          color: "text-primary",
          effect: "new",
          panel: { display: false },
        }
        // {
        //   type: "xeCompareChart",
        //   title: "CompareChart",
        //   icon: "fa fa-chart-line",
        //   color: "text-primary",
        //   effect: "new",
        //   panel: {
        //     display: true,
        //     backgroundColor: "#1d2226",
        //     fontColor: "#FFFFFF",
        //     colorOpens: false,
        //   },
        // },
        // {
        //   type: "xeGridTable",
        //   title: "Grid",
        //   icon: "fa fa-list-alt",
        //   color: "text-orange",
        //   effect: "new",
        //   panel: { display: true },
        // },
        // {
        //   type: "xeDataCard",
        //   title: "DataCard",
        //   icon: "fa fa-id-card",
        //   color: "text-orange",
        //   effect: "new",
        //   panel: { display: false },
        // },
         // {
        //   type: "xeImage",
        //   title: "Image",
        //   icon: "fa fa-building",
        //   color: "text-primary",
        //   effect: "new",
        //   panel: { display: false },
        // },
      ],
    };
  },
};
</script>

<template>
	<div>
		<div class="row form-group">
			<div class="col-6">
				<label><trans>알람 그룹</trans></label>
				<input type="text" class="form-control" :value="$t(alarmLog.alarmGroup)" readonly />
			</div>
			<div class="col-6">
				<label><trans>발생일자</trans></label>
				<input type="text" class="form-control" :value="alarmLog.regDt.format('yyyy-MM-dd HH:mm:ss')" readonly />
			</div>
		</div>
		<div class="row form-group">
			<div class="col-12">
				<label><trans>관제점 주소</trans></label>
				<input type="text" class="form-control" :value="alarmLog.ptAddr" readonly />
			</div>
		</div>
		<div class="row form-group">
			<div class="col-6">
				<label><trans>알람 유형</trans></label>
				<input type="text" class="form-control" :value="alarmLog.alarmType" readonly />
			</div>
			<div class="col-3">
				<label><trans>현재값</trans></label>
				<input type="text" class="form-control" :value="alarmLog.currentValue" readonly />
			</div>
			<div class="col-3">
				<label><trans>상태</trans></label>
				<input type="text" class="form-control" :value="alarmLog.status" readonly />
			</div>
			<div class="col-3" v-show="isMobile">
				<label class="m-auto">&nbsp;</label>
				<div class="checkbox checkbox-css">
					<input type="checkbox" :id="'editPagesPopup_display'" :value="alarmLog.isHold" />
					<label :for="'editPagesPopup_display'"> <trans>알림보류</trans></label>
				</div>
			</div>
		</div>
		<div class="row form-group">
			<div class="col-3">
				<label> LL </label>
				<input type="text" class="form-control" :value="alarmLog.ll" readonly />
			</div>
			<div class="col-3">
				<label> L </label>
				<input type="text" class="form-control" :value="alarmLog.l" readonly />
			</div>
			<div class="col-3">
				<label> H </label>
				<input type="text" class="form-control" :value="alarmLog.h" readonly />
			</div>
			<div class="col-3">
				<label> HH </label>
				<input type="text" class="form-control" :value="alarmLog.hh || null" readonly />
			</div>
		</div>
		<div class="row m-t-10">
			<div class="col-12">
				<b-tabs nav-class="nav-tabs" nav-wrapper-class="nav nav-tabs b-1 b-radius-3" content-class="bg-silver p-0" active-nav-item-class="text-inverse bg-silver">
					<b-tab active>
						<template slot="title">
							<span class="d-sm-none"> <trans>경고</trans> </span>
							<span class="d-sm-block d-none"> <trans>경고메세지</trans> </span>
						</template>
						<textarea class="form-control" rows="10" :value="$t(alarmLog.description)" readonly />
					</b-tab>
					<b-tab>
						<template slot="title">
							<span class="d-sm-none"> <trans>해결</trans> </span>
							<span class="d-sm-block d-none"> <trans>해결방안</trans> </span>
						</template>
						<textarea class="form-control" rows="10" :value="$t(alarmLog.solution)" readonly />
					</b-tab>
				</b-tabs>
			</div>
		</div>
	</div>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		props: ["alarmLogIdx"],
		data() {
			return {
				alarmLog: {
					regDt: "",
					alarmGroup: "",
					ptAddr: "",
					status: "",
					alarmType: "",
					ll: "",
					l: "",
					h: "",
					hh: "",
					description: "",
					solution: "",
				},
				popupMessages,
			};
		},
		computed: {
			logIdx() {
				// props로 alarmLogIdx가 들어오기도 하고,
				return this.alarmLogIdx || this.$route.params.alarmLogIdx;
			},
		},
		mounted() {
			this.initSetting();
		},
		methods: {
			initSetting() {
				if (this.isEmpty(this.logIdx)) {
					this.alertNoti(popupMessages.COMMON_SEARCH_NO_DATA_POPUP_MESSAGE);
					return;
				}

				backEndApi.alarm.getAlarmLog(this.logIdx).then(({ data }) => {
					if (this.$err(data)) return;

					this.alarmLog = data;
				});
			},
		},
	};
</script>

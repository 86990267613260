<script>
	// JSON 화면
	// const Analysis = () => import(/* webpackChunkName: "DataVisualization" */ "../views/old/visual/Analysis.vue");

	// Widget
	const DataVisualization = () => import(/* webpackChunkName: "DataVisualization" */ "../views/widget/DataVisualization.vue");

	// 대시보드
	// const NewAnalysis = () => import("../views/old/visual/componentsV2/common/components/Analysis.vue");

	// -----------------------------------------------------------------------------------------------
	// light
	// version Light 대시보드
	const LightAnalysis = () => import("../views/analysis/LightAnalysis.vue");

	// // version Light 운영관리
	const LightManagement = () => import("../views/management/Management.vue");

	// 운영코드 관리
	// const codeMgt = () => import ("../views/management/code/CodeInfo.vue");

	// // version Light 관제점관리
	// const LightPointManagement = () => import("../views/pointManagement/point/PointManagement.vue"); 기존
	const LightCustromPointManagement = () => import("../views/pointManagement/custom-point/CustomPointManagement.vue");

	/**
	 * 관제점 관리 테스트
	 */
	const PointMgmt = () => import("../views/pointManagement/point/PointManagement.vue");

	// -----------------------------------------------------------------------------------------------

	// 운영항목관리
	// - 사용목표관리 화면
	// const Goal = () => import(/* webpackChunkName: 'Goal' */ "../views/operation/Goal.vue");

	// - 고지서 관리 화면
	// const Payment = () => import(/* webpackChunkName: 'Payment' */ "../views/operation/Payment.vue");

	// - 운영예외처리 화면
	// const Baseline = () => import(/* webpackChunkName: 'Payment' */ "../views/operation/Baseline.vue");

	// 관제점관리 과거
	// - 관제점 등록 내역 관리 화면
	// const PointMgmt = () => import(/* webpackChunkName: 'PointMgmt' */ "../views/old/point/PointMgmt.vue");

	// - 관제점 데이터 조회 화면
	// const PointLogs = () => import(/* webpackChunkName: 'PointLogs' */ "../views/old/point/PointLogs.vue");
	const PointLogs = () => import(/* webpackChunkName: 'PointLogs' */ "../views/sys/data-management/point/PointLogs");

	// - 관제점 계산식 관리
	// const WorkflowMgmt = () => import(/* webpackChunkName: 'WorkflowMgmt' */ "../views/old/workflow/WorkflowMgmt.vue");
	const WorkflowMgmt = () => import(/* webpackChunkName: 'WorkflowMgmt' */ "../views/pointManagement/workflow/WorkflowMgmt.vue");

	// - 관제점 대량 등록
	// const BulkPoint = () => import(/* webpackChunkName: 'BulkPoint' */ "../views/pointManagement/point/BulkPoint.vue");

	// 시스템 관리
	// 공통코드
	// const SystemPublicCode = () => import("../views/sys/codes/public/SystemPublicCode.vue"); 기존
	const PublicLargeCode = () => import("../views/sys/codes/public/large/PublicLargeCode.vue");
	const PublicSmallCode = () => import("../views/sys/codes/public/small/PublicSmallCode.vue");

	// 단위코드
	// const SystemUnitCode = () => import("../views/sys/codes/unit/SystemUnitCode.vue"); 기존
	const UnitLargeCode = () => import("../views/sys/codes/unit/large/UnitLargeCode.vue");
	const UnitSmallCode = () => import("../views/sys/codes/unit/small/UnitSmallCode.vue");

	// 건물용도 코드
	const SystemBuildUsageCode = () => import("../views/sys/codes/build-usage/SystemBuildUsageCode.vue");

	// 구역용도 코드
	const SystemZoneUsageCode = () => import("../views/sys/codes/zone-usage/SystemZoneUsageCode.vue");

	// 지역 코드
	const SystemSidoCode = () => import("../views/sys/codes/sido/SystemSidoCode.vue");

	// 설비계통
	const SystemInfoComponent = () => import("../views/system-type/system-info/SystemInfoComponent.vue");

	// 설비용도
	// const EquipUsageComponent = () => import("../views/system-type/equip-usage/EquipUsageComponent.vue");

	// 설비유형
	const EquipTypeComponent = () => import("../views/system-type/equip-type/EquipTypeComponent.vue");

	// 계측기유형
	const MeterTypeComponent = () => import("../views/system-type/meter-type/MeterTypeComponent.vue");

	// 유형별 설정값
	const TypePropertyComponent = () => import("../views/system-type/type-property/TypePropertyComponent.vue");

	// 데이터 모델 관리 > 설비 모델 관리 > 모델관리
	// const EquipComponent = () => import("../views/data-model/equip-model/EquipComponent.vue");

	// - 장비설정 관리 화면
	const Network4 = () => import(/* webpackChunkName: 'Network4' */ "../views/management/facility/Network4.vue");

	// - 데이터 관리 화면
	const RetentionMgmt = () => import(/* webpackChunkName: 'Engineering' */ "../views/sys/data-management/RetentionMgmt.vue");

	// - 엔지니어링 화면
	// const Engineering = () => import( /* webpackChunkName: 'Engineering' */ "../views/old/management/Engineering.vue");

	// - 엔지니어링 화면
	const Engineering = () => import(/* webpackChunkName: 'Engineering' */ "../views/sys/data-management/Engineering.vue");

	// - 스케쥴 관리 화면
	const ScheduleJobs = () => import(/* webpackChunkName: 'Engineering' */ "../views/sys/data-management/ScheduleJobs.vue");

	// - 시스템로그 화면
	const EventLogs = () => import(/* webpackChunkName: 'EventLogs' */ "../views/sys/system-log/EventLogs.vue");

	// - 시스템 설치 화면
	const init = () => import(/* webpackChunkName: 'Engineering' */ "../plugins/extra/Init.vue");

	// - 권한관리
	// const menuSettings = () => import( /* webpackChunkName: 'Engineering' */ "../views/old/management/Settings.vue");

	const menuSettings = () => import(/* webpackChunkName: 'Engineering' */ "../views/sys/menu/Settings.vue");

	// -대시보드관리
	// const AnalysisList = () => import( /* webpackChunkName: 'Engineering' */ "../views/old/management/AnalysisList.vue");
	const AnalysisList = () => import(/* webpackChunkName: 'Engineering' */ "../views/sys/analysisView/AnalysisList.vue");

	// const Realtime = () => import("../views/old/visual/componentsV2/common/components/Realtime.vue");
	// const EnergyMap = () => import("../views/old/visual/componentsV2/common/components/EnergyMap.vue");

	const AlarmList = () => import(/* webpackChunkName: 'AlarmList' */ "../views/sys/alarm/AlarmList.vue");
	const AlarmMgmt = () => import("../views/sys/alarm/AlarmMgmt.vue");

	// 설비제어 > ehp
	const EquipControl = () => import(/* webpackChunkName: 'AlarmList' */ "../views/equip-control/ehp/EhpControlComponent.vue");

	// 설비 제어 > 조명 제어
	const LightContorol = () => import("../views/equip-control/lighting/LightingMgmt.vue");

	// 매뉴 설정
	// const SettingTabs = () => import(/* webpackChunkName: 'Settings' */ "../views/sys/data-management/SettingTabs.vue");

	// 설비 관리 > 설비 기기
	const EquipDeviceComponent = () => import(/* webpackChunkName: 'EquipDevice' */ "../views/equip-management/equip-device/EquipDeviceComponent.vue");

	// 설비 관리 > 설비 관제점 설정
	const EquipPointComponent = () => import(/* webpackChunkName: 'EquipPoint' */ "../views/equip-management/equip-point-mapping/EquipPointComponent.vue");

	// 설비 기기현황 > 설비 성능 분석 > EHP 실내기
	const EquipPerformanceComponent = () => import(/* webpackChunkName: 'EquipPerformance' */ "../views/equip-analysis-new/equip-performance/EquipPerformanceComponent.vue");

	// 환경 정보 > 실내 환경 정보
	const IndoorEnvironmentComponent = () => import(/* webpackChunkName: 'IndoorEnvironment' */ "../views/equip-analysis-new/equip-performance/EquipPerformanceComponent.vue");

	// 사용목표 관리 > 사용목표 관리
	const TargetUsageComponent = () => import(/* webpackChunkName: 'TargetUsageComponent' */ "../views/target-management/TargetUsageComponent.vue");

	// 시스템 관리 > 계통/유형 관리 > 유형별 제어항목 관리
	const TypeControlComponent = () => import(/* webpackChunkName: 'IndoorEnvironment' */ "../views/system-type/type-control/TypeControlComponent.vue");

	// 에너지 소비 예측 > 에너지 소비량 예측
	const EnergyPredictionComponent = () => import(/* webpackChunkName: 'EnergyPredictionComponent' */ "../views/analysis/predict/EnergyPredictionComponent.vue");

	// TEMP
	const TempRouteComponent = () => import(/* webpackChunkName: 'TempRouteComponent' */ "../views/TempRoutePage.vue");

	///////////////////////////////////////////////////////////////////////////////////////////////////////////
	import store from "./PageStore.vue";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	let prevFromPath = "";
	let prevToPath = "";

	const requireAuth = () => (to, from, next) => {
		try {
			if (!to.path.includes("Login") && to.path !== "/") {
				let menus = JSON.parse(localStorage.menus);

				let isMenuAuth = menus.findIndex((v) => v.path?.split('?')[0] === to.path);
				if (isMenuAuth === -1) {
					// this.alertDanger(popupMessages.COMMON_ROUTE_AUTH_ERROR_POPUP_MESSAGE)

					alert(popupMessages.COMMON_ROUTE_AUTH_ERROR_POPUP_MESSAGE);
					// routes.back();
					return next(false);
				}
			}




			if (store.getters.isAuthenticated) return next();

			//주소창을 치고 들어오면 router가 2번 호출 되어 처음 path가 "/"인지 체크 후 prevFromPath로 다시 체크함.
			if (from.path === "/" && to.path === "/") return next();
			else {
				if (prevFromPath === "/" && prevToPath === "/") return next(`/login`);
				else {
					alert(popupMessages.COMMON_NO_CERTIFICATION_INFO_POPUP_MESSAGE);
					pageOptions.pageEmpty = true;
					if (from.path && from.hash) next(`/login?returnPath=${from.path}?${from.hash}`);
					else if (from.path) next(`/login?returnPath=${from.path}`);
					else next(`/login`);
				}
			}
		} finally {
			prevFromPath = from.path;
			prevToPath = to.path;
		}
	};
	////////////////////////////////////////////////////////////////////////////////////////////////////////////

	import UserLogin from "../views/User-login.vue";
	import UserRegister from "../views/User-register.vue";
	import NotFound from "../plugins/extra/Extra-error.vue";
	import pageOptions from "./PageOptions.vue";


  console.log(global.xe);

	const publicRoutes = [
		{ path: "/login", name: "Login", component: UserLogin },
		{ path: "/register", component: UserRegister },
	];

	const authRoutes = [
		{ path: "/", name: "Home", component: {} },

		// JSON 화면
		// { path: "/visual/widget/:viewCode", component: Analysis, props: true },

		// Widget
		{ path: "/visual/analysis/:viewCode", name: "DataVisualization", component: DataVisualization, props: true },

		// 에너지 소비 현황 분석 > 전기
		// - 에너지 소비 현황 분석 > 전기 > 전체 전력소비 현황분석
		// { path: "/analysis/energyAnalsis/elec/:type", component: NewAnalysis, props: true, type: "elecConsumptionAnalysis" },

		// - 에너지 소비 현황 분석 > 전기 > 구역별 전력소비 현황분석
		// { path: "/analysis/energyAnalsis/elec/:type", component: NewAnalysis, props: true, type: "elecConsumptionByZone" },

		// - 에너지 소비 현황 분석 > 전기 > 용도별 전력소비 현황분석
		// { path: "/analysis/energyAnalsis/elec/:type", component: NewAnalysis, props: true, type: "elecConsumptionByUse" },

		// - 에너지 소비 현황 분석 > 전기 > 공정별 전력소비 현황분석
		// { path: "/analysis/energyAnalsis/elec/:type", component: NewAnalysis, props: true, type: "elecConsumptionByFacility" },

		// - 에너지 소비 현황 분석 > 전기 > 설비별 전력소비 현황분석
		// { path: "/analysis/energyAnalsis/elec/:type", component: NewAnalysis, props: true, type: "elecConsumptionBySystemEquip" },

		// - 에너지 소비 현황 분석 > 전기 > 전력피크 분석
		// { path: "/analysis/energyAnalsis/elec/:type", component: NewAnalysis, props: true, type: "elecPeekAnalysis" },

		// 에너지 비용 분석
		// - 에너지 비용 분석 > 전기 에너지비용 분석
		// { path: "/analysis/energyAnalsis/elec/:type", component: NewAnalysis, props: true, type: "elecEnergyCostAnalysis" },

		// - 에너지 비용 분석 > 전기 에너지비용 비교분석
		// { path: "/analysis/energyAnalsis/elec/:type", component: NewAnalysis, props: true, type: "elecEnergyCostCompareAnalysis" },

		// 에너지 목표 분석
		// - 에너지 목표 분석 > 전기 에너지목표 분석
		// { path: "/analysis/energyAnalsis/elec/:type", component: NewAnalysis, props: true, type: "elecGoalAnalysis" },

		// 에너지 원단위 분석
		// - 에너지 원단위 분석 > 에너지 소비량
		// { path: "/analysis/energyAnalsis/energyCost/:type", component: NewAnalysis, props: true, type: "prodCostVerConsumption" },

		// - 에너지 원단위 분석 > 에너지 사용비용
		// { path: "/analysis/energyAnalsis/energyCost/:type", component: NewAnalysis, props: true, type: "prodCostVerUsageCost" },

		// -----------------------------------------------------------------------------------------------
		// LIGHT VERSION 화면
		// - 에너지 소비 현황 분석 > 용도별 소비 현황 분석
		{ path: "/cspnAnal/:type", component: LightAnalysis, props: true, type: "zoneUsage" },

		// - 에너지 소비 현황 분석 > 에너지원별 소비현황 분석
		{ path: "/cspnAnal/:type", component: LightAnalysis, props: true, type: "zoneEnergySource" },

		// - 에너지 소비 현황 분석 > 목표대비 소비량 분석
		{ path: "/cspnAnal/:type", component: LightAnalysis, props: true, type: "useTarget" },

		// - 에너지 소비 현황 분석 > 소비량 예측
		{ path: "/cspnAnal/:type", component: LightAnalysis, props: true, type: "usagePredict" },

		// - 에너지 소비 현황 분석 > 수요처별 소비현황
		{ path: "/cspnAnal/:type", component: LightAnalysis, props: true, type: "energyConsumptByZone" },

		// - 에너지 소비 현황 분석 > 트랜드 분석
		// { path: "/cspnAnal/:type", component: LightAnalysis, props: true, type: "trend" },

		// - 에너지 소비 현황 분석 > 상관 분석
		// { path: "/cspnAnal/:type", component: LightAnalysis, props: true, type: "correlation" },

		// - 에너지 소비 현황 분석 > 영향인자 분석(PCA)
		// { path: "/cspnAnal/:type", component: LightAnalysis, props: true, type: "factor" },

		// - 에너지 소비 현황 분석 > 다중회귀 분석
		// { path: "/cspnAnal/:type", component: LightAnalysis, props: true, type: "multiple-regression" },

		// - 에너지 소비 현황 분석 > 생산 현황 분석
		{ path: "/cspnAnal/:type", component: LightAnalysis, props: true, type: "renewable" },

		// - 에너지 소비 현황 분석 > 원 단위 분석
		{ path: "/cspnAnal/:type/:pageType", component: LightAnalysis, props: true, type: "orgUnit" },

		// - 에너지 비용 분석 > 비용 분석
		{ path: "/cspnAnal/:type/:pageType", component: LightAnalysis, props: true, type: "usageCost" },

		{ path: "/cspnAnal/:type", component: LightAnalysis, props: true, type: "indoorEnvir" },

		// - 운영관리 >  코드관리
		// { path: "/mgt/:type", component: LightManagement, props: true, type: "code" }, 기존
		{ path: "/mgt/:type", component: LightManagement, props: true, type: "code" },

		// - 운영관리 >  구역관리
		{ path: "/mgt/:type", component: LightManagement, props: true, type: "zone" },

		// - 운영관리 >  검교정 이력 관리
		{ path: "/mgt/:type", component: LightManagement, props: true, type: "clbrtnHist" },

		// - 운영관리 >  요금제 정보 관리
		{ path: "/mgt/:type", component: LightManagement, props: true, type: "cost" },

		// - 운영관리 >  휴일관리
		{ path: "/mgt/:type", component: LightManagement, props: true, type: "holiday" },

		// - 관제점 관리 > 관제점 등록 내역관리
		// 기존 { path: "/point/pointMgmt/New", name: "LightPointManagement", component: LightPointManagement, props: true },
		{ path: "/point/pointMgmt", name: "PointMgmt", component: PointMgmt, props: true },

		// - 관제점 관리 > 관제점 계산식 관리
		// { path: "/workflowMgmt", name: "WorkflowMgmt", component: WorkflowMgmt, props: true },
		{ path: "/point/workflowMgmt", name: "WorkflowMgmt", component: WorkflowMgmt, props: true },

		// - 관제점 관리 > 사용자 정의 관제점 설정
		{ path: "/point/custom-point", name: "LightCustromPointManagement", component: LightCustromPointManagement, props: true },
		// -----------------------------------------------------------------------------------------------

		// 운영항목 관리
		// - 운영항목 관리 > 사용목표 관리
		// { path: "/operation/goal", name: "Goal", component: Goal, props: true },

		// - 운영항목 관리 > 고지서 관리
		// { path: "/operation/payment", name: "Payment", component: Payment, props: true },

		// - 운영항목 관리 > 운영 예외처리
		// { path: "/operation/baseline", name: "Baseline", component: Baseline, props: true },

		// 관제점 관리
		// - 관제점 관리 > 관제점 등록 내역관리
		// { path: "/point/pointMgmt/old", name: "PointMgmt", component: PointMgmt, props: true },

		// - 관제점 관리 > 관제점 데이터 조회
		// { path: "/point/pointLogs", name: "PointLogs", component: PointLogs, props: true },
		{ path: "/sys/data-management/pointLogs", name: "PointLogs", component: PointLogs, props: true },

		// - 관제점 관리 > 관제점 대량 등록
		// { path: "/point/bulkPoint", name: "BulkPoint", component: BulkPoint, props: true },

		// 시스템 관리
		// - 시스템 관리 > 코드 관리 > 공통 코드 > 대분류
		// 기존 { path: "/sys/codes/public/:type/New", component: SystemPublicCode, props: true, type: "large" },
		{ path: "/sys/codes/public/large", component: PublicLargeCode, props: true, type: "large" },

		// - 시스템 관리 > 코드 관리 > 공통 코드 > 소분류
		// 기존 { path: "/sys/codes/public/:type", component: SystemPublicCode, props: true, type: "small" },
		{ path: "/sys/codes/public/small", component: PublicSmallCode, props: true, type: "small" },

		// - 시스템 관리 > 코드 관리 > 단위 코드 > 대분류
		// 기존 { path: "/sys/codes/unit/:type", component: SystemUnitCode, props: true, type: "large" },
		{ path: "/sys/codes/unit/large", component: UnitLargeCode, props: true, type: "large" },

		// - 시스템 관리 > 코드 관리 > 단위 코드 > 소분류
		// 기존 { path: "/sys/codes/unit/:type", component: SystemUnitCode, props: true, type: "small" },
		{ path: "/sys/codes/unit/small", component: UnitSmallCode, props: true, type: "small" },

		// - 시스템 관리 > 코드 관리 > 건물용도 코드
		{ path: "/sys/codes/build-usage", name: "SystemBuildUsageCode", component: SystemBuildUsageCode, props: true },

		// - 시스템 관리 > 코드 관리 > 구역용도 코드
		{ path: "/sys/codes/zone-usage", name: "SystemZoneUsageCode", component: SystemZoneUsageCode, props: true },

		// - 시스템 관리 > 코드 관리 > 지역 코드
		{ path: "/sys/codes/sido", name: "sidoCode", component: SystemSidoCode, props: true },

		{ path: "/facility/Network4", component: Network4, props: true },

		// - 시스템 관리 > 장비설정 관리
		{ path: "/facility/Network4", component: Network4, props: true },

		// - 시스템 관리 > 데이터 관리
		{ path: "/management/RetentionMgmt", name: "RetentionMgmt", component: RetentionMgmt, props: true },

		// - 시스템 관리 > 엔지니어링
		// { path: "/management/engineering", name: "Engineering", component: Engineering, props: true },
		{ path: "/sys/data-management/engineering", name: "Engineering", component: Engineering, props: true },

		// - 시스템 관리 > 스케쥴 관리
		{ path: "/management/ScheduleJobs", name: "ScheduleJobs", component: ScheduleJobs, props: true },

		// - 시스템 관리 > 시스템 로그
		{ path: "/management/eventLogs", name: "EventLogs", component: EventLogs, props: true },

		// - 시스템 관리 > 시스템 설치
		{ path: "/management/init", name: "init", component: init, props: true },

		// - 시스템 관리 > 권한관리
		// { path: "/management/menuSettings", name: "menuSettings", component: menuSettings, props: true },
		{ path: "/sys/menu/settings", name: "menuSettings", component: menuSettings, props: true },

		// 알람 발생 이력
		{ path: "/alarm/alarmList", name: "AlarmHistory", component: AlarmList, props: true },

		{ path: "/alarm/alarmMgmt", name: "AlarmMgmt", component: AlarmMgmt, props: true },

		// - 시스템 관리 > 대시보드관리
		// { path: "/management/AnalysisList", name: "AnalysisList", component: AnalysisList , props: true },
		{ path: "/sys/analysis-view/analysisList", name: "AnalysisList", component: AnalysisList, props: true },

		// 계통/유형 관리
		// - 계통/유형 관리 > 설비계통
		{ path: "/system-type/system-info", name: "SystemInfoComponent", component: SystemInfoComponent, props: true },

		// - 계통/유형 관리 > 설비용도
		// { path: "/system-type/equip-usage", name: "EquipUsageComponent", component: EquipUsageComponent, props: true },

		// - 계통/유형 관리 > 설비유형 > 설비 유형
		{ path: "/system-type/equip-type", name: "EquipTypeComponent", component: EquipTypeComponent, props: true },

		// - 계통/유형 관리 > 계측기유형
		{ path: "/system-type/meter-type", name: "MeterTypeComponent", component: MeterTypeComponent, props: true },

		// - 계통/유형 관리 > 설비유형 > 유형별 설정값 관리
		{ path: "/system-type/type-property", name: "TypePropertyComponent", component: TypePropertyComponent, props: true },

		// - 계통/유형 관리 > 유형별 제어항목 관리
		{ path: "/system-type/type-control", name: "TypeControlComponent", component: TypeControlComponent, props: true },

		// - 설비 관리 > 설비 기기
		{ path: "/equip-manage/equip-device", name: "EquipDeviceComponent", component: EquipDeviceComponent, props: true },

		// - 설비 관리 > 설비 관제점 설정
		{ path: "/equip-manage/equip-point", name: "EquipPointComponent", component: EquipPointComponent, props: true },

		// 데이터 모델 관리 > 설비모델 관리 > 모델관리
		// { path: "/data-model/equip/:type", name: "EquipComponent", component: EquipComponent, props: true, type: "model" },

		{ path: "/equip-control/ehp", name: "EquipControl", component: EquipControl, props: true },

		// 건물 운영 관리 > 조명 관리
		{ path: "/equip-control/light", name: "LightControl", component: LightContorol, props: true },

		// 설비 기기현황 > 설비 성능 분석 > EHP 실내기
		{ path: "/equip-analysis/ehp", name: "EquipPerformanceComponent", component: EquipPerformanceComponent, props: {systemTypeCode: 'Vnt', name: `EquipPerformance`}, key: "EquipPerformance" },

		// 환경 정보 > 실내 환경 정보
		{ path: "/enviroment-info/indoor", name: "IndoorEnvironmentComponent", component: IndoorEnvironmentComponent, props:  { systemTypeCode: 'Env', name: 'IndoorEnviron'}, key: "IndoorEnviron" },

		// 사용목표 관리 > 사용목표 관리
		{ path: "/target-management/usage", name: "TargetUsageComponent", component: TargetUsageComponent, props: true },

		// 에너지 소비 예측 > 에너지 소비량 예측
		{ path: "/energy-prediction/prediction", name: "EnergyPredictionComponent", component: EnergyPredictionComponent, props: true, type: "usagePredict" },

		{ path: "/temp", name: "TempRoutePage", component: TempRouteComponent, props: true },

		// 실시간 화면 링크
		// { path: "/realtime", name: "Realtime", component: Realtime, props: true },

		// { path: "/energyMap", name: "EnergyMap", component: EnergyMap, props: true },

		// 메뉴설정
		// { path: "/management/settings", name: "Settings", component: SettingTabs, props: true },
	];

	let routes = publicRoutes.concat(authRoutes.map((v) => Object.assign(v, { beforeEnter: requireAuth() })));
	routes.push({ path: "*", component: NotFound.routeSet(routes) });

	export default routes;
</script>

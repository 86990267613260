<template>
	<div>
			<div class="row form-group">
				<div class="col-lg-6">
					<label><trans>드라이버 유형</trans></label>
					<input type="text" class="form-control" v-model="drvProp.driverType" readonly />
				</div>
				<div class="col-lg-3">
					<label><trans>버전</trans></label>
					<input type="text" class="form-control" v-model="drvProp.version" readonly />
				</div>
				<div class="col-lg-3">
					<label><trans>CT</trans></label>
					<input type="text" class="form-control" v-model="drvProp.ct" readonly />
				</div>
			</div>
			<div class="row form-group">
				<div class="col-lg-6">
					<label>
						<trans>속성</trans>
						<span class="badge m-l-10 bg-lime" v-if="drvProp.isVirtual"> Virtual </span>
						<span class="badge m-l-10 bg-orange" v-if="drvProp.ptType === 'SUM'"> Sum </span>
					</label>
					<input type="text" class="form-control" v-model="drvProp.propertyName" readonly />
				</div>
				<div class="col-lg-6">
					<label><trans>항목유형</trans></label>
					<input type="text" class="form-control" :value="drvProp.direction == 'Input' ? $t('계측') : $t('제어')" readonly />
				</div>
			</div>
			<div class="row form-group">
				<!-- <div class="col-lg-3">
        <label><trans> 관제점 유형 </trans></label>
        <input type="text" class="form-control" :value="drvProp.isVirtual ? 'Virtual': 'Normal'" readonly />
      </div> -->
				<div class="col-lg-6">
					<label><trans>취합 유형</trans></label>
					<!-- <input type="text" class="form-control" v-model="drvProp.ptType" readonly /> -->
					<select class="form-control" v-model="drvProp.ptType" disabled>
						<option value="LST"> Last </option>
						<option value="SUM"> Sum </option>
						<option value="MAX"> Max </option>
						<option value="AVG"> Avg </option>
					</select>
				</div>
				<div class="col-lg-6">
					<label><trans>값 유형</trans></label>
					<select class="form-control" v-model="drvProp.valueType" disabled>
						<option value="analog"> {{ $t("아날로그") }} </option>
						<option value="digital"> {{ $t("디지털") }} </option>
					</select>
				</div>
			</div>
			<div class="row form-group">
				<div class="col-lg-4">
					<label><trans>최소값</trans></label>
					<input type="text" class="form-control" v-model="drvProp.min" readonly />
				</div>
				<div class="col-lg-4">
					<label><trans>최대값</trans></label>
					<input type="text" class="form-control" v-model="drvProp.max" readonly />
				</div>
				<div class="col-lg-4">
					<label><trans>단위</trans></label>
					<valid-input :inputType="'text'" :inputValue.sync="drvProp.unit" :rules="rules.DASHBOARD_WIDGET_UNIT_RULE" :classList="'form-control'" :NonStatus="true"> </valid-input>
				</div>
			</div>

			<div class="row form-group">
				<div class="col-lg-3">
					<label><trans>데이터 유형</trans></label>
					<input type="text" class="form-control" v-model="drvProp.dataType" readonly />
				</div>
				<div class="col-lg-3">
					<label><trans>frame</trans></label>
					<input type="text" class="form-control" v-model="drvProp.frame" readonly />
				</div>
				<div class="col-lg-3">
					<label><trans>fc</trans></label>
					<input type="text" class="form-control" v-model="drvProp.fc" readonly />
				</div>
				<div class="col-lg-3">
					<label><trans>scale</trans></label>
					<input type="text" class="form-control" v-model="drvProp.scale" readonly />
				</div>
			</div>

			<div class="row form-group">
				<div class="col-lg-12">
					<label><trans>설명</trans></label>
					<input type="text" class="form-control" v-model="drvProp.remark" readonly />
				</div>
			</div>
	</div>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";
	import * as rules from "@src/consts/ruleConsts.js";

	export default {
		props: ["options"], // "driverType", "version", "direction", "propertyName"
		data() {
			return {
				drvProp: {},
				rules
			};
		},
		watch: {
			propertyName: function() {
				this.initSetting();
			},
		},
		mounted() {
			this.initSetting();
		},
		methods: {
			initSetting() {
				this.drvProp = {};

				let { driverType, version, direction, propertyName } = this.options;

				// 장비 드라이버 먼저 조회
				backEndApi.driver.getDriverProp(driverType, version).then(({ data }) => {
					if (this.$err(data)) return;

					this.drvProp = data.find((v) => v.direction == direction && v.propertyName == propertyName);
				});
			},
		},
	};
</script>

<template>
  <div class="row form-group">
    <div :class="['col-md-' + tCol]">
      <h1 class="page-header m-b-0">
        <i class="fa" :class="[mainIcon]"></i>
        <trans :key="mainTitle">{{ mainTitle }}</trans>
        <small> <trans :key="mainDesc" > {{ mainDesc }}</trans></small>
      </h1>
    </div>
    <div :class="['col-md-' + bCol]">
      <div class="pull-right">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleIcon",
  props: ["title", "icon", "desc", "col"],
  computed: {
    mainTitle() {
      if (this.title) return this.title;
      return this.$store.getters.mainMenu && this.$store.getters.mainMenu.title ? this.$store.getters.mainMenu.title : this.title;
    },
    mainDesc() {
      if (this.desc) return this.desc;
      return this.$store.getters.mainMenu && this.$store.getters.mainMenu.desc ? this.$store.getters.mainMenu.desc : this.desc;
    },
    mainIcon() {
      if (this.icon) return this.icon;
      return this.$store.getters.mainMenu && this.$store.getters.mainMenu.icon ? this.$store.getters.mainMenu.icon : this.icon;
    },
    tCol() {
      return this.col ? this.col : 8;
    },
    bCol() {
      return this.col ? 12 - this.col : 4;
    },
  },
};
</script>

<template>
	<div>
		<vvo v-slot="v">
			<div class="mt-1 text-right">
				<button class="ml-3 btn btn-primary" @click="onValidate(v, save)">저장</button>
			</div>
			<div class="text-left font-weight-bold p-5 d-flex" style="font-size: 12px;"><i class="material-icons mr-1">info</i><span class="h4 mb-0">{{$t('관제점 정보')}}</span></div>
			<b-card>
				<detail-box :ref="'detail'" :data="newPointData" :IsNEW="true" />
			</b-card>
			<div class="text-left font-weight-bold p-5 d-flex mt-3" style="font-size: 12px;"><i class="material-icons mr-1">info</i><span class="h4 mb-0">{{$t('관제점 설정')}}</span></div>
			<b-card>
				<detail-setting :ref="'detail'" :data="newPointData" />
			</b-card>
		</vvo>
	</div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import detailBox from "./GridDetail.vue";
import detailSetting from "./GridDetailSetting.vue";
import * as popupMessages from "@src/consts/popupMessageConsts";
import AppErrorHandler from "@src/appErrorHandler";

export default {
	components: { detailBox, detailSetting },
	data() {
		return {
			newPointData: {
				isVirtual: "", // 관제점 유형
				ptTypeCode: "", // 집계유형
				ptGroup: "", // 관제점 그룹
				ptAddr: "", // 관제점 주소
				ptName: "", // 관제점명
				energySourceCode: "", // 에너지원
				applyZoneIdx: "", // 구역
				itemUseageCode: "", // 관제점 용도
				renewTypeCode: "", // 에너지 생산유형
				equipTypeCode: "", // 설비기기 유형
				energySplyTypeCode: "", // 에너지 공급 유형
				unitLargeCode: "", // 단위 대분류
				unitSmallCode: "", // 단위 소분류
				filterMinValue: "0", // 필터링 최소값
				filterMaxValue: "0", // 필터링 최대값
				fixValue: "0", // 고정값
				enabled: "", // 사용여부
				agrgtnYn: "", // 집계여부
				isCov: "", // 변경시 반영
				isIgnoreZero: "", // 0값 무시
			},
			valCheckCategory: [
				{ key: "isVirtual", name: global.xe.$t("관제점 유형") },
				{ key: "ptTypeCode", name: global.xe.$t("집계 유형") },
				{ key: "ptGroup", name: global.xe.$t("관제점 그룹") },
				{ key: "ptAddr", name: global.xe.$t("관제점 주소") },
				{ key: "ptName", name: global.xe.$t("관제점명") },
			],
		};
	},
	unmounted() { },
	methods: {
		closeModal() {
			console.log("close modal");
			this.$emit("closeModal");
		},
		async save() {
			const validResult = this.valCheck(this.newPointData);

			try {

				if (validResult.isError)
					throw Error(validResult.message);

				const check = await this.alertQuestion(popupMessages.COMMON_SAVE_CONFIRM_POPUP_MESSAGE);

				if (!check.value) return;

				const result = await backEndApi.pointInfo.insertPointInfo(this.newPointData);

				console.log(`관제점 저장 결과 ==> ${result}`);
				console.log(this.newPointData);
				this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
				this.$emit("reSearch");
				this.closeModal();


			} catch (err) {

				const appErrorHandler = new AppErrorHandler(err);

				if (validResult.isError)
					appErrorHandler.setOtherMessage(validResult.message, this.alertWarning);
				else
					appErrorHandler.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger);

				appErrorHandler.errHandling();
			}
		},
		valCheck(pointData) {
			for (let i = 0; i < this.valCheckCategory.length; i++) {
				const val = this.valCheckCategory[i];
				console.log(pointData[val.key]);
				if (pointData[val.key] === "" || pointData[val.key] === null) {
					const message = val.name + popupMessages.POINT_INPUT_ESSENTIAL_VALID_POPUP_MESSAGE;
					return { isError: true, message: message };
				}
			}

			return { isError: false };
		},
	},
};
</script>

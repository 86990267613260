<template>
  <div class="top-menu" v-bind:class="{ 'd-block': pageOptions.pageMobileTopMenu }">
    <top-menu-nav></top-menu-nav>
  </div>
</template>

<script>
import TopMenuNav from "./TopMenuNav.vue";
//import PageOptions from "../../config/PageOptions.vue";

export default {
  name: "TopMenu",
  components: {
    TopMenuNav,
  },
  data() {
    return {
      //pageOptions: PageOptions,
    };
  },
};
</script>

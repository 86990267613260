<template>
	<vvo v-slot="v">
		<div class="row form-group">
			<div class="col-lg-12">
				<label> <trans>워크플로우명</trans> </label>
				<valid-input
					:vid="'워크플로우명'"
					:inputType="'text'"
					:inputValue.sync="newWorkflow.name"
					:rules="rules.POINT_FORMULA_WORKFLOW_NAME_RULE"
					:errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 30)"
					:placeholder="$t('워크플로우명을 입력하세요.')"
				></valid-input>

				<!-- <vvp rules="required" v-slot="{ errors, classes }" name="Workflow Name">
          <input type="text" class="form-control" :class="classes" placeholder="워크플로우명을 입력하세요." v-model="newWorkflow.name" maxlength="50" />
          <span class="invalid-feedback">{{ errors[0] }}</span>
        </vvp> -->
			</div>
		</div>
		<div class="row form-group">
			<div class="col-12">
				<label> <trans>설명</trans> </label>
				<valid-input :inputType="'text'" :inputValue.sync="newWorkflow.comment" :rules="rules.POINT_FORMULA_WORKFLOW_DETAIL_RULE" :NonStatus="true"></valid-input>
				<!-- <input type="text" class="form-control" v-model="newWorkflow.comment" /> -->
			</div>
		</div>
		<div slot="modal-footer">
			<button class="btn btn-sm btn-primary pull-right" @click="onValidate(v, onSaveWorkflow)"><i class="fa fa-save"></i><trans>저장</trans></button>
		</div>
	</vvo>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		props: ["save-done"],
		data() {
			return {
				newWorkflow: {
					name: null,
					comment: null,
				},
				rules,
				validMessage,
			};
		},
		methods: {
			onSaveWorkflow() {
				if (this.isEmpty(this.newWorkflow.name)) {
					this.alertNoti(popupMessages.POINT_CALCULATION_WORKFLOW_NAME_VALID_POPUP_MESSAGE);
					return;
				}

				this.alertQuestion(popupMessages.POINT_CALCULATION_WORKFLOW_ADD_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;
					// 새로운 워크플로우 저장
					this.newWorkflow.updDt = new Date().format("yyyy-MM-dd");
					this.newWorkflow.workflow = { name: this.newWorkflow.name, uplType: "Start", children: [] };
					this.newWorkflow.workflow.children.push({
						name: "Caculation",
						uplType: "Group",
						children: [],
					});
					// TODO: 기본 그룹까지는 만들어 놓음..  트리에서 UPL 추가가 없음...

					backEndApi.workflow.createWorkflow(this.newWorkflow).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
						this.$emit("save-done");
					});
				});
			},
		},
	};
</script>

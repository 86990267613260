var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.pageOptions.pageEmpty)?_c('div',{key:_vm.locale,class:{ 'page-content-full-height': _vm.pageOptions.pageContentFullHeight }},[_c('div',{staticClass:"content-full",class:{ 'content-full-width': _vm.pageOptions.pageContentFullWidth, 'content-inverse-mode': _vm.pageOptions.pageContentInverseMode }},[_c('router-view')],1)]):_c('div',{key:_vm.locale,staticClass:"fade page-sidebar-fixed page-header-fixed show page-container",class:{
		'page-sidebar-minified': _vm.pageOptions.pageSidebarMinified,
		'page-content-full-height': _vm.pageOptions.pageContentFullHeight,
		'page-without-sidebar': _vm.pageOptions.pageWithoutSidebar,
		'page-with-right-sidebar': _vm.pageOptions.pageWithRightSidebar,
		'page-with-two-sidebar': _vm.pageOptions.pageWithTwoSidebar,
		'page-with-wide-sidebar': _vm.pageOptions.pageWithWideSidebar,
		'page-with-light-sidebar': _vm.pageOptions.pageWithLightSidebar,
		'page-with-top-menu': _vm.pageOptions.pageWithTopMenu,
		'page-sidebar-toggled': _vm.pageOptions.pageMobileSidebarToggled,
		'page-right-sidebar-toggled': _vm.pageOptions.pageMobileRightSidebarToggled || _vm.pageOptions.pageRightSidebarToggled,
		'page-right-sidebar-collapsed': _vm.pageOptions.pageRightSidebarCollapsed,
		'has-scroll': _vm.pageOptions.pageBodyScrollTop,
	},style:(_vm.contentFontColor)},[_c('Header'),(_vm.pageOptions.pageWithTopMenu)?_c('TopMenu'):_vm._e(),(!_vm.pageOptions.pageWithoutSidebar)?_c('Sidebar'):_vm._e(),(_vm.pageOptions.pageWithTwoSidebar)?_c('SidebarRight'):_vm._e(),_c('ToolBar'),_c('div',{staticClass:"content",style:([_vm.contentBgColor, _vm.pageOptions.pageMobileSidebarToggled ? { marginLeft: '220px' } : {}])},[_c('router-view')],1),_c('notifications',{staticClass:"noti-right",attrs:{"group":"noti-right","position":"top right","max":5,"speed":500,"duration":5000}}),_c('notifications',{staticClass:"noti-bottom",attrs:{"group":"noti-bottom","position":"bottom right","max":5,"speed":500,"duration":5000}}),(_vm.pageOptions.pageWithFooter)?_c('Footer'):_vm._e(),_c('globalTimerLogout')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="register register-with-news-feed">
    <vvo v-slot="{ passes }">
      <div class="news-feed">
        <div class="news-image" style="background-image: url(/assets/xems/common/bg/bg_signup.jpg)"></div>
        <div class="news-caption"></div>
      </div>
      <div class="right-content">
        <h1 class="register-header">
          {{$t('회원가입')}}
          <small>{{$t('새로운 계정을 생성하세요.')}}</small>
        </h1>
        <!-- 입력폼 -->
        <div class="register-content">
          <label class="control-label" v-show="masterGroup && masterGroup.length > 1">{{$t('소속')}}</label>
          <div class="form-group m-b-15" v-show="masterGroup && masterGroup.length > 1">
            <select class="form-control form-control-lg" v-model="masterCode">
              <option value="" disabled>{{$t('소속선택')}}</option>
              <option v-for="(m, i) in masterGroup" :key="i" :value="m.masterCode"> {{ m.brand }} </option>
            </select>
          </div>
          <label class="control-label">{{$t('사용자명')}}</label>
          <div class="row row-space-10">
            <div class="col-md-12 m-b-15">
              <input type="text" class="form-control" placeholder="Name" v-model="userName" maxlength="50" />
            </div>
          </div>
          <label class="control-label">{{$t('사용자 아이디')}} <span class="text-danger">*</span></label>
          <div class="row m-b-15">
            <div class="col-md-12">
              <vvp rules="required" v-slot="{ errors, classes }" name="User ID">
                <input type="text" class="form-control" :class="classes" placeholder="사용자 아이디" v-model="userId" @change="isDuplicateUserId" @keydown="isDuplicateUserId" maxlength="50" />
                <div v-show="dupUserIdError" class="invalid-xe-error">{{ dupUserIdError }}</div>
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </vvp>
            </div>
          </div>
          <label class="control-label">{{$t('이메일')}} <span class="text-danger">*</span></label>
          <div class="row m-b-15">
            <div class="col-md-12">
              <vvp rules="required|email" v-slot="{ errors, classes }" name="Email">
                <input type="email" class="form-control" :class="classes" placeholder="Email address" v-model="email" @change="isDuplicateEmail" @keydown="isDuplicateEmail" maxlength="50" />
                <div v-show="dupEmailError" class="invalid-xe-error">{{ dupEmailError }}</div>
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </vvp>
            </div>
          </div>
          <label class="control-label">{{$t('비밀번호')}} <span class="text-danger">*</span></label>
          <div class="row m-b-15">
            <div class="col-md-12">
              <vvp rules="required|min:6|max:14|password" v-slot="{ errors, classes }" name="Password">
                <input type="password" class="form-control" :class="classes" placeholder="Password" v-model="userPwd" maxlength="14" />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </vvp>
            </div>
          </div>
          <label class="control-label">{{$t('비밀번호 (확인용)')}} <span class="text-danger">*</span></label>
          <div class="row m-b-15">
            <div class="col-md-12">
              <vvp rules="required|confirmed:Password" v-slot="{ errors, classes }" name="Confirm Password">
                <input type="password" class="form-control" :class="classes" placeholder="Re-enter password" v-model="confirmPwd" maxlength="14" />
                <p class="m-0">* Please write a combination of English case letters + digits + special characters in less than 14 characters.</p>
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </vvp>
            </div>
          </div>
          <label class="control-label">{{$t('전화번호')}} </label>
          <div class="row m-b-15">
            <div class="col-md-12">
              <input type="text" class="form-control" placeholder="Enter phone number (optional)" v-model="phone" maxlength="20" />
            </div>
          </div>
          <div class="register-buttons">
            <button type="submit" class="btn btn-primary btn-block btn-lg" @click="passes(signUp)">{{$t('회원가입')}}</button>
          </div>
          <div class="m-t-20 m-b-40 p-b-40 text-inverse">Already a member? Click <a href="/#/login">here</a> to login.</div>
          <hr />
          <p class="text-center">&copy; {{ master.copy }}</p>
        </div>
      </div>
    </vvo>
  </div>
</template>

<style scoped>
.invalid-xe-error {
  color: #ff1b30;
}
</style>

<script>
import backEndApi from "@api/backEndApi.js";
import * as popupMessages from "@src/consts/popupMessageConsts";
//import PageOptions from "../config/PageOptions.vue";

export default {
  data() {
    return {
      masterCode: "",
      userId: "",
      userName: "",
      email: "",
      userPwd: "",
      confirmPwd: "",
      phone: "",

      // 중복체크 제어
      to_master: null,
      to_dup_id: null,
      to_dup_email: null,
      dupUserIdError: "",
      dupEmailError: "",
			popupMessages
    };
  },
  computed: {
    masterGroup() {
      let obj = this.$store.getters.masterGroup;
      if (obj && Array.isArray(obj)) return obj.filter((v) => v.policy.UserRegister == true);
      else return [];
    },
    master() {
      if (this.masterCode && this.masterGroup && Array.isArray(this.masterGroup)) {
        let match = this.masterGroup.find((v) => v.masterCode == this.masterCode);
        if (match) return match;
      }
      return { masterCode: "", desc: "", theme: "", version: "", logo: "", loginBg: "", brand: "", description: "", copy: "" };
    }
  },
  created() {
    this.pageOptions.pageEmpty = true;
    document.body.className = "bg-white";

    // 회원가입할 마스터(도메인)이 없다면 루트로 화면 다시 이동
    if (this.masterGroup.length == 0) this.$router.push("/");
  },
  beforeRouteLeave(to, from, next) {
    this.pageOptions.pageEmpty = false;
    document.body.className = "";

    clearTimeout(this.to_dup_id);
    clearTimeout(this.to_dup_email);
    
    next();
  },
  beforeDestroy() {
    // 중복아이디 체크 취소..
    clearTimeout(this.to_dup_id);
    clearTimeout(this.to_dup_email);
  },
  mounted() {
    this.initSetting();
  },
  methods: {
    initSetting() {
      if (this.$route.query.masterCode != null && this.$route.query.masterCode != "") {
        this.masterCode = this.$route.query.masterCode;
      } else {
        this.to_master = setInterval(() => {
          if (this.masterCode === "") {
            if (this.masterGroup && this.masterGroup.length > 0) this.masterCode = this.masterGroup[0].masterCode;
          } else {
            clearInterval(this.to_master);
          }
        }, 100);
      }
    },
    isDuplicateUserId() {
      if (!this.isEmpty(this.userId)) {
        this.dupUserIdError = "";
        if (this.to_dup_id) clearTimeout(this.to_dup_id);
        this.to_dup_id = setTimeout(() => {
          backEndApi.login.isDuplicateUserId(this.userId).then(({ data }) => {
            if(this.$err(data)) return;
            this.dupUserIdError = data.isDup ? this.$t("이미 존재하는 사용자 아이디 입니다.") : "";
          });
        }, 500);
      }
    },
    isDuplicateEmail() {
      if (!this.isEmpty(this.email)) {
        this.dupEmailError = "";
        if (this.to_dup_email) clearTimeout(this.to_dup_email);
        this.to_dup_email = setTimeout(() => {
          backEndApi.login.isDuplicateEmail(this.email).then(({ data }) => {
            if(this.$err(data)) return;
            this.dupEmailError = data.isDup ? this.$t("이미 존재하는 Email입니다.") : "";
          });
        }, 700);
      }
    },
    signUp() {
      let userRecommend = {
        masterCode: this.masterCode,
        userId: this.userId,
        userName: this.userName,
        email: this.email,
        userPwd: this.userPwd,
        phone: this.phone,
      };

      this.alertQuestion(popupMessages.REGISTER_SIGNUP_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        backEndApi.login.insertUserRecommend(userRecommend).then(({ data }) => {
          if (this.$err(data)) {
            this.alertDanger(popupMessages.REGISTER_FAILURE_POPUP_MESSAGE);
            return;
          }

          this.alertNoti(popupMessages.REGISTER_REQUEST_POPUP_MESSAGE).then(() => {
            setTimeout(() => {
              this.$router.push({ path: "/login" });
            }, 500);
          });
        });
      });
    },
  },
};
</script>

<template>
  <div v-xe-pm.E.A>
    <div class="row" style="margin-top:-20px;margin-bottom:20px;">
      <div class="col-12">
        <ul class="xe-tab-bg">
          <li class="xe-tab" v-for="(tab, i) in tabs" :key="i">
            <a href="javascript:;" class="xe-tab-default" :class="{ 'xe-tab-active': tab.show }" @click="onActiveTab(tab)"
              v-if="(tab.id == 'engineering' || tab.id == 'bulk-point') ? checkRole({E:'E'}) : true"
            > 
              <i class="fa fa-fw fa-md m-r-4" :class="tab.icon" v-if="tab.icon"></i> 
              {{ $t(tab.title) }} 
            </a>
          </li>
        </ul>
      </div>
    </div>
    
    <template v-for="(tab) in tabs" >
      <point-mgmt          v-if="tab.id == 'point-mgmt' && tab.show" :key="tab.id" />
      <network4       v-else-if="tab.id == 'network4' && tab.show" :key="tab.id" />
      <workflow-mgmt  v-else-if="tab.id == 'workflow-mgmt' && tab.show" :key="tab.id" />
      <alarm-mgmt     v-else-if="tab.id == 'alarm-mgmt' && tab.show" :key="tab.id" />
      <alarm-list     v-else-if="tab.id == 'alarm-list' && tab.show" :key="tab.id"/>
      <point-logs     v-else-if="tab.id == 'point-logs' && tab.show" :key="tab.id" />
      <engineering   v-xe-pm.E  v-else-if="tab.id == 'engineering' && tab.show" :key="tab.id" />
      <event-logs    v-xe-pm.E  v-else-if="tab.id == 'event-logs' && tab.show" :key="tab.id" />
      <!-- <schedule-jobs v-xe-pm.E  v-else-if="tab.id == 'schedule-jobs' && tab.show" :key="tab.id" /> -->
      <retention-mgmt v-xe-pm.E  v-else-if="tab.id == 'retention-mgmt' && tab.show" :key="tab.id" />
      <!-- <bulk-point  v-xe-pm.E  v-else-if="tab.id == 'bulk-point' && tab.show" :key="tab.id" /> -->
      <!-- <live        v-xe-pm.E  v-else-if="tab.id == 'live' && tab.show" :key="tab.id" /> -->
      <init       v-xe-pm.E  v-else-if="tab.id == 'init' && tab.show" :key="tab.id" />
    </template>
  </div>
</template>

<script>
import PointMgmt      from "@src/views/pointManagement/point/PointMgmt.vue";
import Network4       from "@src/views/management/facility/Network4.vue";
import WorkflowMgmt   from "@src/views/pointManagement/workflow/WorkflowMgmt.vue";
import AlarmMgmt      from "@src/views/sys/alarm/AlarmMgmt.vue";
import AlarmList      from "@src/views/sys/alarm/AlarmList.vue";
// import BulkPoint      from "@src/views/pointManagement/point/BulkPoint.vue";
import PointLogs      from "@src/views/sys/data-management/point/components/PointLog.vue";
import Engineering    from "@src/views/sys/data-management/Engineering.vue";
import EventLogs      from "@src/views/sys/system-log/EventLogs.vue";
// import ScheduleJobs   from "@src/views/sys/data-management/ScheduleJobs.vue";
import RetentionMgmt  from "@src/views/sys/data-management/RetentionMgmt.vue";
// import Live           from "@src/views/pointManagement/point/Live.vue";
import Init          from "./Init.vue";

export default {
  components: {
    PointMgmt,
    Network4,
    WorkflowMgmt,
    AlarmMgmt,
    AlarmList,
    PointLogs,
    Engineering,
    EventLogs,
    // ScheduleJobs,
    // BulkPoint,
    RetentionMgmt,
    // Live,
    Init
  },
  data() {
    return {
      tabs: [
        { title: "관제점 관리",     id:'point-mgmt',      icon: "fa-asterisk", show: true },
        { title: "장비",            id:'network4',        icon: "fa-plug", show: false },
        { title: "워크플로우",      id:'workflow-mgmt',   icon: "fas fa-calculator", show: false },
        { title: "알람",            id:'alarm-mgmt',      icon: "fa-bell", show: false },
        { title: "알람로그",        id:'alarm-list',      icon: "fa-bullhorn", show: false },
        
        { title: "관제점 대량등록", id:'bulk-point',      icon: "fa-truck", show: false },
        { title: "관제점 로그",     id:'point-logs',      icon: "fa-info-circle", show: false },
        { title: "엔지니어링",      id:'engineering',     icon: "fa-wrench", show: false },
        { title: "이벤트로그",      id:'event-logs',      icon: "fa-list",        show: false },
        { title: "스케쥴 작업",     id:'schedule-jobs',   icon: "fa-wrench",        show: false },
        { title: "자료보관",        id:'retention-mgmt',  icon: "fa-archive", show: false },
        { title: "라이브",          id:'live',            icon: "fa-fighter-jet", show: false },
        { title: "설치",            id:'init',           icon: "fa-wrench", show: false },
        
      ],
    };
  },
  mounted(){

  },
  methods: {
    onCheckRole(tab){
      if(
        tab.id == 'bulk-point' ||
        tab.id == 'engineering' ||
        tab.id == 'event-logs' ||
        tab.id == 'schedule-jobs' ||
        tab.id == 'retention-mgmt' ||
        tab.id == 'live' ||
        tab.id == 'setup'
      ) {
        return this.checkRole( {E:'E'} );
      }

      return true;
    },
    onActiveTab(cur) {
      this.tabs.forEach((tab) => {
        tab.show = cur == tab;
      });
    },
  },
};
</script>

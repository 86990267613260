
/** 트리 No Search 메시지 */
export const COMMON_TREE_NOSEARCH_MESSAGE = "조회 결과가 존재하지 않습니다.";


/** 구역 트리 PlaceHolder 메시지 */
export const ZONE_TREE_PLACEHOLDER_MESSAGE = "구역을 선택하세요.";

/** 구역 트리 하위가 없을 경우 */
export const ZONE_TREE_NOCHILDREN_MESSAGE = "하위 구역이 존재하지 않습니다.";


/** 관제점 트리 PlaceHolder 메시지 */
export const POINT_TREE_PLACEHOLDER_MESSAGE = "관제점을 선택하세요.";

/** 관제점 트리 하위가 없을 경우 */
export const POINT_TREE_NOCHILDERN_MESSAGE = "하위 관제점이 존재하지 않습니다.";


/** 단위 트리 PlaceHolder 메시지 */
export const UNIT_TREE_PLACEHOLDER_MESSAGE = "단위를 선택하세요.";

/** 단위 트리 하위가 없을 경우 */
export const UNIT_TREE_NOCHILDERN_MESSAGE = "하위 단위가 존재하지 않습니다.";


/** 원본단위 트리 PlaceHolder 메시지 */
export const ORG_UNIT_TREE_PLACEHOLDER_MESSAGE = "원본단위를 선택하세요.";

/** 원본단위 트리 하위가 없을 경우 */
export const ORG_UNIT_TREE_NOCHILDERN_MESSAGE = "하위 원본단위가 존재하지 않습니다.";


/** 리소스 키 트리 PlaceHolder 메시지 */
export const RESOURCE_KEY_TREE_PLACEHOLDER_MESSAGE = "리소스 키를 선택하세요.";

/** 리소스 키 트리 하위가 없을 경우 */
export const RESOURCE_KEY_TREE_NOCHILDERN_MESSAGE = "하위 리소스 키가 존재하지 않습니다.";
<template>
	<div class="p-t-10">
		<span :class="{ 'text-black-transparent-6': !tableInfo.isShow }">
			<span> {{ $t(tableInfo.title) }} </span>
			<button v-b-tooltip.hover :title="$t('새로고침')" class="btn btn-circle btn-xs" :disabled="!tableInfo.isShow"><i class="fa fa-redo" @click="onRefreshTable"></i></button>
			<span v-b-tooltip.hover :title="tableInfo.sDt.format('yyyy-MM-dd HH:mm:ss') + ' ~ ' + tableInfo.eDt.format('yyyy-MM-dd HH:mm:ss')">
				{{ tableInfo.sDt ? tableInfo.sDt.format(tableInfo.dtFormat) : "" }} {{ tableInfo.eDt ? " ~ " + tableInfo.eDt.format(tableInfo.dtFormat) : "" }}
			</span>

			<!-- <span class="pull-right m-l-10" v-if="ptAddr">
        <button class="btn btn-xs" @click="onRunSampling" v-if="tableInfo.searchType == 7"><i class="fa fa-retweet" /></button>
        <button class="btn btn-xs" @click="onRunSummary" v-else><i class="fa fa-retweet" /></button>
      </span> -->
			<span class="pull-right" :class="{ 'text-danger': viewTableInfo.rows.length >= max }"> {{ viewTableInfo.rows.length >= max ? "Max" : $t("데이터수") }} : {{ viewTableInfo.rows.length }} </span>
		</span>

		<div class="row" v-if="isQuery">
			<div class="col-12">
				<textarea class="form-control" :value="query.delete" />
			</div>
		</div>
		<div class="row" v-if="isQuery && pt.ptType == 'LST'">
			<div class="col-12">
				<textarea class="form-control" :value="query.lst" />
			</div>
		</div>
		<div class="row" v-if="isQuery && pt.ptType != 'LST'">
			<div class="col-12">
				<textarea class="form-control" :value="query.etc" />
			</div>
		</div>

		<div class="bg-white" :class="[viewTableInfo.styleClass, { 'panel-loading': tableInfo.loading }]" :style="{ 'max-height': viewTableInfo.height }">
			<vue-good-table
				v-bind="merge(GRID_OPT, { rowStyleClass: onRowStyleClass })"
				:columns="viewTableColumns"
				:rows="viewTableInfo.rows"
				:pagination-options="{ enabled: false }"
				:fixed-header="true"
				:max-height="viewTableInfo.height"
			>
				<div slot="emptystate" style="text-align: center;">
					{{$t('조회된 결과가 없습니다.')}}
				</div>
				<template slot="table-row" slot-scope="props">
					<template v-if="props.column.field == 'regDt'">
						<a href="javascript:;" v-if="viewTableInfo.target == 'summary1Min' && watchTimes[props.row.regDt]" @click="openSummaryWatch(props.row)">
							{{ props.row.regDt && typeof props.row.regDt == "number" ? props.row.regDt.format(tableInfo.dtFormat) : `${props.row.regDt}` }}
						</a>
						<span v-else>
							{{ props.row.regDt && typeof props.row.regDt == "number" ? props.row.regDt.format(tableInfo.dtFormat) : `${props.row.regDt}` }}
						</span>
					</template>
					<template v-else-if="props.column.field == 'ptVal'">
						<strike v-if="props.row.applyFilter" :class="{ 'text-orange': props.row.applyFilter }" v-b-tooltip.hover :title="props.row.applyFilter">
							{{ `${comma(props.row.ptVal, tableInfo.fixed) === null ? 0 : comma(props.row.ptVal, tableInfo.fixed)}` }}
						</strike>
						<span v-else-if="props.row.applyUnit" :class="{ 'text-blue': props.row.applyUnit }" v-b-tooltip.hover :title="props.row.applyUnit">
							{{ `${comma(props.row.ptVal, tableInfo.fixed) === null ? 0 : comma(props.row.ptVal, tableInfo.fixed)}` }}
						</span>
						<span v-else>
							{{ `${comma(props.row.ptVal, tableInfo.fixed) === null ? 0 : comma(props.row.ptVal, tableInfo.fixed)}` }}
						</span>
					</template>
					<template v-else-if="props.column.field == 'ptOrg'">
						{{ `${props.row.ptOrg}`.indexOf("-987654321") > -1 ? `보간` : comma(props.row.ptOrg, tableInfo.fixed) }}
					</template>
					<template v-else-if="props.column.field == 'ptPre'">
						<span v-if="`${props.row.ptOrg}`.indexOf('-987654321') > -1" v-b-tooltip.hover :title="props.row.ptPre.format('yyyy-MM-dd HH:mm:ss')">
							{{ props.row.ptPre.format("HH:mm:ss") }}
						</span>
						<span v-else>
							{{ comma(props.row.ptPre, tableInfo.fixed) }}
						</span>
					</template>
				</template>
			</vue-good-table>
			<div class="panel-loader" v-if="tableInfo.loading"><span class="spinner-small"></span></div>
		</div>

		<b-modal ref="watchModal" :hide-footer="true" size="lg" title="디버깅정보">
			<div class="form-inline form-group">
				<ln-select :label="$t('계산유형')" v-model="showPlanType" all="false" :items="{ self: '자신만', upl: '계산식', all: '전체' }" />
				<ln-select :label="$t('보기유형')" v-model="showPlanPtName" all="false" :items="{ false: '주소', true: '이름' }" />
				<div>Time: {{ watchDt | filterFormat("yyyy-MM-dd HH:mm") }} Rows: {{ watchList.length }}</div>
			</div>
			<div class="row m-t-10">
				<div class="col-12">
					<label>{{$t('연산기록')}}</label>
				</div>
				<div class="col-12">
					<vue-good-table
						v-bind="GRID_OPT"
						:columns="watchColumns"
						:rows="watchList"
						:pagination-options="{ enabled: false }"
						:fixed-header="true"
						:row-style-class="
							(row) => {
								return row.ptAddr == this.ptAddr ? 'bg-blue-transparent-1' : 'xe-grid-row';
							}
						"
					>
						<div slot="emptystate" style="text-align: center;">
							{{$t('조회된 결과가 없습니다.')}}
						</div>
						<template slot="table-row" slot-scope="props">
							<template v-if="props.column.field == 'ptAddr'">
								<span :class="{ 'text-blue': props.row.ptAddr == ptAddr }">
									{{ props.row.ptAddr }} <br />
									{{ props.row.ptName }}
								</span>
							</template>
							<template v-else-if="props.column.field == 'plan'">
								<textarea :rows="props.row.planLines" class="form-control" :value="parseBool(showPlanPtName) ? props.row.planWithName : props.row.plan" />

								<div v-if="props.row.applyFilter">{{ props.row.applyFilter }}</div>
								<div v-if="props.row.applyUnit">{{ props.row.applyUnit }}</div>
								<div v-if="props.row.errorInfo">{{ props.row.errorInfo }}</div>
							</template>
						</template>
					</vue-good-table>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<label>상위연산</label>
				</div>
				<div class="col-12">
					<vue-good-table
						v-bind="GRID_OPT"
						:columns="watchColumns"
						:rows="upperWatchs"
						:pagination-options="{ enabled: false }"
						:fixed-header="true"
						:max-height="viewTableInfo.height"
						:row-style-class="
							(row) => {
								return row.ptAddr == this.ptAddr ? 'bg-blue-transparent-1' : 'xe-grid-row';
							}
						"
					>
						<div slot="emptystate" style="text-align: center;">
							{{$t('조회된 결과가 없습니다.')}}
						</div>
						<template slot="table-row" slot-scope="props">
							<template v-if="props.column.field == 'ptAddr'">
								<span :class="{ 'text-blue': props.row.ptAddr == ptAddr }">
									{{ props.row.ptAddr }} <br />
									{{ props.row.ptName }}
								</span>
							</template>
							<template v-else-if="props.column.field == 'plan'">
								<textarea :rows="props.row.planLines" class="form-control" :value="parseBool(showPlanPtName) ? props.row.planWithName : props.row.plan" />

								<div v-if="props.row.applyFilter">{{ props.row.applyFilter }}</div>
								<div v-if="props.row.applyUnit">{{ props.row.applyUnit }}</div>
								<div v-if="props.row.errorInfo">{{ props.row.errorInfo }}</div>
							</template>
						</template>
					</vue-good-table>
				</div>
			</div>
			<div class="row  m-t-10">
				<div class="col-12">
					<label>{{$t('하위연산')}}</label>
				</div>
				<div class="col-12">
					<vue-good-table
						v-bind="GRID_OPT"
						:columns="watchColumns"
						:rows="lowerWatchs"
						:pagination-options="{ enabled: false }"
						:fixed-header="true"
						:max-height="viewTableInfo.height"
						:row-style-class="
							(row) => {
								return row.ptAddr == this.ptAddr ? 'bg-blue-transparent-1' : 'xe-grid-row';
							}
						"
					>
						<div slot="emptystate" style="text-align: center;">
							{{$t('조회된 결과가 없습니다.')}}
						</div>
						<template slot="table-row" slot-scope="props">
							<template v-if="props.column.field == 'ptAddr'">
								<span :class="{ 'text-blue': props.row.ptAddr == ptAddr }">
									{{ props.row.ptAddr }} <br />
									{{ props.row.ptName }}
								</span>
							</template>
							<template v-else-if="props.column.field == 'plan'">
								<textarea :rows="props.row.planLines" class="form-control" :value="parseBool(showPlanPtName) ? props.row.planWithName : props.row.plan" />

								<div v-if="props.row.applyFilter">{{ props.row.applyFilter }}</div>
								<div v-if="props.row.applyUnit">{{ props.row.applyUnit }}</div>
								<div v-if="props.row.errorInfo">{{ props.row.errorInfo }}</div>
							</template>
						</template>
					</vue-good-table>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<style scoped>
	.log-label {
		padding-left: 5px;
	}
	.height-350 {
		height: 350px !important;
	}
	.height-750 {
		height: 750px !important;
	}
	.form-control[readonly] {
		background: #fff !important;
		color: #2d353c !important;
		opacity: 1 !important;
	}
</style>

<script>
	import backEndApi from "../../../../../api/backEndApi.js";

	export default {
		props: ["ptAddr", "tableInfo", "max", "refresh", "action", "isSimple", "isQuery"],
		data() {
			return {
				viewType: null,

				// 원본로그
				gridTotalLogList: {
					target: "totalLog",
					columns: [
						{
							label: this.$t("시간"),
							field: "regDt",
							type: "number",
							width: "40%",
							tdClass: "text-center text-nowrap",
							thClass: "text-center text-nowrap",
						},
						{
							label: this.$t("값"),
							field: "ptVal",
							type: "number",
							//width: "30%",
							tdClass: "text-right f-s-14 f-w-600 text-inverse p-t-0 p-b-0 text-nowrap",
							thClass: "text-center text-nowrap",
						},
						{
							label: this.$t("원본"),
							field: "ptOrg",
							type: "number",
							//width: "10%",
							tdClass: "text-right f-s-14 f-w-600 text-inverse p-t-0 p-b-0 text-nowrap",
							thClass: "text-center text-nowrap",
						},
						{
							label: this.$t("이전"),
							field: "ptPre",
							type: "number",
							//width: "10%",
							tdClass: "text-right f-s-14 f-w-600 text-inverse p-t-0 p-b-0 text-nowrap",
							thClass: "text-center text-nowrap",
						},
					],
					rows: [
						// { regDt: '20181031010201', ptVal: 13.22, ptOrg: 5, ptPre: "NULL" },
						// { regDt: '20181031010202', ptVal: 13.22, ptOrg: 5, ptPre: "NULL" },
					],
					height: "750px",
					styleClass: "height-750",
					//height: "350px",
					//styleClass: "height-350",
				},
				// 1분로그
				gridSummary1MinList: {
					target: "summary1Min",
					columns: [
						{
							label: this.$t("시간"),
							field: "regDt",
							type: "number",
							width: "40%",
							tdClass: "text-center text-nowrap",
							thClass: "text-center text-nowrap",
						},
						{
							label: this.$t("값"),
							field: "ptVal",
							type: "number",
							//width: "30%",
							tdClass: "text-right f-s-14 f-w-600 text-inverse p-t-0 p-b-0 text-nowrap",
							thClass: "text-center text-nowrap",
						},
						{
							label: this.$t("원본"),
							field: "ptOrg",
							type: "number",
							//width: "10%",
							tdClass: "text-right f-s-14 f-w-600 text-inverse p-t-0 p-b-0 text-nowrap",
							thClass: "text-center text-nowrap",
						},
					],
					rows: [
						// { regDt: '20181031010201', ptVal: 13.22, ptOrg: 5, ptPre: "NULL" },
					],
					height: "750px",
					styleClass: "height-750",
					//height: "350px",
					//styleClass: "height-350",
				},
				// 취합로그
				gridSummaryList: {
					target: "summary",
					columns: [
						{
							label: this.$t("시간"),
							field: "regDt",
							type: "number",
							width: "40%",
							tdClass: "text-center text-nowrap",
							thClass: "text-center text-nowrap",
						},
						{
							label: this.$t("값"),
							field: "ptVal",
							type: "number",
							//width: "30%",
							tdClass: "text-right f-s-14 f-w-600 text-inverse p-t-0 p-b-0 text-nowrap",
							thClass: "text-center text-nowrap",
						},
					],
					rows: [],
					height: "350px",
					styleClass: "height-350",
				},
				// 디버깅정보
				isDebugWatch: true,

				watchTimes: {}, // map

				watchDt: "",
				watchs: [],
				showPlanPtName: false,
				showPlanType: "self",
				watchColumns: [
					{
						label: this.$t("주소"),
						field: "ptAddr",
						type: "text",
						width: "15%",
						tdClass: "text-left text-nowrap",
						thClass: "text-center text-nowrap",
					},
					{
						label: this.$t("값"),
						field: "ptVal",
						type: "number",
						width: "15%",
						tdClass: "text-left text-nowrap",
						thClass: "text-center text-nowrap",
					},
					{
						label: this.$t("계획"),
						field: "plan",
						type: "text",
						width: "70%",
						tdClass: "text-left text-nowrap",
						thClass: "text-center text-nowrap",
					},
				],

				query: {},

				liveAM: this.readStorage("liveAM"),
			};
		},
		computed: {
			pt() {
				console.log(this.liveAM);
				return this.liveAM[this.ptAddr];
			},
			viewTableInfo() {
				if (this.tableInfo.searchType == 7) return this.gridTotalLogList;
				else if (this.tableInfo.searchType == 6) return this.gridSummary1MinList;
				else return this.gridSummaryList;
			},
			viewTableColumns() {
				if (this.isSimple) {
					return [
						{
							label: this.$t("시간"),
							field: "regDt",
							type: "number",
							width: "40%",
							tdClass: "text-center text-nowrap",
							thClass: "text-center text-nowrap",
						},
						{
							label: this.$t("값"),
							field: "ptVal",
							type: "number",
							//width: "30%",
							tdClass: "text-right f-s-14 f-w-600 text-inverse p-t-0 p-b-0 text-nowrap",
							thClass: "text-center text-nowrap",
						},
					];
				}

				return this.viewTableInfo.columns;
			},
			watchList() {
				if (this.showPlanType == "self") return this.isEmpty(this.self) ? [] : [this.self];
				if (this.showPlanType == "upl") return (this.isEmpty(this.self) ? [] : [this.self]).concat(this.watchs.filter((v) => v.plan.indexOf("#") != 0)); // 현재 조회중인 관제점을 무조건 찾아서 최상위에 넣는다.
				return this.watchs;
			},
			self() {
				if (!this.isEmpty(this.ptAddr)) {
					return this.watchs.find((v) => v.ptAddr == this.ptAddr);
				}
				return null;
			},
			upperWatchs() {
				if (this.isEmpty(this.self)) return [];

				// 상위연산 - 자기의 계산식이 가져다 사용한 주소들
				let addrs = Object.keys(this.liveAM).filter((ptAddr) => this.self.plan.indexOf(ptAddr) > -1);
				return this.watchs.filter((v) => addrs.filter((addr) => v.ptAddr == addr).length);
			},
			lowerWatchs() {
				if (this.isEmpty(this.self)) return [];

				// 하위연산- 자기값을 가져다 사용한 계산식
				return this.watchs.filter((v) => v.plan.indexOf(this.self.ptAddr) > -1);
			},
		},
		watch: {
			"tableInfo.logData": function(newV) {
				this.viewTableInfo.rows.range(newV);

				this.bindQuery();
				this.bindSummaryWatchTimes();
			},
			"tableInfo.isShow"() {
				if (!this.tableInfo.isShow) this.viewTableInfo.rows.clear();
			},
		},
		mounted() {
			this.initSetting();
		},
		methods: {
			initSetting() {
				this.liveAM = this.readStorage("liveAM");

				this.gridTotalLogList.rows.clear();
				this.gridSummary1MinList.rows.clear();
				this.gridSummaryList.rows.clear();
			},
			onRefreshTable() {
				this.$emit("refresh", this.tableInfo);
			},
			bindQuery() {
				if (this.isEmpty(this.tableInfo.sDt) || this.isEmpty(this.tableInfo.eDt)) return;
				if (this.tableInfo.searchType < 2 || this.tableInfo.searchType > 6) return;

				let timeType = parseInt(this.tableInfo.searchType) - 1;

				let { longS, longE } = global.xe.PtLib.getTimeType(timeType, this.tableInfo.sDt, this.tableInfo.eDt);

				backEndApi.live.runSummaryQuery(timeType, longS, longE, this.ptAddr).then(({ data }) => {
					if (this.$err(data)) return;

					this.query = data;
				});
			},
			onRowStyleClass(row) {
				function min15(dt) {
					let m = parseInt(dt.format("mm"));

					if (m >= 0 && m < 15) return "00";
					if (m >= 15 && m < 30) return "15";
					if (m >= 30 && m < 45) return "30";
					if (m >= 45 && m < 60) return "45";
				}

				if (this.isEmpty(row.regDt)) {
					return "";
				}

				if (typeof row.regDt !== "number") {
					return "bg-white ";
				}

				if (this.tableInfo.searchType > 4) {
					let mm = min15(row.regDt);
					switch (mm) {
						case "00":
							return "bg-yellow-transparent-1 " + mm;
						case "15":
							return "bg-blue-transparent-1 " + mm;
						case "30":
							return "bg-indigo-transparent-1 " + mm;
						case "45":
							return "bg-green-transparent-1 " + mm;
						default:
							return "" + mm;
					}
				}
			},
			onRunSampling() {
				this.$emit("action", this.tableInfo);
			},
			onRunSummary() {
				this.$emit("action", this.tableInfo);
			},
			openSummaryWatch(row) {
				this.watchDt = row.regDt;
				this.bindSummaryWatch();
				this.$refs.watchModal.show();
			},
			bindSummaryWatchTimes() {
				if (this.viewTableInfo.target !== "summary1Min") return;
				if (this.isEmpty(this.ptAddr) || this.isEmpty(this.tableInfo.sDt) || this.isEmpty(this.tableInfo.eDt)) return;

				this.watchTimes = {};

				backEndApi.summary.searchSummaryWatchTimes(this.ptAddr, this.tableInfo.sDt, this.tableInfo.eDt).then(({ data }) => {
					if (this.$err(data)) return;

					this.watchTimes = data.toMap("regDt");
				});
			},
			bindSummaryWatch() {
				this.watchs.clear();

				backEndApi.summary.searchSummaryWatch(this.watchDt).then(({ data }) => {
					if (this.$err(data)) return;

					data.forEach((v) => {
						try {
							let pt = this.liveAM[v.ptAddr];
							if (pt) v.ptName = pt.ptName;

							v.plan = v.plan
								.trim()
								.replaceAll("+ ", "+\n")
								.replaceAll("- ", "-\n")
								.replaceAll("/ ", "/\n")
								.replaceAll("* ", "*\n");

							v.planLines = v.plan.split("\n").length + 1;

							v.planWithName = v.plan;
							Object.keys(this.liveAM).forEach((ptAddr) => {
								let pt = this.liveAM[ptAddr];
								if (pt) v.planWithName = v.planWithName.replace(pt.ptAddr, pt.ptName);
							});
						} catch (ex) {
							//console.log(v);
							console.error(ex);
						}
					});

					this.watchs.range(data);
				});
			},
		},
	};
</script>

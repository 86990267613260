<template>
  <a href="javascript:;"> {{ clockDate + " " + clockTime }}</a>
</template>

<script>
export default {
  data() {
    return {
      // Clock
      serverNow: "",
      localSec: 0,
      clockDate: "",
      clockTime: "",
    };
  },
  watch: {
    "$store.getters.serverNow": function(newV) {
      this.localSec = 0; // 클라이언트에서 진행된 시간
      this.serverNow = newV; // 서버에서 전달된 서버 시간
    },
  },
  created() {
    this.startClock();
  },
  methods: {
    startClock() {
      // 서버시계와 동기화되는 시계 작동을 위한 함수.
      setInterval(this.changeClock, 1000);
    },
    changeClock() {
      try {
        if (this.isEmpty(this.serverNow)) return;

        let now = this.serverNow.addSec(++this.localSec);

        this.clockDate = now.format("yyyy-MM-dd");
        this.clockTime = now.format("HH:mm:ss");
      } catch (err) {
        console.log("Header changeClock error serverNow =", this.serverNow, ", localSec =", this.localSec, ", err =", err);
      }
    },
  },
};
</script>

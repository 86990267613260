<template>
  <span @click="onClick($event)">
    <template v-if="child.total > 0">
      <i v-if="child.checked > 0 && child.checked == child.total" class="fa fa-lg fa-check-square text-primary" />
      <i v-else-if="child.checked > 0 && child.checked < child.total" class="fa fa-lg fa-minus-square text-secondary" />
      <i v-else class="fa fa-lg fa-square text-silver" />
    </template>
    <template v-else>
      <i v-if="checked" class="fa fa-lg fa-check-square text-primary" />
      <i v-else class="fa fa-lg fa-square text-silver" />
    </template>
  </span>
</template>

<style scoped></style>

<script>
export default {
  name: "CheckBox3",
  props: ["checked", "child", "click", "need-checked", "need-unchecked"],
  data() {
    return {};
  },
  watch: {
    checked: function() {
      this.onNeedChecked();
    },
    child: function() {
      this.onNeedChecked();
    },
  },
  methods: {
    onNeedChecked() {
      if (this.checked == false && this.child.checked > 0) {
        // 체크박스내에서 자식메뉴가 그룹에 포함되어 있는데, 부모노드가 포함되어 있지 않은경우
        this.$emit("need-checked");
      }

      if (this.checked == true && this.child.total > 0 && this.child.checked == 0) {
        // 체크박스내에서 자식메뉴가 그룹에 없는데, 부모노드가 포함되어 있는경우
        this.$emit("need-unchecked");
      }
    },
    onClick(evt) {
      evt.stopPropagation();
      this.$emit("click");
    },
  },
};
</script>

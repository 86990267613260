import TitleIconComponent from "./TitleIcon.vue";

const TitleIcon = {
  install(Vue) {
    //Vue.component(TitleIconComponent.name, TitleIconComponent);
    Vue.component(TitleIconComponent.name, () => import("./TitleIcon.vue"));
  },
};

export default TitleIcon;

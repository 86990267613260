<!-- Default Title Component -->
<template>
  <div class="row">
    <div class="col-lg-4">
      <div class="pull-left">
        <h4> {{ title }} </h4>
      </div>
    </div>
  </div>
</template>

<script>
// import backEndApi from "../../../../../api/backEndApi";

export default {
  components: {},
  props: ["title"],
  watch: {},
  data() {
    return { };
  },
  computed: { },
  created() { },
  methods: { },
};
</script>

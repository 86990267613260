<template>
  <div v-xe-pm.E>
    <div class="row ">
      <div class="col-11">
        <title-icon :title="$t('설치')" icon="fa-wrench" />
      </div>
      <div class="col-1">
        <button class="btn btn-default pull-right" @click="bindConfig()" v-b-tooltip.hover :title="$t('새로고침')"> <i class="fa fa-redo"></i> {{ $t('새로고침') }} </button>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-12">
        <b-tabs nav-class="nav-tabs" nav-wrapper-class="nav nav-tabs b-1 b-radius-3" content-class="bg-silver p-0" active-nav-item-class="text-inverse bg-silver">
          
          <b-tab>
            <template slot="title">
              <span class="d-sm-none"> <trans>DB</trans> </span>
              <span class="d-sm-block d-none"> <trans>DB</trans> </span>
            </template>
            <!-- let conn = { "host": "192.168.10.10", "port": "13306", "user": "root", "password": "peterRabbit^^7", "database": "xemsv20_testdb" }; -->
            <div class="row form-group m-t-10">
              <div class="col-6">
                <label><trans>호스트</trans></label>
                <input type="text" class="form-control" :placeholder="$t('IP주소나 호스트명을 입력하세요.')" v-model="syncDb.host" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-6">
                <label><trans>포트</trans></label>
                <input type="number" class="form-control" :placeholder="$t('포트를 입력하세요.')" v-model="syncDb.port" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-6">
                <label><trans>Database명</trans></label>
                <input type="text" class="form-control" :placeholder="$t('Database명을 입력하세요.')" v-model="syncDb.database" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-6">
                <label><trans>사용자 아이디</trans></label>
                <input type="text" class="form-control" :placeholder="$t('사용자 아이디를 입력하세요.')" v-model="syncDb.user" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-6">
                <label><trans>비밀번호</trans></label>
                <input type="password" class="form-control" :placeholder="$t('비밀번호를 입력하세요.')" v-model="syncDb.password" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-6 m-t-10">
                <button class="btn btn-sm btn-purple m-r-10" @click="onSyncDb()" v-b-tooltip.hover :title="$t('DB 스키마 동기화')"><i class="fa fa-play m-r-2"></i> {{ $t('DB 스키마 동기화') }}</button>
              </div>
            </div>
          </b-tab>
          
          <b-tab>
            <template slot="title">
              <span class="d-sm-none"> <trans>빌드</trans> </span>
              <span class="d-sm-block d-none"> <trans>빌드</trans> </span>
            </template>
            <div class="row form-group m-t-10">
              <div class="col-6">
                <label><trans>경로</trans></label>
                <input type="text" class="form-control" :placeholder="$t('디렉토리경로를 입력하세요.')" v-model="srcDir" />
              </div>
            </div>
            <div class="row form-group m-t-10">
              <div class="col-6">
                <label><trans>마스터코드</trans></label>
                <input type="text" class="form-control" :placeholder="$t('마스터코드를 입력하세요.')" v-model="srcMaster" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-6">
                <label><trans>프로젝트</trans></label>
                <select class="form-control" v-model="srcPrj">
                  <option v-for="prj in srcPrjs" :key="prj" > {{ prj }} </option>
                </select>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-6">
                <label><trans>GIT계정</trans></label>
                <input type="text" class="form-control" :placeholder="$t('GIT계정을 입력하세요.')" v-model="gitUid" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-6">
                <label><trans>비밀번호</trans></label>
                <input type="password" class="form-control" :placeholder="$t('GIT비번을 입력하세요.')" v-model="gitPwd" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-6 m-t-10">
                <button class="btn btn-sm btn-purple m-r-10" @click="onRunSyncGit()" v-b-tooltip.hover :title="$t('소스 동기화')"><i class="fa fa-play m-r-2"></i> {{ $t('소스 동기화') }}</button>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-6 m-t-10">
                <button class="btn btn-sm btn-purple m-r-10" @click="onRunBuild()" v-b-tooltip.hover :title="$t('프론트엔드 빌드')"><i class="fa fa-play m-r-2"></i> {{ $t('프론트엔드 빌드') }}</button>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-6 m-t-10">
                백엔드를 시작하려면, 환경설정에서 포트를 변경하여 충돌나지 하지 않게 해야 한다.
              </div>
            </div>
            <div class="row form-group">
              <div class="col-6 m-t-10">
                <button class="btn btn-sm btn-purple m-r-10" @click="onRunStartBackEnd()" v-b-tooltip.hover :title="$t('백엔드 시작')"><i class="fa fa-play m-r-2"></i> {{ $t('백엔드 시작') }}</button>
              </div>
            </div>
          </b-tab>

          <b-tab>
            <template slot="title">
              <span class="d-sm-none"> <trans>환경</trans> </span>
              <span class="d-sm-block d-none"> <trans>환경설정</trans> </span>
            </template>
            <table class="table table-valign-middle">
              <thead>
                <tr>
                  <th style="width:30%">{{$t('속성')}}</th>
                  <th style="width:70%">{{$t('값')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr> <td> {{$t('마스터')}} </td> <td> {{masterConfig.standAlone}} </td> </tr>
                
                <tr> <td> {{$t('서버IP')}} </td> <td> {{masterConfig.serverIp}} </td> </tr>
                <tr> <td> {{$t('라이브IP')}} </td> <td> {{masterConfig.live_handler_ip}} </td> </tr>

                <tr> <td> {{$t('데이터베이스')}} </td> <td> {{dbUrl}} </td> </tr>

                <tr> <td> Web-API {{$t('포트')}} </td> <td> {{masterConfig.port}} </td> </tr>
                <tr> <td> Web-API {{$t('작업자')}} </td> <td> {{masterConfig.expressWorkers}} </td> </tr>
                <tr> <td> {{$t('기본')}} {{$t('웹소켓')}} {{$t('포트')}} </td> <td> {{masterConfig.wssPort}} </td> </tr>
                <tr> <td> {{$t('브라우저 실시간 접속 주소')}} </td> <td> {{wssUrl}} </td> </tr>

                <tr> <td> {{$t('에이전트')}} {{$t('웹소켓')}} {{$t('포트')}} </td> <td> {{masterConfig.agentPort}} </td> </tr>
                <!-- <tr> <td> I/F 포트 </td> <td> {{masterConfig.interfacePort}} </td> </tr> -->
                <!-- <tr> <td> {{$t('샘플링')}} {{$t('간격')}} </td> <td> {{masterConfig.summaryInterval}} 분 </td> </tr> -->
                <tr> <td> {{$t('로깅여부')}} </td> <td> {{masterConfig.isLog ? $t('사용중') : $t('사용중지') }} </td> </tr>
                <tr> <td> {{$t('샘플링')}} {{$t('감시')}} </td> <td> {{masterConfig.summary && masterConfig.summary.isWatch ? $t('사용중') : $t('사용중지') }} {{masterConfig.summary && masterConfig.summary.watchPts.join(',') }} </td> </tr>

                <tr> <td> {{$t('시뮬레이터')}} </td> <td> {{simulatorUrl}} </td> </tr>


                <tr v-for="(pack,i) in masterConfig.packages" :key="'pack'+i" >
                  <td> {{ pack.name }} </td>
                  <td> {{ pack.version }} </td> 
                </tr>
                <tr v-for="(svc,i) in masterConfig.services" :key="'svc'+i" >
                  <td> {{ svc.name }} </td>
                  <td> {{ svc.status | codeIsRun }} </td> 
                </tr>

              </tbody>
            </table>
          </b-tab>

          <b-tab>
            <template slot="title">
              <span class="d-sm-none"> <trans>JSON</trans> </span>
              <span class="d-sm-block d-none"> <trans>JSON</trans> </span>
            </template>
              <textarea class="form-control" rows="35" :value="JSON.stringify(masterConfig, null, 2)" disabled></textarea>
          </b-tab>

        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import backEndApi from "@api/backEndApi.js";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
  props: [],
  data() {
    return {
      masterConfig: { status: "empty" },

      // 여기는 모두 마스터 기준임.
      srcMaster: "test",
      srcPrjs: [ 'Total', 'xEMS-BackEnd', 'xEMS-FrontEnd', 'xEMS-Agent'],
      srcDir: "D:/xEMS/",
      srcPrj: "Total",
      gitUid: "thekie",
      gitPwd: "",

      syncDb: {
        host: "192.168.10.10", 
        port: "13306", 
        user: "xems", 
        password: "", 
        database: "xemsv20_testdb"
      },

      apiResult:"",
			popupMessages

    };
  },
  computed:{
    wssUrl(){
      return this.$store.getters.wssUrl;
    },
    dbUrl(){
      if(this.isEmpty(this.masterConfig.xemsmst)) return "";
      return `${this.masterConfig.xemsmst.dbType}://${this.masterConfig.xemsmst.host}:${this.masterConfig.xemsmst.port}/${this.masterConfig.xemsmst.database}`;
    },
    simulatorUrl(){
      if(this.isEmpty(this.masterConfig.simulator)) return "";
      return `http://${this.masterConfig.simulator.host}:${this.masterConfig.simulator.port}/`;
    }
    
  },
  mounted() {
    this.initSetting();
  },
  methods: {
    initSetting() {
      this.$store.dispatch("UPDATE_PT_MAP");

      // Engineering의 설정은 현재 백엔드를 보는 것이고, setup은 build에 지정된 마스터의 설정을 쳐다봐야 함.
      
      this.bindConfig();
    },
    bindConfig() {
      backEndApi.engineering.getConfig().then(({ data }) => {
        if (this.$err(data)) return;

        this.masterConfig = data;


        // this.masterConfig.db.dumpFiles.push("D:/Database/Dump/xemsv20/backup_tb_server_full_20201030_130308.csv");
      });
    },
    
    // DB 생성
    onSyncDb(){
      
      let conn = this.syncDb;
      // let url = `mariadb://${conn.host}:${conn.port}/${conn.database}@${conn.user}`;

      this.alertQuestion(popupMessages.EXTRA_DB_SCHEMA_SYNC_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        backEndApi.engineering.syncDb(conn).then(({ data }) => {
          if (this.$err(data)) return;

          this.apiResult = data;
          this.alertNoti(popupMessages.EXTRA_DB_SCHEMA_SYNC_SUCCESS_POPUP_MESSAGE);
        });
      });
    },
    // 소스 동기화
    onRunSyncGit() {

      this.alertQuestion(popupMessages.EXTRA_SOURCE_SYNC_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        backEndApi.engineering.syncGit(this.srcDir, this.srcMaster, this.srcPrj, this.gitUid, this.gitPwd).then(({ data }) => {
          if (this.$err(data)) return;

          this.alertNoti(popupMessages.EXTRA_SOURCE_SYNC_SUCCESS_POPUP_MESSAGE);
        });

      });
    },
    // 소스 빌드
    onRunBuild() {
      this.alertQuestion(popupMessages.EXTRA_FRONTEND_BUILD_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        backEndApi.engineering.buildFrontEnd(this.srcDir, this.srcMaster, this.srcPrj).then(({ data }) => {
          if (this.$err(data)) return;

          this.alertNoti(popupMessages.EXTRA_FRONTEND_BUILD_SUCCESS_POPUP_MESSAGE);
        });

      });
    },
    // BackEnd 시작
    onRunStartBackEnd() {
      this.alertQuestion(popupMessages.EXTRA_BACKEND_BUILD_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        backEndApi.engineering.startBackEnd(this.srcDir, this.srcMaster).then(({ data }) => {
          if (this.$err(data)) return;

          this.alertNoti(popupMessages.EXTRA_BACKEND_BUILD_SUCCESS_POPUP_MESSAGE);
        });

      });
    },
  
  },
};
</script>

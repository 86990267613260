<template>
	<!-- menu with submenu -->
	<li v-if="menu.children" class="has-sub"
		v-bind:class="{ active: subIsActive(menu.path), expand: this.stat == 'expand' }">
		<a :class="`menu${menu.menuIdx}`" href="#" v-on:click.prevent.stop="expand($event)"
			@mouseover="mouseOver(menu.children, $event)" @mouseleave="hideFloatSubmenu"
			:style="{ color: mainMenu.title === menu.title ? '#fff !important' : 'transparents' }">
			<span v-if="menu.badge" class="badge pull-right">{{ menu.badge }}</span>
			<b v-else class="caret"></b>
			<i v-if="menu.icon && menu.icon.includes('material')" :class="'material-icons'"
				:style="{ alignSelf: 'center', fontSize: '14px', width: '14px', height: '14px', textAlign: 'center' }">{{
					menu.icon.split(" ")[1] }}</i>
			<i v-else v-bind:class="menu.icon"
				:style="{ alignSelf: 'center', width: '14px', height: '14px', textAlign: 'center' }"></i>
			<div v-if="menu.img" class="icon-img"><img v-bind:src="menu.img" alt="" /></div>
			<span class="menuTitles">{{ $t(menu.title) }}</span>
			<i v-if="menu.highlight" class="fa fa-paper-plane text-theme m-l-5"></i>
			<span v-if="menu.label" class="label label-theme m-l-15">{{ menu.label }}</span>
		</a>
		<ul class="sub-menu" v-bind:class="{ 'd-block': this.stat == 'expand', 'd-none': this.stat == 'collapse' }"
			v-bind:style="{ marginTop: pageOptions.pageSidebarMinified ? -(scrollTop + 40) + 'px' : '' }">
			<template v-for="submenu in menu.children">
				<sidebar-nav-list v-bind:menu="submenu" :key="submenu.menuIdx" ref="sidebarNavList"
					v-on:calcFloatSubmenu="handleCalcFloatSubmenu" v-on:collapse-other="handleCollapseOther(submenu)"
					:style="{ color: mainMenu.title === menu.title ? '#fff !important' : 'transparents' }"></sidebar-nav-list>
			</template>
		</ul>
	</li>

	<!-- menu without submenu -->
	<!-- router-link NavigationDuplicated 오류로  router-link -> router push로 변경함 -->
	<li v-else-if="menu.path" active-class="active" tag="li">
		<a :class="`menu${menu.menuIdx}`" href="javascript:;" @click="onClick(menu.path)" :style="{ display: 'flex' }">
			<span v-if="menu.badge" class="badge bg-yellow text-inverse pull-right">{{ menu.badge }}</span>
			<i v-if="menu.icon && menu.icon.includes('material')" :class="'material-icons'"
				:style="{ alignSelf: 'center', fontSize: '13px', width: '12px', height: '12px', textAlign: 'center' }">{{
					menu.icon.split(" ")[1] }}</i>
			<i v-else v-bind:class="menu.icon"
				:style="{ alignSelf: 'center', width: '12px', height: '12px', textAlign: 'center' }"></i>
			<div v-if="menu.img" class="icon-img"><img v-bind:src="menu.img" alt="" /></div>
			<span>{{ $t(menu.title) }}</span>
			<i v-if="menu.highlight" class="fa fa-paper-plane text-theme m-l-5"></i>
			<span v-if="menu.label" class="label label-theme m-l-15">{{ menu.label }}</span>
		</a>
	</li>

	<div :class="`menu${menu.menuIdx}`" v-else>
		{{ menu.name }}
	</div>
</template>

<style lang="scss" scoped>
.has-sub {
	a {
		background: transparent !important;
	}
}
</style>

<script>
import SidebarNavList from "./SidebarNavList.vue";
//import PageOptions from "../../config/PageOptions.vue";

export default {
	name: "SidebarNavList",
	props: ["menu", "scrollTop"],
	components: {
		SidebarNavList,
	},
	computed: {
		mainMenu() {
			return this.$store.getters.mainMenu;
		},
	},
	data() {
		return {
			stat: "",
		};
	},
	methods: {
		mouseOver: function (data, event) {
			if (this.pageOptions.pageSidebarMinified) {
				var offset = event.target.offsetParent.getBoundingClientRect();
				this.$emit("showFloatSubmenu", data, offset);
			}
		},
		hideFloatSubmenu: function () {
			if (this.pageOptions.pageSidebarMinified) {
				this.$emit("hideFloatSubmenu");
			}
		},
		expand: function () {
			if (this.stat == "") {

				this.stat = this.subIsActive(this.menu.path) ? "collapse" : "expand";
			} else {
				this.stat = this.stat == "expand" ? "collapse" : "expand";
			}
			this.$emit("collapse-other", this.menu);

			if (this.pageOptions.pageSidebarMinified) {
				this.$emit("calcFloatSubmenu");
			}
		},
		handleCalcFloatSubmenu: function () {
			if (this.pageOptions.pageSidebarMinified) {
				this.$emit("calcFloatSubmenu");
			}
		},
		collapse: function (menu) {
			if (this.menu != menu) {
				this.stat = "collapse";
			}
		},
		collapseOther: function () {
			this.$emit("collapse-other", this.menu);
		},
		handleCollapseOther: function (menu) {
			// console.log(menu, this.mainMenu)
			for (var i = 0; i < this.menu.children.length; i++) {
				this.$refs.sidebarNavList[i].collapse(menu);
			}
			this.setMenuStyle(this.mainMenu, menu);
		},
		onClick: function (path) {
			this.collapseOther();

			this.routerPush(path);
		},
		setMenuStyle(formerMenu, isSelectMenu) {
			let allOtherMenu = document.querySelectorAll('a[class*="menu"]')

			for (let i = 0; i < allOtherMenu.length; i++) {
				if (allOtherMenu[i].classList.contains("isActiveMenu")) {
					allOtherMenu[i].classList.remove("isActiveMenu");
				}
			}

			let lastMenu = document.getElementsByClassName(`menu${formerMenu.menuIdx}`);

			if (lastMenu[0].classList.contains("isActiveMenu")) {
				lastMenu[0].classList.remove("isActiveMenu");
			}

			let menu = document.getElementsByClassName(`menu${isSelectMenu.menuIdx}`);

			if (!menu[0].classList.contains("isActiveMenu")) {
				menu[0].classList.add("isActiveMenu");
			}
		},
		subIsActive(path) {
			const paths = Array.isArray(path) ? path : [path];
			return paths.some((path) => {
				return this.$route.path.indexOf(path) === 0;
			});
		},
	},
};
</script>

<style scoped>
.isActiveMenu {
	color: #fff !important;
}
</style>

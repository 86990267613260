<script>
let pageOptions = {
  pageSidebarMinified: false,
  pageContentFullWidth: false,
  pageContentInverseMode: false,
  pageContentFullHeight: false,
  pageWithSearchBar: false,
  pageWithLanguageBar: true,
  pageWithFooter: false,
  pageWithoutSidebar: false,
  pageWithRightSidebar: false,
  pageWithTwoSidebar: false, // true
  pageWithWideSidebar: false,
  pageWithLightSidebar: false,
  pageWithMegaMenu: true,
  pageWithTopMenu: false,
  pageSidebarTransparent: false,
  pageEmpty: false,
  pageMobileSidebarToggled: false,
  pageMobileRightSidebarToggled: false,
  pageMobileTopMenu: false,
  pageMobileMegaMenu: false,
  pageRightSidebarToggled: false,
  pageRightSidebarCollapsed: true, // toggled 동작하지 않아서 collapsed를 추가함.
  pageBodyScrollTop: 0,
  pageDualMenu: false, // 탑메뉴는 상단 메가메뉴에, 2depth부터는 navigation 영역에 표시할지 여부
  pageHeaderInverseMode: false,
  pageHelpDocsToggled: false,
};

try {
  if (localStorage.pageOptions) {
    let options = JSON.parse(localStorage.pageOptions);
    pageOptions.pageSidebarMinified = options.pageSidebarMinified;
  }
} catch (err) {
  console.log(err);
}

export default pageOptions;
</script>

<template>
  <!-- begin navbar-collapse -->
  <div class="collapse navbar-collapse pull-left" v-bind:class="{ 'd-block': pageOptions.pageMobileMegaMenu }">
    <ul class="nav navbar-nav">
      <li v-for="menu in navs" :key="menu.menuIdx">
        <a href="javascript:;" @click="onMenuClick(menu)">
          <i class="fa fa-fw" :class="menu.icon"></i>
          {{ menu.title }}
        </a>
      </li>
    </ul>
  </div>
  <!-- end navbar-collapse -->
</template>

<script>
import Vue from "vue";
//import PageOptions from "../../config/PageOptions.vue";

export default {
  name: "HeaderMegaMenu",
  data() {
    return {
      //pageOptions: PageOptions,
    };
  },
  computed: {
    navs() {
      //return this.$store.getters.navs;
      //return this.readStorage("navs");
      let menus = this.readStorage("menus");
      return Vue.convertNavList(menus);
    },
  },
  methods: {
    onMenuClick(menu) {
      this.$store.commit("UPDATE_CURRENT_MENUS", menu);
    },
  },
};
</script>

<template>
	<div>
		<vvo v-slot="v">
			<div class="row form-group">
				<div class="col-lg-12">
					<label>{{$t('사용자 아이디')}}</label>
					<!-- <input type="text" v-model="newUser.userId"> -->
					<valid-input
						:vid="'사용자 아이디'"
						:inputType="'text'"
						:classList="'form-control'"
						:placeholder="$t('사용자 아이디를 입력하세요.')"
						:inputValue.sync="newUser.userId"
						:rules="rules.MENU_USER_ID_RULE"
						:errorMessage="validMessage.MENU_USER_ID_VALID_MESSAGE"
						:readonly="mode == 'update'"
					></valid-input>
				</div>
			</div>
			<div class="row form-group" v-if="mode === 'insert'">
				<div class="col-lg-12">
					<label>{{$t('비밀번호')}}</label>
					<valid-input
						:vid="'비밀번호'"
						:inputType="'password'"
						:classList="'form-control'"
						:placeholder="$t('비밀번호를 입력하세요.')"
						:inputValue.sync="newUser.userPwd"
						:rules="rules.MENU_PASSWORD_RULE(newUser.userId)"
						:errorMessage="validMessage.MENU_PASSWORD_VALID_MESSAGE()"
					></valid-input>
				</div>
			</div>
			<div class="row form-group" v-if="mode === 'insert'">
				<div class="col-lg-12">
					<label>{{$t('비밀번호 확인')}}</label>
					<valid-input
						:vid="'비밀번호 확인'"
						:inputType="'password'"
						:classList="'form-control'"
						:placeholder="$t('비밀번호를 다시 입력하세요.')"
						:inputValue.sync="confirmedPWd"
						:rules="rules.PASSWORD_CHANGE_CONFIRM('비밀번호')"
						:errorMessage="validMessage.PASSWORD_CHANGE_CONFIRM_VALID_MESSAGE"
					>
					</valid-input>
				</div>
			</div>
			<div class="row form-group">
				<div class="col-lg-12">
					<label>{{$t('사용자명')}}</label>
					<valid-input
						:vid="'사용자명'"
						:inputType="'text'"
						:inputValue.sync="newUser.userName"
						:classList="'form-control'"
						:rules="rules.MENU_USER_NAME_RULE"
						:placeholder="$t('사용자명을 입력하세요.')"
						:errorMessage="validMessage.MENU_USER_NAME_VALID_MESSAGE"
					></valid-input>
				</div>
			</div>
			<div class="row form-group">
				<div class="col-lg-12">
					<label>{{$t('이메일')}}</label>
					<valid-input
						:vid="'이메일'"
						:inputType="'email'"
						:classList="'form-control'"
						:placeholder="$t('이메일을 입력하세요.')"
						:inputValue.sync="newUser.email"
						:rules="rules.MENU_EMAIL_RULE"
						:errorMessage="validMessage.MENU_EMAIL_VALID_MESSAGE"
						:readonly="mode == 'update'"
					></valid-input>
					<!-- <input type="email" class="form-control" :class="classes" :placeholder="$t('이메일을 입력하세요.')"
            v-model="newUser.email" maxlength="50" :readonly="mode == 'update'" /> -->
				</div>
			</div>
			<div class="row form-group">
				<div class="col-lg-12">
					<label>{{$t('전화번호')}}</label>
					<valid-input
						:vid="'전화번호'"
						:inputType="'text'"
						:classList="'form-control'"
						:placeholder="$t('전화번호를 입력하세요.')"
						:inputValue.sync="newUser.phone"
						:rules="rules.MENU_PHONE_RULE"
					></valid-input>
					<!-- <input type="text" class="form-control" :placeholder="$t('전화번호를 입력하세요.')" v-model="newUser.phone"
            maxlength="50" /> -->
				</div>
			</div>
			<div v-if="mode === 'update'" class="row form-group">
				<div class="col-lg-6">
					<label>{{$t('등록일')}}</label>

					<span class="form-control" :readonly="mode == 'update'">{{ isEmpty(newUser.regDt) ? "" : newUser.regDt.format("yyyy-MM-dd HH:mm") }}</span>
				</div>
				<div class="col-lg-6">
					<label>
						<trans>최종수정일</trans>
					</label>
					<span class="form-control" :readonly="mode == 'update'">{{ isEmpty(newUser.updDt) ? "" : newUser.updDt.format("yyyy-MM-dd HH:mm") }}</span>
				</div>
			</div>

			<div slot="modal-footer" class="w-100">
				<button v-xe-pm.E type="button" v-if="mode === 'update' && isShowDelete" class="btn btn-sm btn-danger m-r-5" @click="onDeleteUser()"><i class="fa fa-times"></i> {{ $t("삭제") }}</button>
				<button v-if="mode === 'update' && isChangePassword" type="button" class="btn btn-sm btn-warning" @click="openChangeUserModal()"><i class="fa fa-lock"></i> {{ $t("비밀번호 재설정") }}</button>
				<!-- <button class="btn btn-sm btn-primary m-r-5 pull-right" @click="v.invalid ? validFailed(v.errors) : onSaveUser()"><i class="fa fa-save"></i> {{ $t("저장")}}</button> -->
				<button class="btn btn-sm btn-primary m-r-5 pull-right" @click="onValidate(v, onSaveUser)"><i class="fa fa-save"></i> {{ $t("저장") }}</button>
				<slot name="button" v-if="hasButtonSlot"></slot>
			</div>
		</vvo>
	</div>
</template>

<script>
	import backEndApi from "../../../../api/backEndApi.js";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import AppErrorHandler from "@src/appErrorHandler.js";
	import { StatusCodes } from "http-status-codes";

	export default {
		props: ["changeUser", "isShowDelete", "isChangePassword", "save-done", "change-password", "curGroupIdx"],
		data() {
			return {
				mode: "insert",
				newUser: {},
				confirmedPWd: "",
				rules: rules,
				validMessage: validMessage,
				popupMessages: popupMessages,
			};
		},
		computed: {
			hasButtonSlot() {
				return !!this.$slots.button;
			},
		},
		watch: {
			changeUser: function() {
				this.initSetting();
			},
		},
		mounted() {
			this.initSetting();
		},
		methods: {
			initSetting() {
				this.newUser = Object.assign({}, this.changeUser);
				this.mode = this.isEmpty(this.changeUser.userId) ? "insert" : "update";
			},
			openChangeUserModal() {
				this.$emit("change-password");
			},
			validFailed(errors) {
				console.log(errors);
				// let keys = Object.keys(errors);

				// for (let i = 0; i < keys.length; i++) {
				// 	if (errors[keys[i]].length !== 0) {
				// 		switch (keys[i]) {
				// 			case "사용자명": {
				// 				this.alertNoti(popupMessages.PROFILE_USERNAME_FAILURE_VALID_POPUP_MESSAGE);
				// 				break;
				// 			}
				// 			case "전화번호": {
				// 				this.alertNoti(popupMessages.PROFILE_PHONE_FAILURE_VALID_POPUP_MESSAGE);
				// 				break;
				// 			}
				// 			default: {
				// 				this.alertNoti("형식이 잘못되었습니다.");
				// 				break;
				// 			}
				// 		}
				// 	}
				// }

				// console.log(value);
			},

			async onSaveUser() {
				this.mode == "insert" ? await this.insertUser() : await this.updateUser();
			},

			async insertUser() {
				const popupResult = await this.alertQuestion(`${popupMessages.PERMISSION_USER_ADD_POPUP_MESSAGE}`);
				if (!popupResult.value) return;

				try {
					let newUser = Object.assign({}, this.newUser);
					newUser.masterCode = this.$store.getters.master.masterCode;

					await backEndApi.user.insertUser(newUser);

					let groupUser = { groupIdx: this.curGroupIdx, userId: newUser.userId };

					await backEndApi.group.insertGroupUser(groupUser);
					this.newUser = {};

					this.alertNoti(popupMessages.PERMISSION_COMMON_REGIST_SUCCESS_POPUP_MESSAGE); // 사용자정보가 추가되었습니다.
					this.$emit("save-done");
				} catch (err) {
					console.log(err.response.data.detail);
					new AppErrorHandler(err)
						.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
						.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
						.createBizHandle(this.ERR_KIND.INVALID_ARGUMENT, popupMessages.COMMON_INPUT_ERROR_POPUP_MESSAGE, this.alertNoti)
						.createBizHandle(this.ERR_KIND.DUPLICATE_DATA, popupMessages.COMMON_INPUT_DUP_ERROR_POPUP_MESSAGE, this.alertNoti)
						.createBizHandle(this.ERR_KIND.ERROR_OCCURRED, err.response.data.detail, this.alertDanger)
						.errHandling();
				}
			},
			async updateUser() {
				const popupResult = await this.alertQuestion(popupMessages.PERMISSION_USER_UPDATE_POPUP_MESSAGE);
				if (!popupResult.value) return;

				try {
					await backEndApi.user.updateUser(this.newUser);

					this.alertNoti(popupMessages.COMMON_UPDATE_POPUP_MESSAGE); // 사용자정보를 변경하였습니다.
					this.$emit("save-done");
				} catch (err) {
					new AppErrorHandler(err)
						.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
						.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
						.createBizHandle(this.ERR_KIND.INVALID_ARGUMENT, popupMessages.COMMON_SAVE_ERROR_POPUP_MESSAGE, this.alertDanger)
						.createBizHandle(this.ERR_KIND.DUPLICATE_DATA, popupMessages.COMMON_SAVE_ERROR_POPUP_MESSAGE, this.alertDanger)
						.createBizHandle(this.ERR_KIND.ERROR_OCCURRED, err.response.data.detail, this.alertDanger)
						.errHandling();
				}
			},
			async onDeleteUser() {
				// this.alertConfirmWarning(`${popupMessages.PERMISSION_USER_DELETE_POPUP_MESSAGE}`).then((result) => {
				// 	if (!result.value) return;

				// 	backEndApi.user.deleteUser(this.newUser.userId).then(({ data }) => {
				// 		if (this.$err(data)) return;

				// 		this.alertNoti(`${popupMessages.COMMON_DELETE_POPUP_MESSAGE}`); // 사용자정보를 삭제하였습니다
				// 		this.$emit("save-done");
				// 		this.onRefresh();
				// 	});
				// });
				const popupResult = await this.alertConfirmWarning(popupMessages.PERMISSION_USER_DELETE_POPUP_MESSAGE);
				if (!popupResult.value) return;

				try {
					const result = await backEndApi.user.deleteUser(this.newUser.userId);
					if (!result.data) return;
					this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE); // 사용자정보를 삭제하였습니다
					this.$emit("save-done");
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			},
		},
	};
</script>

<template>
	<div v-if="retention.logTable">
		<div class="row">
			<div class="col-lg-12">
				<h5>{{ $t(retention.tableComment) }}</h5>
			</div>
		</div>
		<div class="row form-group">
			<div class="col-lg-12">
				<div class="form-inline m-b-10">
					<ln-select :label="$t('테이블')" v-model="condition.tableName" :items="tableNames" />
					<ln-picker :label="$t('시작일')" v-model="condition.sDt" :setting="{ format: 'YYYY-MM-DD' }" />
					<ln-picker :label="$t('종료일')" v-model="condition.eDt" :setting="{ format: 'YYYY-MM-DD' }" />
					<ln-button :label="$t('조회')" color="primary" icon="fa-search" :tooltip="$t('조회하려면 클릭하세요.')" @click="bindRetentions()" />
				</div>
			</div>
		</div>
		<div class="row form-group">
			<div class="col-lg-12">
				<vue-good-table
					ref="grid"
					:columns="columns"
					:rows="dumpList"
					v-bind="merge(GRID_OPT, { selectOptions: { enabled: true } })"
					:pagination-options="{
						enabled: true,
						nextLabel: '다음',
						prevLabel: '이전',
						ofLabel: '/',
						pageLabel: '페이지',
						rowsPerPageLabel: $t('표시 개수'),
					}"
				>
					<div slot="selected-row-actions">
						<button class="btn btn-danger pull-right" @click="onRestoreSelectedRows()"><i class="fa fa-trash-alt"></i> {{ $t("복원") }}</button>
					</div>
					<template slot="table-row" slot-scope="props">
						<template v-if="props.column.field == 'size'">
							{{ storageSize(props.row.size, "B") }}
						</template>
						<template v-else-if="props.column.field == 'cnt'">
							{{ props.row.cnt | filterComma(0) }}
						</template>
						<template v-else-if="props.column.field == 'ptCnt'">
							{{ props.row.ptCnt | filterComma(0) }}
						</template>
						<template v-else-if="props.column.field == 'action'">
							<button class="btn btn-xs btn-default" :disabled="isEmpty(props.row.dumpFile)" @click="onRestoreFile(props.row)" v-b-tooltip.hover :title="$t('이 백업파일을 테이블에 복원합니다.')">
								{{ $t("복원") }}
							</button>
							<button class="btn btn-xs btn-default" :disabled="isEmpty(props.row.dumpFile)" @click="onRemoveBackupFile(props.row)" v-b-tooltip.hover :title="$t('이 백업파일을 제거합니다.')">
								{{ $t("제거") }}
							</button>
							<button
								class="btn btn-xs btn-default"
								:disabled="isEmpty(props.row.dumpFile)"
								@click="onClearSummary(props.row)"
								v-b-tooltip.hover
								:title="$t('테이블의 데이터를 삭제합니다. 백업파일이 있을때만 가능합니다.')"
							>
								{{ $t("삭제") }}
							</button>
							<button class="btn btn-xs btn-default" @click="onUpdateStatus(props.row)" v-b-tooltip.hover :title="$t('테이블의 통계정보를 갱신합니다.')">{{ $t("갱신") }}</button>
						</template>
					</template>
				</vue-good-table>
			</div>
		</div>
	</div>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		props: ["retention"],
		components: {},
		data() {
			return {
				// 전체백업: d:\database\Dump\xemsv20\  backup_tb_total_log_full_20201030_113907.csv
				// 일별백업: d:\database\Dump\xemsv20\  xemsmst_kbet_tb_total_log_20190917.csv

				condition: {
					sDt: new Date().addDay(-19).format("yyyy-MM-dd"),
					eDt: new Date().format("yyyy-MM-dd"),
					tableName: "",
				},
				tableNames: [
					"tb_total_log",
					"tb_summary_1min",
					"tb_summary_15min",
					"tb_summary_hour",
					"tb_summary_day",
					//'tb_summary_month', 'tb_summary_year' // 일단 보류..
				],

				columns: [
					{
						label: this.$t("백업 날짜"),
						field: "backupDt",
						type: "text",
						width: "10%",
						tdClass: "text-center text-nowrap",
						thClass: "text-center text-nowrap ",
					},
					{
						label: this.$t("경과시간"),
						field: "passed",
						type: "text",
						width: "10%",
						tdClass: "text-center text-nowrap",
						thClass: "text-center text-nowrap",
					},
					{
						label: this.$t("백업 유형"),
						field: "backupType",
						type: "text",
						width: "10%",
						tdClass: "text-center text-nowrap",
						thClass: "text-center text-nowrap",
					},
					{
						label: this.$t("백업파일명"),
						field: "dumpFile",
						type: "text",
						width: "30%",
						tdClass: "text-center text-nowrap xe-grid-text",
						thClass: "text-center text-nowrap ",
					},
					{
						label: this.$t("파일크기"),
						field: "size",
						type: "number",
						width: "10%",
						tdClass: "text-center text-nowrap",
						thClass: "text-center text-nowrap ",
					},
					{
						label: this.$t("건수"),
						field: "cnt",
						type: "number",
						width: "10%",
						tdClass: "text-center text-nowrap",
						thClass: "text-center text-nowrap ",
					},
					{
						label: this.$t("관제점수"),
						field: "ptCnt",
						type: "number",
						width: "10%",
						tdClass: "text-center text-nowrap",
						thClass: "text-center text-nowrap ",
					},
					{
						label: "",
						field: "action",
						type: "text",
						width: "18%",
						tdClass: "text-center text-nowrap",
						thClass: "text-center text-nowrap ",
					},
				],

				dumpInfos: [],
			};
		},
		computed: {
			dumpList() {
				if (this.dumpInfos) {
					let arr = this.dumpInfos.map((v) => {
						//let backupDt = v.fileName.substring(v.fileName.lastIndexOf('_')+1 ).replace('.csv', '');

						let dumpFile = "";
						if (!this.isEmpty(v.fileName)) {
							dumpFile = v.fileName.replaceAll("\\", "/").toLowerCase();
							dumpFile = dumpFile.substring(dumpFile.lastIndexOf("/") + 1).replace(".csv", "");
						}

						return {
							backupType: "일별",
							fileName: v.fileName, // full file path
							dumpFile: dumpFile, // file name without extention
							size: v.size || "",
							cnt: v.cnt || "",
							ptCnt: v.ptCnt || "",
							updDt: v.updDt ? v.updDt.format("yyyy-MM-dd") : "", // 파일 수정일자
							regDt: v.regDt ? v.regDt.format("yyyy-MM-dd") : "", // 파일 생성일자
							backupDt: v.partDt.format("yyyy-MM-dd"), // 백업 기준일자
							passed: v.partDt.passedDay() + "일전",
						};
					});
					return arr.descBy("backupDt");
				}
				return [];
			},
		},
		watch: {
			"retention.logTable"() {
				this.initSetting();
			},
		},
		mounted() {
			this.initSetting();
		},
		methods: {
			initSetting() {
				this.condition.tableName = this.retention.logTable;

				this.bindRetentions();
			},
			bindRetentions() {
				let { tableName, sDt, eDt } = this.condition;

				if (this.isEmpty(tableName)) return;

				backEndApi.engineering.getDumpInfos(tableName, sDt, eDt).then(({ data }) => {
					if (this.$err(data)) return;

					this.dumpInfos = data;
				});
			},
			onRestoreSelectedRows() {
				// let dumpFiles = this.$refs.grid.selectedRows.map((v) => v.dumpFile);

				console.log("복원기능 구현해야 함.");

				//let filePath = this.restorePath;
				//let tableName = this.restoreTable;

				// this.alertQuestion("진행하시겠습니까?", "DB복원", tableName).then((result) => {
				//   if (!result.value) return;

				//   backEndApi.engineering.restore(tableName, filePath).then(({ data }) => {
				//     if (this.$err(data)) return;

				//     this.notiBottom(`데이터 동기화를 요청하였습니다.`);
				//   });

				// });

				this.alertQuestion(popupMessages.SYSTEM_EVENT_LOG_ORIGINAL_DATA_SELECT_RENEWAL_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					//   backEndApi.eventLog.deleteEventLog(eventLogIdxs).then(({ data }) => {
					//     if (this.$err(data)) return;

					//     this.notiBottom("데이터를 삭제하였습니다.");
					//     this.onSearchEventLog();
					//   });
				});
			},
			onRestoreFile(row) {
				let { tableName } = this.condition;

				let dump = this.dumpList[row.originalIndex];

				this.alertQuestion(popupMessages.SYSTEM_EVENT_LOG_ORIGINAL_DATA_BACKUP_RESTORE_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.engineering.restore(tableName, dump.fileName).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.SYSTEM_EVENT_LOG_ORIGINAL_DATA_BACKUP_RESTORE_SUCCESS_POPUP_MESSAGE);
						this.bindRetentions();
					});
				});
			},
			onRemoveBackupFile(row) {
				let { tableName } = this.condition;

				let dump = this.dumpList[row.originalIndex];

				this.alertConfirmWarning(popupMessages.SYSTEM_EVENT_LOG_ORIGINAL_DATA_BACKUP_DELETE_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.engineering.removeBackupFile(tableName, dump.fileName).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.SYSTEM_EVENT_LOG_ORIGINAL_DATA_BACKUP_DELETE_SUCCESS_POPUP_MESSAGE);
						this.bindRetentions();
					});
				});
			},
			onClearSummary() {
				let { sDt, eDt, tableName } = this.condition;

				this.alertConfirmWarning(popupMessages.SYSTEM_EVENT_LOG_ORIGINAL_DATA_DELETE_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.summary.clearSummary(sDt, eDt, tableName).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.SYSTEM_EVENT_LOG_ORIGINAL_DATA_DELETE_SUCCESS_POPUP_MESSAGE);
						this.bindRetentions();
					});
				});
			},
			onUpdateStatus(row) {
				let { tableName } = this.condition;

				this.alertQuestion(popupMessages.SYSTEM_EVENT_LOG_ORIGINAL_DATA_RENEWAL_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.summary.updateLogTableStatus(row.backupDt, row.backupDt, tableName).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.SYSTEM_EVENT_LOG_ORIGINAL_DATA_RENEWAL_SUCCESS_POPUP_MESSAGE);
						this.bindRetentions();
					});
				});
			},
		},
	};
</script>

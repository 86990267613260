<template>
  <div>
    <b-dropdown-item class="media p-t-10 p-b-10" v-for="(alarm, idx) in alarms.filter((v, i) => i < 5)" :key="idx" :value="alarm">
      <alarm-log :alarm="alarm" @holdCallback="holdCallback" />
    </b-dropdown-item>
  </div>
</template>

<script>
import AlarmLog from "./AlarmLog.vue";

// 배열변경시 화면 갱신되도록 하려고 만듬.
export default {
  props: ["alarms"],
  components: {
    AlarmLog,
  },
  methods:{
    holdCallback(){
      this.$emit("holdCallback");
    }
  }
};
</script>

<template>
	<div>
		<div class="d-flex justify-content-end mb-2">
			<!-- <input :ref="'keyword'" v-model="filterString" class="form-control" style="width:160px" placeholder="관제점 주소를 입력하세요." @keydown.enter="onSearch" /> -->
			<button type="button" class="ml-1 btn btn-info" style="width:75px" @click="reSearch()">{{$t('새로고침')}}</button>
		</div>
  
		<b-table hover bordered head-variant="dark" table-variant="light" no-border-collapse show-empty sticky-header="700px" ref="analysisTable" :id="'analysis-table'" :class="'text-center rounded'" :items="items" :fields="fields" :empty-text="$t('조회된 결과가 없습니다.')">
			<template #empty="scope">
				<h4>{{ scope.emptyText }}</h4>
			</template>
     

			<!-- <template  #cell(regDt)="data">
        <div>{{  data }}</div>
      </template> -->
		</b-table>
	</div>
</template>

<script>
	import backEndApi from "../../../../api/backEndApi.js";

	export default {
		props: ["defaultFilter", "facility"],
		data() {
			return {
				fields: null,
				items: null,
				filterString: "",
			};
		},
		computed: {},
		watch: {},
		created() {
			this.filterString = !this.defaultFilter ? "" : this.defaultFilter;
			this.setFields();
			this.searchTotalRealList();
		},
		mounted() {},
		methods: {
			setFields() {
				this.fields = [
					{ key: "ptAddr", label: global.xe.$t("관제점 주소") },
					{ key: "ptIdx", label: global.xe.$t("관제점 일련번호") },
					{ key: "ptVal", label: global.xe.$t("현재값") },
					{ key: "ptOrg", label: global.xe.$t("원본값") },
					{ key: "ptPre", label: global.xe.$t("이전값") },
					{ key: "regDt", label: global.xe.$t("수집시간") },
					// { key: 'updDt', label: "수정일" },
				];
			},
			searchTotalRealList() {
				backEndApi.pointInfo.searchTotalRealList().then(({ data }) => {
					this.setItems(data);
				});
			},
			onSearch() {
				this.searchTotalRealList();
			},
			async setItems(data) {
				console.log(this.facility);
				this.items = null;

				var keyword = this.filterString; //관제점 주소

				let facilityFilterData = data;

				let subFacilityList = await backEndApi.facilityMap.searchFacilityMapWithPtAddr(this.facility.facilityIdx);
				let subFacilityListPtAddr = subFacilityList.data.map((v) => v.ptAddr.toUpperCase());
				facilityFilterData = data.filter((data) => subFacilityListPtAddr.includes(data.ptAddr.toUpperCase()));

				// if(this.facility.type === "subFacility"){
				//   facilityFilterData = facilityFilterData.filter((data) => data.ptAddr.toUpperCase().includes(this.facility.facilityName.toUpperCase()));
				// }

				// console.log(keyword);
				// var defaultFilterData = data.filter((data) => data.ptAddr.includes(this.facility.facilityName));

				var result = facilityFilterData.filter((data) => (keyword == "" ? data.ptAddr.toUpperCase() == data.ptAddr.toUpperCase() : data.ptAddr.toUpperCase().includes(keyword.toUpperCase())));

				this.items = result;

				console.log(this.items);

				this.items.forEach((item) => {
					item.regDt = this.setDate(item.regDt);

					let regDt = new Date(item.regDt);
					let currentDt = new Date();

					if (this.calcDateDiff(currentDt, regDt) > 60) {
						item._rowVariant = "danger";
					}
				});
			},
			setDate(str) {
				let year = str.substr(0, 4);
				let month = str.substr(4, 2);
				let day = str.substr(6, 2);
				let hour = str.substr(8, 2);
				let minute = str.substr(10, 2);
				let second = str.substr(12, 2);

				return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
			},
			calcDateDiff(currentDt, regDt) {
				//두 날짜 사이의 간격을 type 으로 계산해 출력
				let min = parseInt((currentDt.getTime() - regDt.getTime()) / 1000 / 60);
				// let ret = Math.abs(currentDt - regDt);
				// ret /= 60;

				return min;
			},
			reSearch() {
				this.onSearch();
			},
		},
	};
</script>

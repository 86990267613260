<template>
  <div class="input-group">
    <div class="input-group-append" @click="onTogglePicker">
      <slot v-if="hasIconSlot" name="icon"></slot>
      <span v-else class="input-group-text">
        <i
          v-if="
            current
              ? current.includes('material')
              : newIcon.includes('material')
          "
          class="material-icons f-s-15"
          >{{
            current
              ? current.replace("material ", "")
              : newIcon.replace("material ", "")
          }}</i
        >
        <i v-else :class="[current ? current : newIcon]"></i>
      </span>
    </div>

    <input
      type="text"
      v-if="!hideText"
      class="form-control"
      :placeholder="$t('아이콘 이름을 입력하세요.')"
      v-model="searchText"
      @click="onTogglePicker"
      readonly
      />

    <ul
      v-show="isShowPicker"
      class="dropdown-menu xe-icon-list"
      style="min-width:348px; max-height: 400px; display: block;"
    >
      <div class="m-b-10">
        <a
          href="javascript:;"
          v-for="(tab, i) in tabs"
          :key="i"
          class="xe-tab-underline xe-icon-tab"
          @click.prevent="toggleTabs(i)"
          :class="{ 'xe-tab-line-blue': i == tabIdx }"
          >{{ tab.name }}</a
        >
      </div>
      <div v-for="(tab, i) in tabs" :key="i">
        <div v-show="tabIdx == i">
          <!-- <pre>{tab}</pre> -->
          <li
            v-for="(icon, j) in tab.icons"
            :key="j"
            :class="{ 'selected-icon': matchIcon == icon }"
            class="xe-icon text-center"
            @click="onChangeIcon(icon)"
          >
            <div v-if="tab.name.equal('Material')" class="fa-2x text-inverse">
              <i class="material-icons">{{
                icon.icon.replace("material ", "")
              }}</i>
            </div>
            <div v-else class="fa-2x text-inverse " :class="icon.icon"></div>
          </li>
        </div>
      </div>
    </ul>
  </div>
</template>

<style>
.xe-icon-list {
  overflow-x: hidden;
  overflow-y: auto;
}
.xe-icon {
  float: left;
  width: 55px;
  padding: 5px;
}
.xe-icon:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
</style>

<style scoped>
.selected-icon {
  background-color: rgb(216, 216, 216);
}
</style>
<script>
import Icons from "../FontAwesomeIcons.js";

// TODO: input에서 탭 버튼 클릭시 닫힘..스크롤은...됨

export default {
  name: "IconSelect",
  props: ["current", "hideText", "on-change"],
  data() {
    return {
      newIcon: "",
      searchText: "",
      tabs: [
        { name: "Solid", icons: Icons.FontAwesome },
        { name: "Simple Line", icons: Icons.Icons },
        { name: "Android", icons: Icons.IonMd },
        { name: "Ios", icons: Icons.IonIos },
        { name: "Logo", icons: Icons.IonLogo },
        { name: "Material", icons: Icons.Material },
      ],
      tabIdx: 0,
      isShowPicker: false,

      addedFn: null,
      icons: [],
      emptyIcon: [],
    };
  },
  computed: {
    hasIconSlot() {
      return !!this.$slots.icon;
    },
    matchIcon() {
      return this.icons.find((v) => v.icon == this.current);
    },
  },
  watch: {
    current() {
      this.initSetting();
    },
    searchText() {
      if (!this.searchText) {
        this.onChangeIcon();
      }
    },
  },
  created() {
    this.tabs.forEach((tab) => {
      tab.icons.forEach((icons) => this.icons.push(icons));
    });
  },
  mounted() {
    this.initSetting();
  },
  methods: {
    initSetting() {
      let match = this.icons.find((v) => v.icon == this.current);
      if (match) this.searchText = match.title;
    },
    onTogglePicker(e) {
      e.stopPropagation(); // this will stop propagation of this event to upper level

      this.isShowPicker = !this.isShowPicker;
      if (this.isShowPicker) {
        this.addedFn = this.onRegistToggle.bind(this);
        window.addEventListener("click", this.addedFn);
      } else {
        window.removeEventListener("click", this.addedFn);
      }
    },
    onRegistToggle(evt) {
      // xe-icon-list내에 모든 element에 대한 click을 검사해야 하지만, 현재는 탭버튼만 class로 비교함..
      if (evt.target.classList.contains("xe-icon-tab")) {
        return;
      }

      this.isShowPicker = false;
    },
    onChangeIcon(icon) {
      this.searchText = "";
      this.newIcon = "";

      if (icon) {
        this.searchText = icon.title;
        this.newIcon = icon.icon;
  
      }

      this.$emit("on-change", icon);
    },
    toggleTabs(idx) {
      this.tabIdx = idx;
    },
  },
};
</script>

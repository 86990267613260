<template>
	<div class="SelectPointGroupModal">
		<b-table striped hover :items="smallCodeList" @row-clicked="rowClick" show-empty> 
       <template #empty="scope">
      <h4>{{ $t("데이터가 존재하지 않습니다.") }}</h4>
    </template>
  </b-table>
	</div>
</template>

<script>
	import backEndApi from "@src/api/backEndApi";

	export default {
		name: "SelectPointGroupModal",
		props: ["pointGroupCode"],
		data() {
			return {
				smallCodeList: [],
			};
		},
		async mounted() {
			let result = await backEndApi.publicCode.getSmallCodeList(this.pointGroupCode);

			for (let i = 0; i < result.data.length; i++) {
				this.smallCodeList.push({
					"관제점 코드": result.data[i].smallCode,
					"관제점 코드명": result.data[i].smallCodeName,
				});
			}

			console.log(result);
		},
		methods: {
			rowClick(row) {
        this.$emit('hideModal', {ptAddr: row['관제점 코드'], ptName: row['관제점 코드명']});
				// console.log(a, b, c, d);
			},
		},
	};
</script>

<style></style>

const FontAwesome = [
  { title: "address-book", icon: "fas fa-address-book" },
  { title: "address-card", icon: "fas fa-address-card" },
  { title: "adjust", icon: "fas fa-adjust" },
  { title: "align-center", icon: "fas fa-align-center" },
  { title: "align-justify", icon: "fas fa-align-justify" },
  { title: "align-left", icon: "fas fa-align-left" },
  { title: "align-right", icon: "fas fa-align-right" },
  { title: "ambulance", icon: "fas fa-ambulance" },
  {
    title: "american-sign-language-interpreting",
    icon: "fas fa-american-sign-language-interpreting",
  },
  { title: "anchor", icon: "fas fa-anchor" },
  { title: "angle-double-down", icon: "fas fa-angle-double-down" },
  { title: "angle-double-left", icon: "fas fa-angle-double-left" },
  { title: "angle-double-right", icon: "fas fa-angle-double-right" },
  { title: "angle-double-up", icon: "fas fa-angle-double-up" },
  { title: "angle-down", icon: "fas fa-angle-down" },
  { title: "angle-left", icon: "fas fa-angle-left" },
  { title: "angle-right", icon: "fas fa-angle-right" },
  { title: "angle-up", icon: "fas fa-angle-up" },
  { title: "archive", icon: "fas fa-archive" },
  { title: "arrow-alt-circle-down", icon: "fas fa-arrow-alt-circle-down" },
  { title: "arrow-alt-circle-left", icon: "fas fa-arrow-alt-circle-left" },
  { title: "arrow-alt-circle-right", icon: "fas fa-arrow-alt-circle-right" },
  { title: "arrow-alt-circle-up", icon: "fas fa-arrow-alt-circle-up" },
  { title: "arrow-circle-down", icon: "fas fa-arrow-circle-down" },
  { title: "arrow-circle-left", icon: "fas fa-arrow-circle-left" },
  { title: "arrow-circle-right", icon: "fas fa-arrow-circle-right" },
  { title: "arrow-circle-up", icon: "fas fa-arrow-circle-up" },
  { title: "arrow-down", icon: "fas fa-arrow-down" },
  { title: "arrow-left", icon: "fas fa-arrow-left" },
  { title: "arrow-right", icon: "fas fa-arrow-right" },
  { title: "arrow-up", icon: "fas fa-arrow-up" },
  { title: "arrows-alt", icon: "fas fa-arrows-alt" },
  { title: "arrows-alt-h", icon: "fas fa-arrows-alt-h" },
  { title: "arrows-alt-v", icon: "fas fa-arrows-alt-v" },
  {
    title: "assistive-listening-systems",
    icon: "fas fa-assistive-listening-systems",
  },
  { title: "asterisk", icon: "fas fa-asterisk" },
  { title: "at", icon: "fas fa-at" },
  { title: "audio-description", icon: "fas fa-audio-description" },
  { title: "backward", icon: "fas fa-backward" },
  { title: "balance-scale", icon: "fas fa-balance-scale" },
  { title: "ban", icon: "fas fa-ban" },
  { title: "barcode", icon: "fas fa-barcode" },
  { title: "bars", icon: "fas fa-bars" },
  { title: "bath", icon: "fas fa-bath" },
  { title: "battery-empty", icon: "fas fa-battery-empty" },
  { title: "battery-full", icon: "fas fa-battery-full" },
  { title: "battery-half", icon: "fas fa-battery-half" },
  { title: "battery-quarter", icon: "fas fa-battery-quarter" },
  { title: "battery-three-quarters", icon: "fas fa-battery-three-quarters" },
  { title: "bed", icon: "fas fa-bed" },
  { title: "beer", icon: "fas fa-beer" },
  { title: "bell", icon: "fas fa-bell" },
  { title: "bell-slash", icon: "fas fa-bell-slash" },
  { title: "bicycle", icon: "fas fa-bicycle" },
  { title: "binoculars", icon: "fas fa-binoculars" },
  { title: "birthday-cake", icon: "fas fa-birthday-cake" },
  { title: "blind", icon: "fas fa-blind" },
  { title: "bold", icon: "fas fa-bold" },
  { title: "bolt", icon: "fas fa-bolt" },
  { title: "bomb", icon: "fas fa-bomb" },
  { title: "book", icon: "fas fa-book" },
  { title: "bookmark", icon: "fas fa-bookmark" },
  { title: "braille", icon: "fas fa-braille" },
  { title: "briefcase", icon: "fas fa-briefcase" },
  { title: "bug", icon: "fas fa-bug" },
  { title: "building", icon: "fas fa-building" },
  { title: "bullhorn", icon: "fas fa-bullhorn" },
  { title: "bullseye", icon: "fas fa-bullseye" },
  { title: "bus", icon: "fas fa-bus" },
  { title: "calculator", icon: "fas fa-calculator" },
  { title: "calendar", icon: "fas fa-calendar" },
  { title: "calendar-alt", icon: "fas fa-calendar-alt" },
  { title: "calendar-check", icon: "fas fa-calendar-check" },
  { title: "calendar-minus", icon: "fas fa-calendar-minus" },
  { title: "calendar-plus", icon: "fas fa-calendar-plus" },
  { title: "calendar-times", icon: "fas fa-calendar-times" },
  { title: "camera", icon: "fas fa-camera" },
  { title: "camera-retro", icon: "fas fa-camera-retro" },
  { title: "car", icon: "fas fa-car" },
  { title: "caret-down", icon: "fas fa-caret-down" },
  { title: "caret-left", icon: "fas fa-caret-left" },
  { title: "caret-right", icon: "fas fa-caret-right" },
  { title: "caret-square-down", icon: "fas fa-caret-square-down" },
  { title: "caret-square-left", icon: "fas fa-caret-square-left" },
  { title: "caret-square-right", icon: "fas fa-caret-square-right" },
  { title: "caret-square-up", icon: "fas fa-caret-square-up" },
  { title: "caret-up", icon: "fas fa-caret-up" },
  { title: "cart-arrow-down", icon: "fas fa-cart-arrow-down" },
  { title: "cart-plus", icon: "fas fa-cart-plus" },
  { title: "certificate", icon: "fas fa-certificate" },
  { title: "chart-area", icon: "fas fa-chart-area" },
  { title: "chart-bar", icon: "fas fa-chart-bar" },
  { title: "chart-line", icon: "fas fa-chart-line" },
  { title: "chart-pie", icon: "fas fa-chart-pie" },
  { title: "check", icon: "fas fa-check" },
  { title: "check-circle", icon: "fas fa-check-circle" },
  { title: "check-square", icon: "fas fa-check-square" },
  { title: "chevron-circle-down", icon: "fas fa-chevron-circle-down" },
  { title: "chevron-circle-left", icon: "fas fa-chevron-circle-left" },
  { title: "chevron-circle-right", icon: "fas fa-chevron-circle-right" },
  { title: "chevron-circle-up", icon: "fas fa-chevron-circle-up" },
  { title: "chevron-down", icon: "fas fa-chevron-down" },
  { title: "chevron-left", icon: "fas fa-chevron-left" },
  { title: "chevron-right", icon: "fas fa-chevron-right" },
  { title: "chevron-up", icon: "fas fa-chevron-up" },
  { title: "child", icon: "fas fa-child" },
  { title: "circle", icon: "fas fa-circle" },
  { title: "circle-notch", icon: "fas fa-circle-notch" },
  { title: "clipboard", icon: "fas fa-clipboard" },
  { title: "clock", icon: "fas fa-clock" },
  { title: "clone", icon: "fas fa-clone" },
  { title: "closed-captioning", icon: "fas fa-closed-captioning" },
  { title: "cloud", icon: "fas fa-cloud" },
  { title: "cloud-download-alt", icon: "fas fa-cloud-download-alt" },
  { title: "cloud-upload-alt", icon: "fas fa-cloud-upload-alt" },
  { title: "code", icon: "fas fa-code" },
  { title: "code-branch", icon: "fas fa-code-branch" },
  { title: "coffee", icon: "fas fa-coffee" },
  { title: "cog", icon: "fas fa-cog" },
  { title: "cogs", icon: "fas fa-cogs" },
  { title: "columns", icon: "fas fa-columns" },
  { title: "comment", icon: "fas fa-comment" },
  { title: "comment-alt", icon: "fas fa-comment-alt" },
  { title: "comments", icon: "fas fa-comments" },
  { title: "compass", icon: "fas fa-compass" },
  { title: "compress", icon: "fas fa-compress" },
  { title: "copy", icon: "fas fa-copy" },
  { title: "copyright", icon: "fas fa-copyright" },
  { title: "credit-card", icon: "fas fa-credit-card" },
  { title: "crop", icon: "fas fa-crop" },
  { title: "crosshairs", icon: "fas fa-crosshairs" },
  { title: "cube", icon: "fas fa-cube" },
  { title: "cubes", icon: "fas fa-cubes" },
  { title: "cut", icon: "fas fa-cut" },
  { title: "database", icon: "fas fa-database" },
  { title: "deaf", icon: "fas fa-deaf" },
  { title: "desktop", icon: "fas fa-desktop" },
  { title: "dollar-sign", icon: "fas fa-dollar-sign" },
  { title: "dot-circle", icon: "fas fa-dot-circle" },
  { title: "download", icon: "fas fa-download" },
  { title: "edit", icon: "fas fa-edit" },
  { title: "eject", icon: "fas fa-eject" },
  { title: "ellipsis-h", icon: "fas fa-ellipsis-h" },
  { title: "ellipsis-v", icon: "fas fa-ellipsis-v" },
  { title: "envelope", icon: "fas fa-envelope" },
  { title: "envelope-open", icon: "fas fa-envelope-open" },
  { title: "envelope-square", icon: "fas fa-envelope-square" },
  { title: "eraser", icon: "fas fa-eraser" },
  { title: "euro-sign", icon: "fas fa-euro-sign" },
  { title: "exchange-alt", icon: "fas fa-exchange-alt" },
  { title: "exclamation", icon: "fas fa-exclamation" },
  { title: "exclamation-circle", icon: "fas fa-exclamation-circle" },
  { title: "exclamation-triangle", icon: "fas fa-exclamation-triangle" },
  { title: "expand", icon: "fas fa-expand" },
  { title: "expand-arrows-alt", icon: "fas fa-expand-arrows-alt" },
  { title: "external-link-alt", icon: "fas fa-external-link-alt" },
  {
    title: "external-link-square-alt",
    icon: "fas fa-external-link-square-alt",
  },
  { title: "eye", icon: "fas fa-eye" },
  { title: "eye-dropper", icon: "fas fa-eye-dropper" },
  { title: "eye-slash", icon: "fas fa-eye-slash" },
  { title: "fast-backward", icon: "fas fa-fast-backward" },
  { title: "fast-forward", icon: "fas fa-fast-forward" },
  { title: "fax", icon: "fas fa-fax" },
  { title: "female", icon: "fas fa-female" },
  { title: "fighter-jet", icon: "fas fa-fighter-jet" },
  { title: "file", icon: "fas fa-file" },
  { title: "file-alt", icon: "fas fa-file-alt" },
  { title: "file-archive", icon: "fas fa-file-archive" },
  { title: "file-audio", icon: "fas fa-file-audio" },
  { title: "file-code", icon: "fas fa-file-code" },
  { title: "file-excel", icon: "fas fa-file-excel" },
  { title: "file-image", icon: "fas fa-file-image" },
  { title: "file-pdf", icon: "fas fa-file-pdf" },
  { title: "file-powerpoint", icon: "fas fa-file-powerpoint" },
  { title: "file-video", icon: "fas fa-file-video" },
  { title: "file-word", icon: "fas fa-file-word" },
  { title: "film", icon: "fas fa-film" },
  { title: "filter", icon: "fas fa-filter" },
  { title: "fire", icon: "fas fa-fire" },
  { title: "fire-extinguisher", icon: "fas fa-fire-extinguisher" },
  { title: "flag", icon: "fas fa-flag" },
  { title: "flag-checkered", icon: "fas fa-flag-checkered" },
  { title: "flask", icon: "fas fa-flask" },
  { title: "folder", icon: "fas fa-folder" },
  { title: "folder-open", icon: "fas fa-folder-open" },
  { title: "font", icon: "fas fa-font" },
  { title: "forward", icon: "fas fa-forward" },
  { title: "frown", icon: "fas fa-frown" },
  { title: "futbol", icon: "fas fa-futbol" },
  { title: "gamepad", icon: "fas fa-gamepad" },
  { title: "gavel", icon: "fas fa-gavel" },
  { title: "gem", icon: "fas fa-gem" },
  { title: "genderless", icon: "fas fa-genderless" },
  { title: "gift", icon: "fas fa-gift" },
  { title: "glass-martini", icon: "fas fa-glass-martini" },
  { title: "globe", icon: "fas fa-globe" },
  { title: "graduation-cap", icon: "fas fa-graduation-cap" },
  { title: "h-square", icon: "fas fa-h-square" },
  { title: "hand-lizard", icon: "fas fa-hand-lizard" },
  { title: "hand-paper", icon: "fas fa-hand-paper" },
  { title: "hand-peace", icon: "fas fa-hand-peace" },
  { title: "hand-point-down", icon: "fas fa-hand-point-down" },
  { title: "hand-point-left", icon: "fas fa-hand-point-left" },
  { title: "hand-point-right", icon: "fas fa-hand-point-right" },
  { title: "hand-point-up", icon: "fas fa-hand-point-up" },
  { title: "hand-pointer", icon: "fas fa-hand-pointer" },
  { title: "hand-rock", icon: "fas fa-hand-rock" },
  { title: "hand-scissors", icon: "fas fa-hand-scissors" },
  { title: "hand-spock", icon: "fas fa-hand-spock" },
  { title: "handshake", icon: "fas fa-handshake" },
  { title: "hashtag", icon: "fas fa-hashtag" },
  { title: "hdd", icon: "fas fa-hdd" },
  { title: "heading", icon: "fas fa-heading" },
  { title: "headphones", icon: "fas fa-headphones" },
  { title: "heart", icon: "fas fa-heart" },
  { title: "heartbeat", icon: "fas fa-heartbeat" },
  { title: "history", icon: "fas fa-history" },
  { title: "home", icon: "fas fa-home" },
  { title: "hospital", icon: "fas fa-hospital" },
  { title: "hourglass", icon: "fas fa-hourglass" },
  { title: "hourglass-end", icon: "fas fa-hourglass-end" },
  { title: "hourglass-half", icon: "fas fa-hourglass-half" },
  { title: "hourglass-start", icon: "fas fa-hourglass-start" },
  { title: "i-cursor", icon: "fas fa-i-cursor" },
  { title: "id-badge", icon: "fas fa-id-badge" },
  { title: "id-card", icon: "fas fa-id-card" },
  { title: "image", icon: "fas fa-image" },
  { title: "images", icon: "fas fa-images" },
  { title: "inbox", icon: "fas fa-inbox" },
  { title: "indent", icon: "fas fa-indent" },
  { title: "industry", icon: "fas fa-industry" },
  { title: "info", icon: "fas fa-info" },
  { title: "info-circle", icon: "fas fa-info-circle" },
  { title: "italic", icon: "fas fa-italic" },
  { title: "key", icon: "fas fa-key" },
  { title: "keyboard", icon: "fas fa-keyboard" },
  { title: "language", icon: "fas fa-language" },
  { title: "laptop", icon: "fas fa-laptop" },
  { title: "leaf", icon: "fas fa-leaf" },
  { title: "lemon", icon: "fas fa-lemon" },
  { title: "level-down-alt", icon: "fas fa-level-down-alt" },
  { title: "level-up-alt", icon: "fas fa-level-up-alt" },
  { title: "life-ring", icon: "fas fa-life-ring" },
  { title: "lightbulb", icon: "fas fa-lightbulb" },
  { title: "link", icon: "fas fa-link" },
  { title: "lira-sign", icon: "fas fa-lira-sign" },
  { title: "list", icon: "fas fa-list" },
  { title: "list-alt", icon: "fas fa-list-alt" },
  { title: "list-ol", icon: "fas fa-list-ol" },
  { title: "list-ul", icon: "fas fa-list-ul" },
  { title: "location-arrow", icon: "fas fa-location-arrow" },
  { title: "lock", icon: "fas fa-lock" },
  { title: "lock-open", icon: "fas fa-lock-open" },
  { title: "long-arrow-alt-down", icon: "fas fa-long-arrow-alt-down" },
  { title: "long-arrow-alt-left", icon: "fas fa-long-arrow-alt-left" },
  { title: "long-arrow-alt-right", icon: "fas fa-long-arrow-alt-right" },
  { title: "long-arrow-alt-up", icon: "fas fa-long-arrow-alt-up" },
  { title: "low-vision", icon: "fas fa-low-vision" },
  { title: "magic", icon: "fas fa-magic" },
  { title: "magnet", icon: "fas fa-magnet" },
  { title: "male", icon: "fas fa-male" },
  { title: "map", icon: "fas fa-map" },
  { title: "map-marker", icon: "fas fa-map-marker" },
  { title: "map-marker-alt", icon: "fas fa-map-marker-alt" },
  { title: "map-pin", icon: "fas fa-map-pin" },
  { title: "map-signs", icon: "fas fa-map-signs" },
  { title: "mars", icon: "fas fa-mars" },
  { title: "mars-double", icon: "fas fa-mars-double" },
  { title: "mars-stroke", icon: "fas fa-mars-stroke" },
  { title: "mars-stroke-h", icon: "fas fa-mars-stroke-h" },
  { title: "mars-stroke-v", icon: "fas fa-mars-stroke-v" },
  { title: "medkit", icon: "fas fa-medkit" },
  { title: "meh", icon: "fas fa-meh" },
  { title: "mercury", icon: "fas fa-mercury" },
  { title: "microchip", icon: "fas fa-microchip" },
  { title: "microphone", icon: "fas fa-microphone" },
  { title: "microphone-slash", icon: "fas fa-microphone-slash" },
  { title: "minus", icon: "fas fa-minus" },
  { title: "minus-circle", icon: "fas fa-minus-circle" },
  { title: "minus-square", icon: "fas fa-minus-square" },
  { title: "mobile", icon: "fas fa-mobile" },
  { title: "mobile-alt", icon: "fas fa-mobile-alt" },
  { title: "money-bill-alt", icon: "fas fa-money-bill-alt" },
  { title: "moon", icon: "fas fa-moon" },
  { title: "motorcycle", icon: "fas fa-motorcycle" },
  { title: "mouse-pointer", icon: "fas fa-mouse-pointer" },
  { title: "music", icon: "fas fa-music" },
  { title: "neuter", icon: "fas fa-neuter" },
  { title: "newspaper", icon: "fas fa-newspaper" },
  { title: "object-group", icon: "fas fa-object-group" },
  { title: "object-ungroup", icon: "fas fa-object-ungroup" },
  { title: "outdent", icon: "fas fa-outdent" },
  { title: "paint-brush", icon: "fas fa-paint-brush" },
  { title: "paper-plane", icon: "fas fa-paper-plane" },
  { title: "paperclip", icon: "fas fa-paperclip" },
  { title: "paragraph", icon: "fas fa-paragraph" },
  { title: "paste", icon: "fas fa-paste" },
  { title: "pause", icon: "fas fa-pause" },
  { title: "pause-circle", icon: "fas fa-pause-circle" },
  { title: "paw", icon: "fas fa-paw" },
  { title: "pen-square", icon: "fas fa-pen-square" },
  { title: "pencil-alt", icon: "fas fa-pencil-alt" },
  { title: "percent", icon: "fas fa-percent" },
  { title: "phone", icon: "fas fa-phone" },
  { title: "phone-square", icon: "fas fa-phone-square" },
  { title: "phone-volume", icon: "fas fa-phone-volume" },
  { title: "plane", icon: "fas fa-plane" },
  { title: "play", icon: "fas fa-play" },
  { title: "play-circle", icon: "fas fa-play-circle" },
  { title: "plug", icon: "fas fa-plug" },
  { title: "plus", icon: "fas fa-plus" },
  { title: "plus-circle", icon: "fas fa-plus-circle" },
  { title: "plus-square", icon: "fas fa-plus-square" },
  { title: "podcast", icon: "fas fa-podcast" },
  { title: "pound-sign", icon: "fas fa-pound-sign" },
  { title: "power-off", icon: "fas fa-power-off" },
  { title: "print", icon: "fas fa-print" },
  { title: "puzzle-piece", icon: "fas fa-puzzle-piece" },
  { title: "qrcode", icon: "fas fa-qrcode" },
  { title: "question", icon: "fas fa-question" },
  { title: "question-circle", icon: "fas fa-question-circle" },
  { title: "quote-left", icon: "fas fa-quote-left" },
  { title: "quote-right", icon: "fas fa-quote-right" },
  { title: "random", icon: "fas fa-random" },
  { title: "recycle", icon: "fas fa-recycle" },
  { title: "redo", icon: "fas fa-redo" },
  { title: "redo-alt", icon: "fas fa-redo-alt" },
  { title: "registered", icon: "fas fa-registered" },
  { title: "reply", icon: "fas fa-reply" },
  { title: "reply-all", icon: "fas fa-reply-all" },
  { title: "retweet", icon: "fas fa-retweet" },
  { title: "road", icon: "fas fa-road" },
  { title: "rocket", icon: "fas fa-rocket" },
  { title: "rss", icon: "fas fa-rss" },
  { title: "rss-square", icon: "fas fa-rss-square" },
  { title: "ruble-sign", icon: "fas fa-ruble-sign" },
  { title: "rupee-sign", icon: "fas fa-rupee-sign" },
  { title: "save", icon: "fas fa-save" },
  { title: "search", icon: "fas fa-search" },
  { title: "search-minus", icon: "fas fa-search-minus" },
  { title: "search-plus", icon: "fas fa-search-plus" },
  { title: "server", icon: "fas fa-server" },
  { title: "share", icon: "fas fa-share" },
  { title: "share-alt", icon: "fas fa-share-alt" },
  { title: "share-alt-square", icon: "fas fa-share-alt-square" },
  { title: "share-square", icon: "fas fa-share-square" },
  { title: "shekel-sign", icon: "fas fa-shekel-sign" },
  { title: "shield-alt", icon: "fas fa-shield-alt" },
  { title: "ship", icon: "fas fa-ship" },
  { title: "shopping-bag", icon: "fas fa-shopping-bag" },
  { title: "shopping-basket", icon: "fas fa-shopping-basket" },
  { title: "shopping-cart", icon: "fas fa-shopping-cart" },
  { title: "shower", icon: "fas fa-shower" },
  { title: "sign-in-alt", icon: "fas fa-sign-in-alt" },
  { title: "sign-language", icon: "fas fa-sign-language" },
  { title: "sign-out-alt", icon: "fas fa-sign-out-alt" },
  { title: "signal", icon: "fas fa-signal" },
  { title: "sitemap", icon: "fas fa-sitemap" },
  { title: "sliders-h", icon: "fas fa-sliders-h" },
  { title: "smile", icon: "fas fa-smile" },
  { title: "snowflake", icon: "fas fa-snowflake" },
  { title: "sort", icon: "fas fa-sort" },
  { title: "sort-alpha-down", icon: "fas fa-sort-alpha-down" },
  { title: "sort-alpha-up", icon: "fas fa-sort-alpha-up" },
  { title: "sort-amount-down", icon: "fas fa-sort-amount-down" },
  { title: "sort-amount-up", icon: "fas fa-sort-amount-up" },
  { title: "sort-down", icon: "fas fa-sort-down" },
  { title: "sort-numeric-down", icon: "fas fa-sort-numeric-down" },
  { title: "sort-numeric-up", icon: "fas fa-sort-numeric-up" },
  { title: "sort-up", icon: "fas fa-sort-up" },
  { title: "space-shuttle", icon: "fas fa-space-shuttle" },
  { title: "spinner", icon: "fas fa-spinner" },
  { title: "square", icon: "fas fa-square" },
  { title: "star", icon: "fas fa-star" },
  { title: "star-half", icon: "fas fa-star-half" },
  { title: "step-backward", icon: "fas fa-step-backward" },
  { title: "step-forward", icon: "fas fa-step-forward" },
  { title: "stethoscope", icon: "fas fa-stethoscope" },
  { title: "sticky-note", icon: "fas fa-sticky-note" },
  { title: "stop", icon: "fas fa-stop" },
  { title: "stop-circle", icon: "fas fa-stop-circle" },
  { title: "stopwatch", icon: "fas fa-stopwatch" },
  { title: "street-view", icon: "fas fa-street-view" },
  { title: "strikethrough", icon: "fas fa-strikethrough" },
  { title: "subscript", icon: "fas fa-subscript" },
  { title: "subway", icon: "fas fa-subway" },
  { title: "suitcase", icon: "fas fa-suitcase" },
  { title: "sun", icon: "fas fa-sun" },
  { title: "superscript", icon: "fas fa-superscript" },
  { title: "sync", icon: "fas fa-sync" },
  { title: "sync-alt", icon: "fas fa-sync-alt" },
  { title: "table", icon: "fas fa-table" },
  { title: "tablet", icon: "fas fa-tablet" },
  { title: "tablet-alt", icon: "fas fa-tablet-alt" },
  { title: "tachometer-alt", icon: "fas fa-tachometer-alt" },
  { title: "tag", icon: "fas fa-tag" },
  { title: "tags", icon: "fas fa-tags" },
  { title: "tasks", icon: "fas fa-tasks" },
  { title: "taxi", icon: "fas fa-taxi" },
  { title: "terminal", icon: "fas fa-terminal" },
  { title: "text-height", icon: "fas fa-text-height" },
  { title: "text-width", icon: "fas fa-text-width" },
  { title: "th", icon: "fas fa-th" },
  { title: "th-large", icon: "fas fa-th-large" },
  { title: "th-list", icon: "fas fa-th-list" },
  { title: "thermometer-empty", icon: "fas fa-thermometer-empty" },
  { title: "thermometer-full", icon: "fas fa-thermometer-full" },
  { title: "thermometer-half", icon: "fas fa-thermometer-half" },
  { title: "thermometer-quarter", icon: "fas fa-thermometer-quarter" },
  {
    title: "thermometer-three-quarters",
    icon: "fas fa-thermometer-three-quarters",
  },
  { title: "thumbs-down", icon: "fas fa-thumbs-down" },
  { title: "thumbs-up", icon: "fas fa-thumbs-up" },
  { title: "thumbtack", icon: "fas fa-thumbtack" },
  { title: "ticket-alt", icon: "fas fa-ticket-alt" },
  { title: "times", icon: "fas fa-times" },
  { title: "times-circle", icon: "fas fa-times-circle" },
  { title: "tint", icon: "fas fa-tint" },
  { title: "toggle-off", icon: "fas fa-toggle-off" },
  { title: "toggle-on", icon: "fas fa-toggle-on" },
  { title: "trademark", icon: "fas fa-trademark" },
  { title: "train", icon: "fas fa-train" },
  { title: "transgender", icon: "fas fa-transgender" },
  { title: "transgender-alt", icon: "fas fa-transgender-alt" },
  { title: "trash", icon: "fas fa-trash" },
  { title: "trash-alt", icon: "fas fa-trash-alt" },
  { title: "tree", icon: "fas fa-tree" },
  { title: "trophy", icon: "fas fa-trophy" },
  { title: "truck", icon: "fas fa-truck" },
  { title: "tty", icon: "fas fa-tty" },
  { title: "tv", icon: "fas fa-tv" },
  { title: "umbrella", icon: "fas fa-umbrella" },
  { title: "underline", icon: "fas fa-underline" },
  { title: "undo", icon: "fas fa-undo" },
  { title: "undo-alt", icon: "fas fa-undo-alt" },
  { title: "universal-access", icon: "fas fa-universal-access" },
  { title: "university", icon: "fas fa-university" },
  { title: "unlink", icon: "fas fa-unlink" },
  { title: "unlock", icon: "fas fa-unlock" },
  { title: "unlock-alt", icon: "fas fa-unlock-alt" },
  { title: "upload", icon: "fas fa-upload" },
  { title: "user", icon: "fas fa-user" },
  { title: "user-circle", icon: "fas fa-user-circle" },
  { title: "user-md", icon: "fas fa-user-md" },
  { title: "user-plus", icon: "fas fa-user-plus" },
  { title: "user-secret", icon: "fas fa-user-secret" },
  { title: "user-times", icon: "fas fa-user-times" },
  { title: "users", icon: "fas fa-users" },
  { title: "utensil-spoon", icon: "fas fa-utensil-spoon" },
  { title: "utensils", icon: "fas fa-utensils" },
  { title: "venus", icon: "fas fa-venus" },
  { title: "venus-double", icon: "fas fa-venus-double" },
  { title: "venus-mars", icon: "fas fa-venus-mars" },
  { title: "video", icon: "fas fa-video" },
  { title: "volume-down", icon: "fas fa-volume-down" },
  { title: "volume-off", icon: "fas fa-volume-off" },
  { title: "volume-up", icon: "fas fa-volume-up" },
  { title: "wheelchair", icon: "fas fa-wheelchair" },
  { title: "wifi", icon: "fas fa-wifi" },
  { title: "window-close", icon: "fas fa-window-close" },
  { title: "window-maximize", icon: "fas fa-window-maximize" },
  { title: "window-minimize", icon: "fas fa-window-minimize" },
  { title: "window-restore", icon: "fas fa-window-restore" },
  { title: "won-sign", icon: "fas fa-won-sign" },
  { title: "wrench", icon: "fas fa-wrench" },
  { title: "yen-sign", icon: "fas fa-yen-sign" },
];

const Icons = [
  { title: "user", icon: "icon-user" },
  { title: "user-female", icon: "icon-user-female" },
  { title: "user", icon: "icon-user" },
  { title: "user-follow", icon: "icon-user-follow" },
  { title: "user-following", icon: "icon-user-following" },
  { title: "user-unfollow", icon: "icon-user-unfollow" },
  { title: "trophy", icon: "icon-trophy" },
  { title: "speedometer", icon: "icon-speedometer" },
  { title: "social-youtube", icon: "icon-social-youtube" },
  { title: "social-twitter", icon: "icon-social-twitter" },
  { title: "social-tumblr", icon: "icon-social-tumblr" },
  { title: "social-facebook", icon: "icon-social-facebook" },
  { title: "social-dropbox", icon: "icon-social-dropbox" },
  { title: "social-dribbble", icon: "icon-social-dribbble" },
  { title: "shield", icon: "icon-shield" },
  { title: "screen-tablet", icon: "icon-screen-tablet" },
  { title: "screen-smartphone", icon: "icon-screen-smartphone" },
  { title: "screen-desktop", icon: "icon-screen-desktop" },
  { title: "plane", icon: "icon-plane" },
  { title: "notebook", icon: "icon-notebook" },
  { title: "mustache", icon: "icon-mustache" },
  { title: "mouse", icon: "icon-mouse" },
  { title: "magnet", icon: "icon-magnet" },
  { title: "magic-wand", icon: "icon-magic-wand" },
  { title: "hourglass", icon: "icon-hourglass" },
  { title: "graduation", icon: "icon-graduation" },
  { title: "ghost", icon: "icon-ghost" },
  { title: "game-controller", icon: "icon-game-controller" },
  { title: "fire", icon: "icon-fire" },
  { title: "eyeglass", icon: "icon-eyeglass" },
  { title: "envelope-open", icon: "icon-envelope-open" },
  { title: "envelope-letter", icon: "icon-envelope-letter" },
  { title: "energy", icon: "icon-energy" },
  { title: "emotsmile", icon: "icon-emotsmile" },
  { title: "disc", icon: "icon-disc" },
  { title: "cursor-move", icon: "icon-cursor-move" },
  { title: "crop", icon: "icon-crop" },
  { title: "credit-card", icon: "icon-credit-card" },
  { title: "chemistry", icon: "icon-chemistry" },
  { title: "bell", icon: "icon-bell" },
  { title: "badge", icon: "icon-badge" },
  { title: "anchor", icon: "icon-anchor" },
  { title: "action-redo", icon: "icon-action-redo" },
  { title: "action-undo", icon: "icon-action-undo" },
  { title: "bag", icon: "icon-bag" },
  { title: "basket", icon: "icon-basket" },
  { title: "basket-loaded", icon: "icon-basket-loaded" },
  { title: "book-open", icon: "icon-book-open" },
  { title: "briefcase", icon: "icon-briefcase" },
  { title: "bubbles", icon: "icon-bubbles" },
  { title: "calculator", icon: "icon-calculator" },
  { title: "call-end", icon: "icon-call-end" },
  { title: "call-in", icon: "icon-call-in" },
  { title: "call-out", icon: "icon-call-out" },
  { title: "compass", icon: "icon-compass" },
  { title: "cup", icon: "icon-cup" },
  { title: "diamond", icon: "icon-diamond" },
  { title: "direction", icon: "icon-direction" },
  { title: "directions", icon: "icon-directions" },
  { title: "docs", icon: "icon-docs" },
  { title: "drawer", icon: "icon-drawer" },
  { title: "drop", icon: "icon-drop" },
  { title: "earphones", icon: "icon-earphones" },
  { title: "earphones-alt", icon: "icon-earphones-alt" },
  { title: "feed", icon: "icon-feed" },
  { title: "film", icon: "icon-film" },
  { title: "folder-alt", icon: "icon-folder-alt" },
  { title: "frame", icon: "icon-frame" },
  { title: "globe", icon: "icon-globe" },
  { title: "globe-alt", icon: "icon-globe-alt" },
  { title: "handbag", icon: "icon-handbag" },
  { title: "layers", icon: "icon-layers" },
  { title: "map", icon: "icon-map" },
  { title: "picture", icon: "icon-picture" },
  { title: "pin", icon: "icon-pin" },
  { title: "playlist", icon: "icon-playlist" },
  { title: "present", icon: "icon-present" },
  { title: "printer", icon: "icon-printer" },
  { title: "puzzle", icon: "icon-puzzle" },
  { title: "speech", icon: "icon-speech" },
  { title: "vector", icon: "icon-vector" },
  { title: "wallet", icon: "icon-wallet" },
  { title: "arrow-down", icon: "icon-arrow-down" },
  { title: "arrow-left", icon: "icon-arrow-left" },
  { title: "arrow-right", icon: "icon-arrow-right" },
  { title: "arrow-up", icon: "icon-arrow-up" },
  { title: "chart", icon: "icon-chart" },
  { title: "bulb", icon: "icon-bulb" },
  { title: "calendar", icon: "icon-calendar" },
  { title: "control-end", icon: "icon-control-end" },
  { title: "control-forward", icon: "icon-control-forward" },
  { title: "control-pause", icon: "icon-control-pause" },
  { title: "control-play", icon: "icon-control-play" },
  { title: "control-rewind", icon: "icon-control-rewind" },
  { title: "control-start", icon: "icon-control-start" },
  { title: "cursor", icon: "icon-cursor" },
  { title: "dislike", icon: "icon-dislike" },
  { title: "equalizer", icon: "icon-equalizer" },
  { title: "graph", icon: "icon-graph" },
  { title: "grid", icon: "icon-grid" },
  { title: "home", icon: "icon-home" },
  { title: "like", icon: "icon-like" },
  { title: "list", icon: "icon-list" },
  { title: "login", icon: "icon-login" },
  { title: "logout", icon: "icon-logout" },
  { title: "loop", icon: "icon-loop" },
  { title: "microphone", icon: "icon-microphone" },
  { title: "music-tone", icon: "icon-music-tone" },
  { title: "music-tone-alt", icon: "icon-music-tone-alt" },
  { title: "note", icon: "icon-note" },
  { title: "pencil", icon: "icon-pencil" },
  { title: "pie-chart", icon: "icon-pie-chart" },
  { title: "question", icon: "icon-question" },
  { title: "rocket", icon: "icon-rocket" },
  { title: "share", icon: "icon-share" },
  { title: "share-alt", icon: "icon-share-alt" },
  { title: "shuffle", icon: "icon-shuffle" },
  { title: "size-actual", icon: "icon-size-actual" },
  { title: "size-fullscreen", icon: "icon-size-fullscreen" },
  { title: "support", icon: "icon-support" },
  { title: "tag", icon: "icon-tag" },
  { title: "trash", icon: "icon-trash" },
  { title: "umbrella", icon: "icon-umbrella" },
  { title: "wrench", icon: "icon-wrench" },
  { title: "ban", icon: "icon-ban" },
  { title: "bubble", icon: "icon-bubble" },
  { title: "camrecorder", icon: "icon-camrecorder" },
  { title: "camera", icon: "icon-camera" },
  { title: "check", icon: "icon-check" },
  { title: "clock", icon: "icon-clock" },
  { title: "close", icon: "icon-close" },
  { title: "cloud-download", icon: "icon-cloud-download" },
  { title: "cloud-upload", icon: "icon-cloud-upload" },
  { title: "doc", icon: "icon-doc" },
  { title: "envelope", icon: "icon-envelope" },
  { title: "eye", icon: "icon-eye" },
  { title: "flag", icon: "icon-flag" },
  { title: "folder", icon: "icon-folder" },
  { title: "heart", icon: "icon-heart" },
  { title: "info", icon: "icon-info" },
  { title: "key", icon: "icon-key" },
  { title: "link", icon: "icon-link" },
  { title: "lock", icon: "icon-lock" },
  { title: "lock-open", icon: "icon-lock-open" },
  { title: "magnifier", icon: "icon-magnifier" },
  { title: "magnifier-add", icon: "icon-magnifier-add" },
  { title: "magnifier-remove", icon: "icon-magnifier-remove" },
  { title: "paper-clip", icon: "icon-paper-clip" },
  { title: "paper-plane", icon: "icon-paper-plane" },
  { title: "plus", icon: "icon-plus" },
  { title: "location-pin", icon: "icon-location-pin" },
  { title: "power", icon: "icon-power" },
  { title: "refresh", icon: "icon-refresh" },
  { title: "reload", icon: "icon-reload" },
  { title: "settings", icon: "icon-settings" },
  { title: "star", icon: "icon-star" },
  { title: "symbol-female", icon: "icon-symbol-female" },
  { title: "symbol-male", icon: "icon-symbol-male" },
  { title: "target", icon: "icon-target" },
  { title: "volume-1", icon: "icon-volume-1" },
  { title: "volume-2", icon: "icon-volume-2" },
  { title: "volume-off", icon: "icon-volume-off" },
];

const IonMd = [
  { title: "add-circle-outline", icon: "ion-md-add-circle-outline" },
  { title: "add-circle", icon: "ion-md-add-circle" },
  { title: "add", icon: "ion-md-add" },
  { title: "airplane", icon: "ion-md-airplane" },
  { title: "alarm", icon: "ion-md-alarm" },
  { title: "albums", icon: "ion-md-albums" },
  { title: "alert", icon: "ion-md-alert" },
  { title: "american-football", icon: "ion-md-american-football" },
  { title: "analytics", icon: "ion-md-analytics" },
  { title: "aperture", icon: "ion-md-aperture" },
  { title: "apps", icon: "ion-md-apps" },
  { title: "appstore", icon: "ion-md-appstore" },
  { title: "archive", icon: "ion-md-archive" },
  { title: "arrow-back", icon: "ion-md-arrow-back" },
  { title: "arrow-down", icon: "ion-md-arrow-down" },
  { title: "arrow-dropdown-circle", icon: "ion-md-arrow-dropdown-circle" },
  { title: "arrow-dropdown", icon: "ion-md-arrow-dropdown" },
  { title: "arrow-dropleft-circle", icon: "ion-md-arrow-dropleft-circle" },
  { title: "arrow-dropleft", icon: "ion-md-arrow-dropleft" },
  { title: "arrow-dropright-circle", icon: "ion-md-arrow-dropright-circle" },
  { title: "arrow-dropright", icon: "ion-md-arrow-dropright" },
  { title: "arrow-dropup-circle", icon: "ion-md-arrow-dropup-circle" },
  { title: "arrow-dropup", icon: "ion-md-arrow-dropup" },
  { title: "arrow-forward", icon: "ion-md-arrow-forward" },
  { title: "arrow-round-back", icon: "ion-md-arrow-round-back" },
  { title: "arrow-round-down", icon: "ion-md-arrow-round-down" },
  { title: "arrow-round-forward", icon: "ion-md-arrow-round-forward" },
  { title: "arrow-round-up", icon: "ion-md-arrow-round-up" },
  { title: "arrow-up", icon: "ion-md-arrow-up" },
  { title: "at", icon: "ion-md-at" },
  { title: "attach", icon: "ion-md-attach" },
  { title: "backspace", icon: "ion-md-backspace" },
  { title: "barcode", icon: "ion-md-barcode" },
  { title: "baseball", icon: "ion-md-baseball" },
  { title: "basket", icon: "ion-md-basket" },
  { title: "basketball", icon: "ion-md-basketball" },
  { title: "battery-charging", icon: "ion-md-battery-charging" },
  { title: "battery-dead", icon: "ion-md-battery-dead" },
  { title: "battery-full", icon: "ion-md-battery-full" },
  { title: "beaker", icon: "ion-md-beaker" },
  { title: "bed", icon: "ion-md-bed" },
  { title: "beer", icon: "ion-md-beer" },
  { title: "bicycle", icon: "ion-md-bicycle" },
  { title: "bluetooth", icon: "ion-md-bluetooth" },
  { title: "boat", icon: "ion-md-boat" },
  { title: "body", icon: "ion-md-body" },
  { title: "bonfire", icon: "ion-md-bonfire" },
  { title: "book", icon: "ion-md-book" },
  { title: "bookmark", icon: "ion-md-bookmark" },
  { title: "bookmarks", icon: "ion-md-bookmarks" },
  { title: "bowtie", icon: "ion-md-bowtie" },
  { title: "briefcase", icon: "ion-md-briefcase" },
  { title: "browsers", icon: "ion-md-browsers" },
  { title: "brush", icon: "ion-md-brush" },
  { title: "bug", icon: "ion-md-bug" },
  { title: "build", icon: "ion-md-build" },
  { title: "bulb", icon: "ion-md-bulb" },
  { title: "bus", icon: "ion-md-bus" },
  { title: "business", icon: "ion-md-business" },
  { title: "cafe", icon: "ion-md-cafe" },
  { title: "calculator", icon: "ion-md-calculator" },
  { title: "calendar", icon: "ion-md-calendar" },
  { title: "call", icon: "ion-md-call" },
  { title: "camera", icon: "ion-md-camera" },
  { title: "car", icon: "ion-md-car" },
  { title: "card", icon: "ion-md-card" },
  { title: "cart", icon: "ion-md-cart" },
  { title: "cash", icon: "ion-md-cash" },
  { title: "cellular", icon: "ion-md-cellular" },
  { title: "chatboxes", icon: "ion-md-chatboxes" },
  { title: "chatbubbles", icon: "ion-md-chatbubbles" },
  { title: "checkbox-outline", icon: "ion-md-checkbox-outline" },
  { title: "checkbox", icon: "ion-md-checkbox" },
  {
    title: "checkmark-circle-outline",
    icon: "ion-md-checkmark-circle-outline",
  },
  { title: "checkmark-circle", icon: "ion-md-checkmark-circle" },
  { title: "checkmark", icon: "ion-md-checkmark" },
  { title: "clipboard", icon: "ion-md-clipboard" },
  { title: "clock", icon: "ion-md-clock" },
  { title: "close-circle-outline", icon: "ion-md-close-circle-outline" },
  { title: "close-circle", icon: "ion-md-close-circle" },
  { title: "close", icon: "ion-md-close" },
  { title: "cloud-circle", icon: "ion-md-cloud-circle" },
  { title: "cloud-done", icon: "ion-md-cloud-done" },
  { title: "cloud-download", icon: "ion-md-cloud-download" },
  { title: "cloud-outline", icon: "ion-md-cloud-outline" },
  { title: "cloud-upload", icon: "ion-md-cloud-upload" },
  { title: "cloud", icon: "ion-md-cloud" },
  { title: "cloudy-night", icon: "ion-md-cloudy-night" },
  { title: "cloudy", icon: "ion-md-cloudy" },
  { title: "code-download", icon: "ion-md-code-download" },
  { title: "code-working", icon: "ion-md-code-working" },
  { title: "code", icon: "ion-md-code" },
  { title: "cog", icon: "ion-md-cog" },
  { title: "color-fill", icon: "ion-md-color-fill" },
  { title: "color-filter", icon: "ion-md-color-filter" },
  { title: "color-palette", icon: "ion-md-color-palette" },
  { title: "color-wand", icon: "ion-md-color-wand" },
  { title: "compass", icon: "ion-md-compass" },
  { title: "construct", icon: "ion-md-construct" },
  { title: "contact", icon: "ion-md-contact" },
  { title: "contacts", icon: "ion-md-contacts" },
  { title: "contract", icon: "ion-md-contract" },
  { title: "contrast", icon: "ion-md-contrast" },
  { title: "copy", icon: "ion-md-copy" },
  { title: "create", icon: "ion-md-create" },
  { title: "crop", icon: "ion-md-crop" },
  { title: "cube", icon: "ion-md-cube" },
  { title: "cut", icon: "ion-md-cut" },
  { title: "desktop", icon: "ion-md-desktop" },
  { title: "disc", icon: "ion-md-disc" },
  { title: "document", icon: "ion-md-document" },
  { title: "done-all", icon: "ion-md-done-all" },
  { title: "download", icon: "ion-md-download" },
  { title: "easel", icon: "ion-md-easel" },
  { title: "egg", icon: "ion-md-egg" },
  { title: "exit", icon: "ion-md-exit" },
  { title: "expand", icon: "ion-md-expand" },
  { title: "eye-off", icon: "ion-md-eye-off" },
  { title: "eye", icon: "ion-md-eye" },
  { title: "fastforward", icon: "ion-md-fastforward" },
  { title: "female", icon: "ion-md-female" },
  { title: "filing", icon: "ion-md-filing" },
  { title: "film", icon: "ion-md-film" },
  { title: "finger-print", icon: "ion-md-finger-print" },
  { title: "fitness", icon: "ion-md-fitness" },
  { title: "flag", icon: "ion-md-flag" },
  { title: "flame", icon: "ion-md-flame" },
  { title: "flash-off", icon: "ion-md-flash-off" },
  { title: "flash", icon: "ion-md-flash" },
  { title: "flashlight", icon: "ion-md-flashlight" },
  { title: "flask", icon: "ion-md-flask" },
  { title: "flower", icon: "ion-md-flower" },
  { title: "folder-open", icon: "ion-md-folder-open" },
  { title: "folder", icon: "ion-md-folder" },
  { title: "football", icon: "ion-md-football" },
  { title: "funnel", icon: "ion-md-funnel" },
  { title: "gift", icon: "ion-md-gift" },
  { title: "git-branch", icon: "ion-md-git-branch" },
  { title: "git-commit", icon: "ion-md-git-commit" },
  { title: "git-compare", icon: "ion-md-git-compare" },
  { title: "git-merge", icon: "ion-md-git-merge" },
  { title: "git-network", icon: "ion-md-git-network" },
  { title: "git-pull-request", icon: "ion-md-git-pull-request" },
  { title: "glasses", icon: "ion-md-glasses" },
  { title: "globe", icon: "ion-md-globe" },
  { title: "grid", icon: "ion-md-grid" },
  { title: "hammer", icon: "ion-md-hammer" },
  { title: "hand", icon: "ion-md-hand" },
  { title: "happy", icon: "ion-md-happy" },
  { title: "headset", icon: "ion-md-headset" },
  { title: "heart", icon: "ion-md-heart" },
  { title: "heart-dislike", icon: "ion-md-heart-dislike" },
  { title: "heart-empty", icon: "ion-md-heart-empty" },
  { title: "heart-half", icon: "ion-md-heart-half" },
  { title: "help-buoy", icon: "ion-md-help-buoy" },
  { title: "help-circle-outline", icon: "ion-md-help-circle-outline" },
  { title: "help-circle", icon: "ion-md-help-circle" },
  { title: "help", icon: "ion-md-help" },
  { title: "home", icon: "ion-md-home" },
  { title: "hourglass", icon: "ion-md-hourglass" },
  { title: "ice-cream", icon: "ion-md-ice-cream" },
  { title: "image", icon: "ion-md-image" },
  { title: "images", icon: "ion-md-images" },
  { title: "infinite", icon: "ion-md-infinite" },
  {
    title: "information-circle-outline",
    icon: "ion-md-information-circle-outline",
  },
  { title: "information-circle", icon: "ion-md-information-circle" },
  { title: "information", icon: "ion-md-information" },
  { title: "jet", icon: "ion-md-jet" },
  { title: "journal", icon: "ion-md-journal" },
  { title: "key", icon: "ion-md-key" },
  { title: "keypad", icon: "ion-md-keypad" },
  { title: "laptop", icon: "ion-md-laptop" },
  { title: "leaf", icon: "ion-md-leaf" },
  { title: "link", icon: "ion-md-link" },
  { title: "list-box", icon: "ion-md-list-box" },
  { title: "list", icon: "ion-md-list" },
  { title: "locate", icon: "ion-md-locate" },
  { title: "lock", icon: "ion-md-lock" },
  { title: "log-in", icon: "ion-md-log-in" },
  { title: "log-out", icon: "ion-md-log-out" },
  { title: "magnet", icon: "ion-md-magnet" },
  { title: "mail-open", icon: "ion-md-mail-open" },
  { title: "mail-unread", icon: "ion-md-mail-unread" },
  { title: "mail", icon: "ion-md-mail" },
  { title: "male", icon: "ion-md-male" },
  { title: "man", icon: "ion-md-man" },
  { title: "map", icon: "ion-md-map" },
  { title: "medal", icon: "ion-md-medal" },
  { title: "medical", icon: "ion-md-medical" },
  { title: "medkit", icon: "ion-md-medkit" },
  { title: "megaphone", icon: "ion-md-megaphone" },
  { title: "menu", icon: "ion-md-menu" },
  { title: "mic-off", icon: "ion-md-mic-off" },
  { title: "mic", icon: "ion-md-mic" },
  { title: "microphone", icon: "ion-md-microphone" },
  { title: "moon", icon: "ion-md-moon" },
  { title: "more", icon: "ion-md-more" },
  { title: "move", icon: "ion-md-move" },
  { title: "musical-note", icon: "ion-md-musical-note" },
  { title: "musical-notes", icon: "ion-md-musical-notes" },
  { title: "navigate", icon: "ion-md-navigate" },
  { title: "notifications-off", icon: "ion-md-notifications-off" },
  { title: "notifications-outline", icon: "ion-md-notifications-outline" },
  { title: "notifications", icon: "ion-md-notifications" },
  { title: "nuclear", icon: "ion-md-nuclear" },
  { title: "nutrition", icon: "ion-md-nutrition" },
  { title: "open", icon: "ion-md-open" },
  { title: "options", icon: "ion-md-options" },
  { title: "outlet", icon: "ion-md-outlet" },
  { title: "paper-plane", icon: "ion-md-paper-plane" },
  { title: "paper", icon: "ion-md-paper" },
  { title: "partly-sunny", icon: "ion-md-partly-sunny" },
  { title: "pause", icon: "ion-md-pause" },
  { title: "paw", icon: "ion-md-paw" },
  { title: "people", icon: "ion-md-people" },
  { title: "person-add", icon: "ion-md-person-add" },
  { title: "person", icon: "ion-md-person" },
  { title: "phone-landscape", icon: "ion-md-phone-landscape" },
  { title: "phone-portrait", icon: "ion-md-phone-portrait" },
  { title: "photos", icon: "ion-md-photos" },
  { title: "pie", icon: "ion-md-pie" },
  { title: "pin", icon: "ion-md-pin" },
  { title: "pint", icon: "ion-md-pint" },
  { title: "pizza", icon: "ion-md-pizza" },
  { title: "planet", icon: "ion-md-planet" },
  { title: "play-circle", icon: "ion-md-play-circle" },
  { title: "play", icon: "ion-md-play" },
  { title: "podium", icon: "ion-md-podium" },
  { title: "power", icon: "ion-md-power" },
  { title: "pricetag", icon: "ion-md-pricetag" },
  { title: "pricetags", icon: "ion-md-pricetags" },
  { title: "print", icon: "ion-md-print" },
  { title: "pulse", icon: "ion-md-pulse" },
  { title: "qr-scanner", icon: "ion-md-qr-scanner" },
  { title: "quote", icon: "ion-md-quote" },
  { title: "radio-button-off", icon: "ion-md-radio-button-off" },
  { title: "radio-button-on", icon: "ion-md-radio-button-on" },
  { title: "radio", icon: "ion-md-radio" },
  { title: "rainy", icon: "ion-md-rainy" },
  { title: "recording", icon: "ion-md-recording" },
  { title: "redo", icon: "ion-md-redo" },
  { title: "refresh-circle", icon: "ion-md-refresh-circle" },
  { title: "refresh", icon: "ion-md-refresh" },
  { title: "remove-circle-outline", icon: "ion-md-remove-circle-outline" },
  { title: "remove-circle", icon: "ion-md-remove-circle" },
  { title: "remove", icon: "ion-md-remove" },
  { title: "reorder", icon: "ion-md-reorder" },
  { title: "repeat", icon: "ion-md-repeat" },
  { title: "resize", icon: "ion-md-resize" },
  { title: "restaurant", icon: "ion-md-restaurant" },
  { title: "return-left", icon: "ion-md-return-left" },
  { title: "return-right", icon: "ion-md-return-right" },
  { title: "reverse-camera", icon: "ion-md-reverse-camera" },
  { title: "rewind", icon: "ion-md-rewind" },
  { title: "ribbon", icon: "ion-md-ribbon" },
  { title: "rocket", icon: "ion-md-rocket" },
  { title: "rose", icon: "ion-md-rose" },
  { title: "sad", icon: "ion-md-sad" },
  { title: "save", icon: "ion-md-save" },
  { title: "school", icon: "ion-md-school" },
  { title: "search", icon: "ion-md-search" },
  { title: "send", icon: "ion-md-send" },
  { title: "settings", icon: "ion-md-settings" },
  { title: "share-alt", icon: "ion-md-share-alt" },
  { title: "share", icon: "ion-md-share" },
  { title: "shirt", icon: "ion-md-shirt" },
  { title: "shuffle", icon: "ion-md-shuffle" },
  { title: "skip-backward", icon: "ion-md-skip-backward" },
  { title: "skip-forward", icon: "ion-md-skip-forward" },
  { title: "snow", icon: "ion-md-snow" },
  { title: "speedometer", icon: "ion-md-speedometer" },
  { title: "square-outline", icon: "ion-md-square-outline" },
  { title: "square", icon: "ion-md-square" },
  { title: "star-half", icon: "ion-md-star-half" },
  { title: "star-outline", icon: "ion-md-star-outline" },
  { title: "star", icon: "ion-md-star" },
  { title: "stats", icon: "ion-md-stats" },
  { title: "stopwatch", icon: "ion-md-stopwatch" },
  { title: "subway", icon: "ion-md-subway" },
  { title: "sunny", icon: "ion-md-sunny" },
  { title: "swap", icon: "ion-md-swap" },
  { title: "switch", icon: "ion-md-switch" },
  { title: "sync", icon: "ion-md-sync" },
  { title: "tablet-landscape", icon: "ion-md-tablet-landscape" },
  { title: "tablet-portrait", icon: "ion-md-tablet-portrait" },
  { title: "tennisball", icon: "ion-md-tennisball" },
  { title: "text", icon: "ion-md-text" },
  { title: "thermometer", icon: "ion-md-thermometer" },
  { title: "thumbs-down", icon: "ion-md-thumbs-down" },
  { title: "thumbs-up", icon: "ion-md-thumbs-up" },
  { title: "thunderstorm", icon: "ion-md-thunderstorm" },
  { title: "time", icon: "ion-md-time" },
  { title: "timer", icon: "ion-md-timer" },
  { title: "today", icon: "ion-md-today" },
  { title: "train", icon: "ion-md-train" },
  { title: "transgender", icon: "ion-md-transgender" },
  { title: "trash", icon: "ion-md-trash" },
  { title: "trending-down", icon: "ion-md-trending-down" },
  { title: "trending-up", icon: "ion-md-trending-up" },
  { title: "trophy", icon: "ion-md-trophy" },
  { title: "tv", icon: "ion-md-tv" },
  { title: "umbrella", icon: "ion-md-umbrella" },
  { title: "undo", icon: "ion-md-undo" },
  { title: "unlock", icon: "ion-md-unlock" },
  { title: "videocam", icon: "ion-md-videocam" },
  { title: "volume-high", icon: "ion-md-volume-high" },
  { title: "volume-low", icon: "ion-md-volume-low" },
  { title: "volume-mute", icon: "ion-md-volume-mute" },
  { title: "volume-off", icon: "ion-md-volume-off" },
  { title: "wallet", icon: "ion-md-wallet" },
  { title: "walk", icon: "ion-md-walk" },
  { title: "warning", icon: "ion-md-warning" },
  { title: "watch", icon: "ion-md-watch" },
  { title: "water", icon: "ion-md-water" },
  { title: "wifi", icon: "ion-md-wifi" },
  { title: "wine", icon: "ion-md-wine" },
  { title: "woman", icon: "ion-md-woman" },
];

const IonIos = [
  { title: "add-circle-outline", icon: "ion-ios-add-circle-outline" },
  { title: "add-circle", icon: "ion-ios-add-circle" },
  { title: "add", icon: "ion-ios-add" },
  { title: "airplane", icon: "ion-ios-airplane" },
  { title: "alarm", icon: "ion-ios-alarm" },
  { title: "albums", icon: "ion-ios-albums" },
  { title: "alert", icon: "ion-ios-alert" },
  { title: "american-football", icon: "ion-ios-american-football" },
  { title: "analytics", icon: "ion-ios-analytics" },
  { title: "aperture", icon: "ion-ios-aperture" },
  { title: "apps", icon: "ion-ios-apps" },
  { title: "appstore", icon: "ion-ios-appstore" },
  { title: "archive", icon: "ion-ios-archive" },
  { title: "arrow-back", icon: "ion-ios-arrow-back" },
  { title: "arrow-down", icon: "ion-ios-arrow-down" },
  { title: "arrow-dropdown-circle", icon: "ion-ios-arrow-dropdown-circle" },
  { title: "arrow-dropdown", icon: "ion-ios-arrow-dropdown" },
  { title: "arrow-dropleft-circle", icon: "ion-ios-arrow-dropleft-circle" },
  { title: "arrow-dropleft", icon: "ion-ios-arrow-dropleft" },
  { title: "arrow-dropright-circle", icon: "ion-ios-arrow-dropright-circle" },
  { title: "arrow-dropright", icon: "ion-ios-arrow-dropright" },
  { title: "arrow-dropup-circle", icon: "ion-ios-arrow-dropup-circle" },
  { title: "arrow-dropup", icon: "ion-ios-arrow-dropup" },
  { title: "arrow-forward", icon: "ion-ios-arrow-forward" },
  { title: "arrow-round-back", icon: "ion-ios-arrow-round-back" },
  { title: "arrow-round-down", icon: "ion-ios-arrow-round-down" },
  { title: "arrow-round-forward", icon: "ion-ios-arrow-round-forward" },
  { title: "arrow-round-up", icon: "ion-ios-arrow-round-up" },
  { title: "arrow-up", icon: "ion-ios-arrow-up" },
  { title: "at", icon: "ion-ios-at" },
  { title: "attach", icon: "ion-ios-attach" },
  { title: "backspace", icon: "ion-ios-backspace" },
  { title: "barcode", icon: "ion-ios-barcode" },
  { title: "baseball", icon: "ion-ios-baseball" },
  { title: "basket", icon: "ion-ios-basket" },
  { title: "basketball", icon: "ion-ios-basketball" },
  { title: "battery-charging", icon: "ion-ios-battery-charging" },
  { title: "battery-dead", icon: "ion-ios-battery-dead" },
  { title: "battery-full", icon: "ion-ios-battery-full" },
  { title: "beaker", icon: "ion-ios-beaker" },
  { title: "bed", icon: "ion-ios-bed" },
  { title: "beer", icon: "ion-ios-beer" },
  { title: "bicycle", icon: "ion-ios-bicycle" },
  { title: "bluetooth", icon: "ion-ios-bluetooth" },
  { title: "boat", icon: "ion-ios-boat" },
  { title: "body", icon: "ion-ios-body" },
  { title: "bonfire", icon: "ion-ios-bonfire" },
  { title: "book", icon: "ion-ios-book" },
  { title: "bookmark", icon: "ion-ios-bookmark" },
  { title: "bookmarks", icon: "ion-ios-bookmarks" },
  { title: "bowtie", icon: "ion-ios-bowtie" },
  { title: "briefcase", icon: "ion-ios-briefcase" },
  { title: "browsers", icon: "ion-ios-browsers" },
  { title: "brush", icon: "ion-ios-brush" },
  { title: "bug", icon: "ion-ios-bug" },
  { title: "build", icon: "ion-ios-build" },
  { title: "bulb", icon: "ion-ios-bulb" },
  { title: "bus", icon: "ion-ios-bus" },
  { title: "business", icon: "ion-ios-business" },
  { title: "cafe", icon: "ion-ios-cafe" },
  { title: "calculator", icon: "ion-ios-calculator" },
  { title: "calendar", icon: "ion-ios-calendar" },
  { title: "call", icon: "ion-ios-call" },
  { title: "camera", icon: "ion-ios-camera" },
  { title: "car", icon: "ion-ios-car" },
  { title: "card", icon: "ion-ios-card" },
  { title: "cart", icon: "ion-ios-cart" },
  { title: "cash", icon: "ion-ios-cash" },
  { title: "cellular", icon: "ion-ios-cellular" },
  { title: "chatboxes", icon: "ion-ios-chatboxes" },
  { title: "chatbubbles", icon: "ion-ios-chatbubbles" },
  { title: "checkbox-outline", icon: "ion-ios-checkbox-outline" },
  { title: "checkbox", icon: "ion-ios-checkbox" },
  {
    title: "checkmark-circle-outline",
    icon: "ion-ios-checkmark-circle-outline",
  },
  { title: "checkmark-circle", icon: "ion-ios-checkmark-circle" },
  { title: "checkmark", icon: "ion-ios-checkmark" },
  { title: "clipboard", icon: "ion-ios-clipboard" },
  { title: "clock", icon: "ion-ios-clock" },
  { title: "close-circle-outline", icon: "ion-ios-close-circle-outline" },
  { title: "close-circle", icon: "ion-ios-close-circle" },
  { title: "close", icon: "ion-ios-close" },
  { title: "cloud-circle", icon: "ion-ios-cloud-circle" },
  { title: "cloud-done", icon: "ion-ios-cloud-done" },
  { title: "cloud-download", icon: "ion-ios-cloud-download" },
  { title: "cloud-outline", icon: "ion-ios-cloud-outline" },
  { title: "cloud-upload", icon: "ion-ios-cloud-upload" },
  { title: "cloud", icon: "ion-ios-cloud" },
  { title: "cloudy-night", icon: "ion-ios-cloudy-night" },
  { title: "cloudy", icon: "ion-ios-cloudy" },
  { title: "code-download", icon: "ion-ios-code-download" },
  { title: "code-working", icon: "ion-ios-code-working" },
  { title: "code", icon: "ion-ios-code" },
  { title: "cog", icon: "ion-ios-cog" },
  { title: "color-fill", icon: "ion-ios-color-fill" },
  { title: "color-filter", icon: "ion-ios-color-filter" },
  { title: "color-palette", icon: "ion-ios-color-palette" },
  { title: "color-wand", icon: "ion-ios-color-wand" },
  { title: "compass", icon: "ion-ios-compass" },
  { title: "construct", icon: "ion-ios-construct" },
  { title: "contact", icon: "ion-ios-contact" },
  { title: "contacts", icon: "ion-ios-contacts" },
  { title: "contract", icon: "ion-ios-contract" },
  { title: "contrast", icon: "ion-ios-contrast" },
  { title: "copy", icon: "ion-ios-copy" },
  { title: "create", icon: "ion-ios-create" },
  { title: "crop", icon: "ion-ios-crop" },
  { title: "cube", icon: "ion-ios-cube" },
  { title: "cut", icon: "ion-ios-cut" },
  { title: "desktop", icon: "ion-ios-desktop" },
  { title: "disc", icon: "ion-ios-disc" },
  { title: "document", icon: "ion-ios-document" },
  { title: "done-all", icon: "ion-ios-done-all" },
  { title: "download", icon: "ion-ios-download" },
  { title: "easel", icon: "ion-ios-easel" },
  { title: "egg", icon: "ion-ios-egg" },
  { title: "exit", icon: "ion-ios-exit" },
  { title: "expand", icon: "ion-ios-expand" },
  { title: "eye-off", icon: "ion-ios-eye-off" },
  { title: "eye", icon: "ion-ios-eye" },
  { title: "fastforward", icon: "ion-ios-fastforward" },
  { title: "female", icon: "ion-ios-female" },
  { title: "filing", icon: "ion-ios-filing" },
  { title: "film", icon: "ion-ios-film" },
  { title: "finger-print", icon: "ion-ios-finger-print" },
  { title: "fitness", icon: "ion-ios-fitness" },
  { title: "flag", icon: "ion-ios-flag" },
  { title: "flame", icon: "ion-ios-flame" },
  { title: "flash-off", icon: "ion-ios-flash-off" },
  { title: "flash", icon: "ion-ios-flash" },
  { title: "flashlight", icon: "ion-ios-flashlight" },
  { title: "flask", icon: "ion-ios-flask" },
  { title: "flower", icon: "ion-ios-flower" },
  { title: "folder-open", icon: "ion-ios-folder-open" },
  { title: "folder", icon: "ion-ios-folder" },
  { title: "football", icon: "ion-ios-football" },
  { title: "funnel", icon: "ion-ios-funnel" },
  { title: "gift", icon: "ion-ios-gift" },
  { title: "git-branch", icon: "ion-ios-git-branch" },
  { title: "git-commit", icon: "ion-ios-git-commit" },
  { title: "git-compare", icon: "ion-ios-git-compare" },
  { title: "git-merge", icon: "ion-ios-git-merge" },
  { title: "git-network", icon: "ion-ios-git-network" },
  { title: "git-pull-request", icon: "ion-ios-git-pull-request" },
  { title: "glasses", icon: "ion-ios-glasses" },
  { title: "globe", icon: "ion-ios-globe" },
  { title: "grid", icon: "ion-ios-grid" },
  { title: "hammer", icon: "ion-ios-hammer" },
  { title: "hand", icon: "ion-ios-hand" },
  { title: "happy", icon: "ion-ios-happy" },
  { title: "headset", icon: "ion-ios-headset" },
  { title: "heart", icon: "ion-ios-heart" },
  { title: "heart-dislike", icon: "ion-ios-heart-dislike" },
  { title: "heart-empty", icon: "ion-ios-heart-empty" },
  { title: "heart-half", icon: "ion-ios-heart-half" },
  { title: "help-buoy", icon: "ion-ios-help-buoy" },
  { title: "help-circle-outline", icon: "ion-ios-help-circle-outline" },
  { title: "help-circle", icon: "ion-ios-help-circle" },
  { title: "help", icon: "ion-ios-help" },
  { title: "home", icon: "ion-ios-home" },
  { title: "hourglass", icon: "ion-ios-hourglass" },
  { title: "ice-cream", icon: "ion-ios-ice-cream" },
  { title: "image", icon: "ion-ios-image" },
  { title: "images", icon: "ion-ios-images" },
  { title: "infinite", icon: "ion-ios-infinite" },
  {
    title: "information-circle-outline",
    icon: "ion-ios-information-circle-outline",
  },
  { title: "information-circle", icon: "ion-ios-information-circle" },
  { title: "information", icon: "ion-ios-information" },
  { title: "jet", icon: "ion-ios-jet" },
  { title: "journal", icon: "ion-ios-journal" },
  { title: "key", icon: "ion-ios-key" },
  { title: "keypad", icon: "ion-ios-keypad" },
  { title: "laptop", icon: "ion-ios-laptop" },
  { title: "leaf", icon: "ion-ios-leaf" },
  { title: "link", icon: "ion-ios-link" },
  { title: "list-box", icon: "ion-ios-list-box" },
  { title: "list", icon: "ion-ios-list" },
  { title: "locate", icon: "ion-ios-locate" },
  { title: "lock", icon: "ion-ios-lock" },
  { title: "log-in", icon: "ion-ios-log-in" },
  { title: "log-out", icon: "ion-ios-log-out" },
  { title: "magnet", icon: "ion-ios-magnet" },
  { title: "mail-open", icon: "ion-ios-mail-open" },
  { title: "mail-unread", icon: "ion-ios-mail-unread" },
  { title: "mail", icon: "ion-ios-mail" },
  { title: "male", icon: "ion-ios-male" },
  { title: "man", icon: "ion-ios-man" },
  { title: "map", icon: "ion-ios-map" },
  { title: "medal", icon: "ion-ios-medal" },
  { title: "medical", icon: "ion-ios-medical" },
  { title: "medkit", icon: "ion-ios-medkit" },
  { title: "megaphone", icon: "ion-ios-megaphone" },
  { title: "menu", icon: "ion-ios-menu" },
  { title: "mic-off", icon: "ion-ios-mic-off" },
  { title: "mic", icon: "ion-ios-mic" },
  { title: "microphone", icon: "ion-ios-microphone" },
  { title: "moon", icon: "ion-ios-moon" },
  { title: "more", icon: "ion-ios-more" },
  { title: "move", icon: "ion-ios-move" },
  { title: "musical-note", icon: "ion-ios-musical-note" },
  { title: "musical-notes", icon: "ion-ios-musical-notes" },
  { title: "navigate", icon: "ion-ios-navigate" },
  { title: "notifications-off", icon: "ion-ios-notifications-off" },
  { title: "notifications-outline", icon: "ion-ios-notifications-outline" },
  { title: "notifications", icon: "ion-ios-notifications" },
  { title: "nuclear", icon: "ion-ios-nuclear" },
  { title: "nutrition", icon: "ion-ios-nutrition" },
  { title: "open", icon: "ion-ios-open" },
  { title: "options", icon: "ion-ios-options" },
  { title: "outlet", icon: "ion-ios-outlet" },
  { title: "paper-plane", icon: "ion-ios-paper-plane" },
  { title: "paper", icon: "ion-ios-paper" },
  { title: "partly-sunny", icon: "ion-ios-partly-sunny" },
  { title: "pause", icon: "ion-ios-pause" },
  { title: "paw", icon: "ion-ios-paw" },
  { title: "people", icon: "ion-ios-people" },
  { title: "person-add", icon: "ion-ios-person-add" },
  { title: "person", icon: "ion-ios-person" },
  { title: "phone-landscape", icon: "ion-ios-phone-landscape" },
  { title: "phone-portrait", icon: "ion-ios-phone-portrait" },
  { title: "photos", icon: "ion-ios-photos" },
  { title: "pie", icon: "ion-ios-pie" },
  { title: "pin", icon: "ion-ios-pin" },
  { title: "pint", icon: "ion-ios-pint" },
  { title: "pizza", icon: "ion-ios-pizza" },
  { title: "planet", icon: "ion-ios-planet" },
  { title: "play-circle", icon: "ion-ios-play-circle" },
  { title: "play", icon: "ion-ios-play" },
  { title: "podium", icon: "ion-ios-podium" },
  { title: "power", icon: "ion-ios-power" },
  { title: "pricetag", icon: "ion-ios-pricetag" },
  { title: "pricetags", icon: "ion-ios-pricetags" },
  { title: "print", icon: "ion-ios-print" },
  { title: "pulse", icon: "ion-ios-pulse" },
  { title: "qr-scanner", icon: "ion-ios-qr-scanner" },
  { title: "quote", icon: "ion-ios-quote" },
  { title: "radio-button-off", icon: "ion-ios-radio-button-off" },
  { title: "radio-button-on", icon: "ion-ios-radio-button-on" },
  { title: "radio", icon: "ion-ios-radio" },
  { title: "rainy", icon: "ion-ios-rainy" },
  { title: "recording", icon: "ion-ios-recording" },
  { title: "redo", icon: "ion-ios-redo" },
  { title: "refresh-circle", icon: "ion-ios-refresh-circle" },
  { title: "refresh", icon: "ion-ios-refresh" },
  { title: "remove-circle-outline", icon: "ion-ios-remove-circle-outline" },
  { title: "remove-circle", icon: "ion-ios-remove-circle" },
  { title: "remove", icon: "ion-ios-remove" },
  { title: "reorder", icon: "ion-ios-reorder" },
  { title: "repeat", icon: "ion-ios-repeat" },
  { title: "resize", icon: "ion-ios-resize" },
  { title: "restaurant", icon: "ion-ios-restaurant" },
  { title: "return-left", icon: "ion-ios-return-left" },
  { title: "return-right", icon: "ion-ios-return-right" },
  { title: "reverse-camera", icon: "ion-ios-reverse-camera" },
  { title: "rewind", icon: "ion-ios-rewind" },
  { title: "ribbon", icon: "ion-ios-ribbon" },
  { title: "rocket", icon: "ion-ios-rocket" },
  { title: "rose", icon: "ion-ios-rose" },
  { title: "sad", icon: "ion-ios-sad" },
  { title: "save", icon: "ion-ios-save" },
  { title: "school", icon: "ion-ios-school" },
  { title: "search", icon: "ion-ios-search" },
  { title: "send", icon: "ion-ios-send" },
  { title: "settings", icon: "ion-ios-settings" },
  { title: "share-alt", icon: "ion-ios-share-alt" },
  { title: "share", icon: "ion-ios-share" },
  { title: "shirt", icon: "ion-ios-shirt" },
  { title: "shuffle", icon: "ion-ios-shuffle" },
  { title: "skip-backward", icon: "ion-ios-skip-backward" },
  { title: "skip-forward", icon: "ion-ios-skip-forward" },
  { title: "snow", icon: "ion-ios-snow" },
  { title: "speedometer", icon: "ion-ios-speedometer" },
  { title: "square-outline", icon: "ion-ios-square-outline" },
  { title: "square", icon: "ion-ios-square" },
  { title: "star-half", icon: "ion-ios-star-half" },
  { title: "star-outline", icon: "ion-ios-star-outline" },
  { title: "star", icon: "ion-ios-star" },
  { title: "stats", icon: "ion-ios-stats" },
  { title: "stopwatch", icon: "ion-ios-stopwatch" },
  { title: "subway", icon: "ion-ios-subway" },
  { title: "sunny", icon: "ion-ios-sunny" },
  { title: "swap", icon: "ion-ios-swap" },
  { title: "switch", icon: "ion-ios-switch" },
  { title: "sync", icon: "ion-ios-sync" },
  { title: "tablet-landscape", icon: "ion-ios-tablet-landscape" },
  { title: "tablet-portrait", icon: "ion-ios-tablet-portrait" },
  { title: "tennisball", icon: "ion-ios-tennisball" },
  { title: "text", icon: "ion-ios-text" },
  { title: "thermometer", icon: "ion-ios-thermometer" },
  { title: "thumbs-down", icon: "ion-ios-thumbs-down" },
  { title: "thumbs-up", icon: "ion-ios-thumbs-up" },
  { title: "thunderstorm", icon: "ion-ios-thunderstorm" },
  { title: "time", icon: "ion-ios-time" },
  { title: "timer", icon: "ion-ios-timer" },
  { title: "today", icon: "ion-ios-today" },
  { title: "train", icon: "ion-ios-train" },
  { title: "transgender", icon: "ion-ios-transgender" },
  { title: "trash", icon: "ion-ios-trash" },
  { title: "trending-down", icon: "ion-ios-trending-down" },
  { title: "trending-up", icon: "ion-ios-trending-up" },
  { title: "trophy", icon: "ion-ios-trophy" },
  { title: "tv", icon: "ion-ios-tv" },
  { title: "umbrella", icon: "ion-ios-umbrella" },
  { title: "undo", icon: "ion-ios-undo" },
  { title: "unlock", icon: "ion-ios-unlock" },
  { title: "videocam", icon: "ion-ios-videocam" },
  { title: "volume-high", icon: "ion-ios-volume-high" },
  { title: "volume-low", icon: "ion-ios-volume-low" },
  { title: "volume-mute", icon: "ion-ios-volume-mute" },
  { title: "volume-off", icon: "ion-ios-volume-off" },
  { title: "wallet", icon: "ion-ios-wallet" },
  { title: "walk", icon: "ion-ios-walk" },
  { title: "warning", icon: "ion-ios-warning" },
  { title: "watch", icon: "ion-ios-watch" },
  { title: "water", icon: "ion-ios-water" },
  { title: "wifi", icon: "ion-ios-wifi" },
  { title: "wine", icon: "ion-ios-wine" },
  { title: "woman", icon: "ion-ios-woman" },
];

const IonLogo = [
  { title: "android", icon: "ion-logo-android" },
  { title: "angular", icon: "ion-logo-angular" },
  { title: "apple", icon: "ion-logo-apple" },
  { title: "bitbucket", icon: "ion-logo-bitbucket" },
  { title: "bitcoin", icon: "ion-logo-bitcoin" },
  { title: "buffer", icon: "ion-logo-buffer" },
  { title: "chrome", icon: "ion-logo-chrome" },
  { title: "closed-captioning", icon: "ion-logo-closed-captioning" },
  { title: "codepen", icon: "ion-logo-codepen" },
  { title: "css3", icon: "ion-logo-css3" },
  { title: "designernews", icon: "ion-logo-designernews" },
  { title: "dribbble", icon: "ion-logo-dribbble" },
  { title: "dropbox", icon: "ion-logo-dropbox" },
  { title: "euro", icon: "ion-logo-euro" },
  { title: "facebook", icon: "ion-logo-facebook" },
  { title: "flickr", icon: "ion-logo-flickr" },
  { title: "foursquare", icon: "ion-logo-foursquare" },
  { title: "freebsd-devil", icon: "ion-logo-freebsd-devil" },
  { title: "game-controller-a", icon: "ion-logo-game-controller-a" },
  { title: "game-controller-b", icon: "ion-logo-game-controller-b" },
  { title: "github", icon: "ion-logo-github" },
  { title: "google", icon: "ion-logo-google" },
  { title: "googleplus", icon: "ion-logo-googleplus" },
  { title: "hackernews", icon: "ion-logo-hackernews" },
  { title: "html5", icon: "ion-logo-html5" },
  { title: "instagram", icon: "ion-logo-instagram" },
  { title: "ionic", icon: "ion-logo-ionic" },
  { title: "ionitron", icon: "ion-logo-ionitron" },
  { title: "javascript", icon: "ion-logo-javascript" },
  { title: "linkedin", icon: "ion-logo-linkedin" },
  { title: "markdown", icon: "ion-logo-markdown" },
  { title: "model-s", icon: "ion-logo-model-s" },
  { title: "no-smoking", icon: "ion-logo-no-smoking" },
  { title: "nodejs", icon: "ion-logo-nodejs" },
  { title: "npm", icon: "ion-logo-npm" },
  { title: "octocat", icon: "ion-logo-octocat" },
  { title: "pinterest", icon: "ion-logo-pinterest" },
  { title: "playstation", icon: "ion-logo-playstation" },
  { title: "polymer", icon: "ion-logo-polymer" },
  { title: "python", icon: "ion-logo-python" },
  { title: "reddit", icon: "ion-logo-reddit" },
  { title: "rss", icon: "ion-logo-rss" },
  { title: "sass", icon: "ion-logo-sass" },
  { title: "skype", icon: "ion-logo-skype" },
  { title: "slack", icon: "ion-logo-slack" },
  { title: "snapchat", icon: "ion-logo-snapchat" },
  { title: "steam", icon: "ion-logo-steam" },
  { title: "tumblr", icon: "ion-logo-tumblr" },
  { title: "tux", icon: "ion-logo-tux" },
  { title: "twitch", icon: "ion-logo-twitch" },
  { title: "twitter", icon: "ion-logo-twitter" },
  { title: "usd", icon: "ion-logo-usd" },
  { title: "vimeo", icon: "ion-logo-vimeo" },
  { title: "vk", icon: "ion-logo-vk" },
  { title: "whatsapp", icon: "ion-logo-whatsapp" },
  { title: "windows", icon: "ion-logo-windows" },
  { title: "wordpress", icon: "ion-logo-wordpress" },
  { title: "xbox", icon: "ion-logo-xbox" },
  { title: "xing", icon: "ion-logo-xing" },
  { title: "yahoo", icon: "ion-logo-yahoo" },
  { title: "yen", icon: "ion-logo-yen" },
  { title: "youtube	ion", icon: "ion-logo-youtube" },
];

const Material = [
  { title: "ac_unit", icon: "material ac_unit" },
  { title: "access_alarm", icon: "material access_alarm" },
  { title: "access_alarms", icon: "material access_alarms" },
  { title: "access_time", icon: "material access_time" },
  { title: "accessibility", icon: "material accessibility" },
  { title: "accessible_forward", icon: "material accessible_forward" },
  { title: "accessible", icon: "material accessible" },
  { title: "account_balance", icon: "material account_balance" },
  {
    title: "account_balance_wallet",
    icon: "material account_balance_wallet"
  },
  { title: "account_box", icon: "material account_box" },
  { title: "account_circle", icon: "material account_circle" },
  { title: "adb", icon: "material adb" },
  { title: "add", icon: "material add" },
  { title: "add_a_photo", icon: "material add_a_photo" },
  { title: "add_alarm", icon: "material add_alarm" },
  { title: "add_alert", icon: "material add_alert" },
  { title: "add_box", icon: "material add_box" },
  { title: "add_circle", icon: "material add_circle" },
  { title: "add_circle_outline", icon: "material add_circle_outline" },
  { title: "add_location", icon: "material add_location" },
  { title: "add_shopping_cart", icon: "material add_shopping_cart" },
  { title: "add_to_photos", icon: "material add_to_photos" },
  { title: "add_to_queue", icon: "material add_to_queue" },
  { title: "adjust", icon: "material adjust" },
  { title: "airline_seat_flat", icon: "material airline_seat_flat" },
  {
    title: "airline_seat_flat_angled",
    icon: "material airline_seat_flat_angled"
  },
  {
    title: "airline_seat_individual_suite",
    icon: "material airline_seat_individual_suite"
  },
  {
    title: "airline_seat_legroom_extra",
    icon: "material airline_seat_legroom_extra"
  },
  {
    title: "airline_seat_legroom_normal",
    icon: "material airline_seat_legroom_normal"
  },
  {
    title: "airline_seat_legroom_reduced",
    icon: "material airline_seat_legroom_reduced"
  },
  {
    title: "airline_seat_recline_extra",
    icon: "material airline_seat_recline_extra"
  },
  {
    title: "airline_seat_recline_normal",
    icon: "material airline_seat_recline_normal"
  },
  { title: "airplanemode_active", icon: "material airplanemode_active" },
  {
    title: "airplanemode_inactive",
    icon: "material airplanemode_inactive"
  },
  { title: "airplay", icon: "material airplay" },
  { title: "airport_shuttle", icon: "material airport_shuttle" },
  { title: "alarm", icon: "material alarm" },
  { title: "alarm_add", icon: "material alarm_add" },
  { title: "alarm_off", icon: "material alarm_off" },
  { title: "alarm_on", icon: "material alarm_on" },
  { title: "album", icon: "material album" },
  { title: "all_inclusive", icon: "material all_inclusive" },
  { title: "all_out", icon: "material all_out" },
  { title: "android", icon: "material android" },
  { title: "announcement", icon: "material announcement" },
  { title: "apps", icon: "material apps" },
  { title: "archive", icon: "material archive" },
  { title: "arrow_back", icon: "material arrow_back" },
  { title: "arrow_downward", icon: "material arrow_downward" },
  { title: "arrow_drop_down", icon: "material arrow_drop_down" },
  {
    title: "arrow_drop_down_circle",
    icon: "material arrow_drop_down_circle"
  },
  { title: "arrow_drop_up", icon: "material arrow_drop_up" },
  { title: "arrow_forward", icon: "material arrow_forward" },
  { title: "arrow_upward", icon: "material arrow_upward" },
  { title: "art_track", icon: "material art_track" },
  { title: "aspect_ratio", icon: "material aspect_ratio" },
  { title: "assessment", icon: "material assessment" },
  { title: "assignment", icon: "material assignment" },
  { title: "assignment_ind", icon: "material assignment_ind" },
  { title: "assignment_late", icon: "material assignment_late" },
  { title: "assignment_return", icon: "material assignment_return" },
  { title: "assignment_returned", icon: "material assignment_returned" },
  {
    title: "assignment_turned_in",
    icon: "material assignment_turned_in"
  },
  { title: "assistant", icon: "material assistant" },
  { title: "assistant_photo", icon: "material assistant_photo" },
  { title: "attach_file", icon: "material attach_file" },
  { title: "attach_money", icon: "material attach_money" },
  { title: "attachment", icon: "material attachment" },
  { title: "audiotrack", icon: "material audiotrack" },
  { title: "autorenew", icon: "material autorenew" },
  { title: "av_timer", icon: "material av_timer" },
  { title: "backspace", icon: "material backspace" },
  { title: "backup", icon: "material backup" },
  { title: "battery_alert", icon: "material battery_alert" },
  {
    title: "battery_charging_full",
    icon: "material battery_charging_full"
  },
  { title: "battery_full", icon: "material battery_full" },
  { title: "battery_std", icon: "material battery_std" },
  { title: "battery_unknown", icon: "material battery_unknown" },
  { title: "beach_access", icon: "material beach_access" },
  { title: "beenhere", icon: "material beenhere" },
  { title: "block", icon: "material block" },
  { title: "bluetooth", icon: "material bluetooth" },
  { title: "bluetooth_audio", icon: "material bluetooth_audio" },
  { title: "bluetooth_connected", icon: "material bluetooth_connected" },
  { title: "bluetooth_disabled", icon: "material bluetooth_disabled" },
  { title: "bluetooth_searching", icon: "material bluetooth_searching" },
  { title: "blur_circular", icon: "material blur_circular" },
  { title: "blur_linear", icon: "material blur_linear" },
  { title: "blur_off", icon: "material blur_off" },
  { title: "blur_on", icon: "material blur_on" },
  { title: "book", icon: "material book" },
  { title: "bookmark", icon: "material bookmark" },
  { title: "bookmark_border", icon: "material bookmark_border" },
  { title: "border_all", icon: "material border_all" },
  { title: "border_bottom", icon: "material border_bottom" },
  { title: "border_clear", icon: "material border_clear" },
  { title: "border_color", icon: "material border_color" },
  { title: "border_horizontal", icon: "material border_horizontal" },
  { title: "border_inner", icon: "material border_inner" },
  { title: "border_left", icon: "material border_left" },
  { title: "border_outer", icon: "material border_outer" },
  { title: "border_right", icon: "material border_right" },
  { title: "border_style", icon: "material border_style" },
  { title: "border_top", icon: "material border_top" },
  { title: "border_vertical", icon: "material border_vertical" },
  { title: "branding_watermark", icon: "material branding_watermark" },
  { title: "brightness_1", icon: "material brightness_1" },
  { title: "brightness_2", icon: "material brightness_2" },
  { title: "brightness_3", icon: "material brightness_3" },
  { title: "brightness_4", icon: "material brightness_4" },
  { title: "brightness_5", icon: "material brightness_5" },
  { title: "brightness_6", icon: "material brightness_6" },
  { title: "brightness_7", icon: "material brightness_7" },
  { title: "brightness_auto", icon: "material brightness_auto" },
  { title: "brightness_high", icon: "material brightness_high" },
  { title: "brightness_low", icon: "material brightness_low" },
  { title: "brightness_medium", icon: "material brightness_medium" },
  { title: "broken_image", icon: "material broken_image" },
  { title: "brush", icon: "material brush" },
  { title: "bubble_chart", icon: "material bubble_chart" },
  { title: "bug_report", icon: "material bug_report" },
  { title: "build", icon: "material build" },
  { title: "burst_mode", icon: "material burst_mode" },
  { title: "business", icon: "material business" },
  { title: "business_center", icon: "material business_center" },
  { title: "cached", icon: "material cached" },
  { title: "cake", icon: "material cake" },
  { title: "call", icon: "material call" },
  { title: "call_end", icon: "material call_end" },
  { title: "call_made", icon: "material call_made" },
  { title: "call_merge", icon: "material call_merge" },
  { title: "call_missed", icon: "material call_missed" },
  {
    title: "call_missed_outgoing",
    icon: "material call_missed_outgoing"
  },
  { title: "call_received", icon: "material call_received" },
  { title: "call_split", icon: "material call_split" },
  { title: "call_to_action", icon: "material call_to_action" },
  { title: "camera", icon: "material camera" },
  { title: "camera_alt", icon: "material camera_alt" },
  { title: "camera_enhance", icon: "material camera_enhance" },
  { title: "camera_front", icon: "material camera_front" },
  { title: "camera_rear", icon: "material camera_rear" },
  { title: "camera_roll", icon: "material camera_roll" },
  { title: "cancel", icon: "material cancel" },
  { title: "card_giftcard", icon: "material card_giftcard" },
  { title: "card_membership", icon: "material card_membership" },
  { title: "card_travel", icon: "material card_travel" },
  { title: "casino", icon: "material casino" },
  { title: "cast", icon: "material cast" },
  { title: "cast_connected", icon: "material cast_connected" },
  { title: "center_focus_strong", icon: "material center_focus_strong" },
  { title: "center_focus_weak", icon: "material center_focus_weak" },
  { title: "change_history", icon: "material change_history" },
  { title: "chat", icon: "material chat" },
  { title: "chat_bubble", icon: "material chat_bubble" },
  { title: "chat_bubble_outline", icon: "material chat_bubble_outline" },
  { title: "check", icon: "material check" },
  { title: "check_box", icon: "material check_box" },
  {
    title: "check_box_outline_blank",
    icon: "material check_box_outline_blank"
  },
  { title: "check_circle", icon: "material check_circle" },
  { title: "chevron_left", icon: "material chevron_left" },
  { title: "chevron_right", icon: "material chevron_right" },
  { title: "child_care", icon: "material child_care" },
  { title: "child_friendly", icon: "material child_friendly" },
  { title: "chrome_reader_mode", icon: "material chrome_reader_mode" },
  { title: "class", icon: "material class" },
  { title: "clear", icon: "material clear" },
  { title: "clear_all", icon: "material clear_all" },
  { title: "close", icon: "material close" },
  { title: "closed_caption", icon: "material closed_caption" },
  { title: "cloud", icon: "material cloud" },
  { title: "cloud_circle", icon: "material cloud_circle" },
  { title: "cloud_done", icon: "material cloud_done" },
  { title: "cloud_download", icon: "material cloud_download" },
  { title: "cloud_off", icon: "material cloud_off" },
  { title: "cloud_queue", icon: "material cloud_queue" },
  { title: "cloud_upload", icon: "material cloud_upload" },
  { title: "code", icon: "material code" },
  { title: "collections", icon: "material collections" },
  {
    title: "collections_bookmark",
    icon: "material collections_bookmark"
  },
  { title: "color_lens", icon: "material color_lens" },
  { title: "colorize", icon: "material colorize" },
  { title: "comment", icon: "material comment" },
  { title: "compare", icon: "material compare" },
  { title: "compare_arrows", icon: "material compare_arrows" },
  { title: "computer", icon: "material computer" },
  { title: "confirmation_number", icon: "material confirmation_number" },
  { title: "contact_mail", icon: "material contact_mail" },
  { title: "contact_phone", icon: "material contact_phone" },
  { title: "contacts", icon: "material contacts" },
  { title: "content_copy", icon: "material content_copy" },
  { title: "content_cut", icon: "material content_cut" },
  { title: "content_paste", icon: "material content_paste" },
  { title: "control_point", icon: "material control_point" },
  {
    title: "control_point_duplicate",
    icon: "material control_point_duplicate"
  },
  { title: "copyright", icon: "material copyright" },
  { title: "create", icon: "material create" },
  { title: "create_new_folder", icon: "material create_new_folder" },
  { title: "credit_card", icon: "material credit_card" },
  { title: "crop", icon: "material crop" },
  { title: "crop_16_9", icon: "material crop_16_9" },
  { title: "crop_3_2", icon: "material crop_3_2" },
  { title: "crop_5_4", icon: "material crop_5_4" },
  { title: "crop_7_5", icon: "material crop_7_5" },
  { title: "crop_din", icon: "material crop_din" },
  { title: "crop_free", icon: "material crop_free" },
  { title: "crop_landscape", icon: "material crop_landscape" },
  { title: "crop_original", icon: "material crop_original" },
  { title: "crop_portrait", icon: "material crop_portrait" },
  { title: "crop_rotate", icon: "material crop_rotate" },
  { title: "crop_square", icon: "material crop_square" },
  { title: "dashboard", icon: "material dashboard" },
  { title: "data_usage", icon: "material data_usage" },
  { title: "date_range", icon: "material date_range" },
  { title: "dehaze", icon: "material dehaze" },
  { title: "delete", icon: "material delete" },
  { title: "delete_forever", icon: "material delete_forever" },
  { title: "delete_sweep", icon: "material delete_sweep" },
  { title: "description", icon: "material description" },
  { title: "desktop_mac", icon: "material desktop_mac" },
  { title: "desktop_windows", icon: "material desktop_windows" },
  { title: "details", icon: "material details" },
  { title: "developer_board", icon: "material developer_board" },
  { title: "developer_mode", icon: "material developer_mode" },
  { title: "device_hub", icon: "material device_hub" },
  { title: "devices", icon: "material devices" },
  { title: "devices_other", icon: "material devices_other" },
  { title: "dialer_sip", icon: "material dialer_sip" },
  { title: "dialpad", icon: "material dialpad" },
  { title: "directions", icon: "material directions" },
  { title: "directions_bike", icon: "material directions_bike" },
  { title: "directions_boat", icon: "material directions_boat" },
  { title: "directions_bus", icon: "material directions_bus" },
  { title: "directions_car", icon: "material directions_car" },
  { title: "directions_railway", icon: "material directions_railway" },
  { title: "directions_run", icon: "material directions_run" },
  { title: "directions_subway", icon: "material directions_subway" },
  { title: "directions_transit", icon: "material directions_transit" },
  { title: "directions_walk", icon: "material directions_walk" },
  { title: "disc_full", icon: "material disc_full" },
  { title: "dns", icon: "material dns" },
  { title: "do_not_disturb", icon: "material do_not_disturb" },
  { title: "do_not_disturb_alt", icon: "material do_not_disturb_alt" },
  { title: "do_not_disturb_off", icon: "material do_not_disturb_off" },
  { title: "do_not_disturb_on", icon: "material do_not_disturb_on" },
  { title: "dock", icon: "material dock" },
  { title: "domain", icon: "material domain" },
  { title: "done", icon: "material done" },
  { title: "done_all", icon: "material done_all" },
  { title: "donut_large", icon: "material donut_large" },
  { title: "donut_small", icon: "material donut_small" },
  { title: "drafts", icon: "material drafts" },
  { title: "drag_handle", icon: "material drag_handle" },
  { title: "drive_eta", icon: "material drive_eta" },
  { title: "dvr", icon: "material dvr" },
  { title: "edit", icon: "material edit" },
  { title: "edit_location", icon: "material edit_location" },
  { title: "eject", icon: "material eject" },
  { title: "email", icon: "material email" },
  { title: "enhanced_encryption", icon: "material enhanced_encryption" },
  { title: "equalizer", icon: "material equalizer" },
  { title: "error", icon: "material error" },
  { title: "error_outline", icon: "material error_outline" },
  { title: "euro_symbol", icon: "material euro_symbol" },
  { title: "ev_station", icon: "material ev_station" },
  { title: "event", icon: "material event" },
  { title: "event_available", icon: "material event_available" },
  { title: "event_busy", icon: "material event_busy" },
  { title: "event_note", icon: "material event_note" },
  { title: "event_seat", icon: "material event_seat" },
  { title: "exit_to_app", icon: "material exit_to_app" },
  { title: "expand_less", icon: "material expand_less" },
  { title: "expand_more", icon: "material expand_more" },
  { title: "explicit", icon: "material explicit" },
  { title: "explore", icon: "material explore" },
  { title: "exposure", icon: "material exposure" },
  { title: "exposure_neg_1", icon: "material exposure_neg_1" },
  { title: "exposure_neg_2", icon: "material exposure_neg_2" },
  { title: "exposure_plus_1", icon: "material exposure_plus_1" },
  { title: "exposure_plus_2", icon: "material exposure_plus_2" },
  { title: "exposure_zero", icon: "material exposure_zero" },
  { title: "extension", icon: "material extension" },
  { title: "face", icon: "material face" },
  { title: "fast_forward", icon: "material fast_forward" },
  { title: "fast_rewind", icon: "material fast_rewind" },
  { title: "favorite", icon: "material favorite" },
  { title: "favorite_border", icon: "material favorite_border" },
  { title: "featured_play_list", icon: "material featured_play_list" },
  { title: "featured_video", icon: "material featured_video" },
  { title: "feedback", icon: "material feedback" },
  { title: "fiber_dvr", icon: "material fiber_dvr" },
  { title: "fiber_manual_record", icon: "material fiber_manual_record" },
  { title: "fiber_new", icon: "material fiber_new" },
  { title: "fiber_pin", icon: "material fiber_pin" },
  { title: "fiber_smart_record", icon: "material fiber_smart_record" },
  { title: "file_download", icon: "material file_download" },
  { title: "file_upload", icon: "material file_upload" },
  { title: "filter", icon: "material filter" },
  { title: "filter_1", icon: "material filter_1" },
  { title: "filter_2", icon: "material filter_2" },
  { title: "filter_3", icon: "material filter_3" },
  { title: "filter_4", icon: "material filter_4" },
  { title: "filter_5", icon: "material filter_5" },
  { title: "filter_6", icon: "material filter_6" },
  { title: "filter_7", icon: "material filter_7" },
  { title: "filter_8", icon: "material filter_8" },
  { title: "filter_9", icon: "material filter_9" },
  { title: "filter_9_plus", icon: "material filter_9_plus" },
  { title: "filter_b_and_w", icon: "material filter_b_and_w" },
  { title: "filter_center_focus", icon: "material filter_center_focus" },
  { title: "filter_drama", icon: "material filter_drama" },
  { title: "filter_frames", icon: "material filter_frames" },
  { title: "filter_hdr", icon: "material filter_hdr" },
  { title: "filter_list", icon: "material filter_list" },
  { title: "filter_none", icon: "material filter_none" },
  { title: "filter_tilt_shift", icon: "material filter_tilt_shift" },
  { title: "filter_vintage", icon: "material filter_vintage" },
  { title: "find_in_page", icon: "material find_in_page" },
  { title: "find_replace", icon: "material find_replace" },
  { title: "fingerprint", icon: "material fingerprint" },
  { title: "first_page", icon: "material first_page" },
  { title: "fitness_center", icon: "material fitness_center" },
  { title: "flag", icon: "material flag" },
  { title: "flare", icon: "material flare" },
  { title: "flash_auto", icon: "material flash_auto" },
  { title: "flash_off", icon: "material flash_off" },
  { title: "flash_on", icon: "material flash_on" },
  { title: "flight", icon: "material flight" },
  { title: "flight_land", icon: "material flight_land" },
  { title: "flight_takeoff", icon: "material flight_takeoff" },
  { title: "flip", icon: "material flip" },
  { title: "flip_to_back", icon: "material flip_to_back" },
  { title: "flip_to_front", icon: "material flip_to_front" },
  { title: "folder", icon: "material folder" },
  { title: "folder_open", icon: "material folder_open" },
  { title: "folder_shared", icon: "material folder_shared" },
  { title: "folder_special", icon: "material folder_special" },
  { title: "font_download", icon: "material font_download" },
  { title: "format_align_center", icon: "material format_align_center" },
  {
    title: "format_align_justify",
    icon: "material format_align_justify"
  },
  { title: "format_align_left", icon: "material format_align_left" },
  { title: "format_align_right", icon: "material format_align_right" },
  { title: "format_bold", icon: "material format_bold" },
  { title: "format_clear", icon: "material format_clear" },
  { title: "format_color_fill", icon: "material format_color_fill" },
  { title: "format_color_reset", icon: "material format_color_reset" },
  { title: "format_color_text", icon: "material format_color_text" },
  {
    title: "format_indent_decrease",
    icon: "material format_indent_decrease"
  },
  {
    title: "format_indent_increase",
    icon: "material format_indent_increase"
  },
  { title: "format_italic", icon: "material format_italic" },
  { title: "format_line_spacing", icon: "material format_line_spacing" },
  {
    title: "format_list_bulleted",
    icon: "material format_list_bulleted"
  },
  {
    title: "format_list_numbered",
    icon: "material format_list_numbered"
  },
  { title: "format_paint", icon: "material format_paint" },
  { title: "format_quote", icon: "material format_quote" },
  { title: "format_shapes", icon: "material format_shapes" },
  { title: "format_size", icon: "material format_size" },
  {
    title: "format_strikethrough",
    icon: "material format_strikethrough"
  },
  {
    title: "format_textdirection_l_to_r",
    icon: "material format_textdirection_l_to_r"
  },
  {
    title: "format_textdirection_r_to_l",
    icon: "material format_textdirection_r_to_l"
  },
  { title: "format_underlined", icon: "material format_underlined" },
  { title: "forum", icon: "material forum" },
  { title: "forward", icon: "material forward" },
  { title: "forward_10", icon: "material forward_10" },
  { title: "forward_30", icon: "material forward_30" },
  { title: "forward_5", icon: "material forward_5" },
  { title: "free_breakfast", icon: "material free_breakfast" },
  { title: "fullscreen", icon: "material fullscreen" },
  { title: "fullscreen_exit", icon: "material fullscreen_exit" },
  { title: "functions", icon: "material functions" },
  { title: "g_translate", icon: "material g_translate" },
  { title: "gamepad", icon: "material gamepad" },
  { title: "games", icon: "material games" },
  { title: "gavel", icon: "material gavel" },
  { title: "gesture", icon: "material gesture" },
  { title: "get_app", icon: "material get_app" },
  { title: "gif", icon: "material gif" },
  { title: "golf_course", icon: "material golf_course" },
  { title: "gps_fixed", icon: "material gps_fixed" },
  { title: "gps_not_fixed", icon: "material gps_not_fixed" },
  { title: "gps_off", icon: "material gps_off" },
  { title: "grade", icon: "material grade" },
  { title: "gradient", icon: "material gradient" },
  { title: "grain", icon: "material grain" },
  { title: "graphic_eq", icon: "material graphic_eq" },
  { title: "grid_off", icon: "material grid_off" },
  { title: "grid_on", icon: "material grid_on" },
  { title: "group", icon: "material group" },
  { title: "group_add", icon: "material group_add" },
  { title: "group_work", icon: "material group_work" },
  { title: "hd", icon: "material hd" },
  { title: "hdr_off", icon: "material hdr_off" },
  { title: "hdr_on", icon: "material hdr_on" },
  { title: "hdr_strong", icon: "material hdr_strong" },
  { title: "hdr_weak", icon: "material hdr_weak" },
  { title: "headset", icon: "material headset" },
  { title: "headset_mic", icon: "material headset_mic" },
  { title: "healing", icon: "material healing" },
  { title: "hearing", icon: "material hearing" },
  { title: "help", icon: "material help" },
  { title: "help_outline", icon: "material help_outline" },
  { title: "high_quality", icon: "material high_quality" },
  { title: "highlight", icon: "material highlight" },
  { title: "highlight_off", icon: "material highlight_off" },
  { title: "history", icon: "material history" },
  { title: "home", icon: "material home" },
  { title: "hot_tub", icon: "material hot_tub" },
  { title: "hotel", icon: "material hotel" },
  { title: "hourglass_empty", icon: "material hourglass_empty" },
  { title: "hourglass_full", icon: "material hourglass_full" },
  { title: "http", icon: "material http" },
  { title: "https", icon: "material https" },
  { title: "image", icon: "material image" },
  { title: "image_aspect_ratio", icon: "material image_aspect_ratio" },
  { title: "import_contacts", icon: "material import_contacts" },
  { title: "import_export", icon: "material import_export" },
  { title: "important_devices", icon: "material important_devices" },
  { title: "inbox", icon: "material inbox" },
  {
    title: "indeterminate_check_box",
    icon: "material indeterminate_check_box"
  },
  { title: "info", icon: "material info" },
  { title: "info_outline", icon: "material info_outline" },
  { title: "input", icon: "material input" },
  { title: "insert_chart", icon: "material insert_chart" },
  { title: "insert_comment", icon: "material insert_comment" },
  { title: "insert_drive_file", icon: "material insert_drive_file" },
  { title: "insert_emoticon", icon: "material insert_emoticon" },
  { title: "insert_invitation", icon: "material insert_invitation" },
  { title: "insert_link", icon: "material insert_link" },
  { title: "insert_photo", icon: "material insert_photo" },
  { title: "invert_colors", icon: "material invert_colors" },
  { title: "invert_colors_off", icon: "material invert_colors_off" },
  { title: "iso", icon: "material iso" },
  { title: "keyboard", icon: "material keyboard" },
  { title: "keyboard_arrow_down", icon: "material keyboard_arrow_down" },
  { title: "keyboard_arrow_left", icon: "material keyboard_arrow_left" },
  {
    title: "keyboard_arrow_right",
    icon: "material keyboard_arrow_right"
  },
  { title: "keyboard_arrow_up", icon: "material keyboard_arrow_up" },
  { title: "keyboard_backspace", icon: "material keyboard_backspace" },
  { title: "keyboard_capslock", icon: "material keyboard_capslock" },
  { title: "keyboard_hide", icon: "material keyboard_hide" },
  { title: "keyboard_return", icon: "material keyboard_return" },
  { title: "keyboard_tab", icon: "material keyboard_tab" },
  { title: "keyboard_voice", icon: "material keyboard_voice" },
  { title: "kitchen", icon: "material kitchen" },
  { title: "label", icon: "material label" },
  { title: "label_outline", icon: "material label_outline" },
  { title: "landscape", icon: "material landscape" },
  { title: "language", icon: "material language" },
  { title: "laptop", icon: "material laptop" },
  { title: "laptop_chromebook", icon: "material laptop_chromebook" },
  { title: "laptop_mac", icon: "material laptop_mac" },
  { title: "laptop_windows", icon: "material laptop_windows" },
  { title: "last_page", icon: "material last_page" },
  { title: "launch", icon: "material launch" },
  { title: "layers", icon: "material layers" },
  { title: "layers_clear", icon: "material layers_clear" },
  { title: "leak_add", icon: "material leak_add" },
  { title: "leak_remove", icon: "material leak_remove" },
  { title: "lens", icon: "material lens" },
  { title: "library_add", icon: "material library_add" },
  { title: "library_books", icon: "material library_books" },
  { title: "library_music", icon: "material library_music" },
  { title: "lightbulb_outline", icon: "material lightbulb_outline" },
  { title: "line_style", icon: "material line_style" },
  { title: "line_weight", icon: "material line_weight" },
  { title: "linear_scale", icon: "material linear_scale" },
  { title: "link", icon: "material link" },
  { title: "linked_camera", icon: "material linked_camera" },
  { title: "list", icon: "material list" },
  { title: "live_help", icon: "material live_help" },
  { title: "live_tv", icon: "material live_tv" },
  { title: "local_activity", icon: "material local_activity" },
  { title: "local_airport", icon: "material local_airport" },
  { title: "local_atm", icon: "material local_atm" },
  { title: "local_bar", icon: "material local_bar" },
  { title: "local_cafe", icon: "material local_cafe" },
  { title: "local_car_wash", icon: "material local_car_wash" },
  {
    title: "local_convenience_store",
    icon: "material local_convenience_store"
  },
  { title: "local_dining", icon: "material local_dining" },
  { title: "local_drink", icon: "material local_drink" },
  { title: "local_florist", icon: "material local_florist" },
  { title: "local_gas_station", icon: "material local_gas_station" },
  { title: "local_grocery_store", icon: "material local_grocery_store" },
  { title: "local_hospital", icon: "material local_hospital" },
  { title: "local_hotel", icon: "material local_hotel" },
  {
    title: "local_laundry_service",
    icon: "material local_laundry_service"
  },
  { title: "local_library", icon: "material local_library" },
  { title: "local_mall", icon: "material local_mall" },
  { title: "local_movies", icon: "material local_movies" },
  { title: "local_offer", icon: "material local_offer" },
  { title: "local_parking", icon: "material local_parking" },
  { title: "local_pharmacy", icon: "material local_pharmacy" },
  { title: "local_phone", icon: "material local_phone" },
  { title: "local_pizza", icon: "material local_pizza" },
  { title: "local_play", icon: "material local_play" },
  { title: "local_post_office", icon: "material local_post_office" },
  { title: "local_printshop", icon: "material local_printshop" },
  { title: "local_see", icon: "material local_see" },
  { title: "local_shipping", icon: "material local_shipping" },
  { title: "local_taxi", icon: "material local_taxi" },
  { title: "location_city", icon: "material location_city" },
  { title: "location_disabled", icon: "material location_disabled" },
  { title: "location_off", icon: "material location_off" },
  { title: "location_on", icon: "material location_on" },
  { title: "location_searching", icon: "material location_searching" },
  { title: "lock", icon: "material lock" },
  { title: "lock_open", icon: "material lock_open" },
  { title: "lock_outline", icon: "material lock_outline" },
  { title: "looks", icon: "material looks" },
  { title: "looks_3", icon: "material looks_3" },
  { title: "looks_4", icon: "material looks_4" },
  { title: "looks_5", icon: "material looks_5" },
  { title: "looks_6", icon: "material looks_6" },
  { title: "looks_one", icon: "material looks_one" },
  { title: "looks_two", icon: "material looks_two" },
  { title: "loop", icon: "material loop" },
  { title: "loupe", icon: "material loupe" },
  { title: "low_priority", icon: "material low_priority" },
  { title: "loyalty", icon: "material loyalty" },
  { title: "mail", icon: "material mail" },
  { title: "mail_outline", icon: "material mail_outline" },
  { title: "map", icon: "material map" },
  { title: "markunread", icon: "material markunread" },
  { title: "markunread_mailbox", icon: "material markunread_mailbox" },
  { title: "memory", icon: "material memory" },
  { title: "menu", icon: "material menu" },
  { title: "merge_type", icon: "material merge_type" },
  { title: "message", icon: "material message" },
  { title: "mic", icon: "material mic" },
  { title: "mic_none", icon: "material mic_none" },
  { title: "mic_off", icon: "material mic_off" },
  { title: "mms", icon: "material mms" },
  { title: "mode_comment", icon: "material mode_comment" },
  { title: "mode_edit", icon: "material mode_edit" },
  { title: "monetization_on", icon: "material monetization_on" },
  { title: "money_off", icon: "material money_off" },
  { title: "monochrome_photos", icon: "material monochrome_photos" },
  { title: "mood", icon: "material mood" },
  { title: "mood_bad", icon: "material mood_bad" },
  { title: "more", icon: "material more" },
  { title: "more_horiz", icon: "material more_horiz" },
  { title: "more_vert", icon: "material more_vert" },
  { title: "motorcycle", icon: "material motorcycle" },
  { title: "mouse", icon: "material mouse" },
  { title: "move_to_inbox", icon: "material move_to_inbox" },
  { title: "movie", icon: "material movie" },
  { title: "movie_creation", icon: "material movie_creation" },
  { title: "movie_filter", icon: "material movie_filter" },
  { title: "multiline_chart", icon: "material multiline_chart" },
  { title: "music_note", icon: "material music_note" },
  { title: "music_video", icon: "material music_video" },
  { title: "my_location", icon: "material my_location" },
  { title: "nature", icon: "material nature" },
  { title: "nature_people", icon: "material nature_people" },
  { title: "navigate_before", icon: "material navigate_before" },
  { title: "navigate_next", icon: "material navigate_next" },
  { title: "navigation", icon: "material navigation" },
  { title: "near_me", icon: "material near_me" },
  { title: "network_cell", icon: "material network_cell" },
  { title: "network_check", icon: "material network_check" },
  { title: "network_locked", icon: "material network_locked" },
  { title: "network_wifi", icon: "material network_wifi" },
  { title: "new_releases", icon: "material new_releases" },
  { title: "next_week", icon: "material next_week" },
  { title: "nfc", icon: "material nfc" },
  { title: "no_encryption", icon: "material no_encryption" },
  { title: "no_sim", icon: "material no_sim" },
  { title: "not_interested", icon: "material not_interested" },
  { title: "note", icon: "material note" },
  { title: "note_add", icon: "material note_add" },
  { title: "notifications", icon: "material notifications" },
  { title: "notifications_active", icon: "material notifications_active" },
  { title: "notifications_none", icon: "material notifications_none" },
  { title: "notifications_off", icon: "material notifications_off" },
  { title: "notifications_paused", icon: "material notifications_paused" },
  { title: "offline_pin", icon: "material offline_pin" },
  { title: "ondemand_video", icon: "material ondemand_video" },
  { title: "opacity", icon: "material opacity" },
  { title: "open_in_browser", icon: "material open_in_browser" },
  { title: "open_in_new", icon: "material open_in_new" },
  { title: "open_with", icon: "material open_with" },
  { title: "pages", icon: "material pages" },
  { title: "pageview", icon: "material pageview" },
  { title: "palette", icon: "material palette" },
  { title: "pan_tool", icon: "material pan_tool" },
  { title: "panorama", icon: "material panorama" },
  { title: "panorama_fish_eye", icon: "material panorama_fish_eye" },
  { title: "panorama_horizontal", icon: "material panorama_horizontal" },
  { title: "panorama_vertical", icon: "material panorama_vertical" },
  { title: "panorama_wide_angle", icon: "material panorama_wide_angle" },
  { title: "party_mode", icon: "material party_mode" },
  { title: "pause", icon: "material pause" },
  { title: "pause_circle_filled", icon: "material pause_circle_filled" },
  {
    title: "pause_circle_outline",
    icon: "material pause_circle_outline"
  },
  { title: "payment", icon: "material payment" },
  { title: "people", icon: "material people" },
  { title: "people_outline", icon: "material people_outline" },
  { title: "perm_camera_mic", icon: "material perm_camera_mic" },
  {
    title: "perm_contact_calendar",
    icon: "material perm_contact_calendar"
  },
  { title: "perm_data_setting", icon: "material perm_data_setting" },
  {
    title: "perm_device_information",
    icon: "material perm_device_information"
  },
  { title: "perm_identity", icon: "material perm_identity" },
  { title: "perm_media", icon: "material perm_media" },
  { title: "perm_phone_msg", icon: "material perm_phone_msg" },
  { title: "perm_scan_wifi", icon: "material perm_scan_wifi" },
  { title: "person", icon: "material person" },
  { title: "person_add", icon: "material person_add" },
  { title: "person_outline", icon: "material person_outline" },
  { title: "person_pin", icon: "material person_pin" },
  { title: "person_pin_circle", icon: "material person_pin_circle" },
  { title: "personal_video", icon: "material personal_video" },
  { title: "pets", icon: "material pets" },
  { title: "phone", icon: "material phone" },
  { title: "phone_android", icon: "material phone_android" },
  {
    title: "phone_bluetooth_speaker",
    icon: "material phone_bluetooth_speaker"
  },
  { title: "phone_forwarded", icon: "material phone_forwarded" },
  { title: "phone_in_talk", icon: "material phone_in_talk" },
  { title: "phone_iphone", icon: "material phone_iphone" },
  { title: "phone_locked", icon: "material phone_locked" },
  { title: "phone_missed", icon: "material phone_missed" },
  { title: "phone_paused", icon: "material phone_paused" },
  { title: "phonelink", icon: "material phonelink" },
  { title: "phonelink_erase", icon: "material phonelink_erase" },
  { title: "phonelink_lock", icon: "material phonelink_lock" },
  { title: "phonelink_off", icon: "material phonelink_off" },
  { title: "phonelink_ring", icon: "material phonelink_ring" },
  { title: "phonelink_setup", icon: "material phonelink_setup" },
  { title: "photo", icon: "material photo" },
  { title: "photo_album", icon: "material photo_album" },
  { title: "photo_camera", icon: "material photo_camera" },
  { title: "photo_filter", icon: "material photo_filter" },
  { title: "photo_library", icon: "material photo_library" },
  {
    title: "photo_size_select_actual",
    icon: "material photo_size_select_actual"
  },
  {
    title: "photo_size_select_large",
    icon: "material photo_size_select_large"
  },
  {
    title: "photo_size_select_small", icon: "material photo_size_select_small" },
  { title: "picture_as_pdf", icon: "material picture_as_pdf" },
  { title: "picture_in_picture", icon: "material picture_in_picture" },
  {
    title: "picture_in_picture_alt",
    icon: "material picture_in_picture_alt"
  },
  { title: "pie_chart", icon: "material pie_chart" },
  { title: "pie_chart_outlined", icon: "material pie_chart_outlined" },
  { title: "pin_drop", icon: "material pin_drop" },
  { title: "place", icon: "material place" },
  { title: "play_arrow", icon: "material play_arrow" },
  { title: "play_circle_filled", icon: "material play_circle_filled" },
  { title: "play_circle_outline", icon: "material play_circle_outline" },
  { title: "play_for_work", icon: "material play_for_work" },
  { title: "playlist_add", icon: "material playlist_add" },
  { title: "playlist_add_check", icon: "material playlist_add_check" },
  { title: "playlist_play", icon: "material playlist_play" },
  { title: "plus_one", icon: "material plus_one" },
  { title: "poll", icon: "material poll" },
  { title: "polymer", icon: "material polymer" },
  { title: "pool", icon: "material pool" },
  { title: "portable_wifi_off", icon: "material portable_wifi_off" },
  { title: "portrait", icon: "material portrait" },
  { title: "power", icon: "material power" },
  { title: "power_input", icon: "material power_input" },
  { title: "power_settings_new", icon: "material power_settings_new" },
  { title: "pregnant_woman", icon: "material pregnant_woman" },
  { title: "present_to_all", icon: "material present_to_all" },
  { title: "print", icon: "material print" },
  { title: "priority_high", icon: "material priority_high" },
  { title: "public", icon: "material public" },
  { title: "publish", icon: "material publish" },
  { title: "query_builder", icon: "material query_builder" },
  { title: "question_answer", icon: "material question_answer" },
  { title: "queue", icon: "material queue" },
  { title: "queue_music", icon: "material queue_music" },
  { title: "queue_play_next", icon: "material queue_play_next" },
  { title: "radio", icon: "material radio" },
  {
    title: "radio_button_checked",
    icon: "material radio_button_checked"
  },
  {
    title: "radio_button_unchecked",
    icon: "material radio_button_unchecked"
  },
  { title: "rate_review", icon: "material rate_review" },
  { title: "receipt", icon: "material receipt" },
  { title: "recent_actors", icon: "material recent_actors" },
  { title: "record_voice_over", icon: "material record_voice_over" },
  { title: "redeem", icon: "material redeem" },
  { title: "redo", icon: "material redo" },
  { title: "refresh", icon: "material refresh" },
  { title: "remove", icon: "material remove" },
  { title: "remove_circle", icon: "material remove_circle" },
  {
    title: "remove_circle_outline",
    icon: "material remove_circle_outline"
  },
  { title: "remove_from_queue", icon: "material remove_from_queue" },
  { title: "remove_red_eye", icon: "material remove_red_eye" },
  {
    title: "remove_shopping_cart",
    icon: "material remove_shopping_cart"
  },
  { title: "reorder", icon: "material reorder" },
  { title: "repeat", icon: "material repeat" },
  { title: "repeat_one", icon: "material repeat_one" },
  { title: "replay", icon: "material replay" },
  { title: "replay_10", icon: "material replay_10" },
  { title: "replay_30", icon: "material replay_30" },
  { title: "replay_5", icon: "material replay_5" },
  { title: "reply", icon: "material reply" },
  { title: "reply_all", icon: "material reply_all" },
  { title: "report", icon: "material report" },
  { title: "report_problem", icon: "material report_problem" },
  { title: "restaurant", icon: "material restaurant" },
  { title: "restaurant_menu", icon: "material restaurant_menu" },
  { title: "restore", icon: "material restore" },
  { title: "restore_page", icon: "material restore_page" },
  { title: "ring_volume", icon: "material ring_volume" },
  { title: "room", icon: "material room" },
  { title: "room_service", icon: "material room_service" },
  {
    title: "rotate_90_degrees_ccw",
    icon: "material rotate_90_degrees_ccw"
  },
  { title: "rotate_left", icon: "material rotate_left" },
  { title: "rotate_right", icon: "material rotate_right" },
  { title: "rounded_corner", icon: "material rounded_corner" },
  { title: "router", icon: "material router" },
  { title: "rowing", icon: "material rowing" },
  { title: "rss_feed", icon: "material rss_feed" },
  { title: "rv_hookup", icon: "material rv_hookup" },
  { title: "satellite", icon: "material satellite" },
  { title: "save", icon: "material save" },
  { title: "scanner", icon: "material scanner" },
  { title: "schedule", icon: "material schedule" },
  { title: "school", icon: "material school" },
  {
    title: "screen_lock_landscape",
    icon: "material screen_lock_landscape"
  },
  {
    title: "screen_lock_portrait",
    icon: "material screen_lock_portrait"
  },
  {
    title: "screen_lock_rotation",
    icon: "material screen_lock_rotation"
  },
  { title: "screen_rotation", icon: "material screen_rotation" },
  { title: "screen_share", icon: "material screen_share" },
  { title: "sd_card", icon: "material sd_card" },
  { title: "sd_storage", icon: "material sd_storage" },
  { title: "search", icon: "material search" },
  { title: "security", icon: "material security" },
  { title: "select_all", icon: "material select_all" },
  { title: "send", icon: "material send" },
  {
    title: "sentiment_dissatisfied",
    icon: "material sentiment_dissatisfied"
  },
  { title: "sentiment_neutral", icon: "material sentiment_neutral" },
  { title: "sentiment_satisfied", icon: "material sentiment_satisfied" },
  {
    title: "sentiment_very_dissatisfied",
    icon: "material sentiment_very_dissatisfied"
  },
  {
    title: "sentiment_very_satisfied",
    icon: "material sentiment_very_satisfied"
  },
  { title: "settings", icon: "material settings" },
  {
    title: "settings_applications",
    icon: "material settings_applications"
  },
  {
    title: "settings_backup_restore",
    icon: "material settings_backup_restore"
  },
  { title: "settings_bluetooth", icon: "material settings_bluetooth" },
  { title: "settings_brightness", icon: "material settings_brightness" },
  { title: "settings_cell", icon: "material settings_cell" },
  { title: "settings_ethernet", icon: "material settings_ethernet" },
  {
    title: "settings_input_antenna",
    icon: "material settings_input_antenna"
  },
  {
    title: "settings_input_component",
    icon: "material settings_input_component"
  },
  {
    title: "settings_input_composite",
    icon: "material settings_input_composite"
  },
  { title: "settings_input_hdmi", icon: "material settings_input_hdmi" },
  {
    title: "settings_input_svideo",
    icon: "material settings_input_svideo"
  },
  { title: "settings_overscan", icon: "material settings_overscan" },
  { title: "settings_phone", icon: "material settings_phone" },
  { title: "settings_power", icon: "material settings_power" },
  { title: "settings_remote", icon: "material settings_remote" },
  {
    title: "settings_system_daydream",
    icon: "material settings_system_daydream"
  },
  { title: "settings_voice", icon: "material settings_voice" },
  { title: "share", icon: "material share" },
  { title: "shop", icon: "material shop" },
  { title: "shop_two", icon: "material shop_two" },
  { title: "shopping_basket", icon: "material shopping_basket" },
  { title: "shopping_cart", icon: "material shopping_cart" },
  { title: "short_text", icon: "material short_text" },
  { title: "show_chart", icon: "material show_chart" },
  { title: "shuffle", icon: "material shuffle" },
  {
    title: "signal_cellular_4_bar",
    icon: "material signal_cellular_4_bar"
  },
  {
    title: "signal_cellular_connected_no_internet_4_bar",
    icon: "material signal_cellular_connected_no_internet_4_bar"
  },
  {
    title: "signal_cellular_no_sim",
    icon: "material signal_cellular_no_sim"
  },
  {
    title: "signal_cellular_null",
    icon: "material signal_cellular_null"
  },
  { title: "signal_cellular_off", icon: "material signal_cellular_off" },
  { title: "signal_wifi_4_bar", icon: "material signal_wifi_4_bar" },
  {
    title: "signal_wifi_4_bar_lock",
    icon: "material signal_wifi_4_bar_lock"
  },
  { title: "signal_wifi_off", icon: "material signal_wifi_off" },
  { title: "sim_card", icon: "material sim_card" },
  { title: "sim_card_alert", icon: "material sim_card_alert" },
  { title: "skip_next", icon: "material skip_next" },
  { title: "skip_previous", icon: "material skip_previous" },
  { title: "slideshow", icon: "material slideshow" },
  { title: "slow_motion_video", icon: "material slow_motion_video" },
  { title: "smartphone", icon: "material smartphone" },
  { title: "smoke_free", icon: "material smoke_free" },
  { title: "smoking_rooms", icon: "material smoking_rooms" },
  { title: "sms", icon: "material sms" },
  { title: "sms_failed", icon: "material sms_failed" },
  { title: "snooze", icon: "material snooze" },
  { title: "sort", icon: "material sort" },
  { title: "sort_by_alpha", icon: "material sort_by_alpha" },
  { title: "spa", icon: "material spa" },
  { title: "space_bar", icon: "material space_bar" },
  { title: "speaker", icon: "material speaker" },
  { title: "speaker_group", icon: "material speaker_group" },
  { title: "speaker_notes", icon: "material speaker_notes" },
  { title: "speaker_notes_off", icon: "material speaker_notes_off" },
  { title: "speaker_phone", icon: "material speaker_phone" },
  { title: "spellcheck", icon: "material spellcheck" },
  { title: "star", icon: "material star" },
  { title: "star_border", icon: "material star_border" },
  { title: "star_half", icon: "material star_half" },
  { title: "stars", icon: "material stars" },
  {
    title: "stay_current_landscape",
    icon: "material stay_current_landscape"
  },
  {
    title: "stay_current_portrait",
    icon: "material stay_current_portrait"
  },
  {
    title: "stay_primary_landscape",
    icon: "material stay_primary_landscape"
  },
  {
    title: "stay_primary_portrait",
    icon: "material stay_primary_portrait"
  },
  { title: "stop", icon: "material stop" },
  { title: "stop_screen_share", icon: "material stop_screen_share" },
  { title: "storage", icon: "material storage" },
  { title: "store", icon: "material store" },
  {
    title: "store_mall_directory",
    icon: "material store_mall_directory"
  },
  { title: "straighten", icon: "material straighten" },
  { title: "streetview", icon: "material streetview" },
  { title: "strikethrough_s", icon: "material strikethrough_s" },
  { title: "style", icon: "material style" },
  {
    title: "subdirectory_arrow_left",
    icon: "material subdirectory_arrow_left"
  },
  {
    title: "subdirectory_arrow_right",
    icon: "material subdirectory_arrow_right"
  },
  { title: "subject", icon: "material subject" },
  { title: "subscriptions", icon: "material subscriptions" },
  { title: "subtitles", icon: "material subtitles" },
  { title: "subway", icon: "material subway" },
  { title: "supervisor_account", icon: "material supervisor_account" },
  { title: "surround_sound", icon: "material surround_sound" },
  { title: "swap_calls", icon: "material swap_calls" },
  { title: "swap_horiz", icon: "material swap_horiz" },
  { title: "swap_vert", icon: "material swap_vert" },
  {
    title: "swap_vertical_circle",
    icon: "material swap_vertical_circle"
  },
  { title: "switch_camera", icon: "material switch_camera" },
  { title: "switch_video", icon: "material switch_video" },
  { title: "sync", icon: "material sync" },
  { title: "sync_disabled", icon: "material sync_disabled" },
  { title: "sync_problem", icon: "material sync_problem" },
  { title: "system_update", icon: "material system_update" },
  { title: "system_update_alt", icon: "material system_update_alt" },
  { title: "tab", icon: "material tab" },
  { title: "tab_unselected", icon: "material tab_unselected" },
  { title: "tablet", icon: "material tablet" },
  { title: "tablet_android", icon: "material tablet_android" },
  { title: "tablet_mac", icon: "material tablet_mac" },
  { title: "tag_faces", icon: "material tag_faces" },
  { title: "tap_and_play", icon: "material tap_and_play" },
  { title: "terrain", icon: "material terrain" },
  { title: "text_fields", icon: "material text_fields" },
  { title: "text_format", icon: "material text_format" },
  { title: "textsms", icon: "material textsms" },
  { title: "texture", icon: "material texture" },
  { title: "theaters", icon: "material theaters" },
  { title: "thumb_down", icon: "material thumb_down" },
  { title: "thumb_up", icon: "material thumb_up" },
  { title: "thumbs_up_down", icon: "material thumbs_up_down" },
  { title: "time_to_leave", icon: "material time_to_leave" },
  { title: "timelapse", icon: "material timelapse" },
  { title: "timeline", icon: "material timeline" },
  { title: "timer", icon: "material timer" },
  { title: "timer_10", icon: "material timer_10" },
  { title: "timer_3", icon: "material timer_3" },
  { title: "timer_off", icon: "material timer_off" },
  { title: "title", icon: "material title" },
  { title: "toc", icon: "material toc" },
  { title: "today", icon: "material today" },
  { title: "toll", icon: "material toll" },
  { title: "tonality", icon: "material tonality" },
  { title: "touch_app", icon: "material touch_app" },
  { title: "toys", icon: "material toys" },
  { title: "track_changes", icon: "material track_changes" },
  { title: "traffic", icon: "material traffic" },
  { title: "train", icon: "material train" },
  { title: "tram", icon: "material tram" },
  {
    title: "transfer_within_a_station",
    icon: "material transfer_within_a_station"
  },
  { title: "transform", icon: "material transform" },
  { title: "translate", icon: "material translate" },
  { title: "trending_down", icon: "material trending_down" },
  { title: "trending_flat", icon: "material trending_flat" },
  { title: "trending_up", icon: "material trending_up" },
  { title: "tune", icon: "material tune" },
  { title: "turned_in", icon: "material turned_in" },
  { title: "turned_in_not", icon: "material turned_in_not" },
  { title: "tv", icon: "material tv" },
  { title: "unarchive", icon: "material unarchive" },
  { title: "undo", icon: "material undo" },
  { title: "unfold_less", icon: "material unfold_less" },
  { title: "unfold_more", icon: "material unfold_more" },
  { title: "update", icon: "material update" },
  { title: "usb", icon: "material usb" },
  { title: "verified_user", icon: "material verified_user" },
  {
    title: "vertical_align_bottom",
    icon: "material vertical_align_bottom"
  },
  {
    title: "vertical_align_center",
    icon: "material vertical_align_center"
  },
  { title: "vertical_align_top", icon: "material vertical_align_top" },
  { title: "vibration", icon: "material vibration" },
  { title: "video_call", icon: "material video_call" },
  { title: "video_label", icon: "material video_label" },
  { title: "video_library", icon: "material video_library" },
  { title: "videocam", icon: "material videocam" },
  { title: "videocam_off", icon: "material videocam_off" },
  { title: "videogame_asset", icon: "material videogame_asset" },
  { title: "view_agenda", icon: "material view_agenda" },
  { title: "view_array", icon: "material view_array" },
  { title: "view_carousel", icon: "material view_carousel" },
  { title: "view_column", icon: "material view_column" },
  { title: "view_comfy", icon: "material view_comfy" },
  { title: "view_compact", icon: "material view_compact" },
  { title: "view_day", icon: "material view_day" },
  { title: "view_headline", icon: "material view_headline" },
  { title: "view_list", icon: "material view_list" },
  { title: "view_module", icon: "material view_module" },
  { title: "view_quilt", icon: "material view_quilt" },
  { title: "view_stream", icon: "material view_stream" },
  { title: "view_week", icon: "material view_week" },
  { title: "vignette", icon: "material vignette" },
  { title: "visibility", icon: "material visibility" },
  { title: "visibility_off", icon: "material visibility_off" },
  { title: "voice_chat", icon: "material voice_chat" },
  { title: "voicemail", icon: "material voicemail" },
  { title: "volume_down", icon: "material volume_down" },
  { title: "volume_mute", icon: "material volume_mute" },
  { title: "volume_off", icon: "material volume_off" },
  { title: "volume_up", icon: "material volume_up" },
  { title: "vpn_key", icon: "material vpn_key" },
  { title: "vpn_lock", icon: "material vpn_lock" },
  { title: "wallpaper", icon: "material wallpaper" },
  { title: "warning", icon: "material warning" },
  { title: "watch", icon: "material watch" },
  { title: "watch_later", icon: "material watch_later" },
  { title: "wb_auto", icon: "material wb_auto" },
  { title: "wb_cloudy", icon: "material wb_cloudy" },
  { title: "wb_incandescent", icon: "material wb_incandescent" },
  { title: "wb_iridescent", icon: "material wb_iridescent" },
  { title: "wb_sunny", icon: "material wb_sunny" },
  { title: "wc", icon: "material wc" },
  { title: "web", icon: "material web" },
  { title: "web_asset", icon: "material web_asset" },
  { title: "weekend", icon: "material weekend" },
  { title: "whatshot", icon: "material whatshot" },
  { title: "widgets", icon: "material widgets" },
  { title: "wifi", icon: "material wifi" },
  { title: "wifi_lock", icon: "material wifi_lock" },
  { title: "wifi_tethering", icon: "material wifi_tethering" },
  { title: "work", icon: "material work" },
  { title: "wrap_text", icon: "material wrap_text" },
  {
    title: "youtube_searched_for",
    icon: "material youtube_searched_for"
  },
  { title: "zoom_in", icon: "material zoom_in" },
  { title: "zoom_out", icon: "material zoom_out" },
  { title: "zoom_out_map", icon: "material zoom_out_map" },
];
// const all = FontAwesome.concat(Icons).concat(IonMd).concat(IonIos).concat(IonLogo);

// const all = FontAwesome;

export default {
  FontAwesome: FontAwesome,
  Icons: Icons,
  IonMd: IonMd,
  IonIos: IonIos,
  IonLogo: IonLogo,
  Material: Material,
};

<template>
  <b-nav-item-dropdown menu-class="dropdown-menu-right" class="dropdown navbar-user" no-caret>
    <template slot="button-content">
      <div class="image image-icon bg-black text-grey-darker">
        <i class="fa fa-user"></i>
      </div>
      <span class="d-none d-md-inline"> {{ loginUser.userName }} </span> <b class="caret"></b>
    </template>
    <b-dropdown-item @click="openUserModal"> <i class="fa fa-user"></i> {{ $t("프로필") }} </b-dropdown-item>

    <span v-if="!isMobile">
      <b-dropdown-divider v-if="master.headerLinks && master.headerLinks.length"></b-dropdown-divider>
      <b-dropdown-item v-for="(link, i) in master.headerLinks" :key="i" :href="link.url" :target="link.target"> {{ $t(link.label) }} </b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
    </span>
    <b-dropdown-item v-if="isAuthenticated" @click="logoutForm">{{ $t("로그아웃") }}</b-dropdown-item>

    <!-- 프로필 변경 팝업 -->
    <b-modal ref="userModal" :hide-footer="true" :title="$t('프로필')">
      <user-detail :isChangePassword="true" :changeUser="loginUser" @change-password="openChangePwdModal" @save-done="onUserModalSaveDone"> </user-detail>
    </b-modal>
    <!-- 비밀번호 변경 팝업 -->
    <b-modal ref="changePwdModal" :hide-footer="true" :title="$t('비밀번호 변경')" >
      <change-pwd-modal :changeUser="loginUser" @save-done="onChangePwdModalSaveDone" />
    </b-modal>

    <theme-selector ref="themeModal" />

  </b-nav-item-dropdown>
</template>

<script>
import ChangePwdModal from "@src/views/sys/menu/user/ChangePwdModal.vue";
import UserDetail from "@src/views/sys/menu/user/UserDetail.vue";
import ThemeSelector from "@src/views/sys/data-management/ThemeSelector.vue";

export default {
  components: {
    ChangePwdModal, UserDetail, ThemeSelector
  },
  data() {
    return {
      //pageOptions: PageOptions,
    };
  },
  computed: {
    loginUser() {
      return this.$store.getters.loginUser || { userName: "" };
    },
    master() {
      return this.$store.getters.master;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    isTransMode() {
      return this.$store.getters.isTransMode;
    },
    isHelpMode() {
      return this.$store.getters.isHelpMode;
    },
  },
  methods: {
    openUserModal() {
      this.$refs.userModal.show();
    },
    async onUserModalSaveDone() {
      this.$refs.userModal.hide();
      this.$store.dispatch("UPDATE_LOGIN_INFO");

    },
    openSettings() {
      this.$router.push(`/management/settings`);
    },
    openEngineer() {
      this.$router.push(`/engineer`);
    },
    toggleTransMode() {
      this.$store.commit("UPDATE_TRANS_MODE", !this.isTransMode);
    },
    toggleHelpMode() {
      this.$store.commit("UPDATE_HELP_MODE", !this.isHelpMode);
    },
    openChangePwdModal() {
      this.$refs.changePwdModal.show();
    },
    onChangePwdModalSaveDone() {
      this.$refs.changePwdModal.hide();
    },
    openTheme() { 
      this.$refs.themeModal.show();
    },
    logoutForm: function(e) {
      e.preventDefault();

      this.$store.dispatch("LOGOUT", this.loginUser).then(() => {
        setTimeout(() => {
          // mobile 모드일 경우 안드로이드 refesh함수 호출
          if (this.isMobile) new Function("Android.refresh();")();
          else 
            this.$router.push({path: "/Login", params: {isLogout: true} })
            //this.$router.go("/");
        }, 100);

        // console.log('웹 로그아웃 후 웹뷰 새로고침');
      });
    },
  },
};
</script>

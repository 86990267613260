<template>
	<vvo v-slot="v">
		<div class="row form-group">
			<div class="col-md-6">
				<label>
					<trans>알람 그룹</trans>
				</label>
				<valid-input
					:vid="'알람 그룹'"
					:inputType="'text'"
					:classList="'form-control'"
					:placeholder="$t('알람 그룹을 입력하세요.')"
					:inputValue.sync="alarmData.alarmGroup"
					:rules="rules.ALARM_BASE_GROUP_RULE"
					:errorMessage="validMessage.ALARM_BASE_GROUP_VALID_MESSAGE"
          :disabled="isUpdate"
				></valid-input>
				<!-- <input type="text" class="form-control" :class="classes" :placeholder="$t('알람 그룹을 입력하세요.')" v-model="alarmData.alarmGroup" :disabled="isUpdate || readonly" /> -->
			</div>
			<div class="col-md-3 m-auto">
				<label> &nbsp; </label>
				<div class="checkbox checkbox-css m-auto">
					<input type="checkbox" id="modal_alarm_popup_checkbox_use" v-model="alarmData.isUse" :disabled="readonly" />
					<label for="modal_alarm_popup_checkbox_use">{{ $t("사용") }}</label>
				</div>
			</div>
			<div class="col-md-3 m-auto">
				<label> &nbsp; </label>
				<div class="checkbox checkbox-css m-auto">
					<input type="checkbox" id="modal_alarm_popup_checkbox_hold" v-model="alarmData.isHold" :disabled="readonly" />
					<label for="modal_alarm_popup_checkbox_hold">{{ $t("알람보류") }}</label>
				</div>
			</div>
		</div>
		<div class="row form-group">
			<div class="col-md-12">
				<label>
					<trans>관제점 주소</trans>
				</label>
					<input type="hidden"  v-model="alarmData.ptAddr" _xe_remark="point-select가 validation 불가. input 상자를 배치함..." />
					<point-select v-model="alarmData.ptAddr" :disabled="isUpdate || readonly" />
			</div>
		</div>
		<div class="row form-group">
			<div class="col-md-6">
				<label>
					<trans>알람 유형</trans>
				</label>
					<select class="form-control"  v-model="alarmData.alarmType" :disabled="readonly">
						<option value="A0" selected> Low / High</option>
						<option value="A1">Low low / High high</option>
						<option value="D0">True / False</option>
					</select>
		
			</div>
		</div>
		<div class="row form-group">
			<div class="col-md-3" v-if="!(alarmData.alarmType !== 'A1')">
				<label> LL </label>
				<!-- <input type="number" class="form-control" placeholder="Low Low" v-model="alarmData.ll" :disabled="readonly" /> -->
				<valid-input
					:inputType="'number'"
					:classList="'form-control'"
					:placeholder="'Low Low'"
					:inputValue.sync="alarmData.ll"
					:rules="rules.ALARM_BASE_VALUE_LL_RULE"
					:NonStatus="true"
				></valid-input>
			</div>
			<div class="col-md-3" v-if="alarmData.alarmType !== 'D0'">
				<label> L </label>
				<!-- <input type="number" class="form-control" placeholder="Low" v-model="alarmData.l" :disabled="readonly" /> -->
				<valid-input
					:vid="'L'"
					:inputType="'number'"
					:classList="'form-control'"
					:placeholder="'Low'"
					:inputValue.sync="alarmData.l"
					:rules="rules.ALARM_BASE_VALUE_L_RULE"
					:errorMessage="validMessage.ALARM_BASE_VALUE_L_VALUE_H_VALID_MESSAGE"
				></valid-input>
			</div>
			<div class="col-md-3" v-if="alarmData.alarmType !== 'D0'">
				<label> H </label>
				<!-- <input type="number" class="form-control" placeholder="High" v-model="alarmData.h" :disabled="readonly" /> -->
				<valid-input
					:vid="'H'"
					:inputType="'number'"
					:classList="'form-control'"
					:placeholder="'High'"
					:inputValue.sync="alarmData.h"
					:rules="rules.ALARM_BASE_VALUE_H_RULE"
					:errorMessage="validMessage.ALARM_BASE_VALUE_L_VALUE_H_VALID_MESSAGE"
				></valid-input>
			</div>
			<div class="col-md-3" v-if="!(alarmData.alarmType !== 'A1')">
				<label> HH </label>
				<!-- <input type="number" class="form-control" placeholder="High High" v-model="alarmData.hh" :disabled="readonly" /> -->
				<valid-input
					:inputType="'number'"
					:classList="'form-control'"
					:placeholder="'High High'"
					:inputValue.sync="alarmData.hh"
					:rules="rules.ALARM_BASE_VALUE_HH_RULE"
					:NonStatus="true"
				></valid-input>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<b-tabs nav-class="nav-tabs" nav-wrapper-class="nav nav-tabs b-1 b-radius-3" content-class="bg-silver p-0" active-nav-item-class="text-inverse bg-silver">
					<b-tab active>
						<template slot="title">
							<span class="d-sm-none">
								<trans>경고</trans>
							</span>
							<span class="d-sm-block d-none">
								<trans>경고메세지</trans>
							</span>
						</template>
						<valid-textarea
							:inputType="'text'"
							:classList="'form-control'"
							:inputValue.sync="alarmData.description"
							:rules="rules.ALARM_BASE_WARN_MESSAGE_RULE"
							:NonStatus="true"
							:textareaRow="10"
						></valid-textarea>
					</b-tab>
					<b-tab>
						<template slot="title">
							<span class="d-sm-none">
								<trans>해결</trans>
							</span>
							<span class="d-sm-block d-none">
								<trans>해결방안</trans>
							</span>
						</template>
						<valid-textarea
							:inputType="'text'"
							:classList="'form-control'"
							:inputValue.sync="alarmData.solution"
							:rules="rules.ALARM_BASE_SOLVED_RULE"
							:NonStatus="true"
							:textareaRow="10"
						></valid-textarea>
					</b-tab>

					<b-tab v-xe-pm.E.A v-if="!isMobile && isUpdate">
						<template slot="title">
							<span class="d-sm-none">
								<trans>테스트</trans>
							</span>
							<span class="d-sm-block d-none">
								<trans>알람테스트</trans>
							</span>
						</template>

						<div class="bg-white p-30 p-l-50 b-1" style="height:100px;">
							<div class="form-inline m-b-10">
								<span class="m-l-30 m-r-10">테스트값:</span>
								<valid-input
									:inputType="'number'"
									:classList="'form-control m-r-10'"
									:placeholder="$t('알람테스트 값')"
									:inputValue.sync="testVal"
									:rules="rules.ALARM_BASE_TEST_VALUE_RULE"
									:NonStatus="true"
								></valid-input>
								<button class="btn btn-success" @click="onTestAlarm()"  ><i class="fa fa-bullhorn"></i> {{ $t("실행") }}</button>
							</div>
						</div>
					</b-tab>
				</b-tabs>
			</div>
		</div>

		<div v-if="isUpdate" class="row form-group">
			<div class="col-lg-6">
				<label>
					<trans>등록일</trans>
				</label>
				<span class="form-control" :readonly="isUpdate">{{ isEmpty(alarmData.regDt) ? "" : alarmData.regDt.format("yyyy-MM-dd HH:mm") }}</span>
			</div>
			<div class="col-lg-6">
				<label>
					<trans>최종수정일</trans>
				</label>
				<span class="form-control" :readonly="isUpdate">{{ isEmpty(alarmData.updDt) ? "" : alarmData.updDt.format("yyyy-MM-dd HH:mm") }}</span>
			</div>
		</div>

		<div slot="modal-footer" v-if="!readonly">
			<btn-delete v-if="isUpdate" @click="onDeleteAlarm()" />
			<btn-save @click="onValidate(v, onSaveAlarm)" :label="isUpdate ? '수정' : '입력'" />
		</div>
	</vvo>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts"

	export default {
		props: {
			alarm: { type: Object, required: true },
			readonly: { type: Boolean, required: false, default: true },
		},
		data() {
			return {
				alarmData: {},
				testVal: 0,
				rules,
				validMessage,
				popupMessages
			};
		},
		computed: {
			isUpdate() {
				return !this.isEmpty(this.alarm.alarmGroup) && !this.isEmpty(this.alarm.ptAddr);
			},
		},
		watch: {
			alarm() {
				this.initModal();
			},
		},
		mounted() {
			this.initModal();
		},
		methods: {
			initModal() {
				this.alarmData = { alarmType: "A0", l: 0, h: 0, sUse: true, isHold: false };

				// TODO: alarmLog 목록조회에서 object를 넘기기 때문에, 실제 알람이 삭제되었음에도 화면에 표시된다.

				if (this.isUpdate) {
					backEndApi.alarm.getAlarm(this.alarm.alarmGroup, this.alarm.ptAddr).then(({ data }) => {
						if (this.$err(data)) {
							this.alertNoti(popupMessages.COMMON_SEARCH_NO_DATA_POPUP_MESSAGE);
							return;
						}

						this.alarmData = data;
					});
				}
			},
			onSaveAlarm() {
        console.log('alarm')
				this.isUpdate ? this.updateAlarm() : this.insertAlarm();
			},
			updateAlarm() {
				this.alertQuestion(popupMessages.COMMON_UPDATE_CONFIRM_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.alarm.updateAlarm(this.alarmData).then(({ data }) => {
						if (this.$err(data)) return;

						this.alarmData = {};
						this.alertNoti(popupMessages.COMMON_UPDATE_POPUP_MESSAGE);
						this.$emit("save-done");
					});
				});
			},
			insertAlarm() {
				this.alertQuestion(popupMessages.COMMON_SAVE_CONFIRM_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					let { alarmGroup, ptAddr } = this.alarmData;

					// TODO: dup체크 validation 처리 해야 함.
					backEndApi.alarm.isDuplicateAlarm(alarmGroup, ptAddr).then(({ data }) => {
						if (this.$err(data)) return;

						if (data.isDup) {
							this.alertNoti(popupMessages.COMMON_API_CALL_FAIL_MESSAGE);
							return;
						}

						backEndApi.alarm.insertAlarm(this.alarmData).then(({ data }) => {
							if (this.$err(data)) return;

							this.alarmData = {};
							this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
							this.$emit("save-done");
						});
					});
				});
			},
			onDeleteAlarm() {
				this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					let { alarmGroup, ptAddr } = this.alarmData;

					backEndApi.alarm.deleteAlarm(alarmGroup, ptAddr).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
						this.$emit("save-done");
					});
				});
			},
			onTestAlarm() {
				this.alertQuestion(popupMessages.ALARM_TEST_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					let { alarmGroup, ptAddr } = this.alarmData;

					backEndApi.alarm.insertAlarmTest(alarmGroup, ptAddr, this.testVal).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.ALARM_TEST_SUCCESS_POPUP_MESSAGE);
					});
				});
			},
		},
	};
</script>

<template>
	<div class="m-r-15">
		<header-box :title="$t('시스템 로그')" />
		<hr style="margin: 10px 0px 10px 0px" />

		<div class="form-inline m-b-10">
			<ln-select :label="$t('어플리케이션')" v-model="condition.appId" :items="appIds" />
			<ln-select label="이벤트 유형" v-model="condition.eventType" :items="eventTypes" />
			<ln-picker label="시작일" v-model="condition.sDt" :setting="{ format: 'YYYY-MM-DD HH:mm' }" @input="onRefreshCondition" style="min-width:200px" />
			<ln-picker label="종료일" v-model="condition.eDt" :setting="{ format: 'YYYY-MM-DD HH:mm' }" @input="onRefreshCondition" style="min-width:200px" />
			<label>
				<trans>검색어</trans>
			</label>
			<span>&nbsp;</span>
			<valid-input
				:inputType="'text'"
				:inputValue.sync="condition.searchText"
				:placeholder="$t('검색어')"
				@keydownMethod="onSearchEventLog()"
				:rules="rules.LOG_SEARCH_RULE"
				:NonStatus="true"
				style="marginRight: 20px"
			></valid-input>
			<ln-button label="조회" color="primary" icon="fa-search" :tooltip="$t('조회하려면 클릭하세요.')" @click="onSearchEventLog()" />

			<div>{{$t('검색 개수')}}: {{ eventLogs.length }}, {{$t('총 표시 개수')}}: {{ condition.max }}</div>
		</div>

		<div class="row">
			<div class="col-lg-12">
				<div class="text-right">
					<b-button type="button" class="mb-1 btn-light" @click="onExcelDownload()">{{$t('다운로드')}}</b-button>
				</div>
				<vue-good-table
					@on-cell-click="openEventLogModal"
					ref="grid"
					:columns="columns"
					:rows="eventLogs"
					v-bind="merge(GRID_OPT, { selectOptions: { enabled: true } })"
					@on-per-page-change="setGridPerPage($event, eventLogs)"
					:pagination-options="{
						enabled: true,
						nextLabel: $t('다음'),
						prevLabel: $t('이전'),
						ofLabel: '/',
						pageLabel: $t('페이지'), // for 'pages' mode
						rowsPerPageLabel: $t('표시 개수'),
					}"
					:id="'log-table'"
				>
					<div slot="emptystate" style="text-align: center;">
						{{$t('조회된 결과가 없습니다.')}}
					</div>
					<div slot="selected-row-actions">
						<button class="btn btn-danger pull-right" @click="onDeleteSelectedRows()"><i class="fa fa-trash-alt"></i> {{ $t("삭제") }}</button>
					</div>
					<template slot="table-row" slot-scope="props">
						<template v-if="props.column.field == 'Date'">
							{{ props.row.regDt ? props.row.regDt.format("yyyy-MM-dd") : "" }}
						</template>
						<template v-else-if="props.column.field == 'Time'">
							{{ props.row.regDt ? props.row.regDt.format("HH:mm:ss") : "" }}
						</template>
						<template v-else-if="props.column.field == 'passed'">
							{{ props.row.regDt.passedTime() }}
						</template>
						<template v-else-if="props.column.field == 'eventType'">
							<span :class="{ 'text-danger': props.row.eventType == 'Error' }">
								{{ props.row.eventType }}
							</span>
						</template>
						<span v-else-if="props.column.field == 'summary'" class="ellipsis-span" :class="{ 'text-danger': props.row.eventType == 'Error' }">
							{{ props.formattedRow[props.column.field] | codeChangelengthText(100) }}
						</span>
						<span v-else-if="props.column.field == 'stacktrace'" class="ellipsis-span">
							{{ props.formattedRow[props.column.field] | codeChangelengthText(100) }}
						</span>
						<template v-else>{{ props.formattedRow[props.column.field] }}</template>
					</template>
				</vue-good-table>
			</div>
		</div>

		<event-log-popup ref="eventLogModal" :eventLogIdx="curEventLogIdx" @save-done="closeEventLogModal" />
	</div>
</template>

<style scoped>
	/* grid layout */
	.panel-body {
		padding: 15px;
	}

	table.vgt-table td {
		border-right: 1px solid #dcdfe6;
	}

	.b-table-sticky-header,
	.table-responsive,
	[class*="table-responsive-"] {
		margin-bottom: 0;
	}
</style>

<script>
	import backEndApi from "@api/backEndApi.js";
	import HeaderBox from "../../component/headerBox/sys/Header.vue";
	import EventLogPopup from "./eventLog/EventLogPopup.vue";
	import XLSX from "xlsx";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		components: {
			EventLogPopup,
			HeaderBox,
		},
		data() {
			return {
				condition: {
					sDt: new Date().format("yyyy-MM-dd 00:00"),
					eDt: new Date().format("yyyy-MM-dd 23:59"),
					searchText: "",
					eventType: "",
					appId: "",
					max: 500,
				},

				eventTypes: {},
				appIds: {},

				// grid data
				columns: [
					// {
					//   label: this.$t("번호"),
					//   field: "eventLogIdx",
					//   type: "number",
					//   width: "5%",
					//   tdClass: "text-center text-nowrap",
					//   thClass: "text-center text-nowrap",
					// },
					{
						label: this.$t("날짜"),
						field: "Date",
						type: "text",
						width: "5%",
						tdClass: "text-center text-nowrap xe-grid-text",
						thClass: "text-center text-nowrap ",
					},
					{
						label: this.$t("시간"),
						field: "Time",
						type: "text",
						width: "5%",
						tdClass: "text-center text-nowrap xe-grid-text",
						thClass: "text-center text-nowrap ",
					},
					{
						label: this.$t("경과시간"),
						field: "passed",
						type: "text",
						width: "8%",
						tdClass: "text-center text-nowrap",
						thClass: "text-center text-nowrap",
					},
					{
						label: this.$t("어플리케이션"),
						field: "appId",
						type: "text",
						width: "10%",
						tdClass: "text-left text-nowrap xe-grid-text",
						thClass: "text-center text-nowrap ",
					}, // 59
					{
						label: this.$t("이벤트 유형"),
						field: "eventType",
						type: "text",
						width: "5%",
						tdClass: "text-center text-nowrap xe-grid-text",
						thClass: "text-center text-nowrap ",
					},
					{
						label: this.$t("사용자 아이디"),
						field: "userId",
						type: "text",
						width: "10%",
						tdClass: "text-left text-nowrap xe-grid-text",
						thClass: "text-center text-nowrap ",
					},
					{
						label: this.$t("클라이언트 IP"),
						field: "clientIp",
						type: "text",
						width: "8%",
						tdClass: "text-left text-nowrap xe-grid-text",
						thClass: "text-center text-nowrap ",
					},
					{
						label: this.$t("서버 IP"),
						field: "serverIp",
						type: "text",
						width: "8%",
						tdClass: "text-left text-nowrap xe-grid-text",
						thClass: "text-center text-nowrap ",
					},
					{
						label: this.$t("요약정보"),
						field: "summary",
						type: "text",
						width: "26%",
						tdClass: "text-left text-nowrap xe-grid-text text-ellipsis",
						thClass: "text-center text-nowrap ",
					},
					{
						label: this.$t("추적메세지"),
						field: "stacktrace",
						type: "text",
						width: "15%",
						tdClass: "text-left text-nowrap xe-grid-text text-ellipsis",
						thClass: "text-center text-nowrap ",
					},
				],

				eventLogs: [],

				curEventLogIdx: "",

				to_bind1: null,
				to_bind2: null,
				rules,
			};
		},
		mounted() {
			this.initSetting();
		},
		methods: {
			initSetting() {
				this.onRefreshCondition();
			},
			onRefreshCondition() {
				clearTimeout(this.to_bind1);
				this.to_bind1 = setTimeout(() => {
					this.bindEventTypes();
				}, 200);

				clearTimeout(this.to_bind2);
				this.to_bind1 = setTimeout(() => {
					this.bindAppIds();
				}, 300);

				this.$nextTick(() => {
					this.onSearchEventLog();
				});
			},
			bindEventTypes() {
				let { sDt, eDt } = this.condition;

				backEndApi.eventLog.getEventTypes(sDt, eDt).then(({ data }) => {
					if (this.$err(data)) return;

					let obj = {};
					data.forEach((v) => (obj[v.eventType] = `${v.eventType} (${v.cnt})`));
					this.eventTypes = obj;
				});
			},
			bindAppIds() {
				let { sDt, eDt } = this.condition;

				//this.appIds = {};
				backEndApi.eventLog.getAppIds(sDt, eDt).then(({ data }) => {
					if (this.$err(data)) return;

					let obj = {};
					data.forEach((v) => (obj[v.appId] = `${v.appId} (${v.cnt})`));
					this.appIds = obj;
				});
			},
			onSearchEventLog() {
				this.eventLogs.clear();

				this.condition.searchText = this.condition.searchText.trim();
				let { sDt, eDt, eventType, appId, searchText, max } = this.condition;

				backEndApi.eventLog.searchEventLog(sDt, eDt, eventType, appId, searchText, max).then(({ data }) => {
					if (this.$err(data)) return;

					this.eventLogs.range(data);
				});
			},
			onDeleteSelectedRows() {
				let eventLogIdxs = this.$refs.grid.selectedRows.map((v) => v.eventLogIdx);

				this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.eventLog.deleteEventLog(eventLogIdxs).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
						this.initSetting();
					});
				});
			},
			openEventLogModal(param) {
				this.curEventLogIdx = param.row.eventLogIdx;
				this.$refs.eventLogModal.show();
			},
			closeEventLogModal() {
				this.$refs.eventLogModal.hide();
				this.initSetting();
			},

			// onExcelDownload() {
			// 	console.log(this.eventLogs)
			// 	const excelName = "이벤트로그.xlsx";

			// 	this.alertQuestion(popupMessages.COMMON_EXCEL_DOWNLOAD_POPUP_MESSAGE).then((result) => {
			// 		if (!result.value) return;

			// 		// 테이블 복사
			// 		const originalTable = document.getElementById("log-table");
			// 		console.log(originalTable);
			// 		const clonedTable = originalTable.cloneNode(true);

			// 		// 테이블 해더에 불필요한 텍스트 제거
			// 		const headers = clonedTable.querySelectorAll("thead tr th button span");
			// 		headers.forEach((header) => {
			// 			header.innerText = header.innerText = "";
			// 		});

			// 		// 테이블 해더가 2줄 나와서 1줄 제거
			// 		const extraHeaderRow = clonedTable.querySelector("thead tr:last-child");
			// 		extraHeaderRow.remove();

			// 		// 테이블 해더 체크박스 열 제거
			// 		const checkBoxDelete = clonedTable.querySelector("th.vgt-checkbox-col");
			// 		checkBoxDelete.remove();

			// 		// 테이블 바디 체크박스 열 제거
			// 		const rows = clonedTable.querySelectorAll("tbody tr");
			// 		rows.forEach((row) => {
			// 			const checkBox = row.querySelector("th.vgt-checkbox-col");
			// 			checkBox.remove();
			// 		});

			// 		let wb = XLSX.utils.book_new();
			// 		let ws = XLSX.utils.table_to_sheet(clonedTable);
			// 		XLSX.utils.book_append_sheet(wb, ws, excelName);
			// 		XLSX.writeFile(wb, excelName);
			// 	});
			// },
			formatForExcel(row) {
				let formatHeader = {};

				this.columns.forEach((column) => {
					if (column.field == "Date") {
						formatHeader[column.label] = row.regDt ? row.regDt.format("yyyy-MM-dd") : "";
					} else if (column.field === "Time") {
						formatHeader[column.label] = row.regDt ? row.regDt.format("HH:mm:ss") : "";
					} else if (column.field === "passed") {
						formatHeader[column.label] = row.regDt.passedTime();
					} else {
						formatHeader[column.label] = row[column.field];
					}
				});

				return formatHeader;
			},

			async onExcelDownload() {
				console.log(this.$refs.grid);
				console.log(this.$refs.grid.filterRows);
				console.log(this.$refs.grid.filteredRows);

				const popupResult = await this.alertQuestion(popupMessages.COMMON_EXCEL_DOWNLOAD_POPUP_MESSAGE);
				if (!popupResult.value) return;
				if (this.eventLogs.length === 0) {
					this.alertNoti(popupMessages.COMMON_EXCEL_DOWNLOAD_ERROR_POPUP_MESSAGE);
					return;
				}

				const excelName = "이벤트로그.xlsx";
				// let excelData = this.eventLogs.map((v) => this.formatForExcel(v));
				let excelData = this.$refs.grid.filteredRows[0].children.map((v) => this.formatForExcel(v));
				let wb = XLSX.utils.book_new();
				let ws = XLSX.utils.json_to_sheet(excelData);
				XLSX.utils.book_append_sheet(wb, ws, excelName);
				XLSX.writeFile(wb, excelName);
			},
		},
	};
</script>

export default {
  // code
  cityCodes: {
    KR: [
      { code: "002", name: "Seoul" },
      { code: "051", name: "Pusan" },
      { code: "053", name: "Daegu" },
      { code: "032", name: "Incheon" },
      { code: "062", name: "Kwangju" },
      { code: "042", name: "Daejun" },
      { code: "052", name: "Ulsan" },
      { code: "031", name: "Gyeonggi-Do" },
      { code: "033", name: "Gangwon-Do" },
      { code: "043", name: "Chungcheong Buk-Do" },
      { code: "041", name: "Chungcheong Nam-Do" },
      { code: "054", name: "Gyeongsang Buk-Do" },
      { code: "055", name: "Gyeongsang Nam-Do" },
      { code: "063", name: "Jeolla Buk-Do" },
      { code: "061", name: "Jeolla Nam-Do" },
      { code: "064", name: "Jeju-Do" },
    ],
    VN: [
      { code: "NIN", name: "Ninh Binh" },
      { code: "HAN", name: "Hanoi" },
      { code: "SGN", name: "Saigon" },
    ],
    EN: [
      // "EN":[
      { code: "ATL", name: "Atlanta" },
      { code: "BOS", name: "Boston" },
      { code: "CHI", name: "Chicago" },
      { code: "DFW", name: "Dallas" },
      { code: "DTT", name: "Detroit" },
      { code: "HNL", name: "Honolulu" },
      { code: "IND", name: "Indiana" },
    ],
  },

  // error codes
  errors: {
    ER_DUP_ENTRY: {
      message: "데이터가 중복되었습니다.", // 문자열키..
    },
  },

  colorSet: [
    // WChart 기본
    "#48c0b6","#9d6af5","#ffde69","#65bf65","#5C6BC0","#FB499D", // 7   green-lighter "#e8e8e8",
    // 바뀐 컬러셋
    "#1e87ff","#32a932","#ffd900","#f59c1a","#ff5b57","#006699",
    "#009999","#00acac","#fb5597","#fda5ca","#8753de","#57595c", // 12

    // 기존 컬러셋
    "#348fe2", // primary
    "#f8b553", // orange-lighter
    "#959dc8", // purple-lighter
    "#49b6d6", // info
    "#a57ee6", // indigo-lighter
    "#fc80b1", // pink-lighter
    "#54ddd9", // --
    "#ff8481", // red-lighter
    "#acd778", // lime-lighter

    "#2d353c", // inverse
    "#b6c2c9", // default 보다 진한 회색
    "#fff", //white
    "#5e5650", //갈색

    //Compare카드
    "#03adad",
    "#61d4ff",
    "#4da8ef",
    "#58d4d2",
    "#474f6c",
    //IOD카드 
    "#51c1e6",
    "#4484dc",
    //파이차트
    "#7cdbcb",
    "#7cbedb",
    "#74a3e5",
    "#5873af",
    "#a6dba8",
    "#c6ebba",
    //도넛차트
    "#4c7fba",
    "#ccf2b3",
    "#91f0dd",
    "#6fd8e8",
    //막대차트
    "#c2d8ea",
    "#aed4d0",
    "#486eb0",
    "#60a0db",
    "#74d6e0",
    "#e1e1e1",
    //라인차트
    "#3c68a4",
    "#4edfd3",
    "#4d8eef",
    "#94ce61",
    "#92d0f0",
  ],


};

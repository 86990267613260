function init() {  
  //#region 다국어

  // 다국어
  global.xe.strErrors = {};
  
  global.xe.$t = function (text, option, ...fields) {
    if (global.xe.isEmpty(text)) return '';
  
    let locale = global.xe.locale; // kr, en
    let strings = global.xe.readStorage("strings");
    // console.log(text);
    let str = text.toString().trim();
  
    let transSet = strings[str];
    if (transSet != null && transSet != undefined && transSet[locale]) {
      let message = transSet[locale];
  
      if (typeof (message) == 'function') {
        message = message(fields);
      }
  
      return message;
    }
  
    //let pattern_num = /[0-9]/;	// 숫자 
    let pattern_eng = /[a-zA-Z]/;	// 영문자 
    //let pattern_spc = /[~!@#$%^&*()_+|<>?:{}]/; // 특수문자
    let pattern_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크
  
    let pattern = (locale == 'en') ? pattern_kor : pattern_eng;
  
    if (global.xe.isEmpty(option)) option = { remark: '', resource: '' }
  
    if (pattern.test(text)) {
      global.xe.strErrors[text] = { kr: text, en: text, remark: option.remark, resource: option.resource };
      //console.log(`TRANS NOT FOUND >>>${text}<<< locale=${locale}  strings=${Object.keys(strings).length}`, "\n", Object.keys(global.xe.strErrors).length, "\n"+ Object.keys(global.xe.strErrors).join('\n'));
      //console.log(`TRANS NOT FOUND >>>${text}<<< locale=${locale}  strings=${Object.keys(strings).length}`);
    }
  
    return text;
  }
  
  String.prototype.$t = global.xe.$t;
  
  // 모듈 인스턴싱
  global.module = function (vm) {
    return global.vm = vm;
  }
  
  //#endregion 
}

export { init };
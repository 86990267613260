var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-r-15"},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"col-lg-10 p-l-0 p-r-0"},[_c('div',{staticClass:"pull-left"},[_c('div',{staticClass:"h3"},[_vm._v(" "+_vm._s(_vm.$t('시스템 관리'))+" > "+_vm._s(_vm.$t('알람 관리'))+" > "+_vm._s(_vm.$t('알람 기준 설정'))+" ")])])])]),_c('hr',{staticStyle:{"margin":"10px 0px 10px 0px"}}),_c('div',{staticClass:"form-inline form-group"},[_c('ln-select',{attrs:{"label":_vm.$t('그룹'),"items":_vm.alarmGroups},model:{value:(_vm.filterGroup),callback:function ($$v) {_vm.filterGroup=$$v},expression:"filterGroup"}}),_c('ln-select',{attrs:{"label":_vm.$t('사용여부'),"items":{ true: 'Y', false: 'N' }},model:{value:(_vm.filterIsUse),callback:function ($$v) {_vm.filterIsUse=$$v},expression:"filterIsUse"}}),_c('ln-select',{attrs:{"label":_vm.$t('일시중지'),"items":{ true: 'Y', false: 'N' }},model:{value:(_vm.filterIsHold),callback:function ($$v) {_vm.filterIsHold=$$v},expression:"filterIsHold"}}),_c('label',[_c('trans',[_vm._v("검색어")])],1),_c('span',[_vm._v(" ")]),_c('valid-input',{staticStyle:{"marginRight":"20px"},attrs:{"inputType":'text',"inputValue":_vm.searchText,"placeholder":_vm.$t('검색어를 입력하세요.'),"rules":_vm.rules.ALARM_BASE_SEARCH_NAME_RULE,"NonStatus":true},on:{"update:inputValue":function($event){_vm.searchText=$event},"update:input-value":function($event){_vm.searchText=$event},"keydownMethod":function($event){return _vm.bindAlarm()}}}),_c('btn-search',{on:{"click":function($event){return _vm.bindAlarm()}}}),_c('btn-add',{on:{"click":function($event){return _vm.openAlarmModal()}}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('vue-good-table',_vm._b({attrs:{"columns":_vm.columns,"rows":_vm.alarmRows,"pagination-options":{
					enabled: true,
					nextLabel: _vm.$t('다음'),
					prevLabel: _vm.$t('이전'),
					ofLabel: '/',
					pageLabel: _vm.$t('페이지'), // for 'pages' mode
					rowsPerPageLabel: _vm.$t('표시 개수'),
				}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'ptAddr')?[_vm._v(" "+_vm._s(props.row.ptAddr)+" ")]:(props.column.field == 'alarmType')?[_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",modifiers:{"hover":true,"right":true}}],attrs:{"href":"javascript:;","title":_vm.$t('알람 상세')},on:{"click":function($event){return _vm.openAlarmModal(props.row)}}},[_vm._v(" "+_vm._s(_vm._f("codeAlarmTypes")(props.row.alarmType))+" ")])]:(props.column.field == 'isUse')?[_vm._v(" "+_vm._s(_vm._f("codeIsUse")(props.row.isUse))+" ")]:(props.column.field == 'isHold')?[_vm._v(" "+_vm._s(_vm._f("codeIsUse")(props.row.isHold))+" ")]:(props.column.field == 'description')?_c('span',{staticClass:"ellipsis-span"},[_vm._v(" "+_vm._s(props.row.description)+" ")]):(props.column.field == 'updDt')?[_vm._v(" "+_vm._s(props.row.updDt ? props.row.updDt.format("yyyy-MM-dd HH:mm") : "")+" ")]:[_vm._v(_vm._s(props.formattedRow[props.column.field]))]]}}])},'vue-good-table',_vm.GRID_OPT,false),[_c('div',{staticStyle:{"text-align":"center"},attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.$t('조회된 결과가 없습니다.'))+" ")])])],1)]),_c('b-modal',{ref:"pointModal",attrs:{"hide-footer":true,"title":_vm.$t('관제점 상세')}},[_c('point-detail',{attrs:{"ptAddr":_vm.paramPtAddr}})],1),_c('b-modal',{ref:"alarmModal",attrs:{"hide-footer":true,"title":_vm.paramAlarm.alarmGroup && _vm.paramAlarm.ptAddr ? _vm.$t('알람 상세') : _vm.$t('알람 추가')}},[_c('alarm-detail',{attrs:{"readonly":false,"alarm":_vm.paramAlarm},on:{"save-done":_vm.onAlarmSaveDone}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
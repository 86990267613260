<template>
	<vvo v-slot="v">
		<div class="row form-group">
			<div class="col-8">
				<label> <trans>계산식명</trans> </label>
				<valid-input
					:vid="'계산식명'"
					:inputType="'text'"
					:inputValue.sync="newUpl.name"
					:placeholder="$t('계산식명을 입력하세요.')"
					:rules="rules.POINT_FORMULA_NAME_RULE"
					:errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 30)"
				></valid-input>

				<!-- <vvp rules="required" v-slot="{ errors, classes }" name="Name">
					<input type="text" class="form-control" :class="classes" :placeholder="$t('계산식명을 입력하세요.')" v-model="newUpl.name" maxlength="50" />
					<span class="invalid-feedback">{{ errors[0] }}</span>
				</vvp> -->
			</div>
			<div class="col-4">
				<label> <trans>계산식유형</trans> </label>
				<select class="form-control" v-model="newUpl.uplType">
					<option value="Group"> {{ $t("그룹") }} </option>
					<option value="Point"> {{ $t("관제점") }} </option>
					<option value="Var"> {{ $t("변수") }} </option>
				</select>
			</div>
		</div>
		<div class="row form-group">
			<div class="col-12">
				<pt-select :pt="newUpl" />
			</div>
		</div>
		<div class="row form-group">
			<div class="col-12">
				<label> <trans>로직</trans> </label>
				<valid-textarea :inputType="'text'" :inputValue.sync="newUpl.logic" :rows="5" :NonStatus="true" :rules="rules.POINT_FORMULA_ADD_LOGIC_PLAN_CALC_RULE"> </valid-textarea>
				<!-- <textarea rows="5" class="form-control" v-model="newUpl.logic"></textarea> -->
			</div>
		</div>
		<div slot="modal-footer" class="w-100">
			<button class="btn btn-sm btn-primary m-r-5 pull-right" @click="onValidate(v, onSave)"><i class="fa fa-save"></i> <trans>저장</trans></button>
		</div>
	</vvo>
</template>

<script>
	import PtSelect from "@src/views/sys/data-management/point/components/PtSelect.vue";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";
  

	export default {
		props: ["save-done"],
		components: {
			PtSelect,
		},
		data() {
			return {
				newUpl: { uplType: "Point", children: [] },
				rules,
				validMessage,
				popupMessages,
			};
		},
		methods: {
			onSave() {
				let newUpl = Object.assign({}, this.newUpl);

				this.$emit("save-done", newUpl);
			},
		},
	};
</script>

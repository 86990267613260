<template>
	<div :key="locale" v-if="pageOptions.pageEmpty" v-bind:class="{ 'page-content-full-height': pageOptions.pageContentFullHeight }">
		<div class="content-full" style="" v-bind:class="{ 'content-full-width': pageOptions.pageContentFullWidth, 'content-inverse-mode': pageOptions.pageContentInverseMode }">
			<router-view></router-view>
		</div>
	</div>

	<div
		v-else
		:key="locale"
		class="fade page-sidebar-fixed page-header-fixed show page-container"
		v-bind:style="contentFontColor"
		v-bind:class="{
			'page-sidebar-minified': pageOptions.pageSidebarMinified,
			'page-content-full-height': pageOptions.pageContentFullHeight,
			'page-without-sidebar': pageOptions.pageWithoutSidebar,
			'page-with-right-sidebar': pageOptions.pageWithRightSidebar,
			'page-with-two-sidebar': pageOptions.pageWithTwoSidebar,
			'page-with-wide-sidebar': pageOptions.pageWithWideSidebar,
			'page-with-light-sidebar': pageOptions.pageWithLightSidebar,
			'page-with-top-menu': pageOptions.pageWithTopMenu,
			'page-sidebar-toggled': pageOptions.pageMobileSidebarToggled,
			'page-right-sidebar-toggled': pageOptions.pageMobileRightSidebarToggled || pageOptions.pageRightSidebarToggled,
			'page-right-sidebar-collapsed': pageOptions.pageRightSidebarCollapsed,
			'has-scroll': pageOptions.pageBodyScrollTop,
		}"
	>
		<Header />

		<TopMenu v-if="pageOptions.pageWithTopMenu" />
		<Sidebar v-if="!pageOptions.pageWithoutSidebar" />
		<SidebarRight v-if="pageOptions.pageWithTwoSidebar" />
		<ToolBar />
		<!--  :style="{ backgroundColor: this.themeColor.backgroundColor} " -->
		<div class="content" v-bind:style="[contentBgColor, pageOptions.pageMobileSidebarToggled ? { marginLeft: '220px' } : {}]">
			<router-view></router-view>
		</div>

		<notifications class="noti-right" group="noti-right" position="top right" :max="5" :speed="500" :duration="5000" />
		<notifications class="noti-bottom" group="noti-bottom" position="bottom right" :max="5" :speed="500" :duration="5000" />

		<Footer v-if="pageOptions.pageWithFooter" />

		<globalTimerLogout></globalTimerLogout>
	</div>
</template>

<style scoped>
	.content {
		padding: 15px 15px !important;
	}
</style>

<script>
	import Vue from "vue";
	//import PageOptions from "./config/PageOptions.vue";

	// Old Style
	// import Sidebar from "./components/sidebar/Sidebar.vue";
	// import SidebarRight from "./components/sidebar-right/SidebarRight.vue";
	// import Header from "./components/header/Header.vue";
	// import TopMenu from "./components/top-menu/TopMenu.vue";
	// import Footer from "./components/footer/Footer.vue";
	// import ToolBar from "./components/tool-bar/ToolBar.vue";
	// 원래 있던 progressBar 동작하지 않아서, axios-progress-bar를 추가함..
	// import { loadProgressBar } from "axios-progress-bar";
	// import "axios-progress-bar/dist/nprogress.css";

	// New Style
	import Sidebar from "@components/sidebar/Sidebar.vue";
	import SidebarRight from "@components/sidebar/Sidebar.vue";
	import Header from "@components/header/Header.vue";
	import TopMenu from "@components/top-menu/TopMenu.vue";
	import Footer from "@components/footer/Footer.vue";
	import ToolBar from "@components/tool-bar/ToolBar.vue";
	import { loadProgressBar } from "axios-progress-bar";
	import "axios-progress-bar/dist/nprogress.css";
	import globalTimerLogout from "@src/views/globalTimerLogout.vue";

	loadProgressBar();

	//#region // ANDROID -- ANDROID -- ANDROID -- ANDROID ----------------------------------------------------------------------/

	// TODO: 알람 상세 보기 페이지로 이동하기, 상세 페이지 작성 예정
	let _APP = null;
	function _goAndroidAlarm(alarmLogIdx) {
		if (_APP) {
			if (typeof alarmLogIdx != "undefined" && alarmLogIdx !== null) _APP.$router.push(`/alarm/alarmView/${alarmLogIdx}`);
		}
	}
	// android에서 호출
	// myWebView.loadUrl("javascript:goAndroidAlarm('"+ alarmIdx +"')");

	function _logoutByAndroid(loginUser, noRedirect) {
		if (_APP) {
			if (typeof loginUser != "undefined" && loginUser !== null)
				_APP.$store.dispatch("LOGOUT", loginUser).then(() => {
					console.log("안드로이드 웹뷰 로그아웃이 완료되었습니다.");

					if (noRedirect !== true)
						setTimeout(() => {
							_APP.$router.go("/");
						}, 100);
				});
		}
	}

	/// 안드로이드에서 자바스크립트 함수를 호출하기 위해 글로벌 오브젝트로 만듬
	/// https://javascript.info/global-object
	window.android = {
		goAndroidAlarm: _goAndroidAlarm,
		logoutByAndroid: _logoutByAndroid,
	};
	// function makeGlobalObjectForAndroid(app) {
	//   if (typeof Android !== 'undefined' && Android) {
	//     _APP = app;
	//
	//     window.android = {
	//       goAndroidAlarm: _goAndroidAlarm,
	//       logoutByAndroid: _logoutByAndroid
	//     };
	//   }
	// }
	//#endregion // ANDROID -- ANDROID -- ANDROID -- ANDROID ----------------------------------------------------------------------/

	export default {
		name: "app",
		components: {
			Sidebar,
			SidebarRight,
			Header,
			TopMenu,
			Footer,
			ToolBar,
			globalTimerLogout,
		},
		data() {
			return {
				// pageOptions: PageOptions,
				contentBgColor: {
					backgroundColor: "#d9e0e7",
					fontColor: "#333",
				},
				contentFontColor: {
					color: "#333",
				},
				mouseStopTimer: null,
			};
		},
		computed: {
			isAuthenticated() {
				return this.$store.getters.isAuthenticated;
			},
			loginUser() {
				return this.$store.getters.loginUser;
			},
			locale() {
				return this.$store.getters.locale;
			},
		},
		watch: {
			"$route.params": function() {
				this.isShowTab = true; // 메뉴를 눌러 location이 이동하면, 첫번째 tab 으로 이동시킨다.
			},
			$route: function() {
				this.onChangeMainPage();
			},
			// //"$store.getters.menus": function(){
			// "$store.getters.navs": function() {
			//   this.onChangeMainPage();
			// },
		},
		created() {
			this.pageOptions.pageBodyScrollTop = window.scrollY;

			this.$store.dispatch("UPDATE_TOKEN");
			this.$store.dispatch("UPDATE_MASTER");
			this.$store.dispatch("UPDATE_MASTER_GROUP");

			this.$store.dispatch("CREATE_WEB_SOCKET");

			this.$store.commit("RUN_AUTO_LOGOUT_TIMER");

			//if (this.isAuthenticated) this.$store.commit("UPDATE_TARGET_CLIENT", this.isMobile ? "Mobile" : "PC");
			//this.$store.dispatch("UPDATE_STRINGS"); // 리소스 문자열
			//this.$store.dispatch("UPDATE_TRANS"); // DB 다국어 문자열
			//if (this.isAuthenticated) this.$store.dispatch("UPDATE_MENUS");
			this.$store.dispatch("UPDATE_LOGIN_INFO");

			this.$store.dispatch("UPDATE_PT_MAP");

			// resize시에 RightSideBar가 나타나는 오동작 해결된듯...
			if (!this.checkMobile()) this.pageOptions.pageRightSidebarToggled = !this.pageOptions.pageRightSidebarToggled; // pc 모드 시작시 숨기기

			if (this.checkMobile()) this.pageOptions.pageMobileRightSidebarToggled = false;

			// 마지막 scroll 위치를 복원한다.
			window.addEventListener("scroll", this.handleScroll);
		},
		async mounted() {
			// console.log(" APP mounted APP mounted APP mounted APP mounted APP mounted APP mounted APP mounted ");
			_APP = this;
			// this.$insProgress.finish();

      /** localstorage 용량 체크 */
			var _lsTotal = 0,
				_xLen,
				_x;
			for (_x in localStorage) {
				if (!localStorage.hasOwnProperty(_x)) {
					continue;
				}
				_xLen = (localStorage[_x].length + _x.length) * 2;
				_lsTotal += _xLen;
				console.log(_x.substr(0, 50) + " = " + (_xLen / 1024).toFixed(2) + " KB");
			}
			console.log("Total = " + (_lsTotal / 1024).toFixed(2) + " KB");

			this.onChangeMainPage(this.$route);

			// 로그인과 회원가입 페이지가 아니면 로그인정보가 있을때만 접근할 수 있다.
			if (this.$route.path !== "/login" && this.$route.path !== "/register") {
				if (this.isEmpty(this.loginUser)) {
					// console.log("로그아웃하여 루트로 이동");
					this.$router.push("/login");
				}
			}

			// 메뉴가 사라지면 다시 바인딩...
			setTimeout(() => {
				if (Object.keys(this.readStorage("menus")).length == 0) {
					if (this.isAuthenticated) this.$store.dispatch("UPDATE_MENUS");
				}
			}, 3000);

			// this.$store.commit("SET_AUTO_LOGOUT_STATE", true);
			// this.$store.dispatch("UPDATE_AUTO_LOGOUT_INTERVAL", time);

			document.addEventListener("mousemove", () => {
				if (this.$store.getters.getAutoLogoutState === true) {
					// console.log("autologoutStop");
					this.$store.dispatch("UPDATE_AUTO_LOGOUT_STATE", false);
				}

				clearTimeout(this.mouseStopTimer);
				this.mouseStopTimer = setTimeout(this.mouseStopped, 1000);
			});

			document.addEventListener("keydown", () => {
				if (this.$store.getters.getAutoLogoutState === true) {
					// console.log("autologoutStop");
					this.$store.dispatch("UPDATE_AUTO_LOGOUT_STATE", false);
				}

				clearTimeout(this.mouseStopTimer);
				this.mouseStopTimer = setTimeout(this.mouseStopped, 1000);
			});

			// TODO: AccessToken이나 loginUser정보가 없어졌으면,  루트로 이동해야 함.
			// this.$router.go("/");
		},
		methods: {
			mouseStopped() {
				// console.log(window.location);
				let path = window.location.href.toLowerCase();

				if (!path.includes("dashboard") && !path.includes("login")) {
					this.$store.dispatch("UPDATE_AUTO_LOGOUT_STATE", true);
				}
			},
			handleScroll() {
				this.pageOptions.pageBodyScrollTop = window.scrollY;
			},
			onChangeMainPage() {
				// 페이지 변경시 메뉴명을 브라우저 제목으로 지정한다.
				//let menus = this.$store.getters.menus;
				let menus = this.readStorage("menus", []);
				if (!this.isEmpty(menus)) {
					let menu = menus.find((v) => v.path == this.$route.fullPath);

					// 메뉴가 없으면, 기본값으로 설정한다.
					if (this.isEmpty(menu)) {
						this.initDocuemntTitle();
						return;
					}

					// 메뉴가 있으면, 메인메뉴로 지정하고 브라우저 제목을 설정한다.
					this.$store.commit("UPDATE_MAIN_MENUS", menu);
					this.initDocuemntTitle(menu.title);
				}

				this.setThemeBackgroundColor();
			},
			initDocuemntTitle(title) {
				let brand = this.$store.getters.master.brand || "CxEMS v2.1"; // 아직 마스터정보가 없으면 기본값으로 대체한다.
				// let brand = 'CxEMS v2.1' || "CxEMS"; // 아직 마스터정보가 없으면 기본값으로 대체한다.

				let menuTitle = brand || "";
				if (!this.isEmpty(title)) menuTitle += "-" + this.$t(title);

				document.title = menuTitle;
				Vue.nextTick(() => {
					this.$forceUpdate();
				});
			},
			setThemeBackgroundColor() {
				this.contentBgColor.backgroundColor =
					window.location.hash == "#/visual/analysis/overview-dashboard"
						? global.xe.parseBool(localStorage.contentInverse)
							? require("./components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json").dark.backgroundColor
							: require("./components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json").light.backgroundColor
						: "#d9e0e7";
				this.contentFontColor.color =
					window.location.hash == "#/visual/analysis/overview-dashboard"
						? global.xe.parseBool(localStorage.contentInverse)
							? require("./components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json").dark.fontColor
							: require("./components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json").light.fontColor
						: "#333";
				//return this.isDashboard ? this.themeColor.backgroundColor : '#d9e0e7';
			},
		},
	};
</script>

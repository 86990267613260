<template>
  <div v-on:click="dismissSidebar">
    <!-- begin #sidebar -->
    <div id="sidebar" class="sidebar xe-sidebar-bgColor" v-bind:class="{ 'sidebar-transparent': pageOptions.pageSidebarTransparent }">
      <div class="height-full">
        <!-- begin sidebar user -->
        <sidebar-nav-profile></sidebar-nav-profile>
        <!-- end sidebar user -->

        <!-- begin sidebar nav -->
        <sidebar-nav ref="sidebarNav" v-bind:scrollTop="scrollTop" @showFloatSubmenu="handleShowFloatSubmenu" @hideFloatSubmenu="handleHideFloatSubmenu"></sidebar-nav>
        <!-- end sidebar nav -->
      </div>
      <!-- end sidebar scrollbar -->
    </div>
    <div class="sidebar-bg"></div>
    <!-- end #sidebar -->

    <div class="mobile-click" v-on:click="dismissSidebar"></div>

    <div
      class="float-sub-menu-container"
      @mouseover="clearHideFloatSubmenu"
      @mouseleave="handleHideFloatSubmenu"
      v-if="this.pageOptions.pageSidebarMinified"
      :class="{ 'd-none': !this.floatSubmenu }"
      :style="{ top: this.floatSubmenuTop, left: this.floatSubmenuLeft, bottom: this.floatSubmenuBottom, right: this.floatSubmenuRight }"
    >
      <div class="float-sub-menu-arrow" :style="{ top: this.floatSubmenuArrowTop, bottom: this.floatSubmenuArrowBottom }"></div>
      <div class="float-sub-menu-line" :style="{ top: this.floatSubmenuLineTop, bottom: this.floatSubmenuLineBottom }"></div>
      <ul class="float-sub-menu">
        <template v-for="menu in this.floatSubmenuMenu">
          <sidebar-nav-list ref="sidebarNavList" :menu="menu" :key="menu.path" :status="menu.status" @calcFloatSubmenu="handleCalcFloatSubmenu"></sidebar-nav-list>
        </template>
      </ul>
    </div>

    <b-modal ref="systemInfoModal" :hide-footer="true" :title="$t('시스템 정보')">
      <table class="table table-valign-middle">
        <thead>
          <tr>
            <th style="width:30%">{{ $t("속성") }}</th>
            <th style="width:70%">{{ $t("값") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(pack, i) in masterConfig.packages" :key="'pack' + i">
            <td>{{ pack.name }}</td>
            <td>{{ pack.version }}</td>
          </tr>
 
        </tbody>
      </table>
    </b-modal>
  </div>
</template>

<style lang="scss">
.sidebar{
  overflow-y: scroll;
}
</style>

<script>
import SidebarNav from "./SidebarNav.vue";
import SidebarNavProfile from "./SidebarNavProfile.vue";
import SidebarNavList from "./SidebarNavList.vue";
//import PageOptions from "../../config/PageOptions.vue";

export default {
  name: "Sidebar",
  components: {
    SidebarNav,
    SidebarNavProfile,
    SidebarNavList,
  },
  data() {
    return {
      scrollTop: "",
      //pageOptions: PageOptions,
      floatSubmenu: false,
      floatSubmenuMenu: "",
      floatSubmenuTop: "",
      floatSubmenuBottom: "",
      floatSubmenuLeft: "",
      floatSubmenuRight: "",
      floatSubmenuArrowTop: "",
      floatSubmenuArrowBottom: "",
      floatSubmenuLineTop: "",
      floatSubmenuLineBottom: "",
      clearSubmenu: "",
      subMenuOffset: "",
    };
  },
  computed: {
    masterConfig() {
      return this.readStorage("systemInfo");
    },
  },
  mounted() {},
  // slimscroll - 오동작으로 기능 제거..
  // http://voidcanvas.com/7-best-vue-js-scrollbar-plugins-with-demo/
  directives: {
    scroll: {
      inserted: function(el, binding) {
        let f = function(evt) {
          if (binding.value(evt, el)) {
            el.removeEventListener("scroll", f);
          }
        };
        el.addEventListener("scroll", f);

        if (typeof Storage !== "undefined") {
          if (localStorage.sidebarScroll) {
            el.scrollTop = localStorage.sidebarScroll;
          }
        }
      },
    },
  },
  methods: {
    onClickNavProfile() {
      this.$refs.systemInfoModal.show();
    },
    handleCalcFloatSubmenu: function() {
      setTimeout(() => {
        var targetTop = this.subMenuOffset.top;
        var windowHeight = window.innerHeight;
        var targetHeight = document.querySelector(".float-sub-menu-container").offsetHeight;

        if (windowHeight - targetTop > targetHeight) {
          this.floatSubmenuTop = this.subMenuOffset.top + "px";
          this.floatSubmenuBottom = "auto";
          this.floatSubmenuArrowTop = "20px";
          this.floatSubmenuArrowBottom = "auto";
          this.floatSubmenuLineTop = "20px";
          this.floatSubmenuLineBottom = "auto";
        } else {
          this.floatSubmenuTop = "auto";
          this.floatSubmenuBottom = "0";

          var arrowBottom = windowHeight - targetTop - 21;
          this.floatSubmenuArrowTop = "auto";
          this.floatSubmenuArrowBottom = arrowBottom + "px";
          this.floatSubmenuLineTop = "20px";
          this.floatSubmenuLineBottom = arrowBottom + "px";
        }
      }, 0);
    },
    handleShowFloatSubmenu: function(data, offset) {
      this.floatSubmenuMenu = data;
      this.floatSubmenu = true;
      this.subMenuOffset = offset;

      var targetTop = offset.top;
      var windowHeight = window.innerHeight;

      setTimeout(() => {
        var targetHeight = document.querySelector(".float-sub-menu-container").offsetHeight;

        if (windowHeight - targetTop > targetHeight) {
          this.floatSubmenuTop = offset.top + "px";
          this.floatSubmenuBottom = "auto";
          this.floatSubmenuArrowTop = "20px";
          this.floatSubmenuArrowBottom = "auto";
          this.floatSubmenuLineTop = "20px";
          this.floatSubmenuLineBottom = "auto";
        } else {
          this.floatSubmenuTop = "auto";
          this.floatSubmenuBottom = "0";

          var arrowBottom = windowHeight - targetTop - 21;
          this.floatSubmenuArrowTop = "auto";
          this.floatSubmenuArrowBottom = arrowBottom + "px";
          this.floatSubmenuLineTop = "20px";
          this.floatSubmenuLineBottom = arrowBottom + "px";
        }
      }, 0);

      this.floatSubmenuRight = "auto";
      this.floatSubmenuLeft = document.getElementById("sidebar").offsetLeft + offset.width + "px";

      clearTimeout(this.clearSubmenu);
    },
    handleHideFloatSubmenu: function() {
      this.clearSubmenu = setTimeout(() => {
        this.floatSubmenu = false;
      }, 250);
    },
    clearHideFloatSubmenu: function() {
      clearTimeout(this.clearSubmenu);
    },
    handleScroll: function(evt) {
      this.scrollTop = evt.target.scrollTop;
      if (typeof Storage !== "undefined") {
        localStorage.setItem("sidebarScroll", this.scrollTop);
      }
    },
    dismissSidebar: function() {
      this.pageOptions.pageMobileSidebarToggled = false;
      this.pageOptions.pageMobileRightSidebarToggled = false;
    },
  },
};
</script>

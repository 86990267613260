let instanceCnt = 0; // wsc 인스턴스 갯수
let startCnt = 0; // 시작함수 호출수 - 디버깅용..
let reconnectIntervalSec = 30; // keepAlive 접속유지옵션이 true일때, 재접속시도 시간간격

let Wsc = function(wssUrl, options) {
  let that = this;

  ++instanceCnt;

  if (!("WebSocket" in window)) throw "WebSocket NOT supported by your Browser!";

  if (!wssUrl) throw "WebSocket server url is empty!";

  that.client = null;
  that.isConnected = false;
  that.options = Object.assign({ keepAlive: true }, options);
  that.disposed = false;

  // that.options
  let cb_open    = that.options.open;
  let cb_receive = that.options.receive;
  let cb_close   = that.options.close;

  let to_interval = null;

  that.start = () => {
    if (that.client !== null) return that;

    console.log("WebSocket start NO=", instanceCnt, ++startCnt, wssUrl);

    that.client = new WebSocket(wssUrl);
    that.client.onopen = function() {
      that.isConnected = true;
      //console.log("WebSocket opened..");

      if (cb_open) cb_open(that);
    };
    that.client.onclose = function() {
      that.isConnected = false;
      console.log("WebSocket closed...");

      if (cb_close) cb_close(that.client);

      that.client = null;

      if (that.options.keepAlive) {
        if (to_interval) clearInterval(to_interval);

        to_interval = setInterval(function() {
          if (!that.disposed && !that.isConnected) {
            console.log(`WebSocket try reconnect... ${reconnectIntervalSec}`);
            that.start();
          }
        }, reconnectIntervalSec * 1000);
      }
    };

    that.client.onmessage = function(evt) {
      try{
      // console.log("WebSocket receivedMsg"); // , evt.data);
      if (that.disposed) return;
      if (cb_receive) cb_receive(evt);
      }catch(err){
        console.error("WebSocket onmessage error", err);
      }
    };

    that.client.onerror = function(evt) {
      console.error("WebSocket error", evt);
    };

    return that;
  };

  that.send = msg => {
    if (that.client) that.client.send(typeof msg == "object" ? JSON.stringify(msg) : msg);
  };

  that.destroy = () => {
    console.log("WebSocket destroy");

    that.disposed = true;
    that.client.close();
    that.client = null;
  };
};

export default Wsc;

<template>
	<div class="m-r-15">
		<div class="d-flex flex-row">
			<div class="col-lg-10 p-l-0 p-r-0">
				<div class="pull-left">
					<div class="h3">
						{{$t('시스템 관리')}} > {{$t('알람 관리')}} > {{$t('알람 기준 설정')}}
					</div>
				</div>
			</div>
		</div>
		<hr style="margin: 10px 0px 10px 0px" />

		<div class="form-inline form-group">
			<ln-select :label="$t('그룹')" v-model="filterGroup" :items="alarmGroups" />
			<ln-select :label="$t('사용여부')" v-model="filterIsUse" :items="{ true: 'Y', false: 'N' }" />
			<ln-select :label="$t('일시중지')" v-model="filterIsHold" :items="{ true: 'Y', false: 'N' }" />
			<!-- <ln-textin :label="$t('검색어')" v-model="searchText" :placeholder="$t('검색어를 입력하세요.')" @keydown.enter="bindAlarm()" /> -->
			<label><trans>검색어</trans></label>
			<span>&nbsp;</span>
			<valid-input :inputType="'text'" :inputValue.sync="searchText" :placeholder="$t('검색어를 입력하세요.')"
				@keydownMethod="bindAlarm()" :rules="rules.ALARM_BASE_SEARCH_NAME_RULE" :NonStatus="true"
				style="marginRight: 20px"></valid-input>
			<btn-search @click="bindAlarm()" />
			<btn-add @click="openAlarmModal()" />
		</div>

		<div class="row">
			<div class="col-lg-12">
				<vue-good-table v-bind="GRID_OPT" :columns="columns" :rows="alarmRows" :pagination-options="{
					enabled: true,
					nextLabel: $t('다음'),
					prevLabel: $t('이전'),
					ofLabel: '/',
					pageLabel: $t('페이지'), // for 'pages' mode
					rowsPerPageLabel: $t('표시 개수'),
				}">
					<div slot="emptystate" style="text-align: center;">
						{{$t('조회된 결과가 없습니다.')}}
					</div>
					<template slot="table-row" slot-scope="props">
						<template v-if="props.column.field == 'ptAddr'">
							<!-- 관제점 상세 레이어 팝업 삭제 2022.10.26 권철규 
              <a href="javascript:;" @click="openPointModal(props.row)" v-b-tooltip.hover.right :title="$t('관제점 상세')">
                -->
							{{ props.row.ptAddr }}
						</template>
						<template v-else-if="props.column.field == 'alarmType'">
							<a href="javascript:;" @click="openAlarmModal(props.row)" v-b-tooltip.hover.right
								:title="$t('알람 상세')">
								{{ props.row.alarmType | codeAlarmTypes }}
							</a>
						</template>
						<template v-else-if="props.column.field == 'isUse'">
							{{ props.row.isUse | codeIsUse }}
						</template>
						<template v-else-if="props.column.field == 'isHold'">
							{{ props.row.isHold | codeIsUse }}
						</template>
						<span v-else-if="props.column.field == 'description'" class="ellipsis-span">
							{{ props.row.description }}
						</span>
						<template v-else-if="props.column.field == 'updDt'">
							{{ props.row.updDt ? props.row.updDt.format("yyyy-MM-dd HH:mm") : "" }}
						</template>
						<template v-else>{{ props.formattedRow[props.column.field] }}</template>
					</template>
				</vue-good-table>
			</div>
		</div>

		<b-modal ref="pointModal" :hide-footer="true" :title="$t('관제점 상세')">
			<point-detail :ptAddr="paramPtAddr" />
		</b-modal>

		<b-modal ref="alarmModal" :hide-footer="true"
			:title="paramAlarm.alarmGroup && paramAlarm.ptAddr ? $t('알람 상세') : $t('알람 추가')">
			<alarm-detail :readonly="false" :alarm="paramAlarm" @save-done="onAlarmSaveDone" />
		</b-modal>
	</div>
</template>

<script>
import backEndApi from "@api/backEndApi.js";
import AlarmDetail from "./components/AlarmDetail.vue";
import * as rules from "@src/consts/ruleConsts.js";
//import PointDetail from "../point/components/PointDetail.vue";

export default {
	components: {
		AlarmDetail,
		//PointDetail,
	},
	data() {
		return {
			alarmGroups: [],
			filterGroup: "",
			filterIsUse: "",
			filterIsHold: "",
			searchText: "",

			paramAlarm: {},
			paramPtAddr: "",

			alarmList: [],

			columns: [
				{
					label: this.$t("알람 그룹"),
					field: "alarmGroup",
					type: "text",
					width: "10%",
					tdClass: "text-center text-nowrap",
					thClass: "text-center text-nowrap ",
				},
				{
					label: this.$t("관제점 주소"),
					field: "ptAddr",
					type: "text",
					width: "10%",
					tdClass: "text-left text-nowrap",
					thClass: "text-center text-nowrap ",
				},
				{
					label: this.$t("알람 유형"),
					field: "alarmType",
					type: "text",
					width: "5%",
					tdClass: "text-left text-nowrap",
					thClass: "text-center text-nowrap",
				},
				{
					label: "LL",
					field: "ll",
					type: "number",
					width: "2%",
					tdClass: "text-center text-nowrap",
					thClass: "text-center text-nowrap ",
				},
				{
					label: "L",
					field: "l",
					type: "number",
					width: "2%",
					tdClass: "text-center text-nowrap",
					thClass: "text-center text-nowrap ",
				},
				{
					label: "H",
					field: "h",
					type: "number",
					width: "2%",
					tdClass: "text-center text-nowrap",
					thClass: "text-center text-nowrap ",
				},
				{
					label: "HH",
					field: "hh",
					type: "number",
					width: "2%",
					tdClass: "text-center text-nowrap",
					thClass: "text-center text-nowrap ",
				},
				{
					label: this.$t("사용여부"),
					field: "isUse",
					type: "boolean",
					width: "2%",
					tdClass: "text-center text-nowrap",
					thClass: "text-center text-nowrap ",
				},
				{
					label: this.$t("일시중지"),
					field: "isHold",
					type: "boolean",
					width: "3%",
					tdClass: "text-center text-nowrap text-ellipsis",
					thClass: "text-center text-nowrap ",
				},
				{
					label: this.$t("알람메세지"),
					field: "description",
					type: "text",
					width: "20%",
					tdClass: "text-left text-nowrap text-ellipsis",
					thClass: "text-center text-nowrap ",
				},
				{
					label: this.$t("수정일"),
					field: "updDt",
					type: "text",
					width: "8%",
					tdClass: "text-center text-nowrap",
					thClass: "text-center text-nowrap",
				},
			],
			rules,
		};
	},
	computed: {
		alarmRows() {
			let arr = this.alarmList;

			if (!this.isEmpty(this.filterGroup)) {
				arr = arr.filter((v) => v.alarmGroup == this.filterGroup);
			}

			if (!this.isEmpty(this.filterIsUse)) {
				let isUse = global.xe.parseBool(this.filterIsUse);
				arr = arr.filter((v) => v.isUse == isUse);
			}

			if (!this.isEmpty(this.filterIsHold)) {
				let isHold = global.xe.parseBool(this.filterIsHold);
				arr = arr.filter((v) => v.isHold == isHold);
			}

			if (!this.isEmpty(this.filterGroup)) {
				arr = arr.filter((v) => v.alarmGroup == this.filterGroup);
			}

			if (!this.isEmpty(this.searchText)) {
				let searchText = this.searchText.toUpperCase();
				arr = arr.filter((v) => v.ptAddr.toUpperCase().indexOf(searchText) > -1);
			}

			return arr;
		},
	},
	mounted() {
		this.initSetting();
	},
	methods: {
		initSetting() {
			this.bindAlarm();
		},
		bindAlarm() {
			this.alarmList.clear();
			this.alarmGroups.clear();

			backEndApi.alarm.searchAlarm().then(({ data }) => {
				if (this.$err(data)) return;

				this.alarmList.range(data);

				this.alarmGroups.range(data.map((v) => v.alarmGroup).unique());
			});
		},
		openPointModal(alarm) {
			this.paramPtAddr = alarm.ptAddr;
			this.$refs.pointModal.show();
		},
		openAlarmModal(alarm) {
			this.paramAlarm = alarm || {};
			this.$refs.alarmModal.show();
		},
		onAlarmSaveDone() {
			this.$refs.alarmModal.hide();
			this.bindAlarm();
		},
	},
};
</script>

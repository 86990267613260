<template>
	<b-table
		bordered
		head-variant="dark"
		no-border-collapse
		show-empty
		sticky-header
		:ref="'selectableTable'"
		:id="'pointMgmt-table'"
		:class="'text-center'"
		:items="items"
		:fields="fields"
		:empty-text="$t('조회된 결과가 없습니다.')"
		:select-mode="'single'"
		selectable
		:details-td-class="'rowDetailStyle'"
		@row-clicked="onRowDetail"
	>
		<template #row="rows">
			<div></div>
		</template>

		<template #empty="scope">
			<h4>{{ scope.emptyText }}</h4>
		</template>

		<template #cell(selected)="{ rowSelected }">
			<template v-if="rowSelected">
				<span>&check;</span>
				<span class="sr-only">Selected</span>
			</template>
			<template v-else>
				<span>&nbsp;</span>
				<span class="sr-only">Not selected</span>
			</template>
		</template>

		<template #cell(index)="data">
			{{ data.index + 1 }}
		</template>

		<template #cell(isVirtualName)="data">
			{{ data.value }}
			<!-- {{ data.value === "Nomal" ? "물리관제점" : "가상관제점" }} -->
		</template>

		<template #table-busy>
			<div class="text-center my-2">
				<b-spinner class="align-middle"> </b-spinner>
				<strong>{{$t('로딩 중')}}... </strong>
			</div>
		</template>

		<template #row-details="row" :class="'bg-white'" :style="{ cursor: 'default' }">
			<vvo v-slot="v">
				<b-card :style="{ width: '90%', margin: 'auto', cursor: 'default' }">
					<div class="text-left d-flex justify-content-between">
						<span class="h5 mb-0">{{$t('관제점 상세조회')}}</span>
						<b-button-close @click="clearSelected(row)"></b-button-close>
					</div>
					<hr />
					<div>
						<detail-btn @update="onValidate(v, onModify)" @delete="onRemove" @pointLog="pointLogModal(row.item.ptAddr)" />
					</div>
					<div v-if="row.item.isVirtual == 0 || row.item.isVirtual == false" class="text-left font-weight-bold p-5 d-flex" style="font-size: 12px;">
						<i class="material-icons mr-1">info</i><span class="h4 mb-0">{{$t('관제점 계측 구조')}}</span>
					</div>
					<b-card class="mb-3" v-if="row.item.isVirtual == 0 || row.item.isVirtual == false">
						<detail-card :data="ptDetail" />
					</b-card>
					<div class="text-left font-weight-bold p-5 d-flex" style="font-size: 12px;"><i class="material-icons mr-1">info</i><span class="h4 mb-0">{{$t('관제점 정보')}}</span></div>
					<b-card>
						<detail-box :data="ptDetail" :IsNEW="false" @reSearch="reSearch" />
					</b-card>
					<div class="text-left font-weight-bold p-5 d-flex mt-3" style="font-size: 12px;"><i class="material-icons mr-1">info</i><span class="h4 mb-0">{{$t('관제점 설정')}}</span></div>
					<b-card>
						<detail-setting :data="ptDetail" @reSearch="reSearch" />
					</b-card>
				</b-card>
			</vvo>
		</template>
	</b-table>
</template>

<style scoped>
	.b-table-sticky-header {
		max-height: 760px;
	}
</style>

<script>
	// import backEndApi from "../../../../api/backEndApi";
	import detailCard from "./GridCard.vue";
	import detailBox from "./GridDetail.vue";
	import detailSetting from "./GridDetailSetting.vue";
	import detailBtn from "./GridDetailBtn.vue";
	import XLSX from "xlsx";
	import backEndApi from "@src/api/backEndApi";
	import AppErrorHandler from "@src/appErrorHandler";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		components: { detailCard, detailBox, detailBtn, detailSetting },
		props: ["data"],
		data() {
			return {
				fields: null,
				items: null,
				emptyText: null,
				selectedRow: null,
				ptDetail: null,
				ptAllDetails: null,
				isBusy: false,
			};
		},
		watch: {
			data() {
				this.setItems();
			},
		},
		created() {
			this.setFields();
			this.setItems();
		},
		mounted() {},
		methods: {
			async onRowDetail(row) {
				const result = await backEndApi.pointInfo.getPointDetailNew(row.ptIdx);

				this.ptDetail = result.data;

				if (this.selectedRow !== null) {
					if (this.selectedRow.ptIdx === row.ptIdx) {
						this.selectedRow._showDetails = !this.selectedRow._showDetails;
						this.selectedRow = null;
						return;
					}

					this.selectedRow._showDetails = !this.selectedRow._showDetails;
				}

				// // 선택된 row open
				row._showDetails = !row._showDetails;

				// 선택된 row 저장
				this.selectedRow = row;
			},
			clearSelected(row) {
				this.onRowDetail(row.item);
				// row.rowSelected = false;
				// row.item._showDetails = false;
				// console.log( this.$refs.selectableTable);
				// this.$refs.selectableTable.clearSelection();
			},
			setFields() {
				this.fields = [
					{ key: "ptIdx", label: global.xe.$t("일련번호") },
					{ key: "isVirtualName", label: global.xe.$t("관제점 유형") },
					{ key: "ptAddr", label: global.xe.$t("관제점 주소"), tdClass: "text-left" },
					{ key: "ptName", label: global.xe.$t("관제점명"), tdClass: "text-left" },
					{ key: "ptGroupName", label: global.xe.$t("관제점 그룹") },
					{ key: "energySourceName", label: global.xe.$t("에너지원") },
					{ key: "zoneName", label: global.xe.$t("구역") },
					{ key: "itemUsageName", label: global.xe.$t("용도") },
					{ key: "enabled", label: global.xe.$t("사용여부") },

					// { key: "ptTypeName", label: "집계 유형" },
					// { key: "channelName", label: "계측기" },
					// { key: "channelName", label: "채널", tdClass: "text-left" },
				];
			},
			setItems() {
				// console.log(this.data);
				if (this.data) {
					this.items = this.data.map((data) => {
						return { ...data, _showDetails: false };
					});
				}
			},

			async onModify() {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
        ↓↓↓↓↓
      */
				const res = await this.alertQuestion(popupMessages.COMMON_UPDATE_CONFIRM_POPUP_MESSAGE);
				if (!res.value) return;

				try {
					await backEndApi.pointInfo.updatePointInfo(this.ptDetail);
					await this.alertNoti(popupMessages.POINT_UPDATE_SUCCESS_POPUP_MESSAGE);

					this.$emit("reSearch");
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}

				// this.$refs.detail.onModify();
			},
			async onRemove() {
				const res = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
				if (!res.value) return;

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
        ↓↓↓↓↓
      */
				try {
					await backEndApi.pointInfo.deletePointInfo(this.ptDetail.ptIdx);
					await this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
					this.$emit("reSearch");
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			},
			pointLogModal(ptAddr) {
				this.$emit("pointLog:ptAddr", ptAddr);
			},
			reSearch() {
				this.$emit("reSearch");
			},
			onExcelDownload() {
				const excelName = "관제점 내역";

				this.alertQuestion(popupMessages.COMMON_EXCEL_DOWNLOAD_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					var excelData = XLSX.utils.table_to_sheet(document.getElementById("pointMgmt-table")); // table id를 넣어주면된다
					// console.log(excelData);
					var workBook = XLSX.utils.book_new(); // 새 시트 생성

					XLSX.utils.book_append_sheet(workBook, excelData, excelName); // 시트 명명, 데이터 지정
					XLSX.writeFile(workBook, excelName + ".xlsx"); // 엑셀파일 만듬
				});
			},
		},
	};
</script>

<template>
	<vvo v-slot="{ passes }">
		<div class="row form-group">
			<div class="col-lg-12">
				<label v-b-tooltip.hover :title="'NO. ' + newPoint.ptIdx">
					<trans>관제점 주소</trans>
					<!-- <span class="badge m-l-10 bg-lime" v-if="newPoint.isVirtual"> Virtual </span>
          <span class="badge m-l-10 bg-orange" v-if="newPoint.ptType === 'SUM'"> Sum </span> -->
				</label>
				<!-- |not-start-number  숫자로 시작할 수 없습니다. -->
				<vvp rules="required|min:8|max:32|special" v-slot="{ errors, classes }" name="Point Address">
					<input type="text" :readonly="isUpdate" class="form-control" :class="classes" :placeholder="$t('관제점 주소를 입력하세요.')" v-model="newPoint.ptAddr" />
					<span class="invalid-feedback">{{ errors[0] }}</span>
				</vvp>
			</div>
		</div>
		<div class="row form-group">
			<div class="col-md-4">
				<label> <trans>관제점 유형</trans> </label>
				<select class="form-control" v-model="newPoint.isVirtual" :disabled="isUpdate">
					<option :value="true"> {{ $t("물리관제점") }} </option>
					<option :value="false"> {{ $t("가상관제점") }} </option>
				</select>
			</div>
			<div class="col-md-4">
				<label> <trans>취합 유형</trans> </label>
				<select class="form-control" v-model="newPoint.ptTypeCode" :disabled="isUpdate">
					<option value="Lst"> Last </option>
					<option value="Sum"> Sum </option>
					<option value="Max"> Max </option>
					<option value="Avg"> Avg </option>
				</select>
			</div>
			<div class="col-md-4">
				<label> <trans>값유형</trans> </label>
				<select class="form-control" v-model="newPoint.valueTypeCode">
					<option value="analog"> {{ $t("아날로그") }} </option>
					<option value="digital"> {{ $t("디지털") }} </option>
				</select>
			</div>
		</div>
		<div class="row form-group">
			<div class="col-lg-4">
				<label> <trans>관제점 그룹</trans> </label>
				<input type="text" class="form-control" :placeholder="$t('관제점 그룹을 입력하세요.')" v-model="newPoint.ptGroup" />
			</div>
			<div class="col-lg-8">
				<label> <trans>관제점명</trans> </label>
				<!--  TODO : 관제점명 체크 로직 정리 필요함. -->
				<!-- <vvp rules="required|min:2|max:100|special|not-start-number" v-slot="{ errors, classes }" name="Point Name"> -->
				<vvp rules="required|min:2|max:100" v-slot="{ errors, classes }" name="Point Name">
					<input type="text" v-focus class="form-control" :class="classes" :placeholder="$t('관제점명을 입력하세요.')" v-model="newPoint.ptName" />
					<span class="invalid-feedback">{{ errors[0] }}</span>
				</vvp>
			</div>
		</div>

		<div class="row form-group">
			<div class="col-md-6">
				<!-- TODO: 단위에 W 가 있는데    W (unknown)  으로 표시됨 -->
				<label><trans>단위</trans></label>
				<tree-select
					v-model="newPoint.unitLargeCode"
					:load-options="loadOptions"
					:placeholder="controlMessages.UNIT_TREE_PLACEHOLDER_MESSAGE"
					:noChildrenText="controlMessages.UNIT_TREE_NOCHILDERN_MESSAGE"
					:noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)"
					:multiple="false"
					:options="unitOptions"
					:disable-branch-nodes="true"
				>
				</tree-select>
			</div>
			<div class="col-md-6">
				<!-- TODO: 단위 삭제하고 저장하면, 삭제된 채로 저장되지 않음 -->
				<label><trans>원본단위</trans></label>
				<tree-select
					v-model="newPoint.unitSmallCode"
					:load-options="loadOptions"
					:placeholder="controlMessages.ORG_UNIT_TREE_PLACEHOLDER_MESSAGE"
					:noChildrenText="controlMessages.ORG_UNIT_TREE_NOCHILDERN_MESSAGE"
					:noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)"
					:multiple="false"
					:options="unitOptions"
					:disable-branch-nodes="true"
				></tree-select>
			</div>
		</div>
		<div class="row form-group">
			<div class="col-md-6">
				<label> <trans>최소값</trans> </label>
				<input type="number" class="form-control" :readonly="isUpdate" :placeholder="$t('최대값을 입력하세요.')" v-model="newPoint.filterMinValue" />
			</div>
			<div class="col-md-6">
				<label> <trans>최대값</trans> </label>
				<input type="number" class="form-control" :readonly="isUpdate" :placeholder="$t('최대값을 입력하세요.')" v-model="newPoint.filterMaxValue" />
			</div>
		</div>
		<div class="row form-group">
			<div class="col-md-4">
				<label> <trans>오버플로우</trans> </label>
				<input
					type="number"
					class="form-control"
					:readonly="isUpdate"
					:placeholder="$t('오버플로우값을 입력하세요.')"
					v-model="newPoint.fixValue"
					v-b-tooltip.hover
					:title="$t('Min, Max 범위를 벗어난 경우에 사용할 값을 지정합니다. 빈값도 가능합니다.')"
				/>
			</div>
			<div class="col-lg-4">
				<div class="checkbox checkbox-css m-t-25" v-b-tooltip.hover :title="$t('데이터 입력시 이전값과 비교하여 변경된 경우에만 로그를 기록합니다.')">
					<input type="checkbox" :id="'point_checkbox_is_cov_info_' + newPoint.ptAddr" v-model="newPoint.isCov" :readonly="isUpdate" />
					<label :for="'point_checkbox_is_cov_info_' + newPoint.ptAddr"><trans>변경확인</trans></label>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="checkbox checkbox-css m-t-25" v-b-tooltip.hover :title="$t('데이터 입력시 0값이 입력되면 처리하지 않고, 무시합니다.')">
					<input type="checkbox" :id="'point_checkbox_is_ignore_zero_' + newPoint.ptAddr" v-model="newPoint.isIgnoreZero" :readonly="isUpdate" />
					<label :for="'point_checkbox_is_ignore_zero_' + newPoint.ptAddr"><trans>0값무시</trans></label>
				</div>
			</div>
		</div>

		<div v-if="isUpdate" class="row form-group">
			<div class="col-lg-6">
				<label><trans>등록일</trans></label>
				<span class="form-control" :readonly="isUpdate">{{ isEmpty(newPoint.regDate) ? "" : newPoint.regDate.format("yyyy-MM-dd HH:mm") }}</span>
			</div>
			<div class="col-lg-6">
				<label><trans>최종수정일</trans></label>
				<span class="form-control" :readonly="isUpdate">{{ isEmpty(newPoint.modDate) ? "" : newPoint.modDate.format("yyyy-MM-dd HH:mm") }}</span>
			</div>
		</div>

		<div slot="modal-footer" v-if="!readonly">
			<button v-xe-pm.E v-if="ptAddr" class="btn btn-danger " v-on:click="deletePoint"><i class="fa fa-times"></i> <trans>삭제</trans></button>
			<button class="btn btn-primary pull-right " v-on:click="passes(onSavePoint)"><i class="fa fa-save"></i> <trans>저장</trans></button>
		</div>
	</vvo>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";
	import xelib from "xelib";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import * as controlMessages from "@src/consts/controlMessageConsts";

	const { Unit } = xelib.PointBox;

	// TODO: Unit 라이브러리가 실제 변환할 수 있는 것들에 대한 화면제어가 필요함.

	// Unit 라이브러리에서 type을 키로 잡아서 tree-select option을 생성함.
	const unitOptions = (function() {
		return Object.keys(Unit.types).map((type, i) => {
			let children = Object.keys(Unit.types[type]).map((v) => {
				// return { id: i}-${j, label: v };
				return { id: v, label: v };
			});

			return { id: i, label: type, children: children };
		});
	})();

	// TODO: Unit.conversion    unit과 rawUnit이 동시에 사용된 경우, conversion이 지원되는지 검사할 수 있어야 함.

	// TODO: 관제점 테이블에 입출력 direction이 없음.

	export default {
		// props: ["ptAddr", "readonly", "save-done"],
		props: {
			ptAddr: { type: String, required: true },
			readonly: { type: Boolean, required: false, default: true },
			"save-done": { type: Function, required: false },
			ptIdx: { type: Number, require: true },
		},
		data() {
			return {
				// unitTypes: [],
				unitOptions: unitOptions,
				newPoint: {},
				controlMessages,
			};
		},
		computed: {
			hasButtonSlot() {
				return !!this.$slots.button;
			},
			isUpdate() {
				return !this.isEmpty(this.ptAddr);
			},
		},
		watch: {
			ptAddr: function() {
				this.initSetting();
			},
		},
		mounted() {
			this.initSetting();
		},
		methods: {
			initSetting() {
				this.$store.dispatch("UPDATE_PT_MAP");

				this.newPoint = { ptGroup: "", max: 999999999999.99, min: -999999999999.99, isVirtual: false, ptType: "LST", valueType: "analog", overflow: 0, isCov: true };

				if (this.isUpdate) {
					// backEndApi.pointInfo.getPoint(this.ptAddr).then(({ data }) => {
					//   if (this.$err(data)) {2
					//     this.alertNoti("데이터가 존재하지 않습니다.");
					//     return;
					//   }

					//   this.newPoint = data;

					//   // undefined 상태일때, tree-select에서 unknown으로 표시되는 현상 방지...
					//   if(this.isEmpty(this.newPoint.unit))    this.newPoint.unit = null;
					//   if(this.isEmpty(this.newPoint.rawUnit)) this.newPoint.rawUnit = null;

					// });

					// modified by bwh202
					//
					// if (!this.isEmpty(this.$props.ptIdx)) {
					// 	backEndApi.pointInfo.getPointDetailNew(this.$props.ptIdx).then(({ data }) => {
					// 		console.log(data);
					//     if (this.$err(data)) {
					// 			this.alertNoti(popupMessages.COMMON_SEARCH_NO_DATA_POPUP_MESSAGE);
					// 			return;
					// 		}

					// 		this.newPoint = data;

					// 		// undefined 상태일때, tree-select에서 unknown으로 표시되는 현상 방지...
					// 		if (this.isEmpty(this.newPoint.unit)) this.newPoint.unit = null;
					// 		if (this.isEmpty(this.newPoint.rawUnit)) this.newPoint.rawUnit = null;
					// 	});
					// } else {
				
					// }

          backEndApi.pointInfo.getPoint(this.$props.ptAddr).then(({ data }) => {
						if (this.$err(data)) {
							this.alertNoti(popupMessages.COMMON_SEARCH_NO_DATA_POPUP_MESSAGE);
							return;
						}

						this.newPoint = data;

						// undefined 상태일때, tree-select에서 unknown으로 표시되는 현상 방지...
						if (this.isEmpty(this.newPoint.unit)) this.newPoint.unit = null;
						if (this.isEmpty(this.newPoint.rawUnit)) this.newPoint.rawUnit = null;
					});
				}
			},
			onChangeUnit(value) {
				this.newPoint.unit = value.unit;
			},
			onChangeRawUnit(value) {
				this.newPoint.rawUnit = value.rawUnit;
			},
			onSavePoint() {
				this.isUpdate ? this.updatePoint() : this.insertPoint();
			},
			insertPoint() {
				// TODO: DUP 체크 ptList 업데이트되지 않으면, 중복체크 되지 않을 수 있다.
				let liveAM = this.readStorage("liveAM");
				if (liveAM[this.newPoint.ptAddr]) {
					this.alertNoti(popupMessages.POINT_DATA_DUP_POPUP_MESSAGE);
					return;
				}

				this.newPoint.isCov = this.parseBool(this.newPoint.isCov);
				this.newPoint.overflow = this.isEmpty(this.newPoint.overflow) ? null : this.newPoint.overflow;

				this.alertQuestion(popupMessages.COMMON_SAVE_CONFIRM_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.pointInfo.insertPoint(this.newPoint).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
						this.$emit("save-done");
					});
				});
			},
			updatePoint() {
				this.alertQuestion(popupMessages.COMMON_UPDATE_CONFIRM_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.pointInfo.updatePoint(this.newPoint).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.COMMON_UPDATE_POPUP_MESSAGE);
						this.$emit("save-done");
					});
				});
			},
			deletePoint() {
				this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.pointInfo.deletePoint(this.newPoint.ptAddr).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.SYSTEM_EVENT_LOG_SCHEDULE_POPUP_MESSAGE);
						this.$emit("save-done");
					});
				});
			},
			loadOptions({ callback }) {
				callback();
			},
		},
	};
</script>

import CheckBox3Component from "./CheckBox3.vue";

const CheckBox3 = {
  install(Vue) {
    //Vue.component(CheckBox3Component.name, CheckBox3Component);
    Vue.component(CheckBox3Component.name, () => import("./CheckBox3.vue"));
  },
};

export default CheckBox3;

import ActionTextComponent from "./ActionText.vue";

const ActionText = {
  install(Vue) {
    //Vue.component(ActionTextComponent.name, ActionTextComponent);
    Vue.component(ActionTextComponent.name, () => import("./ActionText.vue"));
  },
};

export default ActionText;

// import dotenv from 'dotenv'
// dotenv.config();
import 'dotenv/config'
import Vue from 'vue'
import VueX from 'vuex'

// plugins
import VueResource from 'vue-resource'
import VueRouter from 'vue-router'
import VueBootstrap from 'bootstrap-vue'
import { BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

import VueInsProgressBar from 'vue-ins-progress-bar'
import VueEventCalendar from 'vue-event-calendar'
import VueSparkline from 'vue-sparklines'
import VueSweetalert2 from 'vue-sweetalert2'
import VueNotification from 'vue-notification'
// vue-bootstrap-datetimepicker
// https://www.npmjs.com/package/vue-bootstrap-datetimepicker
// vuejs-datepicker
// https://www.npmjs.com/package/vuejs-datepicker
import VueDateTimePicker from 'vue-bootstrap-datetimepicker'
//import VueDatepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js"
//import VueSlider from 'vue-slider-component'
import VueGoodTable from 'vue-good-table';

import ElementUI from 'element-ui';
import ElementTable from 'element-ui-table';

import VueFullCalendar from 'vue-full-calendar'
//import VueCustomScrollbar from 'vue-custom-scrollbar'; //4.5??
import VueSlimScroll from 'vue-slimscroll'
//import LiquorTree from 'liquor-tree';
//import TreeSelect from "@riophae/vue-treeselect";
import WCharts from 'vue-wcharts';

// custom
import GlobalPlugin   from './plugins/GlobalPlugin.js'
import VueActionIcon  from './plugins/actionIcon/'
import VueActionText  from './plugins/actionText/'
import VueCheckBox3   from './plugins/checkBox3/'
import VueColorPicker from './plugins/colorPicker/'
import VueIconSelect  from './plugins/iconSelect/'
import VuePanel       from './plugins/panel/'
import VueTitleIcon   from './plugins/titleIcon/'
import VueScrollTo from "vue-scrollto";

// plugins css
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-event-calendar/dist/style.css'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import 'simple-line-icons/css/simple-line-icons.css'
import 'flag-icon-css/css/flag-icon.min.css'
import 'ionicons/dist/css/ionicons.min.css'
import 'vue-good-table/dist/vue-good-table.css'
import 'fullcalendar/dist/fullcalendar.css'
import 'vue-slider-component/theme/antd.css'; // slider progress bar control (project)
import 'vue-slimgrid/dist/slimgrid.css'; // 목표, 요금 관리 화면에서 사용
import "bootstrap-social/bootstrap-social.css"; // SNS 버튼
import "@riophae/vue-treeselect/dist/vue-treeselect.css"

import "./components/vue-chartjs/Phase1/TooltipVerticalLine.js";


// sweetalert2 css 추가 (noti 관련)
import 'sweetalert2/dist/sweetalert2.min.css';
// v4.5 ////////////////////////////////////////////////////////////////////////////////////////////
// color admin css - default, facebook, material, transparent, apple
import PageOptions from "./config/PageOptions.vue";

let theme = localStorage.theme || "facebook";
let labelTheme = localStorage.labelTheme || "blue";
let headerInverse = localStorage.headerInverse || PageOptions.pageHeaderInverseMode;
let contentInverse = localStorage.contentInverse || PageOptions.pageContentInverseMode;

PageOptions.pageHeaderInverseMode = global.xe.parseBool(headerInverse);
PageOptions.pageContentInverseMode = global.xe.parseBool(contentInverse);

//      if(theme == "default")     PageOptions.pageHeaderInverseMode = true;
// else if(theme == "facebook")    PageOptions.pageHeaderInverseMode = true;
// else if(theme == "material")    PageOptions.pageHeaderInverseMode = true;
// else if(theme == "transparent") PageOptions.pageHeaderInverseMode = false;
// else if(theme == "apple")       PageOptions.pageHeaderInverseMode = true;
// else                            PageOptions.pageHeaderInverseMode = false;

// xems ////////////////////////////////////////////////////////////////////////////////////// 
// eslint-disable-next-line
import xelib from 'xelib'
import * as multiLang from '@libs/multiLang.js'
import * as multiScreen from '@libs/multiScreen.js'
////////////////////////////////////////////////////////////////////////////////////////////////////

multiLang.init();
multiScreen.init();

Vue.config.productionTip = false

Vue.use(VueX)
Vue.use(VueRouter)
Vue.use(VueResource)
Vue.use(VueBootstrap)
Vue.use(BootstrapVueIcons)
Vue.use(VueEventCalendar, {locale: 'en'})
Vue.use(VueSparkline)
Vue.use(VueSweetalert2)
Vue.use(VueNotification)
Vue.use(ElementUI)
Vue.use(ElementTable)
Vue.use(VueScrollTo)

// vue-bootstrap-datetimepicker
// https://www.npmjs.com/package/vue-bootstrap-datetimepicker
// https://jsfiddle.net/ankurk91/01407frf/
//import 'bootstrap/dist/css/bootstrap.css';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
Vue.use(VueDateTimePicker)
import $   from 'jquery';
$.extend(true, $.fn.datetimepicker.defaults, {
  sideBySide:true, // 시간이 옆에 표시됨
  keyBinds:null,   // 키보드이동을 막음
  locale: "en",    // 다국어
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle'
  }
});

Vue.use(VueGoodTable)
Vue.use(VueFullCalendar)
Vue.use(VueInsProgressBar, {
  position: 'fixed',
  show: true,
  height: '5px'
})


// Global registration
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)

import Resource from "./plugins/Resource.js";
Vue.use(WCharts, {
  colors: Resource.colorSet,
})
// 

// Vue.component("datepicker", VueDatepicker)
// Vue.component("vue-datepicker", VueDatepicker)
// Vue.component("vue-slider", VueSlider)
// Vue.component("vue-custom-scrollbar", VueCustomScrollbar)
// Vue.component("tree-select", TreeSelect)
// Vue.component("tree", LiquorTree)
// Vue.component("liquor-tree", LiquorTree)

// TODO: Vue.component Lazy Load - 해결안됨...
// [Vue warn]: Failed to mount component: template or render function not defined.
// https://stackoverflow.com/questions/41983767/vue-template-or-render-function-not-defined-yet-i-am-using-neither
Vue.component("datepicker", () => import("vuejs-datepicker/dist/vuejs-datepicker.esm.js"))
Vue.component("vue-datepicker", () => import("vuejs-datepicker/dist/vuejs-datepicker.esm.js"))
Vue.component("vue-slider", () => import("vue-slider-component"))
Vue.component("vue-custom-scrollbar", () => import("vue-custom-scrollbar"))
Vue.component("tree-select", () => import("@riophae/vue-treeselect"), {
  props: {noChildrenText: {type: String, default: "하위 단계가 존재하지 않습니다."}}
})
Vue.component("tree", () => import("liquor-tree"))
Vue.component("liquor-tree", () => import("liquor-tree"))

import ECharts from 'vue-echarts' // refers to components/ECharts.vue in webpack
// import ECharts modules manually to reduce bundle size
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/polar'
import 'echarts/lib/component/tooltip'
// ECharts extensions
// import 'echarts-gl'
// register component to use
Vue.component('v-chart', ECharts)


import axios from 'axios'
Vue.prototype.axios = axios;
// (function(){ // enhanceAccessToeken
//   const { accessToken } = localStorage;
//   if (!accessToken) return;
//   axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
//   // UserId : HMAC필요
// })();

// xems - components
import KnobControl from 'vue-knob-control'
import { ValidationProvider, extend, configure, ValidationObserver } from "vee-validate";
import * as rules from 'vee-validate/dist/rules';

// loop over all rules
for (let rule in rules) {
  // add the rule
  extend(rule, rules[rule]);
}
// Add a rule.
// extend('secret', {
//   validate: value => value === 'example',
//   message: 'This is not the magic word'
// });
// extend('password', {
//   getMessage: field => `${field} should include lower-case, numeric digit, special chracter($@$!%*#?&^).`,
//   validate: value => {
//     return /^.*(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[$@$!%*#?&^]).*$/.test(value)
//   }
// });

configure({
  classes: {
    // valid: 'is-valid', // one class
    invalid: 'is-invalid'
  }
});


import Validator from './plugins/Validator.js';
Validator.configure();
import LayoutComponents from './plugins/LayoutComponents.js';
LayoutComponents.use();



Vue.use(VueSlimScroll)
Vue.use(KnobControl)
Vue.component('vvp', ValidationProvider)
Vue.component('vvo', ValidationObserver)
//Vue.component('knob-control', KnobControl)
//Vue.component('vvp', () => import("vee-validate").ValidationProvider )
//Vue.component('vvo', () => import("vee-validate").ValidationObserver)
Vue.component('knob-control', () => import("vue-knob-control"))



// import Pagination from "./plugins/pagination/Pagination.vue";
// import PointSelect from "./plugins/pointSelect/PointSelect.vue";
// import Trans from "./Trans.vue";
// Vue.component('xe-pagination', Pagination)
// Vue.component("point-select", PointSelect)
// Vue.component('trans', Trans)
Vue.component('xe-pagination', () => import("./plugins/pagination/Pagination.vue"))
Vue.component("point-select", () => import("./plugins/pointSelect/PointSelect.vue"))
Vue.component('trans', () => import("./Trans.vue"))
Vue.component("valid-input", () => import('@src/components/validate-input/Valid-Input.vue'))
Vue.component("valid-textarea", () => import('@src/components/validate-input/Valid-textarea.vue'))
Vue.component("valid-select", () => import('@src/components/validate-input/Valid-Select.vue'))

// custom
Vue.use(GlobalPlugin)
Vue.use(VueActionIcon)
Vue.use(VueActionText)
Vue.use(VueCheckBox3)
Vue.use(VueColorPicker)
Vue.use(VueIconSelect)
Vue.use(VuePanel)
Vue.use(VueTitleIcon)

import App from "./App.vue";
import routes from "./config/PageRoutes.vue";
import store from "./config/PageStore.vue";

// Programmatic Navigation : https://router.vuejs.org/guide/essentials/navigation.html
// 기본값은 hash임.  "type1?view_code=test1" 일때  query 파라미터 식별이 되지 않아서, Navigation 할 수 없어서, history 모드로 변경함.. 
// history 모드는 브라우저 <-  ->  이전, 다음 페이지 가기가 가능해 지지만, 해당 페이지를 바로가기 링크를 걸 수 없음.. 이때는 서버에서 처리해 주어야 함..
// HTML5 히스토리 모드 : https://router.vuejs.org/kr/guide/essentials/history-mode.html#%EC%84%9C%EB%B2%84-%EC%84%A4%EC%A0%95-%EC%98%88%EC%A0%9C
const router = new VueRouter({
  mode: 'hash', // history, hash, abstract
	routes
})

// import VueI18n from 'vue-i18n'
// Vue.use(VueI18n)

// const i18n = new VueI18n({
//   locale: 'kr', // set locale
//   messages, // set locale messages
// })


Array.prototype.range = function(arr){
  this.clear();

  if(arr == null || arr == undefined) return;
  
  arr.forEach(v => {
    this.push(v);
  });
}


import(`./assets/css/${theme}/app.min.css`)
.then(() => import (`./assets/css/${theme}/theme/${labelTheme}.min.css`))
.then(() => import ('./scss/vue.scss'))
  //.then(() => global.xe.parseBool(localStorage.contentInverse) ? import ('./scss/kbet-custom.scss') : import ('./scss/kbet-custom-white.scss'))
  //.then(() => import('./scss/kbet-custom.scss'))
.then(() => import ('./scss/kbet-custom-white.scss'))
.then(() => import ('./scss/compareCardStyle.scss'))
.then(() => import ('./assets/css/c-alarm.css'))
.then(() => import ('./assets/css/c-introjs.css'))
.then(() => import ('./assets/css/c-realtime.css'))
.then(() => import ('./assets/css/c-weather.css'))
.then(() => import ('./assets/css/c-common.css'))
.then(() => import(`./assets/css/v-${theme}.css`))
.then(() => {
  new Vue({
    render: h => h(App),
    router,
    store,
  }).$mount('#app')

});



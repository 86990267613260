import Chart from "chart.js";

const originalBarDraw = Chart.controllers.bar.prototype.draw;
  Chart.helpers.extend(Chart.controllers.bar.prototype, {
    draw: function() {
      originalBarDraw.apply(this, arguments);

      if(this.chart.config.type == "bar"){
        if(this.chart.tooltip._active && this.chart.tooltip._active.length){
          const activePoint = this.chart.tooltip._active[0];
            const ctx = this.chart.ctx;
            // const x_axis = chart.scales['x-axis-0'];
            var y_axis = this.chart.scales['y-axis-0'];
    
            if(!y_axis){
              y_axis = this.chart.scales['Left_yAxis'];
            }
    
            const x = activePoint.tooltipPosition().x;
            const topY = y_axis.top
            const bottomY = y_axis.bottom;
    
            // draw line
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY+7);
            ctx.lineTo(x, bottomY+1);
            ctx.setLineDash([2,3]);
            ctx.lineWidth = 1;
            ctx.strokeStyle = '#ff7e01';
            ctx.stroke();
            ctx.restore();
          }
      }
    },
  });

const originalLineDraw = Chart.controllers.line.prototype.draw;
  Chart.helpers.extend(Chart.controllers.line.prototype, {
    draw: function() {
      originalLineDraw.apply(this, arguments);



      if(this.chart.tooltip._active && this.chart.tooltip._active.length){
      const activePoint = this.chart.tooltip._active[0];
        const ctx = this.chart.ctx;
        // const x_axis = chart.scales['x-axis-0'];
        var y_axis = this.chart.scales['y-axis-0'];

        if(!y_axis){
          y_axis = this.chart.scales['Left_yAxis'];
        }

        const x = activePoint.tooltipPosition().x;
        const topY = y_axis.top
        const bottomY = y_axis.bottom;

        // draw line
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY+7);
        ctx.lineTo(x, bottomY+1);
        ctx.setLineDash([2,3]);
        ctx.lineWidth = 2;
        ctx.strokeStyle = '#ff7e01';
        ctx.stroke();
        ctx.restore();
      }
      
    },
  });
const { isEmpty } = global.xe;

const ErrorKind = Object.freeze({
  API: "API",
  BIZ: "BIZ",  
});

class AppErrorHandler {
  
  #isOther = false;
  #otherMessage = null;
  #otherAlert = null;
  #isPrintErrorLog = false;
  #lastApiHandle = null;
  #errorHandles = [];

  constructor(err) {
    this.error = err;
  }  

    /**
   * ApiError Handle를 생성한다.
   * @param {string} bizErrNo Biz 에러 번호
   * @param {*} alertMsg Alert 메시지
   * @param {*} alert Alert창
   * @returns 
   */
  createApiHandle(statusCode, alertMsg, alert = null) {    

    const errHandle = new ErrorHandle(ErrorKind.API, statusCode, alertMsg, alert);
    this.#errorHandles.push(errHandle);
    this.#lastApiHandle = errHandle;
    return this;
  }

  /**
   * BizError Handle를 생성한다.
   * @param {string} bizErrNo Biz 에러 번호
   * @param {*} alertMsg Alert 메시지
   * @param {*} alert Alert창
   * @returns 
   */
  createBizHandle(bizErrNo, alertMsg, alert = null) {

    const errHandle = new ErrorHandle(ErrorKind.BIZ, bizErrNo, alertMsg, alert);

    if(this.#lastApiHandle !== null)    
      this.#lastApiHandle.addChildHandle(errHandle);      
    else
      this.#errorHandles.push(errHandle);

    return this;
  }

  /**
   * 그외 메시지 설정 (에러 핸들에서)
   * @param {string} msg 
   */
  setOtherMessage(msg, alert = null) {
    this.#isOther = true;
    this.#otherMessage = msg;
    this.#otherAlert = alert;
    return this;
  } 

  printErrorLog() {
    this.#isPrintErrorLog = true;
    return this;
  }

  /**
   * Error 핸들링을 수행한다.
   * @param {bool} isThrow 에러를 발생 시킬지 여부
   * @returns 
   */
  errHandling(isThrow = false) {

    let isMatch = false;

    for (const errHandle of this.#errorHandles) {

      //ErrorHandle(ApiError or BizError) 처리
      const errCode = errHandle.errKind === ErrorKind.API ? this.error.response.status : this.error.response.data.errno;

      //HTTP STATUS 오류의 alert이 없으면 아래 로직 처리 안함 (메시지는 OtherMessage로..)
      if(errCode === ErrorKind.API && isEmpty(errHandle.alert))
        continue;

      if (errCode !== errHandle.errCode)
        continue;            

      isMatch = true;

      //팝업이 띄워지면 나머지 err 처리는 하지 않는다.
      if (!isEmpty(errHandle.alert)) {
        errHandle.alert(errHandle.alertMsg);      
        break;
      }

      //ChildHandle(BizError) 처리
      for (const childHandle of errHandle.childHandles) {

        if (this.error.response.data.errno !== childHandle.errCode)
          continue;
        
          if (!isEmpty(childHandle.alert)) {
            childHandle.alert(childHandle.alertMsg);      
            break;
          }
      }
    }

    //이전에 설정한 오류가 발견되지 않았을시 그외 메시지 출력.
    if(this.#isOther && !isMatch) {
      if(!isEmpty(this.#otherAlert))
        this.#otherAlert(this.#otherMessage);      
    }

    //콘솔 에러 출력
    if(this.#isPrintErrorLog)
      console.error(this.error.message);
    
    if (!isThrow)
      return;

    throw this.error;
  }
}

class ErrorHandle {
  childHandles = [];
  constructor(errKind = ErrorKind.API, errCode, alertMsg, alert = null) {
    this.errKind = errKind;
    this.errCode = errCode;    
    this.alertMsg = alertMsg;    
    this.alert = alert;
  }

  addChildHandle(childHandle) {
    this.childHandles.push(childHandle);
  }
}

export default AppErrorHandler;
<template>
  <div
    class="panel xe-panel-hoverbox"
    v-bind:class="[
      { 'panel-expand': expand, 'panel-loading': reload },
      panelTheme,
      panelClass,
    ]"
    v-if="!remove"
  >
    <div
      class="panel-heading"
      v-if="!noTitle"
      v-bind:class="headClass"
      v-bind:style="{ background: backgroundColor, color: fontColor }"
    >
      <slot name="header"></slot>
      <h4 class="panel-title" v-if="!hasHeaderSlot">{{ title }}</h4>
      <div
        class="panel-heading-btn"
        v-if="!noButton"
        :class="{ 'xe-hover-button': isHoverButton }"
      >
        <slot name="button"></slot>
        <button
          class="btn btn-info icons-middle"
          :class="isNormalButton ? btnNormalClasses : btnCircleClasses"
          v-on:click="panelEditing"
          v-if="showEditing === 'true'"
        >
          <i class="fa fa-edit"></i>
        </button>
        <button
          class="btn btn-primary icons-middle"
          :class="isNormalButton ? btnNormalClasses : btnCircleClasses"
          v-on:click="panelSetting"
          v-if="showSetting === 'true'"
        >
          <i class="fa fa-cog"></i>
        </button>
        <button
          class="btn btn-default icons-middle"
          :class="isNormalButton ? btnNormalClasses : btnCircleClasses"
          v-on:click="panelExpand"
          v-if="hideExpand !== 'true'"
        >
          <i class="fa fa-expand"></i>
        </button>
        <button
          class="btn btn-success icons-middle"
          :class="isNormalButton ? btnNormalClasses : btnCircleClasses"
          v.tooltip.hover
          title="Reload"
          v-on:click="panelReload"
          v-if="hideReload !== 'true'"
        >
          <i class="fa fa-redo"></i>
        </button>
        <button
          class="btn btn-warning icons-middle"
          :class="isNormalButton ? btnNormalClasses : btnCircleClasses"
          v-on:click="panelCollapse"
          v-if="hideCollapse !== 'true'"
        >
          <i class="fa fa-minus"></i>
        </button>
        <button
          class="btn btn-danger icons-middle"
          :class="isNormalButton ? btnNormalClasses : btnCircleClasses"
          v-on:click="panelRemove"
          v-if="hideRemove !== 'true'"
        >
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
    <slot name="beforeBody"></slot>
    <div
      class="panel-body"
      v-if="!noBody"
      v-show="!collapse"
      v-bind:class="bodyClass"
      :style="bodyStyle"
    >
      <slot></slot>
      <div class="panel-loader" v-if="reload">
        <span class="spinner-small"></span>
      </div>
    </div>
    <slot v-else></slot>
    <slot name="outsideBody"></slot>
    <div class="panel-footer" v-if="hasFooterSlot" v-bind:class="footerClass">
      <slot name="footer"></slot>
    </div>
    <slot name="afterFooter"></slot>
  </div>
</template>

<style scoped>
/* panel-title에 flex속성으로 버튼을 오른쪽 정렬시키고 있음. header slot의 첫번째노드는 무조건 flex가 되어야 버튼들이 정렬됨. */
.panel-heading div:nth-child(1) {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.xe-panel-hoverbox:hover .xe-hover-button {
  opacity: 1;
}

.panel {
  height: 100%;
}
</style>

<script>
// 주의사항: 패널과 탭이 b-tab 이 같은 것임.. 탭의 css를 변경하면, panel에도 영향을 끼침..
// 메인컨텐츠 즐겨찾기 탭과 위젯패널이 동시에 바뀜...

// TODO: showSetting boolean 형인데, string 'true' / 'false'를 사용하고 있음.  boolean으로 변경..

export default {
  name: "Panel",
  props: [
    "title",
    "body",
    "footer",
    "variant",
    "noTitle",
    "noBody",
    "noButton",
    "outsideBody",
    "bodyClass",
    "footerClass",
    "panelClass",
    "headClass",
    "isCollapse",
    "showEditing",
    "showSetting",
    "hideExpand",
    "hideReload",
    "hideCollapse",
    "hideRemove",
    "fixedHeight",
    "isHoverButton",
    "isNormalButton",
    "backgroundColor",
    "fontColor",
  ],
  data() {
    return {
      expand: false,
      collapse: this.isCollapse, //false,
      remove: false,
      reload: false,
      panelTheme: this.variant ? "panel-" + this.variant : "panel-inverse",
      btnCircleClasses: ["btn-xs", "btn-circle", "btn-icon"],
      btnNormalClasses: ["btn-xs"],
    };
  },
  computed: {
    bodyStyle() {
      if (this.fixedHeight) {
        let h = this.fixedHeight;
        // if (this.headClass && this.headClass.indexOf("hide") > -1) {
        //   h += 20;
        // }
        return `min-height:${h}px; max-height:${h}px; ${this.bodyClass === 'getOverflow' ? 'overflow-y:auto; overflow-x:hidden' : 'overflow:auto'}`;
      }
      return null;
    },
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
    hasButtonSlot() {
      return !!this.$slots.button;
    },
    hasFooterSlot() {
      return !!this.$slots.footer;
    },
  },
  methods: {
    panelEditing: function() {
      this.$emit("panel-editing");
    },
    panelSetting: function() {
      this.$emit("panel-setting");
    },
    panelExpand: function() {
      this.expand = !this.expand;
      this.$emit("panel-expand", this.expand);
    },
    panelCollapse: function() {
      this.collapse = !this.collapse;
      this.$emit("panel-collapse");
    },
    panelRemove: function() {
      this.remove = !this.remove;
      this.$emit("panel-remove");
    },
    panelReload: function() {
      this.reload = true;
      this.$emit("panel-reload");

      // 이 함수는는 dummy임.. 실제 reload 여부는 props 로 연결해야 함..
      // console.log("reload 함수가 지정되지 않았습니다.");

      setTimeout(
        function() {
          this.resetReload();
        }.bind(this),
        600
      );
    },
    resetReload: function() {
      this.reload = false;
    },
  },
};
</script>

<style scoped>
.icons-middle{
  display: grid !important;
  align-content: center !important;
  padding: 0 !important;
}
</style>

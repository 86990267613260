<template>
  <b-modal ref="modal" cancel-variant="default" :hide-footer="true" :title="$t('이벤트로그 상세')" size="lg">
    <div class="row form-group">
      <div class="col-md-4">
        <label> <trans>이벤트 유형</trans> </label>
        <input type="text" class="form-control" v-model="eventLog.eventType" :disabled="isUpdate || readonly" />
      </div>
      <div class="col-md-4">
        <label> <trans>사용자 아이디</trans> </label>
        <input type="text" class="form-control" v-model="eventLog.userId" :disabled="isUpdate || readonly" />
      </div>
      <div class="col-md-4">
        <label> <trans>클라이언트IP</trans> </label>
        <input type="text" class="form-control" v-model="eventLog.clientIp" :disabled="isUpdate || readonly" />
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-4">
        <label> <trans>서버IP</trans> </label>
        <input type="text" class="form-control" v-model="eventLog.serverIp" :disabled="isUpdate || readonly" />
      </div>
      <div class="col-md-4">
        <label> <trans>어플리케이션 이름</trans> </label>
        <input type="text" class="form-control" v-model="eventLog.appId" :disabled="isUpdate || readonly" />
      </div>
      <div class="col-lg-4">
        <label><trans>등록일</trans></label>
        <span class="form-control" :readonly="isUpdate">{{ isEmpty(eventLog.regDt) ? "" : eventLog.regDt.format("yyyy-MM-dd HH:mm") }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-tabs nav-class="nav-tabs" nav-wrapper-class="nav nav-tabs b-1 b-radius-3" content-class="bg-silver p-0" active-nav-item-class="text-inverse bg-silver">
          <b-tab active>
            <template slot="title">
              <span class="d-sm-block"> <trans>요약정보</trans> </span>
            </template>
            <valid-textarea :inputType="'text'" :inputValue.sync="eventLog.summary" :classList="'form-control'" :textareaRow="2" :rules="rules.LOG_SIMPLE_INFO_RULE" :NonStatus="true"></valid-textarea>
          </b-tab>
        </b-tabs>
        <b-tabs nav-class="nav-tabs" nav-wrapper-class="nav nav-tabs b-1 b-radius-3" content-class="bg-silver p-0" active-nav-item-class="text-inverse bg-silver">
          <b-tab>
            <template slot="title">
              <span class="d-sm-block"> <trans>추적메세지</trans> </span>
            </template>
            <!-- <textarea class="form-control" rows="12" v-model="eventLog.stacktrace" :disabled="readonly"></textarea> -->
            <valid-textarea :inputType="'text'" :inputValue.sync="eventLog.stacktrace" :classList="'form-control'" :textareaRow="12" :rules="rules.LOG_TARGET_MESSAGE_RULE" :NonStatus="true"></valid-textarea>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <vvo v-slot="{ passes }">
      <div slot="modal-footer" v-if="!readonly">
        <btn-delete v-if="isUpdate" @click="onDeleteEventLog()" />
        <!-- <btn-save @click="passes(onSaveEventLog)" :label="isUpdate ? '수정' : '입력'" /> -->
      </div>
    </vvo>
  </b-modal>
</template>

<script>
import backEndApi from "../../../../api/backEndApi.js";
import * as rules from "@src/consts/ruleConsts.js";
import * as popupMessages from "@src/consts/popupMessageConsts"

export default {
  props: ["eventLogIdx", "readonly"],
  data() {
    return {
      eventLog: {},
      rules
    };
  },
  computed: {
    isUpdate() {
      return !this.isEmpty(this.eventLogIdx);
    },
  },
  watch: {
    eventLogIdx() {
      this.initModal();
    },
  },
  mounted() {
    this.initModal();
  },
  methods: {
    initModal() {

      this.eventLog = {};

      if (this.isUpdate) {
        backEndApi.eventLog.getEventLog(this.eventLogIdx).then(({ data }) => {
          if (this.$err(data)) {
            this.alertNoti(popupMessages.COMMON_SEARCH_NO_DATA_POPUP_MESSAGE);
            return;
          }

          this.eventLog = data;
        });
      }
    },
    onSaveEventLog() {
      this.isUpdate ? this.updateEventLog() : this.insertEventLog();
    },
    updateEventLog() {
      this.alertQuestion(popupMessages.COMMON_UPDATE_CONFIRM_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        backEndApi.eventLog.updateEventLog(this.eventLog).then(({ data }) => {
          if (this.$err(data)) return;

          this.eventLog = {};
          this.alertNoti(popupMessages.COMMON_UPDATE_POPUP_MESSAGE);
          this.$emit("save-done");
        });
      });
    },
    insertEventLog() {
      this.alertQuestion(popupMessages.COMMON_SAVE_CONFIRM_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        backEndApi.eventLog.insertEventLog(this.eventLog).then(({ data }) => {
          if (this.$err(data)) return;

          this.eventLog = {};
          this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
          this.$emit("save-done");
        });
      });
    },
    onDeleteEventLog() {
      this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        backEndApi.eventLog.deleteEventLog(this.eventLogIdx).then(({ data }) => {
          if (this.$err(data)) return;

          this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
          this.$emit("save-done");
        });
      });
    },
    show() {
      //this.initModal();
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
  },
};
</script>

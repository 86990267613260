import * as validMessage from "@src/consts/validMessageConsts";


export default {
	failedPopupMessage: (failedKey, fieldName) => {
    // alertPopupError(Object.keys(v.errors)[0], v.fields) 호출 메서드
    console.log(failedKey, fieldName);

		switch (failedKey) {
			case "required": {
				return validMessage.FAILEDRULES_REQUIRED_VALID_MESSAGE(fieldName);
			}
      case "non_korean": {
				return validMessage.FAILEDRULES_NON_KOREAN_VALID_MESSAGE(fieldName);
			}
      case "password":{
        return validMessage.PASSWORD_CHANGE_VALID_MESSAGE
      }
      case "confirmed":{
        return validMessage.PASSWORD_CHANGE_CONFIRM_VALID_MESSAGE
      }
      case "min": {
				return validMessage.FAILEDRULES_MIN_VALID_MESSAGE(fieldName);
      }
      case "max": {
				return validMessage.FAILEDRULES_MAX_VALID_MESSAGE(fieldName);
      }
			case "non_special": {
				return validMessage.FAILEDRULES_NON_SPECIAL_VALID_MESSAGE(fieldName);
			}
			case "min_value": {
				return validMessage.FAILEDRULES_MIN_VALUE_VALID_MESSAGE(fieldName);
			}
			case "max_value": {
				return validMessage.FAILEDRULES_MAX_VALUE_VALID_MESSAGE(fieldName);
			}
			case "phone": {
				return validMessage.FAILEDRULES_PHONE_VALID_MESSAGE(fieldName);
			}
			case "both-decimal": {
				return validMessage.FAILEDRULES_BOTH_DECIMAL_VALID_MESSAGE(fieldName);
			}
			case "integer-decimal": {
				return validMessage.FAILEDRULES_INTEGER_DECIMAL_VALID_MESSAGE(fieldName);
			}
      case "check_reserved_id": {
        return validMessage.LOGIN_NOT_ALLOW_ID_VALID_MESSAGE;
      }
		}
	},
};

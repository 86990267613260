<template>
	<b-nav-item-dropdown menu-class="media-list dropdown-menu-right" toggle-class="f-s-14" no-caret>
		<template slot="button-content">
			<i class="fa fa-bell"></i>
			<span class="label" v-if="alarms.length > 0"> {{ alarms.length }}</span>
		</template>
		<b-dropdown-header>{{ $t("알람로그") }} </b-dropdown-header>
		<alarm-list :alarms="alarms" @holdCallback="test" />
		<b-dropdown-item class="text-center" v-if="!isMobile">
			<a href="javascript:;" @click="openAlarmList">{{ $t("더보기") }}</a>
		</b-dropdown-item>
	</b-nav-item-dropdown>
</template>

<script>
	import AlarmList from "./AlarmList.vue";

	const { forObj } = global.xe;

	// 알람을 그룹별로 묶어서 맵형태로 반환한다.
	// function grouping(alarmLogs) {
	// 	const map = {};
	// 	for (const item of alarmLogs) {
	// 		if (item.isHold) continue;

	// 		// 중복체크용 - 그룹, 주소, 상태를 키로 생성하고, 동일건을 그룹으로 모은다.
	// 		//let key = `${item.alarmGroup}/${item.ptAddr}/${item.status}`;
	// 		let key = `${item.alarmGroup}/${item.ptAddr}`;

	// 		if (!map[key]) map[key] = [];

	// 		map[key].push(item);
	// 	}

	// 	return map;
	// }

	// 화면에 표시할 그룹별 대표알람을 배열에 담는다.
	// function setAlarmList(alarmList, alarmMap) {
	// 	alarmList.clear();

	// 	forObj(alarmMap, (group) => {
	// 		alarmList.push(group[0]);
	// 	});
	// }

	export default {
		components: {
			AlarmList,
		},
		data() {
			return {
				alarms: [], // 화면상단에 표시할 그룹별 대표알람 목록. 그룹별 최대 5개까지 표시
				formerAlarms: [],

			};
		},
		computed: {
			alarmRegDt() {
				return this.$store.getters.alarmLogsRegDt;
			},
		},

		watch: {
			alarmRegDt() {
				console.log("watch ::::: alarm log :::::");
				let alarmLogs = JSON.parse(localStorage.alarmLogs);
				this.onChangeAlarms(alarmLogs);
			},
      "localStorage.alarmLogs"(){
        this.initSetting();
      },
		},
		mounted() {
			this.initSetting();

			// localStorage.notifiedAlarms = null;
		},
		methods: {
      test(){

      },
			initSetting() {
				// TODO: 여기서 그룹핑하지 말고, 서버에서 그룹핑하여 전송해야 할듯...
				// backEndApi.notification.searchAlarmNotification(500).then(({ data }) => {
				// 	if (this.$err(data)) return;

				// 	this.onChangeAlarms(data);
				// });


        
       this.onChangeAlarms(JSON.parse(localStorage.alarmLogs));
			},
			onChangeAlarms(alarmLogs) {
				if (this.isEmpty(localStorage.getItem("accessToken"))) return;
        console.log(alarmLogs);

				try {
					for (let i = 0; i < alarmLogs.length; i++) {
						let alarm = alarmLogs[i];

						if (this.formerAlarms.findIndex((v) => v.alarmLogIdx === alarm.alarmLogIdx) !== -1) {
							alarmLogs.splice(i, 1);
							i--;
						}
					}
					console.log(alarmLogs, this.formerAlarms);

					let objectAlarm = {};

					for (let i = 0; i < alarmLogs.length; i++) {
						let alarm = alarmLogs[i];

						if (Object.keys(objectAlarm).includes(`${alarm.alarmGroup}/${alarm.ptAddr}`) === false) {
							objectAlarm[`${alarm.alarmGroup}/${alarm.ptAddr}`] = [];
						}
						objectAlarm[`${alarm.alarmGroup}/${alarm.ptAddr}`].push(alarm);

            if(this.formerAlarms.length > 100){
              this.formerAlarms.shift();
            }

            this.formerAlarms.push(alarm);
            this.alarms.unshift(alarm);
					}

					let notifiedAlarms = this.readStorage("notifiedAlarms");

					forObj(objectAlarm, (group, key) => {
						console.log(group);
						let isIncludes = group.find((v) => v.regDt === notifiedAlarms[key]);
						console.log(isIncludes);

						if (this.isEmpty(isIncludes)) {
							let log = group[0];

							this.alertNoti("알람", `${log.alarmGroup} <br /> ${log.description} <br /><br /> ${log.regDt.passedTime()}`, "info");

							notifiedAlarms[key] = log.regDt;
						}
					});



					// hold 되지 않은 동일건을 그룹화 한다. 여기서는 Noti된 것을 빼면 안됨..
					// const alarmMap = grouping(alarmLogs);
					// 화면에 표시할 알람로그 목록
					// 초기화 후에, Noti와 상관없이, 해결되지 않은 알람이면 화면상단 목록에 표시해야 한다.
					// setAlarmList(this.alarms, alarmMap);
					// 이미 Noti 한것인지 여부를 storage에 저장하여 사용함..

					// forObj(alarmMap, (group, key) => {
					// 	console.log(group);
					// 	let isIncludes = group.find((v) => v.regDt === notifiedAlarms[key]);
					// 	console.log(isIncludes);

					// 	if (this.isEmpty(isIncludes)) {
					// 		let log = group[0];

					// 		this.alertNoti("알람", `${log.alarmGroup} <br /> ${log.description} <br /><br /> ${log.regDt.passedTime()}`, "info");

					// 		notifiedAlarms[key] = log.regDt;
					// 	}
					// });

					// 지속적으로 노티되지 않도록 함..
					localStorage.notifiedAlarms = JSON.stringify(notifiedAlarms);
          localStorage.alarmLogs = JSON.stringify(this.formerAlarms);
				} catch (err) {
					console.error("Header onChangeAlarms error:", err);
				}
			},
			openAlarmList() {
				this.$router.push(`/alarm/alarmList`);
			},
		},
	};
</script>

<style scoped>
.dropdown-menu.media-list{
  max-width: fit-content !important;
}
</style>
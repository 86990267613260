<template>
	<vvo v-slot="v" :key="parentFacilityIdx + facilityIdx">
		<!--     
              부모키   자신키   부모정보,ct 
  장비추가 -   x        x        x
  장비수정 -   x        o        x
  하위추가 -   o        x        o
  하위수정 -   o        o        o
   -->
		<!-- parentFacilityIdx : {{ parentFacilityIdx }} <br />
channelType : {{ channelType }} -->

		<div class="row form-group" v-if="parentFacilityIdx">
			<div class="col-lg-12">
				<div v-if="facilityIdx" class="alert alert-secondary fade show">'{{ parentFacility.facilityName }}' {{
					$t("수집항목 입니다.") }}</div>
				<div v-else class="alert alert-secondary fade show">'{{ parentFacility.facilityName }}' {{ $t("하위에 추가됩니다.")
				}}</div>
			</div>
		</div>
		<div class="row form-group">
			<div class="col-lg-5" v-if="!parentFacilityIdx">
				<label>
					<trans>계측기 아이디</trans>
				</label>
				<valid-input :vid="'계측기 아이디'" :inputType="'text'" :inputValue.sync="newFacility.facilityName"
					:rules="rules.COLLECT_DATA_MEASUING_ID_RULE"
					:errorMessage="validMessage.COMMON_ALPHA_NUM_LENGTH_VALID_MESSAGE(1, 30)"
					:placeholder="$t('계측기 아이디 입력')" :isMessage="true"
					@inputMethod="newFacility.facilityName = newFacility.facilityName.toUpperCase()"></valid-input>

			</div>
			<div class="col-lg-6" v-else>
				<label>
					<trans>모듈명</trans>
				</label>

				<valid-input :vid="'모듈명'" :inputType="'text'" :inputValue.sync="newFacility.facilityName"
					:rules="rules.COLLECT_DATA_MODULE_NAME_RULE"
					:errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 30)" :placeholder="$t('모듈명 입력')"
					:isMessage="true"></valid-input>
			</div>
			<div class="col-lg-4" v-if="!parentFacilityIdx">
				<label>
					<trans>계측기명</trans>
				</label>

				<valid-input :vid="'계측기명'" :inputType="'text'" :inputValue.sync="newFacility.facilityAlias"
					:rules="rules.COLLECT_DATA_MEASUING_NAME_RULE"
					:errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 30)" :placeholder="$t('계측기명 입력')"
					:isMessage="true"></valid-input>
			</div>
			<div class="col-lg-3" v-if="parentFacilityIdx">
				<!-- TODO: 드롭다운으로 변경해야 할듯 -->
				<!-- TODO: 다른 하위장비에서 사용중인 CT 중복 검사해야 함. -->
				<label>
					<trans>모듈번호</trans> (CT)
				</label>
				<valid-input :vid="'모듈번호'" :inputType="'number'" :inputValue.sync="newFacility.ct"
					:rules="rules.COLLECT_DATA_MODULE_NUM_RULE"
					:errorMessage="validMessage.COMMON_NUMBER_LENGTH_VALID_MESSAGE(1, 999)" :placeholder="$t('모듈번호 지정')"
					:isMessage="true"></valid-input>

			</div>
			<div class="col-lg-3">
				<label>&nbsp;</label>
				<div class="checkbox checkbox-css m-t-10">
					<input type="checkbox" :id="'facilityDetail_' + newFacility.facilityIdx" v-model="newFacility.isUse" />
					<label :for="'facilityDetail_' + newFacility.facilityIdx">
						<trans>사용여부</trans>
					</label>
				</div>
			</div>
		</div>
		<div class="row form-group" v-if="parentFacilityIdx">
			<div class="col-lg-6">
				<label>
					<trans>적용구역</trans>
				</label>
				<tree-select class="treeText" :load-options="loadOptions"
					:placeholder="controlMessages.ZONE_TREE_PLACEHOLDER_MESSAGE"
					:noChildrenText="controlMessages.ZONE_TREE_NOCHILDREN_MESSAGE"
					:noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)" v-model="newFacility.applyZoneIdx"
					:multiple="false" :options="getZoneInfoList" :clear-on-select="false" :max-height="200" />

			</div>
			<div class="col-lg-6">

				<label>{{$t('용도')}}</label>
				<select class="form-control" v-model="newFacility.usageCode">
					<option v-for="(usageCodeData, i) in getUsageCodeList" :key="i" :value="usageCodeData.smallCode">{{
						usageCodeData.smallCodeName }}</option>
				</select>
			</div>
		</div>
		<div class="row form-group" v-if="parentFacilityIdx">
			<div class="col-lg-6">
				<label>{{$t('데이터 매핑키')}}</label>
				<valid-input :vid="'데이터 매핑키'" :inputType="'text'" :inputValue.sync="newFacility.mappingKey"
					:rules="rules.COLLECT_DATA_MAPPING_KEY_RULE" :placeholder="$t('매핑키 입력')"
					:NonStatus="true"></valid-input>
			</div>
			<div class="col-lg-6">
				<label>{{$t('에너지 공급 유형')}}</label>
				<select class="form-control" v-model="newFacility.energySplyTypeCode"
					:disabled="!(newFacility.usageCode === 'In')">
					<option v-for="(data, index) in getEnergyTypeCodeList" :key="index" :value="data.smallCode">
						{{ data.smallCodeName }}
					</option>
				</select>
			</div>
		</div>

		<div class="row form-group">
			<div class="col-lg-6">
				<label v-if="parentFacilityIdx">
					<trans>수집항목 선택</trans>
				</label>
				<label v-else>
					<trans>드라이버 선택</trans>
				</label>
				<!-- 신규장비만 드라이버 선택 가능 : 장비 수정, 하위장비 추가, 수정시 모두 선택불가능 -->
				<select class="form-control" v-model="newFacility.driverType" :disabled="isUpdate || !isEmpty(parentFacilityIdx)">
					<option v-for="(driverType, i) in driverTypes" :key="i" :value="driverType">{{ driverType == "" ? $t("드라이버 선택") : driverType }}</option>
				</select>
			</div>
			<div class="col-lg-3">
				<label>
					<trans>버전</trans>
				</label>
				<select class="form-control" v-model="newFacility.version" :disabled="isUpdate || !isEmpty(parentFacilityIdx)">
					<option v-for="(driver, i) in getVersion" :key="i" :value="driver.version">{{ driver.version }}</option>
				</select>
			</div>
			<div class="col-lg-3">
				<label>
					<trans>장치 고유번호</trans>
				</label>
				<valid-input :vid="'장치 고유번호'" :inputType="'number'" :inputValue.sync="newFacility.deviceId"
					:rules="rules.COLLECT_DATA_EQUIP_UNIQUE_NUM_RULE" :placeholder="$t('장치 고유번호')" :NonStatus="true"
					:disabled="!isEmpty(parentFacilityIdx)"></valid-input>
			</div>
		</div>
		<div :class="'row form-group'" v-if="parentFacilityIdx && newFacility.driverType == 'CNC_PULSE_COUNT_TCP'">
			<div class="col-lg-6">
				<label>펄스 당 용량</label>
				<select class="form-control" v-model="facilityOption.pulseUnit" :ref="'pulseUnit'" @input="setPulseUnit">
					<option value="0.001">0.001</option>
					<option value="0.1">0.1</option>
					<option value="1">1</option>
					<option value="10">10</option>
					<option value="100">100</option>
					<option value="1000">1000</option>
				</select>
			</div>
		</div>
		<div class="row form-group" v-if="!parentFacilityIdx">
			<div class="col-lg-6">
				<label>
					<trans>계측기 유형</trans>
				</label>
				<select class="form-control" v-model="newFacility.meterTypeCode">
					<option v-for="(meterData, i) in getMeterTypeList" :key="i" :value="meterData.meterTypeCode">{{
						meterData.meterTypeCodeName }}</option>
				</select>
			</div>
			<div class="col-lg-6">
				<label>
					<trans>적용구역</trans>
				</label>
				<tree-select class="treeText" :load-options="loadOptions"
					:placeholder="controlMessages.ZONE_TREE_PLACEHOLDER_MESSAGE"
					:noChildrenText="controlMessages.ZONE_TREE_NOCHILDREN_MESSAGE"
					:noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)" v-model="newFacility.applyZoneIdx"
					:multiple="false" :options="getZoneInfoList" :clear-on-select="false" :max-height="200" />
			</div>
		</div>
		<div class="row form-group" v-if="!parentFacilityIdx">
			<div class="col-lg-6">
				<label>
					<trans>설치일자</trans>
				</label>
				<input type="text" class="form-control" :class="classes" :placeholder="$t('YYYY-MM-DD')"
					v-model="newFacility.instDate" />
			</div>
			<div class="col-lg-6">
				<label>
					<trans>검교정 주기</trans>
				</label>
				<select class="form-control" v-model="newFacility.clbrPeriodCode">
					<option v-for="(clbrCode, i) in getClbrPeriodList" :key="i" :value="clbrCode.smallCode">{{
						clbrCode.smallCodeName }}</option>
				</select>
			</div>
		</div>
		<!-- 계측기 정보 추가 끝 -->
		<div slot="modal-footer" class="w-100">
			<button v-xe-pm.E class="btn btn-danger" v-if="isUpdate && (isEmpty(childrens) || childrens == 0)"
				@click="onDeleteFacility()"><i class="fa fa-times"></i> {{ $t("삭제") }}</button>
			<button class="btn btn-primary pull-right" @click="onValidate(v, onSaveFacility, newFacility)"><i class="fa fa-save"></i> {{
				$t("저장") }}</button>
		</div>
	</vvo>
</template>

<script>
import backEndApi from "@api/backEndApi.js";
import AppErrorHandler from "@src/appErrorHandler";
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
import * as popupMessages from "@src/consts/popupMessageConsts";
import * as controlMessages from "@src/consts/controlMessageConsts";

export default {
	props: ["serverIdx", "channelIdx", "parentFacilityIdx", "facilityIdx", "channelType", "childrens", "save-done", "newCT"],
	data() {
		return {
			classes: "", // 오류..
			parentFacility: {},
			newFacility: {},
			orgFacility: {},
			//driverList: [],
			drivers: [],
			meterTypeList: [],
			usageCodeList: [],
			clbrPeriodList: [],
			rules,
			validMessage,
			popupMessages,
			controlMessages,
			zoneInfoListOption: [],
			energySplyTypeCodeList: [],
      facilityOption: {
        pulseUnit: null
      },
		};
	},
	computed: {
		isUpdate() {
			return !this.isEmpty(this.facilityIdx);
		},
		driverTypes() {
			// version이 여러개면, driverType이 중복되므로 distinct 해준다.
			return this.drivers.map((v) => v.driverType).unique();
		},
		getVersion() {
			return this.drivers.filter((driver) => driver.driverType == this.newFacility.driverType);
		},
		getMeterTypeList() {
			return this.meterTypeList;
		},
		getUsageCodeList() {
			return this.usageCodeList;
		},
		getClbrPeriodList() {
			return this.clbrPeriodList;
		},
		getZoneInfoList() {
			return this.zoneInfoListOption;
		},
		getEnergyTypeCodeList() {
			return this.energySplyTypeCodeList;
		},
	},
	watch: {
		facilityIdx: function () {
			this.initSetting();
		},
	},
	created() {
	},
	async mounted() {
		await this.initSetting();
		await this.getZoneListHierarchi();
	},
	methods: {
    setPulseUnit(){
      this.newFacility.facilityOption = this.facilityOption;
    },
		driversClear() {
			this.drivers.clear();
		},
		initSetting() {
			this.newFacility = {
				serverIdx: this.serverIdx,
				channelIdx: this.channelIdx,
				parentFacilityIdx: this.parentFacilityIdx,
				facilityOption: {},
				driverType: "",
				deviceId: "",
				mappingKey: "",
				facilityName: "",
				applyZoneIdx: "",
				energySplyTypeCode: "",

			};
			this.drivers.clear();

			// 장비 드라이버 먼저 조회
			backEndApi.driver.searchDriver().then(({ data }) => {
				console.log(data);
				if (this.$err(data)) return;
				if (this.isEmpty(this.channelType)) return;
				this.drivers.range(data.filter((v) => v.channelType == this.channelType));

				this.drivers.unshift({
					channelType: "",
					driverName: "",
					driverType: "",
					remark: "",
					version: "",
				});

				if (this.drivers.length == 0) {
					this.alertNoti(popupMessages.POINT_DATA_COLLECTION_INIT_ERROR_POPUP_MESSAGE);
					return;
				}

				// 장비 상세 정보 조회
				if (this.isUpdate) {
					backEndApi.facility.getFacility(this.facilityIdx).then(({ data }) => {
						if (this.$err(data)) return;
            

						this.newFacility = data;
						this.newFacility.facilityOption = JSON.parse(data.facilityOption) ? JSON.parse(data.facilityOption) : {};
            this.facilityOption = this.newFacility.facilityOption
						this.newFacility.applyZoneIdx = data.applyZoneIdx ? data.applyZoneIdx : "";
						this.newFacility.energySplyTypeCode = data.energySplyTypeCode ? data.energySplyTypeCode : "";
						this.orgFacility = Object.freeze(data);


						this.bindParentDirver();
					});
				} else {
					this.bindParentDirver();
				}
			});

			//계측기 코드 조회 추가 2022-12-12 권철규
			backEndApi.meterType.getMeterTypeList().then(({ data }) => {
				if (this.$err(data)) return;
				this.meterTypeList.range(data);
			});

			//용도 코드 조회 추가 2023-02-08 권철규
			// backEndApi.publicCode.getOprtnSmallCodeList("itemUseageCode").then(({ data }) => {

			//   let dataArray = [];
			//   if (this.$err(data)) return;

			//   for (const code of data) {

			//     if (code.etcInfo2.toUpperCase() == "Y") {
			//         let dataDvsn = {};
			//         dataDvsn.smallCode = code.smallCode;
			//         dataDvsn.smallCodeName = code.smallCodeName;
			//         dataArray.push(dataDvsn);
			//     }
			//   }

			//   this.usageCodeList.range(dataArray);
			// });

			// 용도 코드 조회 수정
			backEndApi.publicCode.getSmallCodeList("phscPointUsage").then(({ data }) => {
				let dataArray = [];
				if (this.$err(data)) return;

				for (const code of data) {
					if (code.enabled.toUpperCase() == "Y") {
						let dataDvsn = {};
						dataDvsn.smallCode = code.smallCode;
						dataDvsn.smallCodeName = code.smallCodeName;
						dataArray.push(dataDvsn);
					}
				}

				this.usageCodeList.range(dataArray);
			});

			//검교정 주기 조회 추가 2022-12-12 권철규
			backEndApi.publicCode.getSmallCodeList("clbrPeriodCode", "", "Y").then(({ data }) => {
				if (this.$err(data)) return;
				this.clbrPeriodList.range(data);
			});

			/**
			 * NOTE - 새로운 ct 값이 존재할 경우에만 추가
			 */
			if (this.newCT) {
				this.newFacility.ct = this.newCT;
			}
			backEndApi.publicCode.getOprtnSmallCodeList("energySplyTypeCode", null, "Y").then(({ data }) => {
				if (this.$err(data)) return;
				this.energySplyTypeCodeList.range(data);
			});
		},
		bindParentDirver() {
			// 하위 장비 등록시 부모정보 조회
			if (this.parentFacilityIdx) {
				backEndApi.facility.getFacility(this.parentFacilityIdx).then(({ data }) => {

					// 하위장비 추가인경우, 부모 드라이버 정보를 변경할 수 없다...
					// TODO: 시간차 오류가 있을 수 있음..
					if (this.$err(data)) return;
					this.parentFacility = data;
					this.newFacility.driverType = data.driverType;
					this.newFacility.version = data.version;
					this.newFacility.deviceId = data.deviceId;
				});
			}
		},
		onSaveFacility() {
			if (this.isEmpty(this.newFacility.driverType) || this.isEmpty(this.newFacility.version)) {
				this.alertNoti(popupMessages.POINT_DATA_COLLECTION_ADD_DRIVER_VALID_POPUP_MESSAGE);
				return;
			}

			const regex = /^[A-Za-z]+[0-9]+$/;

			if (this.newCT === undefined) {
				if (!regex.test(this.newFacility.facilityName.toUpperCase())) {
					this.alertNoti(popupMessages.POINT_DATA_COLLECTION_ADD_ID_VALID_POPUP_MESSAGE);
					return;
				}

				if (this.isEmpty(this.newFacility.facilityAlias)) {
					this.alertNoti(popupMessages.POINT_DATA_COLLECTION_ADD_NAME_VALID_POPUP_MESSAGE);
					return;
				}
			}

			this.isUpdate ? this.updateFacility() : this.insertFacility();
		},
		insertFacility() {
      this.newFacility.facilityOption = JSON.stringify(this.newFacility.facilityOption);


			// TODO: 하위장비 등록시 다른 ct 번호와 중복되는지 확인해야 함.
			// 이미 존재하는 ct는 입력 할 수 없어야 함.
			this.alertQuestion(popupMessages.COMMON_SAVE_CONFIRM_POPUP_MESSAGE).then((result) => {
				if (!result.value) return;
				backEndApi.facility.insertFacility(this.newFacility).then(({ data }) => {
					if (this.$err(data)) return;

					this.newFacility = {};
					this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
					this.$emit("save-done");
				});
			});
		},
		updateFacility() {

			// TODO: 하위장비 변경시 ct가 변경되면, 다른 ct 번호와 중복되는지 확인해야 함.
			// 이미 존재하는 ct는 입력 할 수 없어야 함.
			// TODO: 장비의 드라이버가 변경되면, 하위장비의 드라이버 정보도 전부 변경되어야 한다.
			// if(this.newFacility.facilityOption);

			// 원래의 정보와 driverType이 변경되면...
			if (this.newFacility.driverType !== this.orgFacility.driverType || this.newFacility.version !== this.orgFacility.version) {
				this.alertNoti(popupMessages.POINT_DATA_COLLECTION_DRIVER_UPDATE_ERROR_POPUP_MESSAGE);
				return;
			}

      this.newFacility.facilityOption = JSON.stringify(this.newFacility.facilityOption);

			this.alertQuestion(popupMessages.COMMON_UPDATE_CONFIRM_POPUP_MESSAGE).then((result) => {
				if (!result.value) return;

				backEndApi.facility.updateFacility(this.newFacility).then(({ data }) => {
					if (this.$err(data)) return;
					this.alertNoti(popupMessages.COMMON_UPDATE_POPUP_MESSAGE);
					this.$emit("save-done");
				});
			});
		},
		onDeleteFacility() {
			// TODO: 하위 정보가 있으면 삭제할 수 없습니다.
			// TOOD: childrens 인자로 받지 않고, 서버에서 조회해야 정확함..

			this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE).then((result) => {
				if (!result.value) return;

				backEndApi.facility.deleteFacility(this.newFacility.facilityIdx).then(({ data }) => {
					if (this.$err(data)) return;
					this.newFacility = {};
					this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
					this.$emit("save-done");
				});
			});
		},
		async getZoneListHierarchi() {
			try {
				let result = await backEndApi.zoneInfo.getZoneListHierarchi();
				let options = [];
				let temp = [];
				temp.push(result.data);
				options = this.setZoneTreeOption(temp);
				this.zoneInfoListOption = options;
				this.newFacility.applyZoneIdx = this.zoneInfoListOption[0].zoneIdx;
				return this.zoneInfoListOption;
			} catch (err) {
				new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
			}
		},
		setZoneTreeOption(resultData) {
			resultData.forEach((data) => {
				data.id = data.zoneIdx;
				data.label = data.zoneName;

				if (data.children.length === 0) {
					delete data.children;
				}

				if (data["children"]) {
					return this.setZoneTreeOption(data["children"]);
				} else {
					return data;
				}
			});

			return resultData;
		},

		loadOptions({ callback }) {
			callback();
		},
	},
};
</script>

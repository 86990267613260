<template>
	<div>
		<vvo v-slot="v" :key="workflowNames.length">
			<!-- 디자인 이상해서 일단 숨김..
      <div class="row form-group">
        <div class="col-lg-12">
          <label> <trans>작업그룹명</trans> </label>
          <vvp rules="required" v-slot="{ errors, classes }" name="Name">
            <input type="text" class="form-control" :class="classes" placeholder="Enter workgroup name" v-model="workgroup.workgroupName" maxlength="50">
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </vvp>
        </div>
      </div>  -->

			<!-- 
      TODO: 사용하지 않는 ticks, intervalMin, startDt, endDt 모두 삭제함.
      workgroup.workgroupName;
      workgroup.isUse;  // 기존 isDefault 를 isUse로 변경함.. liveHander에서 true일때만 사용핟
      workgroup.constants; // 별도의 UI가 필요함..  UPL 트리와 동일함. 구조는
      workgroup.workflows; // 이름이 너무 헷갈림.. 변경해야 함..
      -->

			<div class="row form-group">
				<div class="col-12 xe-hover-box">
					<div v-for="(workflow, wfidx) in workflowNames" :key="'workflow_' + wfidx">
						<div class="checkbox checkbox-css">
							<input type="checkbox" :id="'workFolw_' + wfidx" v-model="workflow.checked" />
							<label :for="'workFolw_' + wfidx">
								<span>{{ workflow.name }}</span>
								<a v-xe-pm.E href="javascript:;" class="btn btn-xs xe-hover-button m-l-10" @click="openSaveAsModal(workflow.name)" v-b-tooltip.hover :title="$t('다른 이름으로 저장')"
									><i class="fa fa-copy" />
								</a>
								<a v-xe-pm.E href="javascript:;" class="btn btn-xs xe-hover-button m-l-10" @click="onDeleteWorkflow(workflow.name)" v-b-tooltip.hover :title="$t('삭제')"><i class="fa fa-times" /> </a>
							</label>
						</div>
					</div>
				</div>
			</div>

			<div slot="modal-footer">
				<button class="btn btn-sm btn-default" @click="openWorkflowModal" v-b-tooltip.hover :title="$t('워크플로우') + ' ' + $t('추가')"><i class="fa fa-plus" /> <trans>추가</trans></button>
				<button class="btn btn-sm btn-primary pull-right" @click="onValidate(v, onSave)" v-b-tooltip.hover :title="$t('작업그룹') + ' ' + $t('저장')">
					<i class="fa fa-save"></i> <trans>저장</trans>
				</button>
			</div>

			<b-modal ref="workflowModal" :hide-footer="true" :title="$t('워크플로우')">
				<xe-workflow-detail @save-done="onWorkflowSaveDone" />
			</b-modal>

			<b-modal ref="saveAsModal" body-class="p-20" cancel-variant="default" :title="$t('다른 이름으로 저장')">
				<div class="row form-group">
					<div class="col-md-12">
						<label> <trans>워크플로우명</trans></label>
						<valid-input
							:vid="'워크플로우명'"
							:inputType="'text'"
							:inputValue.sync="saveAsWorkFlowName"
							:rules="rules.POINT_FORMULA_WORKFLOW_NAME_RULE"
							:errorMessage="popupMessages.POINT_CALCULATION_WORKFLOW_NAME_VALID_POPUP_MESSAGE"
						>
						</valid-input>

						<!-- <input type="text" class="form-control" v-model="saveAsWorkFlowName" @change="onDupCheckWorkFlowName" /> -->
						<!-- <span class="invalid-feedback" v-if="isDup"> <trans>데이터가 중복되었습니다.</trans> </span> -->
					</div>
				</div>
				<div slot="modal-footer" class="w-100">
					<button class="btn btn-sm btn-danger" @click="onHide($refs.saveAsModal)"><i class="fa fa-times"></i> {{ $t("취소") }}</button>
					<button class="btn btn-sm btn-primary pull-right" @click="onValidate(v, onSaveAsWorkflow)"><i class="fa fa-save"></i> {{ $t("저장") }}</button>
				</div>
			</b-modal>
		</vvo>
	</div>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";
	import xeWorkflowDetail from "./xeWorkflowDetail.vue";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import { StatusCodes } from "http-status-codes";
	import AppErrorHandler from "@src/appErrorHandler";
  import * as rules from "@src/consts/ruleConsts";

	export default {
		props: ["workgroupName", "save-done"],
		components: {
			xeWorkflowDetail,
		},
		data() {
			return {
				workgroup: null,
				workflowNames: [],

				to_dup: null,
				isDup: false,
				saveAsOriginalName: "",
				saveAsWorkFlowName: "",
        popupMessages,
        rules
			};
		},
		watch: {
			workgroupName: function() {
				this.initSetting();
			},
		},
		mounted() {
			this.initSetting();
		},
		methods: {
			initSetting() {
				if (this.isEmpty(this.workgroupName)) return;

				// 현재 작업그룹 정보를 조회한다.
				backEndApi.workflow.searchWorkgroups().then(({ data }) => {
					if (this.$err(data)) return;

					this.workgroup = data.find((v) => v.workgroupName === this.workgroupName); // 현재 UI에서 첫번째 워크그룹만 처리하도록 고정되어 있음..

					if (this.isEmpty(this.workgroup)) {
						this.alertNoti(popupMessages.COMMON_SEARCH_NO_DATA_POPUP_MESSAGE);
						return;
					}

					// 모든 워크플로우를 조회한다.
					backEndApi.workflow.searchAllJobs().then(({ data }) => {
						if (this.$err(data)) return;

						let workflowObjs = data;

						// 작업그룹에 포함여부를 확인하여 이름목록을 생성한다.
						let workflowNames = workflowObjs.map((v) => {
							let checked = this.workgroup.workflows.find((wfName) => v.name.trim().toUpperCase() == wfName.trim().toUpperCase()) ? true : false;
							return { name: v.name, checked: checked };
						});

						this.workflowNames.range(workflowNames);
					});
				});
			},
			onHide(modal) {
				modal.hide();
			},
			onSave() {
				this.alertQuestion(popupMessages.POINT_CALCULATION_GROUP_SAVE_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					let arr = this.workflowNames.map((v) => (v.checked ? v.name : null)).filter((v) => v);
					this.workgroup.workflows.range(arr);

					console.log(this.workgroup);

					backEndApi.workflow.saveWorkgroups(this.workgroup).then(async ({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
						this.$emit("save-done");
					});
				});
			},
			openWorkflowModal() {
				this.$refs.workflowModal.show();
				this.$refs.workflowModal.blur();
			},
			onWorkflowSaveDone() {
				this.$refs.workflowModal.hide();

				this.initSetting();
			},
			async onDeleteWorkflow(workflowName) {
				const popupResult = await this.alertConfirmWarning(popupMessages.POINT_CALCULATION_WORKFLOW_DELETE_POPUP_MESSAGE);
				if (!popupResult.value) return;

				try {
					await backEndApi.workflow.deleteWorkflow(workflowName);

					this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
					this.initSetting();
				} catch (err) {
					new AppErrorHandler(err)
						.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE)
						.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
						.createBizHandle(this.ERR_KIND.IN_USE, popupMessages.COMMON_INUSE_REMOVE_ERROR_POPUP_MESSAGE, this.alertDanger)
						.errHandling();
				}
			},
			openSaveAsModal(workflowName) {
				this.saveAsOriginalName = workflowName;
				this.saveAsWorkFlowName = workflowName;
				this.$refs.saveAsModal.show();
			},
			// onDupCheckWorkFlowName() {
			// 	if (this.to_dup) clearTimeout(this.to_dup);

			// 	this.to_dup = setTimeout(() => {
			// 		// 변경하려는 Workflow의 이름이 중복되었는지 검사한다.
			// 		backEndApi.workflow.isDupCheckWorkflowName(this.saveAsWorkFlowName).then(({ data }) => {
			// 			if (this.$err(data)) return;

			// 			this.alertNoti(popupMessages.POINT_CALCULATION_WORKFLOW_DATA_DUP_CHECK_POPUP_MESSAGE);
			// 			this.isDup = data.isDup;
			// 		});
			// 	}, 300);
			// },
			onSaveAsWorkflow() {
				if (this.isDup) {
					this.alertNoti(popupMessages.POINT_CALCULATION_WORKFLOW_DATA_DUP_CHECK_POPUP_MESSAGE);
					return;
				}

				this.alertQuestion(popupMessages.POINT_CALCULATION_WORKFLOW_ADD_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					// 새이름으로 저장한다.
					backEndApi.workflow.saveAsWorkflow(this.saveAsOriginalName, this.saveAsWorkFlowName).then(({ data }) => {
						if (this.$err(data)) return;

						this.$refs.saveAsModal.hide();
						this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
						this.initSetting();
					});
				});
			},
		},
	};
</script>

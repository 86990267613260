import IconSelectComponent from "./IconSelect.vue";

const IconSelect = {
  install(Vue) {
    //Vue.component(IconSelectComponent.name, IconSelectComponent);
    Vue.component(IconSelectComponent.name, () => import("./IconSelect.vue"));
  },
};

export default IconSelect;

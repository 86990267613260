/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="GridSetting">
    <div class="row row-cols-5 mb-3">
      <div class="col text-left contens-col">
        <span class="h5 mb-3">{{$t('단위 대구분')}}</span>
        <select class="form-control" :ref="'unitLargeCode'" v-model="model.unitLargeCode" @change="onChangeUnitLargeCode">
          <option value="">{{$t('선택')}}</option>
          <option v-for="(data, index) in unitLargeCodeList" :key="index" :value="data.unitLargeCode">
            {{ data.unitLargeCodeName }}
          </option>
        </select>
      </div>
      
      <div class="col text-left contens-col">
        <span class="h5 mb-0">{{$t('단위 소구분')}}</span>
        <select class="form-control" :ref="'unitSmallCode'" v-model="model.unitSmallCode">
          <option value="">{{$t('선택')}}</option>
          <option v-for="(data, index) in unitSmallCodeList" :key="index" :value="data.unitSmallCode">
            {{ data.unitSmallCodeName }}
          </option>
        </select>
      </div>
      
      <div class="col text-left contens-col">
        <span class="h5 mb-0">{{$t('필터링 최소값')}}</span>

        <valid-input
        :vid="'필터링 최소값'"
					:inputType="'number'"
					:inputValue.sync="model.filterMinValue"
					:rules="rules.POINT_SET_MIN_RULE"
					:errorMessage="validMessage.COMMON_DECIMAL_NUMBER_LENGTH_VALID_MESSAGE(16)"
          @inputMethod="bindNumber"
				>
				</valid-input>

        <!-- <b-form-input type="number" @input="bindNumber" :ref="'filterMinValue'" class="form-control" v-model="model.filterMinValue"/> -->
        
      </div>
      
      <div class="col text-left contens-col">
        <span class="h5 mb-0">{{$t('필터링 최대값')}}</span>

        <valid-input
        :vid="'필터링 최대값'"
					:inputType="'number'"
					:inputValue.sync="model.filterMaxValue"
					:rules="rules.POINT_SET_MAX_RULE"
					:errorMessage="validMessage.COMMON_DECIMAL_NUMBER_LENGTH_VALID_MESSAGE(16)"
          @inputMethod="bindNumber"
				>
				</valid-input>


        <!-- <b-form-input type="number" @input="bindNumber" :ref="'filterMaxValue'" class="form-control" v-model="model.filterMaxValue"/> -->
        
      </div>
      
      <div class="col text-left contens-col">
        <span class="h5 mb-0">{{$t('고정값')}}</span>
        <valid-input
        :vid="'고정값'"
					:inputType="'number'"
					:inputValue.sync="model.fixValue"
					:rules="rules.POINT_SET_FIXED_RULE"
          @inputMethod="bindNumber"
				>
				</valid-input>

        <!-- <b-form-input type="number" @input="bindNumber" :ref="'fixValue'" class="form-control" v-model="model.fixValue"/> -->
      </div>
    </div>
    
    <div class="row row-cols-5">
      <div class="col text-left contens-col">
        <span class="h5 mb-0">{{$t('사용여부')}}</span>
        <select class="form-control" :ref="'enabled'" v-model="model.enabled">
          <option value="">{{$t('선택')}}</option>
          <option value="Y">Y</option>
          <option value="N">N</option>
        </select>
      </div>
      
      <div class="col text-left contens-col">
        <span class="h5 mb-0">{{$t('집계여부')}}</span>
        <!-- <i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px'}">help</i> -->
        <select class="form-control" :ref="'agrgtnYn'" v-model="model.agrgtnYn">
          <option value="">{{$t('선택')}}</option>
          <option value="Y">Y</option>
          <option value="N">N</option>
        </select>
      </div>
      
      <div class="col text-left contens-col">
        <span class="h5 mb-0">{{$t('변경시 반영')}}</span>
        <!-- <i id="question-target-1" class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px'}">help</i> -->
        <select class="form-control" :ref="'isCov'" v-model="model.isCov">
          <option value="">{{$t('선택')}}</option>
          <option v-for="(data, index) in isCovList" :key="index" :value="data.smallCode">
            {{ data.smallCodeName }}
          </option>
        </select>
       
      </div>
      <!-- <b-popover target="question-target-1" triggers="hover" placement="top">
          <template #title>Popover Title</template>
          I am popover <b>component</b> content!
        </b-popover> -->
      <div class="col text-left contens-col">
        <span class="h5 mb-0">{{$t('0값 무시')}}</span>
        <!-- <i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px'}">help</i> -->
        <select class="form-control" :ref="'isIgnoreZero'" v-model="model.isIgnoreZero">
          <option value="">{{$t('선택')}}</option>
          <option v-for="(data, index) in isIgnoreZeroList" :key="index" :value="data.smallCode">
            {{ data.smallCodeName }}
          </option>
        </select>
      </div>
      
      <div class="col">
        
      </div>
    </div>
    
  </div>
  
</template>

<style scoped>
.form-control-name{
  width: 100%;
  height: calc(1.5em + 14px + 2px);
  padding: 7px 0.75rem;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2d353c;
  background-color: #fff;
}

.GridSetting .contens-col i{
  vertical-align: text-bottom;
}
</style>

<script>
import backEndApi from "@api/backEndApi.js";
import xelib from "xelib";
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
import * as popupMessages from "@src/consts/popupMessageConsts"
import AppErrorHandler from "@src/appErrorHandler";

const { Unit } = xelib.PointBox;

const unitOptions = (function () {
  return Object.keys(Unit.types).map((type, i) => {
    let children = Object.keys(Unit.types[type]).map((v) => {
      // return { id: i}-${j, label: v };
      return { id: v, label: v };
    });

    return { id: i, label: type, children: children };
  });
})();

export default {
  components: {
  },
  props: ["data"],
  watch: {

  },
  data() {
    return {
      unitOptions: unitOptions,
      newPoint: {},

      searchPointGroup: null,

      isVirtualList: null,
      ptTypeCodeList: null,
      valueTypeCodeList: null,
      purpsUseCodeList: null,
      dataCreateCodeList: null,
      dataSourceCodeList: null,
      itemUseageCodeList: null,
      itemPropCodeList: null,
      energySourceCodeList: null,
      trgthingCodeList: null,
      renewTypeCodeList: null,
      orgUnitDvsnCodeList: null,
      instmnPeriodCodeList: null,
      agrgtnTargetCodeList: null,
      unitLargeCodeList: null,
      unitSmallCodeList: null,
      covUnitLargeCodeList: null,
      covUnitSmallCodeList: null,

      isCovList: null,
      isIgnoreZeroList: null,

      equipTypeList: null,


      model: {
        filterMinValue: 0,
        filterMaxValue: 0,
      },

      // define the default value
      zoneIdx: null,
      // define options
      options: null,
      rules,
      validMessage
    };
  },
  computed: {

  },
  async created() {
    await this.getSearchPointGroup();
    await this.getSmallCodeByPoint();
    await this.getZoneListHierarchi();
    await this.getUnitLargeCodeList();
    await this.getEquipTypeList();

    await this.getUnitSmallCodeList(this.data.unitLargeCode);
    await this.getCovUnitSmallCodeList(this.data.covUnitLargeCode);

    await this.initSetting();

  },
  async mounted() {
    this.setModel();
  },
  methods: {
    async initSetting() {
      // 기존 initSetting 그대로 복붙

      this.$store.dispatch("UPDATE_PT_MAP");

      this.newPoint = { ptGroup: '', max: 0, min: 0, isVirtual: false, ptType: 'LST', valueType: 'analog', overflow: 0, isCov: true };

      if (this.isUpdate) {

        // 이전 리비전과 소스 에서 아래와 같이 변경함.
        /*           
          ↓↓↓↓↓
        */
        try {
          const result = await backEndApi.pointInfo.getPoint(this.ptAddr);
          const data = result.data;

          if (this.$err(data)) {
            this.alertNoti(popupMessages.COMMON_SEARCH_NO_DATA_POPUP_MESSAGE);
            return;
          }
          this.newPoint = data;

          // undefined 상태일때, tree-select에서 unknown으로 표시되는 현상 방지...
          if (this.isEmpty(this.newPoint.unit)) this.newPoint.unit = null;
          if (this.isEmpty(this.newPoint.rawUnit)) this.newPoint.rawUnit = null;
        } catch (err) {
          new AppErrorHandler(err)
            .printErrorLog()
            .errHandling();
        }
      }
    },
    setModel() {

      this.model = this.data;

      console.log(this.model);
    },

    async getEquipTypeList() {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.equipType.getEquipTypeList();
        this.equipTypeList = result.data;

      } catch (err) {
        new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
      }
    },
    async getSearchPointGroup() {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.pointInfo.searchPointGroup();
        this.searchPointGroup = result.data;

      } catch (err) {
        new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
      }
    },
    async getSmallCodeByPoint() {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.publicCode.getSmallCodeByPoint();
        const data = result.data;

        this.smallCodeByPoint = data;

        // 관제점 유형 select box
        this.isVirtualList = data.filter(data => data.largeCode == "isVirtual");

        // 집계 유형 select box
        this.ptTypeCodeList = data.filter(data => data.largeCode == "ptTypeCode");

        // 계측값 타입 select box
        this.valueTypeCodeList = data.filter(data => data.largeCode == "valueTypeCode");

        // 활용목적 select box
        this.purpsUseCodeList = data.filter(data => data.largeCode == "purpsUseCode");

        // 자료생성 select box
        this.dataCreateCodeList = data.filter(data => data.largeCode == "dataCreateCode");

        // 자료획득원 select box
        this.dataSourceCodeList = data.filter(data => data.largeCode == "dataSourceCode");

        // 관제점 용도 select box
        this.itemUseageCodeList = data.filter(data => data.largeCode == "itemUseageCode");

        // 관제점 속성 select box
        this.itemPropCodeList = data.filter(data => data.largeCode == "itemPropCode");

        // 에너지원 select box
        this.energySourceCodeList = data.filter(data => data.largeCode == "energySourceCode");

        // 대상물질 select box
        this.trgthingCodeList = data.filter(data => data.largeCode == "trgthingCode");

        // 에너지 생성타입 select box
        this.renewTypeCodeList = data.filter(data => data.largeCode == "renewTypeCode");

        // 원단위 구분 select box
        this.orgUnitDvsnCodeList = data.filter(data => data.largeCode == "orgUnitDvsnCode");

        // 계측 주기 select box
        this.instmnPeriodCodeList = data.filter(data => data.largeCode == "instmnPeriodCode");

        // 집계대상 select box
        this.agrgtnTargetCodeList = data.filter(data => data.largeCode == "agrgtnTargetCode");

        // 단위 대분류 select box
        // this.unitLargeCodeList = data.filter(data => data.largeCode == "unitLargeCode");

        // 단위 소분류 select box 
        // this.unitSmallCodeList = data.filter(data => data.largeCode == "unitSmallCode");

        // 변환 단위 대분류 select box
        // this.covUnitLargeCodeList = data.filter(data => data.largeCode == "covUnitLargeCode");

        // 변환 단위 소분류 select box
        // this.covUnitSmallCodeList = data.filter(data => data.largeCode == "covUnitSmallCode");

        this.isIgnoreZeroList = data.filter(data => data.largeCode == "isIgnoreZero");
        this.isCovList = data.filter(data => data.largeCode == "isCov");

      } catch (err) {
        new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
      }

    },
    async getUnitLargeCodeList() {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.unitCode.getUnitLargeCodeList();
        const data = result.data;

        // 단위 대분류 select box
        this.unitLargeCodeList = data;
        this.covUnitLargeCodeList = data;

      } catch (err) {
        new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
      }
    },
    async getUnitSmallCodeList(unitLargeCode) {
      // 단위 소분류

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */

      if(this.isEmpty(unitLargeCode)) return;

      try {
        const result = await backEndApi.unitCode.getUnitSmallCodeList(unitLargeCode);
        this.unitSmallCodeList = result.data;
      } catch (err) {
        new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
      }
    },
    async getCovUnitSmallCodeList(covUnitLargeCode) {

      // 변환 단위 소분류

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        -

      */

      if(this.isEmpty(covUnitLargeCode)) return;

      try {
        const result = await backEndApi.unitCode.getUnitSmallCodeList(covUnitLargeCode);
        const data = result.data;

        this.covUnitSmallCodeList = data;

      } catch (err) {
        new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
      }
    },
    async getZoneListHierarchi() {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.zoneInfo.getZoneListHierarchi();
        const data = result.data;

        this.options = [];

        let temp = [];
        temp.push(data);
        this.options = this.setZoneTreeOption(temp);

      } catch (err) {
        new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
      }
    },
    setZoneTreeOption(resultData) {

      resultData.forEach((data) => {

        data.id = data.zoneIdx;
        data.label = data.zoneName;

        if (data["children"]) {
          return this.setZoneTreeOption(data["children"]);
        } else {
          return data;
        }
      })

      return resultData;
    },
    onZoneSelected(node) {
      this.zoneIdx = node.id;
    },
    isValidation() {
      var flag = true;

      if (!this.$refs.ptAddr.value) {
        this.alertNoti(popupMessages.POINT_INPUT_POINT_ADDRESS_POPUP_MESSAGE);
        this.$refs.ptAddr.focus();
        flag = false;
      } else if (!this.$refs.ptName.value) {
        this.alertNoti(popupMessages.POINT_INPUT_POINT_NAME_POPUP_MESSAGE);
        this.$refs.ptName.focus();
        flag = false;
      } else if (!this.$refs.isVirtual.value) {
        this.alertNoti(popupMessages.POINT_SELECT_POINT_TYPE_POPUP_MESSAGE);
        this.$refs.isVirtual.focus();
        flag = false;
      } else if (!this.$refs.ptTypeCode.value) {
        this.alertNoti(popupMessages.POINT_SELECT_TOTAL_TYPE_POPUP_MESSAGE);
        this.$refs.ptTypeCode.focus();
        flag = false;
      } else if (!this.$refs.valueTypeCode.value) {
        this.alertNoti(popupMessages.POINT_SELECT_MEASUREMENT_VALUE_TYPE_POPUP_MESSAGE);
        this.$refs.valueTypeCode.focus();
        flag = false;
      } else if (!this.$refs.purpsUseCode.value) {
        this.alertNoti(popupMessages.POINT_SELECT_PURPOSE_USE_POPUP_MESSAGE);
        this.$refs.purpsUseCode.focus();
        flag = false;
      } else if (!this.$refs.dataCreateCode.value) {
        this.alertNoti(popupMessages.POINT_SELECT_CREATE_DATA_POPUP_MESSAGE);
        this.$refs.dataCreateCode.focus();
        flag = false;
      } else if (!this.$refs.dataSourceCode.value) {
        this.alertNoti(popupMessages.POINT_SELECT_DATA_SOURCE_POPUP_MESSAGE);
        this.$refs.dataSourceCode.focus();
        flag = false;
      } else if (!this.$refs.enabled.value) {
        this.alertNoti(popupMessages.COMMON_USAGE_VALID_POPUP_MESSAGE);
        this.$refs.enabled.focus();
        flag = false;
      }
      return flag;
    },
    async onRemove() {
      console.log("Grid detail : onRemove");

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const popupResult = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
        if (!popupResult.value)
          return;

        const result = await backEndApi.pointInfo.deletePointInfo(this.model.ptIdx);
        console.log(result.data);
        this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
        this.$emit("reSearch");

      } catch (err) {
        new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
      }

    },
    bindNumber(event) {
      let number = event;

      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;

      // 한글, 영문 체크
      if (reg.exec(number) !== null) {
        event = number.replace(/[^0-9]/g, '');
      }

      // .... 만 입력하게 될 경우 체크
      if (isNaN(parseFloat(number))) {
        event = "";
      }
    },
    async onChangeUnitLargeCode() {
      if (this.model.unitLargeCode) {
        await this.getUnitSmallCodeList(this.model.unitLargeCode);
      } else {
        this.unitSmallCodeList = null;
        this.model.unitSmallCode = "";
      }

    },
    async onChangeCovUnitLargeCode() {
      if (this.model.covUnitLargeCode) {
        await this.getCovUnitSmallCodeList(this.model.covUnitLargeCode);
      } else {
        this.covUnitSmallCodeList = null;
        this.model.covUnitSmallCode = "";
      }
    },
    loadOptions({ callback }) {
      callback();
    }
  }
};
</script>

<template>
  <b-modal ref="themeModal" :hide-footer="true" :title="$t('테마설정')">
    <div class="row form-group">
      <div class="col-6">
        <h4>Theme: <b>{{ theme }}</b> </h4>
      </div>
      <div class="col-6">
        <h4> Header: <b>{{ labelTheme }} </b> </h4>
      </div>
    </div>

    <div class="row form-group" style="justify-content: center;">
      <div class="col-4">
        <xe-checkbox v-model="contentInverse" :label="contentInverse ? 'Dark Mode':'Light Mode'" tooltip="Content Inverse" @change="onChangeContentInverse" />
      </div>
    </div>

    <div class="row form-group">
      <div class="col-12">
        <b-card-body class="p-0">
          <div class="theme-box">
            <div class="theme-box-content">
              <div class="theme-version">
                <a href="javascript:;" :class="{ active: $theme === 'default' }" @click="onChangeTheme('default')">
                  <span style="background-image: url(/assets/img/theme/default.jpg);"></span>
                </a>
              </div>
            </div>
          </div>
        </b-card-body>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <button class="btn btn-primary pull-right" @click="onApply"> {{ $t('적용')}} </button>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>
.theme-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* theme-panel 커스터마이징 안되서 theme-box를 새로 만듬..*/
.theme-box .theme-version {
  margin: 0 5px 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: column;
  display: flex;
  align-content: center;
}
.theme-version a {
  margin: 0px 5px 0px 0px;
}
.theme-box .theme-version a {
  width: calc(50% - 5px);
  padding-top: 35%;
  position: relative;
  text-decoration: none;
}
.theme-box .theme-version a span {
  font-weight: 700;
  margin: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top left;
}
.theme-box .theme-list > li.active > a:before,
.theme-box .theme-version a span {
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

.theme-box .theme-version a.active:before {
  border-left-color: #007aff;
  border-top-color: #007aff;
}
.theme-box .theme-version a.active:before,
.theme-box .theme-version a:hover:before {
  content: "";
  position: absolute;
  border: 12px solid transparent;
  left: 0;
  top: 0;
  z-index: 10;
}
.theme-box .theme-version a.active:after,
.theme-box .theme-version a:hover:after {
  content: "\f00c";
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  z-index: 10;
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 10px;
  text-align: center;
  font-family: Font awesome\5 Free, Font awesome\5 Pro, FontAwesome !important;
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}
</style>

<script>
//import PageOptions from "../../config/PageOptions.vue";

//      if(theme == "default")     PageOptions.pageHeaderInverseMode = true;
// else if(theme == "facebook")    PageOptions.pageHeaderInverseMode = true;
// else if(theme == "material")    PageOptions.pageHeaderInverseMode = true;
// else if(theme == "transparent") PageOptions.pageHeaderInverseMode = false;
// else if(theme == "apple")       PageOptions.pageHeaderInverseMode = true;
// else                            PageOptions.pageHeaderInverseMode = false;

export default {
  name: "ThemeSelector",
  data() {
    return {
      //pageOptions: PageOptions,

      themes: ["default", "facebook", "material", "transparent", "apple" ],
      labelThemes: ["aqua", "black", "blue", "green", "indigo", "lime", "orange", "pink", "purple", "red", "teal", "yellow"],

      theme: "black",
      labelTheme: "",
      headerInverse: false,
      contentInverse: false,
    };
  },
  mounted(){
    this.initSetting();
  },
  methods: {
    initSetting(){ 

      this.theme = localStorage.theme;
      this.labelTheme = localStorage.labelTheme;
      this.headerInverse = global.xe.parseBool(localStorage.headerInverse);
      this.contentInverse = global.xe.parseBool(localStorage.contentInverse);
    },
    show() {
      this.$refs.themeModal.show();
    },
    onChangeTheme(theme) {
      if(this.isEmpty(theme)) return;

      this.theme = theme;
    },
    onChangeLabelTheme(labelTheme) {
      if(this.isEmpty(labelTheme)) return;

      this.labelTheme = labelTheme;
    },
    onChangeHeaderInverse(checked){
      this.pageOptions.pageHeaderInverseMode = checked;
    },
    onChangeContentInverse(checked){

      this.pageOptions.pageContentInverseMode = checked;
    },
    onApply() {
      localStorage.theme = this.theme;// 전체 테마
      localStorage.labelTheme = this.labelTheme;// 헤더 색상 테마
      localStorage.headerInverse = this.headerInverse; // 헤더 반전 모드 pageOptions.pageHeaderInverseMode
      localStorage.contentInverse = this.contentInverse; // 내용 반전 모드 pageOptions.pageContentInverseMode
      this.$router.go("/");

      // this.pageOptions.pageHeaderInverseMode = this.headerInverse;
      // this.pageOptions.pageContentInverseMode = this.contentInverse;

      // // 테마 css 는 동적으로 변경되어야 하기 때문에, lazy loading함.
      // import(`../../assets/css/${this.theme}/app.min.css`)
      //   .then(() => {
      //     return import(`../../assets/css/${this.theme}/theme/${this.labelTheme}.min.css`);
      //   })
      //   .then(() => {
      //     return import(`../../assets/css/v-${this.theme}.css`);
      //   });
    },
  },
};
</script>

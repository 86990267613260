import ActionIconComponent from "./ActionIcon.vue";

const ActionIcon = {
  install(Vue) {
    //Vue.component(ActionIconComponent.name, ActionIconComponent);
    Vue.component(ActionIconComponent.name, () => import("./ActionIcon.vue"));
    
  },
};

export default ActionIcon;

<template>
	<div>
		<div style="width:calc(100% - 80px); float:left;">
			<tree-select
				v-model="pt.ptAddr"
				:load-options="loadOptions"
				:multiple="false"
				:always-open="false"
				:placeholder="$t(controlMessages.POINT_TREE_PLACEHOLDER_MESSAGE)"
				:noChildrenText="$t(controlMessages.POINT_TREE_NOCHILDERN_MESSAGE)"
				:noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)"
				:options="options"
				:disable-branch-nodes="true"
			>
				<div slot="value-label" slot-scope="{ node }">{{ node.raw.customLabel }}</div>
				<label slot="option-label" slot-scope="{ node }">
					{{ node.label }}
				</label>
			</tree-select>
		</div>
		<div :style="{ width: '40px', float: 'left', paddingTop: '1px', paddingBottom: '1px' }">
			<button class="btn btn-default bg-white h-100" @click="openPointLogModal" v-b-tooltip.hover.top :title="$t('관제점 상세')" :style="{ border: '1px solid #d5dbe0' }">
				<i class="fa fa-asterisk" />
			</button>
		</div>
		<div :style="{ width: '40px', float: 'left', paddingTop: '1px', paddingBottom: '1px' }">
			<b-dropdown right variant="default" toggle-class="btn-white" class="pull-right">
				<b-dropdown-item
					@click="
						mode = 'dual';
						refreshTotalReal();
					"
				>
					<i class="fa m-r-10" :class="{ 'fa-check': mode == 'dual' }"></i>
					<label> {{ $t("둘다") }} </label>
				</b-dropdown-item>
				<b-dropdown-item
					@click="
						mode = 'addr';
						refreshTotalReal();
					"
				>
					<i class="fa m-r-10" :class="{ 'fa-check': mode == 'addr' }"></i>
					<label> {{ $t("관제점 주소") }} </label>
				</b-dropdown-item>
				<b-dropdown-item
					@click="
						mode = 'name';
						refreshTotalReal();
					"
				>
					<i class="fa m-r-10" :class="{ 'fa-check': mode == 'name' }"></i>
					<label> {{ $t("관제점명") }} </label>
				</b-dropdown-item>
			</b-dropdown>
		</div>
		<b-modal ref="pointModal" :hide-footer="true" :title="$t('관제점 상세')">
			<PointLogModal :ptAddr="pt.ptAddr"></PointLogModal>
		</b-modal>
	</div>
</template>

<script>
	import backEndApi from "/src/api/backEndApi.js";
	import PointLogModal from "@/views/pointManagement/point/PointLogModal.vue";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import * as controlMessages from "@src/consts/controlMessageConsts";

	// TODO: simulation/components/PtSelect는 ptIdx로 찾음.. 두개를 하나로 병합하고, 공통 라이브러리로 바꿔야 함.

	export default {
		props: ["pt"],
		components: {
			PointLogModal,
		},
		data() {
			return {
				ptList: [],
				// define options
				options: null,
				searchPointText: "",
				dragPoints: [],

				mode: "addr",
				popupMessages,
				controlMessages,
			};
		},
		computed: {
			isEditMode() {
				return this.$store.getters.isEditMode;
			},
			isAuthenticated() {
				return this.$store.getters.isAuthenticated;
			},
		},
		mounted() {
			this.initSetting();
		},
		methods: {
			initSetting() {
				this.searchPoint().then(() => {
					this.refreshTotalReal();
				});
			},
			async searchPoint() {
				// store에서 포인트목록을 이용하여, 그룹을 생성한다.
				this.ptList = await backEndApi.pointInfo.searchPoint().then(({ data }) => {
					if (this.$err(data)) return [];
					return data;
				});
			},
			openPointLogModal() {
				this.$refs.pointModal.show();
			},
			refreshTotalReal() {
				if (!this.isAuthenticated) return;

				let ptGroups = this.ptList
					.map((pt) => {
						return pt.groupCode || "Etc";
					})
					.filter((v) => v)
					.unique()
					.sort();

				// 포인트그룹에 그룹내 포인트정보를
				this.dragPoints = ptGroups.map((ptGroup) => {
					let arr = this.ptList
						.map((pt) => {
							if ((pt.ptGroup || "_ETC_") === ptGroup) return { type: this.CODE.Drag.Point, title: pt.ptName, ptAddr: pt.ptAddr, ptVal: pt.ptVal };
						})
						.filter((v) => v);
					console.log(arr);
					return {
						type: this.CODE.Drag.PointGroup,
						group: ptGroup,
						points: arr,
					};
				});

				this.options = [];

				backEndApi.pointInfo.getpointByGroup().then(({ data }) => {
					this.searchPoint = data;

					this.searchPoint.map((point) => {
						const pointList = point.pointList;
						const groupObject = {
							id: point.groupCode,
							label: point.groupCodeName,
							children: [],
						};

						for (let i = 0; i < pointList.length; i++) {
							let label = "";

							if (this.mode == "dual") label = pointList[i].ptName + " [" + pointList[i].ptAddr + "]";
							else if (this.mode == "name") label = pointList[i].ptName;
							else label = `${pointList[i].ptName}(${pointList[i].ptAddr})`;

							let inputLabel = "";
              // 관제점 그룹 - 관제점 주소
							// inputLabel = pointList[i].ptGroup + " - " + label;
              // 관제점 이름(관제점 주소)
							inputLabel = label;

							groupObject.children.push({
								id: pointList[i].ptAddr,
								label: pointList[i].ptName,
								customLabel: inputLabel,
							});
						}

						this.options.push(groupObject);
					});

					this.options.sort((a, b) => {
						return a.label.localeCompare(b.label);
					});

					this.options.unshift({
						id: "",
						label: global.xe.$t("전체"),
					});

					this.applySearchPoint = "";
				});

				// this.options = ptGroups.map((ptGroup, i) => {
				//   let children = [];

				//   children = this.ptList.map((pt) => {
				//     if ((pt.ptGroup || "_ETC_") === ptGroup) {
				//       let label = "";

				//       if (this.mode == "dual") label = pt.ptName + " [" + pt.ptAddr + "]";
				//       else if (this.mode == "name") label = pt.ptName;
				//       else label = pt.ptAddr;

				//       let inputLabel = "";
				//       inputLabel = pt.ptGroup + " - " + label;

				//       return {
				//         type: this.CODE.Drag.Point,
				//         id: pt.ptAddr,
				//         customLabel: inputLabel,
				//         label: label,
				//       };
				//     }
				//   }).filter((v) => v);

				//   return {
				//     type: this.CODE.Drag.PointGroup,
				//     id: "G" + i,
				//     label: ptGroup,
				//     children: children,
				//   };
				// });

				// this.blink(this.ui);
			},
			handleBodyDragOver(data, event) {
				if (data.item.type === this.CODE.Drag.Point || data.item.type === this.CODE.Drag.PointGroup) event.dataTransfer.dropEffect = "move";
				else event.dataTransfer.dropEffect = "none";
			},
			loadOptions({ callback }) {
				callback();
			},
		},
	};
</script>

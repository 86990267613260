import ColorPickerComponent from "./ColorPicker.vue";

const ColorPicker = {
  install(Vue) {
    //Vue.component(ColorPickerComponent.name, ColorPickerComponent);
    Vue.component(ColorPickerComponent.name, () => import("./ColorPicker.vue"));
  },
};

export default ColorPicker;

const popupMessages = require("@src/consts/popupMessageConsts")

export default {
	dateLengthCheck(timeDsvn, fromDate, toDate) {
		let formatFromDate = "";
		let formatTodate = "";

		if (timeDsvn === "Log" || timeDsvn === "Min") {
			formatFromDate = fromDate;
			formatTodate = toDate;
		} else if (timeDsvn === "Year" || timeDsvn === 'Week') {
			formatFromDate = fromDate.substring(0, 4) + "-" + fromDate.substring(4, 6) + "-01";
			formatTodate = toDate.substring(0, 4) + "-" + toDate.substring(4, 6) + `-${new Date(Number(toDate.substring(0, 4)), Number(toDate.substring(4, 6)) + 1, 0).getDate()}`;
		} else if (timeDsvn === "Yearly") {
			formatFromDate = fromDate.substring(0, 4) + "-01-01";
			formatTodate = toDate.substring(0, 4) + "-12-31";
		} else {
			formatFromDate = fromDate.substring(0, 4) + "-" + fromDate.substring(4, 6) + "-" + fromDate.substring(6, 8);
			formatTodate = toDate.substring(0, 4) + "-" + toDate.substring(4, 6) + "-" + toDate.substring(6, 8);
		}

		let from = new Date(formatFromDate);
		let to = new Date(formatTodate);
    from.setMilliseconds(0);
    from.setSeconds(0);
    to.setMilliseconds(0);
    to.setSeconds(0);

		let diff = to.getTime() - from.getTime();
		let typediffValue = 0;
    // console.log(from, to);
		if (timeDsvn === "Year" || timeDsvn === "Week") {
			typediffValue = this.MonthDayCalc(from);
		} else if (timeDsvn === "Yearly") {
			typediffValue = 31 * 12;
		} else {
			typediffValue = 1;
		}

		let diffDay = Math.abs(diff);
		diffDay = (diff / (1000 * 60 * 60 * 24 * typediffValue));

		// let diffDay = Math.floor(Math.abs(diff / (1000 * 60 * 60 * 24 * typediffValue)));

		// console.log(diff, diffDay);
		return { diff, diffDay, from };
	},

	wrongDateMessage(timeDsvn, diffDay, fromDate) {
		console.log(timeDsvn, diffDay);
    // console.log(this.MonthDayCalc(fromDate));
		let message = "";
		switch (timeDsvn) {
			case "Log":
				if (diffDay > 1) {
					console.log("시간 범위 초과");
					message = popupMessages.ENERGY_SEARCH_MIN_POPUP_MESSAGE;
				}
				break;
			case "Min":
				if (diffDay > 1) {
					console.log("시간 범위 초과");
					message = popupMessages.ENERGY_SEARCH_MIN_POPUP_MESSAGE;
				}
				break;
			case "Day":
				if (diffDay > this.MonthDayCalc(fromDate)) {
					console.log("시간 범위 초과");
					message = popupMessages.ENERGY_SEARCH_HOUR_POPUP_MESSAGE;
				}
				break;
			case "Week":
				if (diffDay > 120) {
					console.log("주간 범위 초과");
					message = popupMessages.ENERGY_SEARCH_WEEK_POPUP_MESSAGE;
				}
				break;
			case "Month":
				if (diffDay > 365) {
					console.log("일 범위 초과");
					message = popupMessages.ENERGY_SEARCH_DAY_POPUP_MESSAGE;
				}
				break;
			case "Year":
				if (diffDay > 120) {
					console.log("월 범위 초과");
					message = popupMessages.ENERGY_SEARCH_MONTH_POPUP_MESSAGE;
				}
				break;
			case "Yearly":
				if (diffDay > 11) {
					console.log("연 범위 초과");
					message = popupMessages.ENERGY_SEARCH_YEAR_POPUP_MESSAGE;
				}
				break;
			default:
				message = "";
				break;
		}

		return message;
	},

  MonthDayCalc(from){
    // console.log(from);
    // console.log(new Date(from.getFullYear(), from.getMonth()+1, 0));
    // console.log(new Date(from.getFullYear(), from.getMonth()+1, 0).getDate());
    return new Date(from.getFullYear(), from.getMonth()+1, 0).getDate();
  },

	searchParamValCheck(param) {
		let message = "";

		if (param.fromDate > param.toDate) {
			message = popupMessages.COMMON_CHART_SEARCH_ERROR_POPUP_MESSAGE;
		}

		// if(param.fromDate > param.toDate){
		//   alert('선택 시간이 잘못되었습니다. 다시 한번 확인해주세요.');
		//   return true;
		// }
		return message;
	},

	previousDay(timeDsvn) {
		if (timeDsvn === "Hour" || timeDsvn === "Day" || timeDsvn === "Month") {
			return true;
		}

		return false;
	},
	previousMonth(timeDsvn) {
		if (timeDsvn === "Hour" || timeDsvn === "Day" || timeDsvn === "Month" || timeDsvn === "Week" || timeDsvn === "Year") {
			return true;
		}

		return false;
	},
	previousYear(timeDsvn) {
		if (timeDsvn === "Hour" || timeDsvn === "Day" || timeDsvn === "Month" || timeDsvn === "Week" || timeDsvn === "Year") {
			return true;
		}

		return false;
	},
};

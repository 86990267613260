<script>
export default {
  name: 'globalTimerLogout',
  computed: {
    isLogout(){
      return this.$store.getters.getisAutoLogout;
    }
  },
  watch:{
    isLogout(value){
      if(value === true){
        this.alertDanger(global.xe.$t("장기간 동작이 없어 자동 로그아웃 됩니다."));
        this.$router.push("/login");
      }
    }
  }
}

</script>
<template>
  <tree 
    v-if="Array.isArray(treeData) && treeData.length > 0 && isShow" 
    :data="treeData" 
    :options="{ propertyNames: { text: 'name' }, dnd: false }" 
    @node:dragging:finish="dragFinish" 
    @node:selected="onNodeSelected" 
  />
</template>

<script>
import LiquorTree from "liquor-tree";

export default {
  name: "xeWorkflowTree",
  components: {
    [LiquorTree.name]: LiquorTree,
  },
  props: ["workflows"],
  data() {
    return {
      isShow: true,
    };
  },
  computed: {
    treeData() {
      let arr = [];

      if (!Array.isArray(this.workflows)) return arr;

      this.workflows.forEach((node) => {
        if (node.workflow && node.workflow.children) {
          let startNode = {
            name: node.name,
            children: [],
            data: node,
            state: {
              expanded: true,
              selected: true,
            },
          };

          node.workflow.children.forTree((child) => {
            if (child.uplType == "Group") {
              let subs = child.children.map((upl) => {
                return { name: upl.name, data: upl };
              });

              // TODO: 모든 자식노드를 루트노드에만 등록하고 있음...   재귀적으로 처리해야 할텐데....  드래그 기능 일단 꺼놓음...
              startNode.children.push({ name: child.name, data: child, children: subs });
            }
          });

          // 첫번째 workgroup을 열어둔다.
          // if (startNode.children.length > 0) {
          //   console.log(startNode);
          //   let first = startNode.children.first();
          //   if (this.isEmpty(first["state"])) first["state"] = {};
          //   first.state.expanded = true;
          //   first.state.selected = true;
          // }

          arr.push(startNode);
        }
      });
      return arr;
    },
  },
  watch: {
    treeData() {
      this.isShow = false;
      this.$nextTick(() => {
        this.isShow = true;
      });
    },
  },
  methods: {
    dragFinish(node, destinationNode) {
      console.log("node, destinationNode", node, destinationNode);
    },
    onNodeSelected(node) {
      this.$emit("select-node", node);
    },
  },
};
</script>

<template>
	<div class="row align-items-end Search_New" style="padding: 10px 10px 10px 0;">
		<div class="font-weight-bold col p-r-0" :style="{ fontSize: '11px', maxWidth: '150px' }">
			<div>
				<span>{{$t('관제점 유형')}}</span>
			</div>
			<div class="">
				<select class="form-control" :ref="'isVirtual'" v-model="isVirtual" @change="onChangeCombo">
					<option value="">{{$t('전체')}}</option>
					<option v-for="(data, index) in valueTypeList" :key="index" :value="data.smallCode">
						{{ data.smallCodeName }}
					</option>
				</select>
			</div>
		</div>
		<div class="font-weight-bold col p-r-0" :style="{ fontSize: '11px', maxWidth: '200px' }">
			<div>
				<span>{{$t('관제점 그룹')}}</span>
			</div>
			<div class="">
				<select class="form-control" :ref="'pointGroup'" @change="onChangeCombo">
					<option value="">{{$t('전체')}}</option>
					<option v-for="(data, index) in pointGroupList" :key="index" :value="data.groupCode">
						{{ data.groupCodeName }}
					</option>
				</select>
			</div>
		</div>
		<div class="font-weight-bold col p-r-0" :style="{ fontSize: '11px', maxWidth: '110px' }">
			<div>
				<span>{{$t('에너지원')}}</span>
			</div>
			<div class="">
				<select class="form-control" ref="energySourceCode" @change="onChangeCombo">
					<option value="">{{$t('전체')}}</option>
					<option v-for="(data, index) in energySourceCodeList" :key="index" :value="data.smallCode">
						{{ data.smallCodeName }}
					</option>
				</select>
			</div>
		</div>
		<div class="font-weight-bold col p-r-0" :style="{ fontSize: '11px', maxWidth: '110px' }">
			<div>
				<span>{{$t('구역')}}</span>
			</div>
			<div class="" :style="{ minWidth: '100px', maxWidth: '200px', fontWeight: 'normal' }">
				<select class="form-control" :ref="'zoneDvsnCode'" v-model="zoneIdx" @change="onChangeCombo">
					<option v-for="(data, index) in zoneInfoListOption" :key="index" :value="data.zoneIdx">
						{{ data.zoneName }}
					</option>
				</select>
			</div>
		</div>
		<div class="font-weight-bold col p-r-0" :style="{ fontSize: '11px', maxWidth: '110px' }">
			<div>
				<span>{{$t('용도')}}</span>
			</div>
			<div class="">
				<select class="form-control" ref="itemUseageCode" @change="onChangeCombo">
					<option value="">{{$t('전체')}}</option>
					<option v-for="(data, index) in selectPointUsageList" :key="index" :value="data.smallCode">
						{{ data.smallCodeName }}
					</option>
				</select>
			</div>
		</div>
		<div class="font-weight-bold col p-r-0" :style="{ fontSize: '11px', maxWidth: '340px' }">
			<div>
				<span>{{$t('관제점')}}</span>
			</div>
			<div class="row pl-2" :style="{ fontWeight: 'normal' }">
				<tree-select
					class="col-9 pr-0 pl-0"
					v-model="applySearchPoint"
					:options="searchPointInfoOptions"
					:placeholder="$t(controlMessages.POINT_TREE_PLACEHOLDER_MESSAGE)"
					:noChildrenText="$t(controlMessages.POINT_TREE_NOCHILDERN_MESSAGE)"
					:noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)"
					:disable-branch-nodes="true"					
					:open-on-focus="true"
					@input="onChangeCombo"
				/>

				<button
					class="btn btn-default bg-white p-0 h-100"
					@click="openPointLogModal"
					v-b-tooltip.hover.top
					:title="$t('관제점 상세')"
					:disabled="applySearchPoint === null || applySearchPoint === ''"
					:style="{ marginTop: '1px', marginBottom: '1px' }"
				>
					<i class="fa fa-asterisk p-10" />
				</button>
				
			</div>
		</div>
		<button class="form-control MgmtSearchBtn m-r-10" @click="reSearch" :style="{ maxWidth: '70px' }">{{$t('조회')}}</button>
		<button class="form-control MgmtSearchBtn" @click="onNew" :style="{ maxWidth: '70px' }">{{$t('신규')}}</button>

		<b-modal ref="pointModal" :hide-footer="true" :title="$t('관제점 상세')">
			<PointLogModal :ptAddr="applySearchPoint"></PointLogModal>
		</b-modal>

		<div class="d-flex font-weight-bold col">
			
			
			<button class="form-control MgmtSearchBtn m-l-10" @click="onExcelDownload()" :style="{ maxWidth: '100px' }">{{$t('다운로드')}}</button>
		</div>
	</div>
</template>

<style>
	.Search_New .vue-treeselect__indent-level-1 .vue-treeselect__option {
		padding-left: 10px;
	}

	.MgmtSearchBtn {
		border: 2px solid rgb(52, 143, 226);
		border-radius: 10px !important;
		padding: 5px;
		height: 34px;
		text-align: center;
		background-color: rgb(255, 255, 255);
		align-items: center;
	}
</style>

<script>
import backEndApi from "../../../api/backEndApi";
import PointLogModal from "./PointLogModal.vue";
import * as popupMessages from "@src/consts/popupMessageConsts"
import * as controlMessages from "@src/consts/controlMessageConsts";
import AppErrorHandler from "@src/appErrorHandler";

export default {
	name: "PointMgmt",
	props: [],
	components: {
		PointLogModal,
	},
	watch: {},
	data() {
		return {
			searchPointGroup: null, // 관제점 그룹
			searchPoint: null, // 관제점 리스트
			searchPointInfoOptions: [],
			isVirtual: "",
			pointGroupList: null,
			smallCodeByPoint: null,

			valueTypeList: null,
			ptTypeCodeList: null,
			//  ptTypeList : null,
			itemUseageCodeList: null,
			phscPointUsageList: null,
			locPointUsageList: null,

			selectUsageList: null,

			energySourceCodeList: null,

			zoneInfoListOption: [],
			pointList: null,

			// define the default value
			zoneIdx: null,
			applySearchPoint: null,

			// define options
			options: null,
			popupMessages,
			controlMessages
		};
	},
	computed: {
		selectPointUsageList() {
			if (this.isVirtual === "0") {
				return this.phscPointUsageList;
			} else if (this.isVirtual === "1") {
				return this.locPointUsageList;
			} else {
				return [];
			}

			// return this.itemUseageCodeList;
		},
	},
	async created() {
		// 처음 진입 시에만 호출
		await this.init();
	},
	mounted() { },
	methods: {
		async init() {
			// 관제점 그룹 select box 초기 값
			await this.getPointGroupList();
			// 관제점 유형, 취합유형, 용도, 에너지원 select box 초기 값
			// this.getSmallCodeByPoint();
			await this.getCommonCodeList();
			await this.getZoneListHierarchi();
			await this.getPointList();
			await this.getSearchPoint("");
		},
		openPointLogModal() {
			this.$refs.pointModal.show();
		},
		async getPointGroupList() {

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {
				const result = await backEndApi.pointInfo.getPointGroupList();
				this.pointGroupList = result.data;

			} catch (err) {
				new AppErrorHandler(err)
					.printErrorLog()
					.errHandling();
			}
		},
		// getpointByGroup(){
		//   backEndApi.pointInfo.getpointByGroup().then(({data}) => {
		//     this.searchPointGroup = data;
		//   })
		// },
		async getSearchPoint() {
			this.searchPointInfoOptions = [];


			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {
				const result = await backEndApi.pointInfo.getpointByGroup();
				const data = result.data;

				this.searchPoint = data;

				this.searchPoint.map((point) => {
					const pointList = point.pointList;
					const groupObject = {
						id: point.groupCode,
						label: point.groupCodeName,
						children: [],
					};

					for (let i = 0; i < pointList.length; i++) {
						groupObject.children.push({
							id: pointList[i].ptAddr,
							label: pointList[i].ptName,
						});
					}

					this.searchPointInfoOptions.push(groupObject);
				});

				this.searchPointInfoOptions.sort((a, b) => {
					return a.label.localeCompare(b.label);
				});

				this.searchPointInfoOptions.unshift({
					id: "",
					label: global.xe.$t("전체"),
				});

				this.applySearchPoint = "";

			} catch (err) {
				new AppErrorHandler(err)
					.printErrorLog()
					.errHandling();
			}
		},
		commonSmallCodeList(commonCode) {
			let targetData = [];

			commonCode.map((v) => {
				if (v.smallCodeList) {
					v.smallCodeList.map((t) => {
						targetData.push(t);
					});
				}
			});

			return targetData;
		},
		async getCommonCodeList() {

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {
				const result = await backEndApi.codes.getCommonCodeList();
				const data = result.data;

				const publicCodes = data.publicCode;

				this.smallCodeByPoint = data;

				// 관제점 유형 select box
				// publicCodes.filter(data=> data.largetCode === 'virtualBounmd')
				this.valueTypeList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode === "isVirtual"));

				// 집계 유형 select box
				this.ptTypeCodeList = publicCodes.map((data) => data.largeCode == "ptTypeCode");

				// // 취합 유형 select box
				// this.ptTypeList = data.filter(data => data.largeCode == "ptType");

				// 용도 select box
				this.itemUseageCodeList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "itemUseageCode"));

				// 에너지원 select box
				this.energySourceCodeList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "energySourceCode"));

				// 물리 관제점 유형
				this.phscPointUsageList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "phscPointUsage"));

				// 가상(논리) 관제점 유형
				this.locPointUsageList = this.commonSmallCodeList(publicCodes.filter((data) => data.largeCode == "locPointUsage"));

			} catch (err) {
				new AppErrorHandler(err)
					.printErrorLog()
					.errHandling();
			}
		},
		async getZoneListHierarchi() {

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {
				const result = await backEndApi.zoneInfo.getZoneListHierarchi();
				const data = result.data;

				this.zoneInfoListOption = [];
				let temp = [];
				temp.push(data);
				this.options = this.setZoneTreeOption(temp);

			} catch (err) {
				new AppErrorHandler(err)
					.printErrorLog()
					.errHandling();
			} finally {
				this.zoneIdx = 1;
			}
		},
		setZoneTreeOption(resultData) {
			resultData.forEach((data) => {
				this.zoneInfoListOption.push(data);

				if (data["children"]) {
					this.setZoneTreeOption(data["children"]);
				}
			});
		},
		async getPointList() {
			this.pointList = null;

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {
				const result = await backEndApi.pointInfo.getPointListNew();
				const data = result.data;

				this.pointList = data;
				this.$emit("search:data", data);
			} catch (err) {
				new AppErrorHandler(err)
					.printErrorLog()
					.errHandling();
			}
		},
		onChangeCombo() {
			if (this.applySearchPoint === undefined) this.applySearchPoint = "";
			this.onSearch();
		},
		// 관제점 그룹 Event()
		async onChangePointGroup() {
			this.onSearch();
			await this.getSearchPoint(this.$refs.pointGroup.value);
		},
		onZoneSelected() {
			this.onSearch();
		},

		onSearch() {
			if (this.pointList === null) {
				return;
			}

			var isVirtual = this.$refs.isVirtual.value; // 관제점 유형 코드
			var pointGroup = this.$refs.pointGroup.value; // 관제점 그룹
			var itemUseageCode = this.$refs.itemUseageCode.value; // 관제점 용도 코드
			var energySourceCode = this.$refs.energySourceCode.value; // 에너지원 코드
			var zoneIdx = this.zoneIdx; // 구역 일련번호
			var keyword = this.applySearchPoint; // 관제점명, 관제점 주소

			var result = this.pointList
				.filter((data) => (isVirtual == "" ? data.isVirtual == data.isVirtual : data.isVirtual == isVirtual))
				.filter((data) => (pointGroup == "" ? data.ptGroup == data.ptGroup : data.ptGroup == pointGroup))
				.filter((data) => (itemUseageCode == "" ? data.itemUseageCode == data.itemUseageCode : data.itemUseageCode == itemUseageCode))
				.filter((data) => (energySourceCode == "" ? data.energySourceCode == data.energySourceCode : data.energySourceCode == energySourceCode))
				.filter((data) => (zoneIdx == 1 ? data.zoneIdx == data.zoneIdx : data.zoneIdx == zoneIdx))
				.filter((data) => (keyword == "" ? data.ptAddr == data.ptAddr : data.ptAddr.includes(keyword)));

			this.$emit("search:data", result);
		},
		async reSearch() {
			this.pointList = null;

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
				↓↓↓↓↓
			*/
			try {
				const result = await backEndApi.pointInfo.getPointListNew();
				const data = result.data;

				this.pointList = data;
				this.onSearch();

			} catch (err) {
				new AppErrorHandler(err)
					.printErrorLog()
					.errHandling();
			}
		},
		onNew() {
			console.log("point management new");
			this.$emit("new");
		},
		onCsvUpload() {
			this.$emit("csvupload");
		},
		// loadOptions({callback}){
		//   callback();
		// },
		onExcelDownload() {
			this.$emit("download");
		},
	},
};
</script>

<style scoped></style>

<template>
	<vvo v-slot="v" :key="newChannel.channelIdx">
		<template>
			<!-- Common 1st Line -->
			<div class="row form-group ">
				<div class="col-6 ">
					<label>{{$t('통신채널 명칭')}}</label>
					<valid-input
            :vid="'통신채널 명칭'"
						:inputType="'text'"
						:inputValue.sync="newChannel.channelName"
						:rules="rules.COLLECT_DATA_CHANNEL_NAME_RULE"
						:errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 20)"
						:placeholder="$t('통신채널 명칭을 입력하세요.')"
						:isMessage="true"
					>
					</valid-input>
				</div>
				<div class="col-4">
					<!-- 채널타입은 변경할 수 없다. 장비의 드라이버가 잘못된다. -->
					<!-- ADD_DRIVER -->
					<label>{{$t('통신 방식')}}</label>
					<select class="form-control" v-model="newChannel.channelType" @change="selectChannelType()" :disabled="isUpdate">
						<option value="ModbusRtu">ModbusRtu</option>
						<option value="ModbusTcp">ModbusTcp</option>
						<option value="RS485">RS485</option>
						<option value="KieMecs">TheKIE OpenAPI Center (KIEMecs)</option>
						<option value="KevinLab">KevinLab {{$t('클라우드')}}</option>
						<option value="Aquaman">Aquaman {{$t('데이터 서버')}}</option>

						<option value="PulseCount">PulseCounterTcp</option>
						<option value="BacnetTcp">BacnetTcp</option>

						<option value="Virtual">Virtual</option>
					</select>
				</div>
				<div class="col-lg-2">
					<label>{{$t('채널 사용')}}</label>
					<div class="checkbox checkbox-css">
						<input type="checkbox" :id="'newGroupUse_' + newChannel.enable" v-model="newChannel.enable" />
						<label :for="'newGroupUse_' + newChannel.enable">{{$t('사용')}}</label>
					</div>
				</div>
			</div>

			<!-- Common 2nd Line -->
			<div class="row form-group ">
				<div class="col-3">
					<label>{{$t('읽기 주기 (초)')}}</label>
					<select class="form-control" v-model="newChannel.channelOption.scan">
						<option v-if="newChannel.channelType !== 'PulseCount'" value="10000">10 sec</option>
						<option v-if="newChannel.channelType !== 'PulseCount'" value="30000">30 sec</option>
						<option value="60000">1 min</option>
						<option value="120000">2 min</option>
						<option value="180000">3 min</option>
						<option value="240000">4 min</option>
						<option value="300000">5 min</option>
						<option value="1500000">15 min</option>
						<option value="3600000">1 hour</option>
						<option value="7200000">2 hour</option>
						<option value="10800000">3 hour</option>
					</select>
				</div>
				<div class="col-3">
					<label>{{$t('타임아웃')}} (ms)</label>
					<select class="form-control" v-model="newChannel.channelOption.timeout">
						<option value="300">300 ms</option>
						<option value="500">500 ms</option>
						<option value="1000">1 sec</option>
						<option value="3000">3 sec</option>
						<option value="5000">5 sec</option>
						<option value="10000">10 sec</option>
						<option value="60000">1 min</option>
						<option value="180000">3 min</option>
					</select>
				</div>
				<div class="col-4">
					<label>{{$t('보고 방식')}}</label>
					<select class="form-control" v-model="newChannel.channelOption.reporting">
						<option value="WebAPI">xEMS WebAPI</option>
						<option value="MQTT">xEMS MQTT</option>
					</select>
				</div>
				<div class="col-2">
					<label>{{$t('가상 모드')}}</label>
					<div class="checkbox checkbox-css">
						<input type="checkbox" :id="'isVirtualMode_' + newChannel.channelIdx" v-model="newChannel.channelOption.isVirtualMode" />
						<label :for="'isVirtualMode_' + newChannel.channelIdx">{{$t('사용')}}</label>
					</div>
				</div>
			</div>
		</template>

		<!-- ModbusRTU || RS485 Case (1st Line)-->
		<!-- ADD_DRIVER -->
		<template v-if="newChannel.channelType === 'ModbusRtu' || newChannel.channelType === 'RS485'">
			<!-- ModbusRTU || RS485 Case (1st Line)-->
			<div class="row form-group">
				<div class="col-6">
					<label> {{$t('통신 포트(COM)')}} </label>
					<valid-input
            :vid="'통신 포트'"
						:inputType="'number'"
						:inputValue.sync="newChannel.channelOption.setting.comPort.dev"
						:rules="rules.COLLECT_DATA_MODBUS_RS_PORT_RULE"
						:errorMessage="validMessage.COMMON_NUMBER_LENGTH_VALID_MESSAGE(1, 255)"
						:placeholder="$t('통신 포트(COM)를 입력하세요.')"
						:isMessage="true"
					>
					</valid-input>
				</div>
				<div class="col-4">
					<label> {{$t('비트/초 (bps)')}} </label>
					<select name="" class="form-control" v-model="newChannel.channelOption.setting.comPort.baudRate">
						<option value="4800">4800</option>
						<option value="9600">9600</option>
						<option value="19200">19200</option>
						<option value="38400">38400</option>
						<option value="115200">115200</option>
					</select>
				</div>
			</div>
			<!-- ModbusRTU || RS485 Case (2nd Line)-->
			<div class="row form-group" v-if="newChannel.channelType === 'ModbusRtu' || newChannel.channelType === 'RS485'">
				<div class="col-3">
					<label> {{$t('데이터 비트')}} </label>
					<select name="" class="form-control" v-model="newChannel.channelOption.setting.comPort.dataBits">
						<option value="5">5</option>
						<option value="6">6</option>
						<option value="7">7</option>
						<option value="8">8</option>
						<option value="9">9</option>
					</select>
				</div>
				<div class="col-3">
					<label> {{$t('패리티')}} </label>
					<select name="" class="form-control" v-model="newChannel.channelOption.setting.comPort.parity">
						<option value="none">none</option>
						<option value="1">1</option>
						<option value="2">2</option>
					</select>
				</div>
				<div class="col-3">
					<label> {{$t('정지 비트')}} </label>
					<select name="" class="form-control" v-model="newChannel.channelOption.setting.comPort.stopBits">
						<option value="1">1</option>
						<option value="2">2</option>
					</select>
				</div>
				<div class="col-3">
					<label> {{$t('자동연결해제')}} </label>
					<div class="checkbox checkbox-css">
						<input type="checkbox" :id="'autoClose_' + newChannel.channelIdx" v-model="newChannel.channelOption.setting.comPort.autoClose" />
						<label :for="'autoClose_' + newChannel.channelIdx">
							{{$t('사용')}}
						</label>
					</div>
				</div>
			</div>
		</template>

		<!-- ModbusTCP Case -->
		<template v-if="newChannel.channelType === 'ModbusTcp'">
			<div class="row form-group">
				<div class="col-5">
					<label> {{$t('호스트명')}}</label>
					<valid-input
						:vid="'호스트명'"
						:inputType="'text'"
						:inputValue.sync="newChannel.channelOption.setting.server.host"
						:rules="rules.COLLECT_DATA_MODBUS_HOST_RULE"
						:errorMessage="validMessage.COMMON_NONKOREAN_VALID_MESSAGE(5, 30)"
						:placeholder="$t('IP주소나 호스트명을 입력하세요.')"
						:isMessage="true"
					>
					</valid-input>
					<!-- <vvp rules="required" v-slot="{ errors, classes }" name="Host">
            <input type="text" class="form-control" :class="classes" :placeholder="$t('IP주소나 호스트명을 입력하세요.')" v-model="newChannel.channelOption.setting.server.host" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </vvp> -->
				</div>
				<div class="col-4">
					<label> {{$t('포트')}} </label>
					<valid-input
            :vid="'포트'"
						:inputType="'number'"
						:inputValue.sync="newChannel.channelOption.setting.server.port"
						:rules="rules.COLLECT_DATA_MODBUS_PORT_RULE"
						:errorMessage="validMessage.COMMON_NUMBER_LENGTH_VALID_MESSAGE(0, 65535)"
						:placeholder="$t('포트번호를 입력하세요.')"
						:isMessage="true"
					>
					</valid-input>

					<!-- <vvp rules="required" v-slot="{ errors, classes }" name="Port">
            <input type="number" min="1" class="form-control" :class="classes" :placeholder="$t('포트번호를 입력하세요.')" v-model="newChannel.channelOption.setting.server.port" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </vvp> -->
				</div>
				<div class="col-3">
					<label> {{$t('자동연결해제')}} </label>
					<div class="checkbox checkbox-css">
						<input type="checkbox" :id="'autoClose_' + newChannel.channelIdx" v-model="newChannel.channelOption.setting.server.autoClose" />
						<label :for="'autoClose_' + newChannel.channelIdx">
							{{$t('사용')}}
						</label>
					</div>
				</div>
			</div>
		</template>

		<!-- BacnetTcp Case -->
		<template v-if="newChannel.channelType === 'BacnetTcp'">
			<div class="row form-group">
				<div class="col-5">
					<label> {{$t('호스트명')}} </label>
					<valid-input
            :vid="'호스트명'"
						:inputType="'text'"
						:inputValue.sync="newChannel.channelOption.setting.server.host"
						:rules="rules.COLLECT_DATA_MODBUS_HOST_RULE"
						:errorMessage="validMessage.COMMON_NONKOREAN_VALID_MESSAGE(5, 30)"
						:placeholder="$t('IP주소나 호스트명을 입력하세요.')"
						:isMessage="true"
					>
					</valid-input>
					<!-- <vvp rules="required" v-slot="{ errors, classes }" name="Host">
            <input type="text" class="form-control" :class="classes" :placeholder="$t('IP주소나 호스트명을 입력하세요.')" v-model="newChannel.channelOption.setting.server.host" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </vvp> -->
				</div>
				<div class="col-4">
					<label> {{$t('포트')}} </label>
					<valid-input
            :vid="'포트'"
						:inputType="'number'"
						:inputValue.sync="newChannel.channelOption.setting.server.port"
						:rules="rules.COLLECT_DATA_MODBUS_PORT_RULE"
						:errorMessage="validMessage.COMMON_NUMBER_LENGTH_VALID_MESSAGE(0, 65535)"
						:placeholder="$t('포트번호를 입력하세요.')"
						:isMessage="true"
					>
					</valid-input>

					<!-- <vvp rules="required" v-slot="{ errors, classes }" name="Port">
            <input type="number" min="1" class="form-control" :class="classes" :placeholder="$t('포트번호를 입력하세요.')" v-model="newChannel.channelOption.setting.server.port" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </vvp> -->
				</div>
				<div class="col-3">
					<label> {{$t('자동연결해제')}} </label>
					<div class="checkbox checkbox-css">
						<input type="checkbox" :id="'autoClose_' + newChannel.channelIdx" v-model="newChannel.channelOption.setting.server.autoClose" />
						<label :for="'autoClose_' + newChannel.channelIdx">
							{{$t('사용')}}
						</label>
					</div>
				</div>
			</div>
		</template>

		<!-- Pulse Counter TCP Case -->
		<template v-if="newChannel.channelType === 'PulseCount'">
			<div class="row form-group">
				<div class="col-5">
					<label> {{$t('호스트명')}} </label>
					<valid-input
            :vid="'호스트명'"
						:inputType="'text'"
						:inputValue.sync="newChannel.channelOption.setting.server.host"
						:rules="rules.COLLECT_DATA_MODBUS_HOST_RULE"
						:errorMessage="validMessage.COMMON_NONKOREAN_VALID_MESSAGE(5, 30)"
						:placeholder="$t('IP주소나 호스트명을 입력하세요.')"
						:isMessage="true"
					>
					</valid-input>
					<!-- <vvp rules="required" v-slot="{ errors, classes }" name="Host">
            <input type="text" class="form-control" :class="classes" :placeholder="$t('IP주소나 호스트명을 입력하세요.')" v-model="newChannel.channelOption.setting.server.host" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </vvp> -->
				</div>
				<div class="col-4">
					<label> {{$t('포트')}} </label>
					<valid-input
            :vid="'포트'"
						:inputType="'number'"
						:inputValue.sync="newChannel.channelOption.setting.server.port"
						:rules="rules.COLLECT_DATA_MODBUS_PORT_RULE"
						:errorMessage="validMessage.COMMON_NUMBER_LENGTH_VALID_MESSAGE(0, 65535)"
						:placeholder="$t('포트번호를 입력하세요.')"
						:isMessage="true"
					>
					</valid-input>

					<!-- <vvp rules="required" v-slot="{ errors, classes }" name="Port">
            <input type="number" min="1" class="form-control" :class="classes" :placeholder="$t('포트번호를 입력하세요.')" v-model="newChannel.channelOption.setting.server.port" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </vvp> -->
				</div>
				<div class="col-3">
					<label> {{$t('자동연결해제')}} </label>
					<div class="checkbox checkbox-css">
						<input type="checkbox" :id="'autoClose_' + newChannel.channelIdx" v-model="newChannel.channelOption.setting.server.autoClose" />
						<label :for="'autoClose_' + newChannel.channelIdx">
							{{$t('사용')}}
						</label>
					</div>
				</div>
			</div>
		</template>

		<!-- KevinLab Case -->
		<template v-if="newChannel.channelType === 'KevinLab'">
			<div class="row form-group">
				<div class="col-3">
					<label> {{$t('프로토콜')}} </label>
					<select name="" class="form-control" v-model="newChannel.channelOption.setting.server.protocol">
						<option value="http">http</option>
						<option value="https">https</option>
					</select>
				</div>
				<div class="col-6">
					<label> {{$t('호스트명')}} </label>
					<valid-input
            :vid="'호스트명'"
						:inputType="'text'"
						:inputValue.sync="newChannel.channelOption.setting.server.hostName"
						:rules="rules.COLLECT_DATA_KIEMECS_KL_AQUA_HOST_RULE"
						:errorMessage="validMessage.COMMON_NONKOREAN_VALID_MESSAGE(5, 30)"
						:placeholder="$t('IP주소나 호스트명을 입력하세요.')"
						:isMessage="true"
					>
					</valid-input>

					<!-- <vvp rules="required" v-slot="{ errors, classes }" name="HostName">
            <input type="text" min="1" class="form-control" :class="classes" :placeholder="$t('IP주소나 호스트명을 입력하세요.')" v-model="newChannel.channelOption.setting.server.hostName" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </vvp> -->
				</div>
				<div class="col-3">
					<label> {{$t('포트')}} </label>
					<valid-input
            :vid="'포트'"
						:inputType="'number'"
						:inputValue.sync="newChannel.channelOption.setting.server.port"
						:rules="rules.COLLECT_DATA_KIEMECS_KL_PORT_RULE"
						:errorMessage="validMessage.COMMON_NUMBER_LENGTH_VALID_MESSAGE(0, 65535)"
						:placeholder="$t('포트번호를 입력하세요.')"
						:isMessage="true"
					>
					</valid-input>
					<!-- 
          <vvp rules="required" v-slot="{ errors, classes }" name="Port">
            <input type="text" min="1" class="form-control" :class="classes" :placeholder="$t('포트번호를 입력하세요.')" v-model="newChannel.channelOption.setting.server.port" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </vvp> -->
				</div>
			</div>

			<div class="row form-group">
				<div class="col-6">
					<label> {{$t('인증 경로')}} </label>
					<valid-input
            :vid="'인증 경로'"
						:inputType="'text'"
						:inputValue.sync="newChannel.channelOption.setting.server.authUrl"
						:rules="rules.COLLECT_DATA_KIEMECS_KL_AQUA_ADDR_RULE"
						:placeholder="$t('인증을 위한 경로를 입력하세요.')"
          :NonStatus="true"
					>
					</valid-input>
					<!-- <vvp rules="required" v-slot="{ errors, classes }" name="인증 URL">
            <input type="text" class="form-control" :class="classes" :placeholder="$t('인증을 위한 경로를 입력하세요.')" v-model="newChannel.channelOption.setting.server.authUrl" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </vvp> -->
				</div>
				<div class="col-3">
					<label> {{$t('암호화 방식')}} </label>
					<select name="" class="form-control" v-model="newChannel.channelOption.setting.server.encodingMethod">
						<option value="base64">base64</option>
						<option value="hex">hex</option>
					</select>
				</div>
				<div class="col-3">
					<label> {{$t('인증 타입')}} </label>
					<select name="" class="form-control" v-model="newChannel.channelOption.setting.server.authType">
						<option value="Cookie">Cookie</option>
						<option value="JWT">JWT</option>
					</select>
				</div>
			</div>

			<div class="row form-group">
				<div class="col-4">
					<label> {{$t('암호화 방식')}} </label>
					<select name="" class="form-control" v-model="newChannel.channelOption.setting.server.encryptMethod">
						<option value="None">{{$t('미사용')}}</option>
						<option value="aes-256-cbc">aes-256-cbc</option>
					</select>
				</div>
				<div class="col-8">
					<label> {{$t('암호화 키')}} </label>
					<valid-input
            :vid="'암호화 키'"
						:inputType="'text'"
						:inputValue.sync="newChannel.channelOption.setting.server.encryptKey"
						:rules="rules.COLLECT_DATA_KL_PASSKEY_RULE"
						:errorMessage="validMessage.COMMON_NONKOREAN_VALID_MESSAGE(1, 50)"
						:placeholder="$t('암호화 키 입력')"
          :NonStatus="true"
					>
					</valid-input>

					<!-- <input type="text" min="1" class="form-control" :class="classes" :placeholder="$t('암호화 키 입력')" v-model="newChannel.channelOption.setting.server.encryptKey" /> -->
				</div>
			</div>

			<div class="row form-group">
				<div class="col-6">
					<label> {{$t('인증 아이디')}} </label>
					<valid-input
            :vid="'인증 아이디'"
						:inputType="'text'"
						:inputValue.sync="newChannel.channelOption.setting.server.authId"
						:rules="rules.COLLECT_DATA_KIEMECS_KL_ID_RULE"
						:errorMessage="validMessage.COMMON_NONKOREAN_VALID_MESSAGE(1, 50)"
						:placeholder="$t('인증 아이디 입력')"
          :NonStatus="true"
					>
					</valid-input>

					<!-- <vvp rules="required" v-slot="{ errors, classes }" name="authId">
            <input type="text" min="1" class="form-control" :class="classes" :placeholder="$t('인증 아이디 입력')" v-model="newChannel.channelOption.setting.server.authId" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </vvp> -->
				</div>
				<div class="col-6">
					<label> {{$t('인증 비밀번호')}} </label>
					<valid-input
            :vid="'인증 비밀번호'"
						:inputType="'text'"
						:inputValue.sync="newChannel.channelOption.setting.server.authSecret"
						:rules="rules.COLLECT_DATA_KIEMECS_KL_AQUA_PASSWORD_RULE"
						:placeholder="$t('인증 비밀번호 입력')"
          :NonStatus="true"
					>
					</valid-input>

					<!-- <vvp rules="required" v-slot="{ errors, classes }" name="authSecret">
            <input type="text" min="1" class="form-control" :class="classes" :placeholder="$t('인증 비밀번호 입력')" v-model="newChannel.channelOption.setting.server.authSecret" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </vvp> -->
				</div>
			</div>

			<div class="row form-group">
				<div class="col-8">
					<label> {{$t('요청 파라미터')}} </label>
					<valid-input
            :vid="'요청 파라미터'"
						:inputType="'text'"
						:inputValue.sync="newChannel.channelOption.setting.server.reqParams"
						:rules="rules.COLLECT_DATA_KIEMECS_KL_AQUA_API_RULE"
						:placeholder="$t('요청 파라미터 입력')"
          :NonStatus="true"
					>
					</valid-input>

					<!-- <input type="text" min="1" class="form-control" :placeholder="$t('요청 파라미터 입력')" v-model="newChannel.channelOption.setting.server.reqParams" /> -->
				</div>
				<div class="col-4">
					<label>{{$t('데이터 유효기간')}}</label>
					<select class="form-control" v-model="newChannel.channelOption.setting.server.validDataTime">
						<option value="9999999">None</option>
						<option value="60">1 min</option>
						<option value="180">3 min</option>
						<option value="300">5 min</option>
						<option value="600">10 min</option>
						<option value="1800">30 min</option>
						<option value="3600">1 hour</option>
						<option value="7200">2 hour</option>
						<option value="10800">3 hour</option>
					</select>
				</div>
			</div>
		</template>

		<!-- Aquaman & KieMecs Case -->
		<template v-if="newChannel.channelType === 'Aquaman' || newChannel.channelType === 'KieMecs'">
			<div class="row form-group">
				<div class="col-3">
					<label> {{$t('프로토콜')}} </label>
					<select name="" class="form-control" v-model="newChannel.channelOption.setting.server.protocol">
						<option value="http">http</option>
						<option value="https">https</option>
					</select>
				</div>
				<div class="col-6">
					<label> {{$t('호스트명')}} </label>
					<valid-input
            :vid="'호스트명'"
						:inputType="'text'"
						:inputValue.sync="newChannel.channelOption.setting.server.hostName"
						:rules="rules.COLLECT_DATA_KIEMECS_KL_AQUA_HOST_RULE"
						:errorMessage="validMessage.COMMON_NONKOREAN_VALID_MESSAGE(5, 30)"
						:placeholder="$t('IP주소나 호스트명을 입력하세요.')"
						:isMessage="true"
					>
					</valid-input>
					<!-- <vvp rules="required" v-slot="{ errors, classes }" name="HostName">
            <input type="text" min="1" class="form-control" :class="classes" :placeholder="$t('호스트명 입력')" v-model="newChannel.channelOption.setting.server.hostName" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </vvp> -->
				</div>
				<div class="col-3">
					<label> {{$t('포트')}} </label>
					<valid-input
            :vid="'포트'"
						:inputType="'number'"
						:inputValue.sync="newChannel.channelOption.setting.server.port"
						:rules="rules.COLLECT_DATA_KIEMECS_KL_PORT_RULE"
            :errorMessage="validMessage.COMMON_NUMBER_LENGTH_VALID_MESSAGE(0, 65535)"
						:placeholder="$t('포트번호를 입력하세요.')"
          :isMessage="true"
					>
					</valid-input>
					<!-- <vvp rules="required" v-slot="{ errors, classes }" name="Port">
            <input type="text" min="1" class="form-control" :class="classes" :placeholder="$t('포트번호 입력')" v-model="newChannel.channelOption.setting.server.port" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </vvp> -->
				</div>
			</div>

			<div class="row form-group">
				<div class="col-9">
					<label> {{$t('인증 경로')}} </label>
					<valid-input
          :vid="'인증 경로'"
						:inputType="'text'"
						:inputValue.sync="newChannel.channelOption.setting.server.authUrl"
						:rules="rules.COLLECT_DATA_KIEMECS_KL_AQUA_ADDR_RULE"
						:placeholder="$t('인증을 위한 경로를 입력하세요.')"
          :NonStatus="true"
					>
					</valid-input>
					<!-- <vvp rules="required" v-slot="{ errors, classes }" name="인증 URL">
            <input type="text" class="form-control" :class="classes" :placeholder="$t('인증을 위한 경로를 입력하세요.')" v-model="newChannel.channelOption.setting.server.authUrl" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </vvp> -->
        </div>
        <div class="col-3">
          <label> {{$t('인증 타입')}} </label>
          <select name="" class="form-control" 
            v-model="newChannel.channelOption.setting.server.authType">
            <option value="Custom">Custom</option>
          </select>
        </div>
      </div>
      
      <div class="row form-group" >        
        <div class="col-6">
          <label> {{$t('인증 아이디')}} </label>
          <valid-input
          :vid="'인증 아이디'"
          :inputType="'text'"
          :inputValue.sync="newChannel.channelOption.setting.server.authId"
          :rules="rules.COLLECT_DATA_KIEMECS_KL_ID_RULE"
          :placeholder="$t('인증 아이디 입력')"
          :NonStatus="true"
          >
          </valid-input>
          <!-- <vvp rules="required" v-slot="{ errors, classes }" name="authId">
            <input type="text" min="1" class="form-control" :class="classes" :placeholder="$t('인증 아이디 입력')" v-model="newChannel.channelOption.setting.server.authId" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </vvp> -->
				</div>
				<div class="col-6">
					<label> {{$t('인증 비밀번호')}} </label>
					<valid-input
          :vid="'인증 비밀번호'"
						:inputType="'text'"
						:inputValue.sync="newChannel.channelOption.setting.server.authSecret"
						:rules="rules.COLLECT_DATA_KIEMECS_KL_AQUA_PASSWORD_RULE"
						:placeholder="$t('인증 비밀번호 입력')"
          :NonStatus="true"
					>
					</valid-input>
					<!-- <vvp rules="required" v-slot="{ errors, classes }" name="authSecret">
            <input type="text" min="1" class="form-control" :class="classes" :placeholder="$t('인증 비밀번호 입력')" v-model="newChannel.channelOption.setting.server.authSecret" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </vvp> -->
				</div>
			</div>

			<div class="row form-group">
				<div class="col-8">
					<label> {{$t('요청 파라미터')}} </label>
					<valid-input
          :vid="'요청 파라미터'"
						:inputType="'text'"
						:inputValue.sync="newChannel.channelOption.setting.server.reqParams"
						:rules="rules.COLLECT_DATA_KIEMECS_KL_AQUA_API_RULE"
						:placeholder="$t('인증 파라미터 입력')"
          :NonStatus="true"
					>
					</valid-input>
					<!-- <input type="text" min="1" class="form-control" :placeholder="$t('요청 파라미터 입력')" v-model="newChannel.channelOption.setting.server.reqParams" /> -->
				</div>
				<div class="col-4">
					<label>{{$t('데이터 유효기간')}}</label>
					<select class="form-control" v-model="newChannel.channelOption.setting.server.validDataTime">
						<option value="9999999">없음</option>
						<option value="60">1 min</option>
						<option value="180">3 min</option>
						<option value="300">5 min</option>
						<option value="600">10 min</option>
						<option value="1800">30 min</option>
						<option value="3600">1 hour</option>
						<option value="7200">2 hour</option>
						<option value="10800">3 hour</option>
					</select>
				</div>
			</div>
		</template>

		<!-- Virtual Case  -->
		<template v-if="newChannel.channelType === 'Virtual'">
			<div class="row form-group" v-if="newChannel.channelType === 'Virtual'">
				<div class="col-lg-12"><label for="">{{$t('가상 채널에는 옵션이 없습니다.')}}</label></div>
			</div>
		</template>

		<template>
			<div class="row form-group">
				<!-- Common Footer -->
				<div slot="modal-footer" class="w-100">
					<button v-xe-pm.E class="btn btn-danger" v-if="isUpdate && (isEmpty(childrens) || childrens == 0)" @click="onDeleteChannel()"><i class="fa fa-times"></i> {{ $t("삭제") }}</button>
					<button class="btn btn-primary pull-right" @click="onValidate(v, onSaveChannel, newChannel)"><i class="fa fa-save"></i> {{ $t("저장") }}</button>
				</div>
			</div>
		</template>
	</vvo>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		props: ["serverIdx", "channelIdx", "childrens", "save-done"],
		data() {
			// console.log('data() is called');
			return {
				// classes:"", // 오류..
				newChannel: {
					serverIdx: this.serverIdx,
					channelType: "ModbusTcp",
					enable: false,
					channelOption: this.makeChannelOpt("ModbusTcp"),
				},
				rules,
				validMessage,
				popupMessages,
			};
		},
		computed: {
			isUpdate() {
				// console.log('computed() is called');
				return !this.isEmpty(this.channelIdx);
			},
		},
		watch: {
			channelIdx: function() {
				// console.log('watch() is called');

				this.initSetting();
			},
		},
		mounted() {
			// console.log('mounted() is called');

			this.initSetting();
		},
		methods: {
			initSetting() {
				// this.newChannel = {
				//   serverIdx: this.serverIdx,
				//   channelType: "ModbusTcp",
				//   enable: false,
				//   channelOption: this.makeChannelOpt("ModbusTcp"),
				//   meterTypeCode : "",
				//   clbrPeriodCode : ""
				// };

				// console.log('on initSetting.... newChannel.channelOption :', JSON.stringify(this.newChannel.channelOption));

				if (this.isUpdate) {
					backEndApi.channel.getChannel(this.channelIdx).then(({ data }) => {
						if (this.$err(data)) return;

						data.channelOption = this.merge(this.makeChannelOpt(data.channelType), data.channelOption);

						this.newChannel = data;
					});
				}

				// 계측기관리 검교정 관련 selectbox
				// this.getSmallCodeByChannel();
			},
			selectChannelType() {
				this.newChannel.channelOption = this.makeChannelOpt(this.newChannel.channelType);

				// console.log('### channelOption : ', JSON.stringify(this.newChannel.channelOption));
			},
			onSaveChannel() {
				this.isUpdate ? this.updateChannel() : this.insertChannel();
			},
			insertChannel() {
				this.alertQuestion(popupMessages.COMMON_SAVE_CONFIRM_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.channel.insertChannel(this.newChannel).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
						this.$emit("save-done");
					});
				});
			},
			updateChannel() {
				this.alertQuestion(popupMessages.COMMON_UPDATE_CONFIRM_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.channel.updateChannel(this.newChannel).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.COMMON_UPDATE_POPUP_MESSAGE);
						this.$emit("save-done");
					});
				});
			},
			onDeleteChannel() {
				// TODO: 하위 정보가 있으면 삭제할 수 없습니다.

      this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

					backEndApi.channel.deleteChannel(this.newChannel.channelIdx).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
						this.$emit("save-done");
					});
				});
			},

			makeChannelOpt(channelType) {
				let channelOption = {
					scan: 60000,
					timeout: 3000,
					reporting: "WebAPI",
					isVirtualMode: false,
				};

				// ADD_DRIVER
				if (channelType === "ModbusRtu" || channelType === "RS485") {
					// "setting":{"comPort":{"dev":"COM1", "baudRate":9600,"parity":"none","dataBits":8,"stopBits":1},"writing":{"retry":5,"afterFailed":500}}} -->
					return Object.assign(channelOption, {
						setting: {
							comPort: {
								dev: "",
								baudRate: 9600,
								parity: "none",
								dataBits: 8,
								stopBits: 1,
								autoClose: true,
							},
						},
					});
				} else if (channelType === "ModbusTcp") {
					return Object.assign(channelOption, {
						setting: {
							server: {
								host: "",
								port: "502",
								autoClose: true,
							},
						},
					});
				} else if (channelType === "PulseCount") {
					return Object.assign(channelOption, {
						setting: {
							server: {
								host: "",
								port: "3000",
								autoClose: true,
							},
						},
					});
				} else if (channelType === "BacnetTcp") {
					return Object.assign(channelOption, {
						setting: {
							server: {
								host: "",
								port: "47808",
								autoClose: true,
							},
						},
					});
				} else if (channelType === "KevinLab") {
					return Object.assign(channelOption, {
						setting: {
							server: {
								protocol: "http",
								hostName: "www.lbems.com",
								port: "80",
								authUrl: "/kbet/login.php",
								authType: "JWT",
								encryptMethod: "aes-256-cbc",
								encodingMethod: "base64",
								encryptKey: "",
								authId: "kbetadmin",
								authSecret: "",
								validDataTime: "9999999",
								reqParams: "",
							},
						},
					});
				} else if (channelType === "Aquaman") {
					return Object.assign(channelOption, {
						setting: {
							server: {
								protocol: "https",
								hostName: "ftlab-vm-api.com",
								port: "443",
								authUrl: "/api/rsv2/customers/login",
								authType: "Custom",
								authId: "",
								authSecret: "",
								validDataTime: "9999999",
								reqParams: "",
							},
						},
					});
				} else if (channelType === "KieMecs") {
					return Object.assign(channelOption, {
						setting: {
							server: {
								protocol: "https",
								hostName: "openapi.thekie.com",
								port: "443",
								authUrl: "/v1/auth",
								authType: "Custom",
								authId: "",
								authSecret: "",
								validDataTime: "9999999",
								reqParams: "",
							},
						},
					});
				} else if (channelType === "Virtual") {
					return Object.assign(channelOption, {
						setting: {
							server: {},
						},
					});
				}

				return channelOption;
			},
			// getSmallCodeByChannel(){
			//   backEndApi.publicCode.getSmallCodeList("meterTypeCode").then(({ data }) => {
			//     if(!data.errno){
			//       this.meterTypeCodeList = data;
			//     }
			//   });

			//   backEndApi.publicCode.getSmallCodeList("clbrPeriodCode").then(({ data }) => {
			//     if(!data.errno){
			//       this.clbrPeriodCodeList = data;
			//     }
			//   });
			// }
		},
	};
</script>

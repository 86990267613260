<script>
	import Vue from "vue";
	import Vuex from "vuex";
	import axios from "axios";
	import wsc from "./PageSocket.js";
	import $ from "jquery";
	import backEndApi, { apiErrorEvent, setAccessToken, getAccessToken } from "@api/backEndApi.js";
	// import LogTableStatus from "@src/views/sys/data-management/retention/LogTableStatus.vue";
	const { isEmpty, readStorage } = global.xe;
	const { encryptObj, decryptObj } = global.xe;
	Vue.use(Vuex);

	apiErrorEvent.addListener("Unauthorized", () => {
		console.log(`★★★ PageStore Unauthorized ★★★`);
		//store.state.accessToken = null;
		//store.actions.LOGOUT("");
		const loginUser = store.getters.loginUser;
		console.log(loginUser);
		if (isEmpty(loginUser)) return;

		store.dispatch("LOGOUT", loginUser);
	});

	const store = new Vuex.Store({
		state: {
			master: (decryptObj(localStorage.loginUser) ? decryptObj(localStorage.loginUser).master : {}) || {},
			masterGroup: {},
			accessToken: null,
			loginUser: decryptObj(localStorage.loginUser),
			loginGroups: decryptObj(localStorage.loginGroups),

			locale: "kr",
			nations: [],
			// localStorage.strings:{},

			isEditMode: false,
			isToolbar: false,
			isTransMode: false,
			isHelpMode: false,

			// navs: {}, // localStorage.menus  // TODO: 모바일 전환시 갱신되지 않음.
			currentMenu: {}, // 상단 대메뉴에서 클릭된 메뉴. currentNav : nav임
			mainMenu: {}, // 현재선택된 메뉴

			weather: {}, // 마스터 도시의 기상정보임.. 헤더상단표시..

			isWscConnected: false,
			//targetClient: "PC",

			alarmLogsRegDt: new Date().format("yyyyMMddHHmmss"), // 알람로그 최종업데이트 일자로 알람목록의 변경을 watch한다.
			// localStorage.alarmLogs: []

			serverNow: "",
			liveDate: "", // localStore에 값이 변경되었는지를 판단하기 위한 변수 (화면상에서 watch를 걸어서  liveDate, liveReal의 변경을 알아내기 위한 용도임...)
			// localStorage.liveReal: {},
			// localStorage.liveAM: {}, // ptAddr map object임
			// localStorage.liveIM: {},  // ptIdx map object임
			socketData: "",

			showErrorPoint: false,
			liveReal: null,
			liveRealLastRegDt: null,
			totalReal: null,
			ISsocket: false,
			tmpTotalArray: [],
			equipUpdateInfo: [],
			chartGridIdx: null,

			TotalTimeout: null,
			checkGetTotal: null,
			chartSelectValue: null,
			sessionLoginTimer: null,
			costInfoLiveList: [],
			costInfoColumnList: [],
			costInfoSelectPointList: [],

			receiveEquipCtrl: false,

			startTimer: { minutes: 10, seconds: 0 },
			autoLogoutTimer: 0,
			autoLogoutInterval: null,
			autoLogoutState: false,
			isAutoLogout: false,
		},
		getters: {
			getisAutoLogout(state) {
				return state.isAutoLogout;
			},
			getAutoLogoutState(state) {
				return state.autoLogoutState;
			},
			getReceiveEquipCtrl(state) {
				return state.receiveEquipCtrl;
			},
			getCostInfoLiveList(state) {
				return state.costInfoLiveList;
			},

			getChartSelectValue(state) {
				return state.chartSelectValue;
			},
			getLiveReal(state) {
				return state.liveReal;
			},
			getTotalReal(state) {
				return state.totalReal;
			},
			wssUrl() {
				return wsc.wssUrl;
			},
			master(state) {
				return state.master;
			},
			masterGroup(state) {
				return state.masterGroup;
			},
			isAuthenticated(state) {
				state.accessToken = state.accessToken || localStorage.accessToken;
				return state.accessToken;
			},
			loginUser(state) {
				return state.loginUser;
			},
			loginGroups(state) {
				return state.loginGroups;
			},
			locale(state) {
				return state.locale;
			},
			isEditMode(state) {
				return state.isEditMode;
			},
			isToolbar(state) {
				return state.isToolbar;
			},
			isTransMode(state) {
				return state.isTransMode;
			},
			isHelpMode(state) {
				return state.isHelpMode;
			},
			// navs(state) {
			//   return state.navs;
			// },
			currentMenu(state) {
				return state.currentMenu;
			},
			mainMenu(state) {
				return state.mainMenu;
			},
			weather(state) {
				return state.weather;
			},
			alarmLogsRegDt(state) {
				return state.alarmLogsRegDt;
			},
			isWscConnected(state) {
				return state.isWscConnected;
			},
			serverNow(state) {
				return state.serverNow;
			},
			liveDate(state) {
				return state.liveDate;
			},
			showErrorPoint(state) {
				return state.showErrorPoint;
			},
			getSessionTimer(state) {
				return state.sessionLoginTimer;
			},
			FILTER_COST_INFO_LIVE_LIST(state) {
				let result = [];
				// console.log(state.costInfoLiveList);
				for (let i = 0; i < state.costInfoLiveList.length; i++) {
					let form = { regDt: state.costInfoLiveList[i].regDt, datas: [] };

					let datas = state.costInfoLiveList[i].datas;
					// console.log(state.costInfoLiveList[i]);
					for (let j = 0; j < state.costInfoColumnList.length; j++) {
						// let abc = datas.find((v) => {
						// console.log(v.ptAddr, state.costInfoColumnList[j]);
						// if (v.ptAddr === state.costInfoColumnList[j]) {
						// return v;
						// }
						// });
						// console.log(abc);
						form.datas.push(datas.find((v) => v.ptAddr === state.costInfoColumnList[j]));
						// console.log(state.totalReal[state.costInfoColumnList[j]].regDt, state.totalReal[state.costInfoColumnList[j]].ptAddr, state.totalReal[state.costInfoColumnList[j]].ptVal);
					}

					result.push(form);
				}

				return result;
			},
			GET_WORKFLOW_SELECT_DATA(state) {
				let result = [];
				for (let i = 0; i < state.costInfoLiveList.length; i++) {
					let form = { regDt: state.costInfoLiveList[i].regDt, datas: [] };

					let datas = state.costInfoLiveList[i].datas;
					// console.log(state.costInfoLiveList[i]);
					for (let j = 0; j < state.costInfoSelectPointList.length; j++) {
						form.datas.push(datas.find((v) => v.ptAddr === state.costInfoSelectPointList[j]));
					}

					result.push(form);
				}
				return result;
			},
		},
		mutations: {
			SET_COST_INFO_SELECT_POINT_LIST(state, value) {
				state.costInfoSelectPointList = value;
			},
			SET_AUTO_LOGOUT_TRIGGER(state, value) {
				state.isAutoLogout = value;
			},
			SET_AUTO_LOGOUT_STATE(state, value) {
				state.autoLogoutState = value;
			},
			SET_RECEIVE_EQUIP_CTRL(state, value) {
				state.receiveEquipCtrl = value;
			},
			SET_COST_INFO_COLUMN_LIST(state, value) {
				state.costInfoColumnList = value;
			},
			SET_SESSION_LOGIN_TIMER(state, value) {
				state.sessionLoginTimer = value;
			},
			SET_CHART_SELECT_VALUE(state, value) {
				state.chartSelectValue = value;
			},
			EQUIP_INFO_INSERT(state, ptAddr) {
				if (state.equipUpdateInfo.includes(ptAddr) === false) {
					state.equipUpdateInfo.push(ptAddr);
				}
				// console.log(state.equipUpdateInfo);
			},
			EQUIP_INFO_DELETE(state, ptAddr) {
				let idx = state.equipUpdateInfo.findIndex((el) => el === ptAddr);

				if (idx !== -1) {
					state.equipUpdateInfo.splice(idx, 1);
				}
			},
			// UPDATE_TOTAL_REAL_LOCAL(state, data) {
			// console.log(state.totalReal, data);
			// if (data.ptAddr !== null && state.totalReal[data.ptAddr]) {
			// 	state.totalReal[data.ptAddr].ptVal = data.ptVal;
			// }
			// },

			// mutations: this.$store.commit("UPDATE_EDIT_MODE", param)
			// action: this.$store.dispatch("LOGIN", param)
			UPDATE_MASTER(state, master) {
				if (state.loginUser) {
					if (state.loginUser.masterCode === master.masterCode) {
						state.master = master;
					} else {
						console.log("로그인사용자와 다른 마스터 정보를 할당할 수 없습니다.");
					}
				} else {
					state.master = master;
				}

				this.dispatch("UPDATE_WEATHER");
				setInterval(() => {
					this.dispatch("UPDATE_WEATHER");
				}, 60 * 60 * 1000);
			},
			UPDATE_MASTER_GROUP(state, masterGroup) {
				state.masterGroup = JSON.parse(JSON.stringify(masterGroup));
			},
			UPDATE_ACCESS_TOKEN(state, accessToken) {
				state.accessToken = accessToken;
			},
			LOGIN(state, { master, accessToken, user, groups, menus, strings, systemInfo }) {
				axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

				// TODO: 로그인시 저장되는 것들 암호화해서 localStorage에 넣어야 함.  키값도 알 수 없게 해야 함.
				// UPDATE_LOGIN,  UPDATE_LOGIN_INFO

				state.master = master;
				//user.master = master; // TODO: 임시..  서버에서도 user.master에 이미 데이터를 넣어 주고 있음. 어디서 사용하는지 찾아서 프로세스 바꿔야 함.

				state.loginUser = user;
				state.loginGroups = groups;
				state.accessToken = accessToken;

				this.commit("UPDATE_LOGIN_INFO", {
					accessToken,
					user,
					groups,
					menus,
					strings,
					systemInfo,
				});

				wsc.createWsc(this);
			},
			UPDATE_LOGIN_INFO(state, { accessToken, user, groups, menus, strings, systemInfo }) {
				// master: master,
				// user: login.user,
				// groups: login.groups,
				// menus: menus,
				// accessToken: accessToken,
				// strings: strings,
				// systemInfo: systemInfo
				localStorage.accessToken = accessToken;
				localStorage.loginUser = encryptObj(user);
				localStorage.loginGroups = encryptObj(groups);
				localStorage.systemInfo = JSON.stringify(systemInfo);

				state.loginUser = user;
				//this.commit("UPDATE_STRINGS", { strings });

				/** 번역 문구 임시 변환 */
				let stringKeys = Object.keys(strings);
				let newStrings = [];
				for (let i = 0; i < stringKeys.length; i++) {
					newStrings.push({
						key: stringKeys[i],
						...strings[stringKeys[i]],
					});
				}

				console.log(newStrings);

				let newObjectStrings = newStrings.reduce((obj, item) => ((obj[item.key] = { [state.locale]: item[state.locale] }), obj), {});

				console.log(newObjectStrings);

				localStorage.strings = JSON.stringify(newObjectStrings);

				this.commit("UPDATE_MENUS", menus);
			},
			LOGOUT(state) {
				axios.defaults.headers.common["Authorization"] = undefined;

				wsc.destroyWsc();
				state.loginUser = {};
				state.loginGroups = {};
				state.accessToken = null;
				delete localStorage.loginUser;
				delete localStorage.loginGroups;
				delete localStorage.accessToken;
			},
			// UPDATE_TARGET_CLIENT(state, targetClient) {
			//   state.targetClient = targetClient;
			// },
			UPDATE_LOCALE(state, locale) {
				try {
					if (isEmpty(locale)) {
						console.error(`UPDATE_LOCALE mutations locale error = ${locale}`);
						return;
					}

					global.xe.locale = locale;
					state.locale = locale;

					// 달력컨트롤 한글화 -// TODO: 바로 적용되지 않음.
					let datepickerlocale = { en: "en", kr: "ko" };
					$.extend(true, $.fn.datetimepicker.defaults, {
						locale: datepickerlocale[state.locale] ? datepickerlocale[state.locale] : state.locale,
					});

					this.dispatch("UPDATE_MENUS");
					// Validation 메세지 다국어
					// Validator.configure(locale);
				} catch (err) {
					console.error("UPDATE_LOCALE mutations", err);
				}
			},
			UPDATE_EDIT_MODE(state, isEditMode) {
				state.isEditMode = isEditMode;
			},
			UPDATE_TOOLBAR(state, isToolbar) {
				state.isToolbar = isToolbar;
			},
			UPDATE_TRANS_MODE(state, isTransMode) {
				state.isTransMode = isTransMode;
			},
			UPDATE_HELP_MODE(state, isHelpMode) {
				state.isHelpMode = isHelpMode;
			},
			UPDATE_MENUS(state, menus) {
				try {
					localStorage.menus = JSON.stringify(menus);
					//state.navs = Vue.convertNavList(menus); // 화면 navigation용 개체로 변환한다.
					//localStorage.navs = JSON.stringify(Vue.convertNavList(menus));
				} catch (err) {
					console.log("UPDATE_MENUS mutation error", err);
				}
			},
			UPDATE_CURRENT_MENUS(state, menu) {
				state.currentMenu = menu; // menu는 nav개체임..  상단 navigation에서 선택된 nav를 현재 nav로 지정한다.
			},
			UPDATE_MAIN_MENUS(state, menu) {
				state.mainMenu = menu;
			},
			UPDATE_WEATHER(state, weathers) {
				if (weathers && Array.isArray(weathers)) {
					let now = new Date().format("yyyyMMddHH") + "00"; // forecastDt는 DB상에 "201906010000" 분까지 들어있음..
					weathers.forEach((weather) => {
						// 현재시간에 해당하는 예보를 찾아서 보여줌..
						if (weather.forecastDt < now) {
							// now에 가장 가까운데이터가 마지막으로 들어감..
							state.weather = weather;
						}
					});
				}
			},
			UPDATE_IS_WSC_CONNECTED(state, isWscConnected) {
				state.isWscConnected = isWscConnected;
			},
			UPDATE_ALARM_LOGS(state, msg) {
				// console.log(msg, state.alarmLogsRegDt);
				if (isEmpty(state.accessToken)) return;

				let alarms = [];
				msg.alarmLogs
					.sort((a, b) => b.regDt - a.regDt) // ASC
					.forEach((v) => {
						alarms.push(v);
					});

				state.alarmLogsRegDt = new Date().format("yyyyMMddHHmmss");
				localStorage.alarmLogs = JSON.stringify(alarms);
				// console.log(JSON.parse(localStorage.alarmLogs));
			},
			UPDATE_LIVE_REAL(state, msg) {
				console.log(state, msg);
				// 샘플링된 데이터를 받는다.
				try {
					state.serverNow = msg.now.parseDate(); // 서버시계 동기화
					state.liveDate = msg.now.format("yyyyMMddHHmmss");
					localStorage.liveReal = JSON.stringify(msg.liveReal);
					state.liveReal = JSON.stringify(msg.liveReal);
					state.liveRealLastRegDt = msg.liveDate;
					console.log(" application localstorage update ");

					this.commit("UPDATE_COST_INFO_LIVE_LIST");
					if (isEmpty(state.accessToken)) return;
				} catch (err) {
					console.error("UPDATE_LIVE_REAL mutations liveReal", err);
				}
			},
			UPDATE_EQUIP_CTRL(state, msg) {
				state.receiveEquipCtrl = true;
				// console.log(msg);
				try {
					let contrlPoint = msg.contrlPoint;

					for (let i = 0; i < contrlPoint.length; i++) {
						let idx = state.equipUpdateInfo.findIndex((el) => el === contrlPoint[i]);

						if (idx !== -1) {
							state.equipUpdateInfo.splice(idx, 1);
						}
					}
				} catch (error) {
					console.error("UPDATE_EQUIP_CTRL ERROR ::: ", error);
				}
			},
			UPDATE_TOTAL_REAL(state, msg) {
				// console.log(msg);
				try {
					if (isEmpty(state.accessToken)) return;

					if (isEmpty(msg.totalReal)) return;

					// let liveReal = JSON.parse(state.liveReal);
					let totalReal;
					// this.totalReal = msg.totalReal;

					if (localStorage.totalReal) {
						totalReal = JSON.parse(localStorage.totalReal);
					} else {
						totalReal = JSON.parse(state.liveReal);
					}

					if (totalReal === null) {
						totalReal = msg.totalReal;
					}

					for (let i = 0; i < msg.totalReal.length; i++) {
						// let ptAddrIdx = Object.keys(totalReal).findIndex((v) => v === msg.totalReal[i].ptAddr);

						totalReal[msg.totalReal[i].ptAddr] = msg.totalReal[i];
					}

					// let ObjectTotalReal = {};

					// for(let i = 0; i < state.totalReal.length; i++){
					//   ObjectTotalReal[state.totalReal[i].ptAddr] = state.totalReal[i];
					// }

					state.totalReal = totalReal;
					// state.liveReal = JSON.stringify(liveReal);
					localStorage.totalReal = JSON.stringify(totalReal);
					// localStorage.liveReal = JSON.stringify(liveReal);

					// totalRealMap.map((tr) => {
					// let cur = totalReal[tr.ptAddr];

					// if (!isEmpty(cur) && cur.regDt > tr.regDt) {
					//   return;
					// }
					// if (state.equipUpdateInfo.includes(tr.ptAddr) === true) {
					// console.log(`::: ${tr.ptAddr} NOT UPDATED :::`);

					// console.log("CALL TOTAL UPDATE RETURN");
					// return;
					// }
					// if (tr.lockYn === false || tr.lockYn === 0) {
					// }
					// console.log(`::: ${tr.ptAddr} UPDATED :::`, tr.ptVal, tr.lockYn);
					// totalReal[tr.ptAddr] = tr;
					// });
					//
					// localStorage.liveReal = JSON.stringify(totalReal);
					// state.totalReal = JSON.parse(JSON.stringify(totalReal));
					// console.log(state.totalReal);
					// state.liveDate = new Date().format("yyyyMMddHHmmss");
				} catch (err) {
					console.error("UPDATE_TOTAL_REAL mutations", err);
				}
			},
			UPDATE_PT_MAP(state, liveAM) {
				try {
					// 내부 동기화 - 관제점 전체 업데이트
					let liveIM = {};
					Object.keys(liveAM).map((ptAddr) => {
						let pt = liveAM[ptAddr];
						liveIM[pt.ptIdx] = pt;
					});

					localStorage.liveAM = JSON.stringify(liveAM);
					localStorage.liveIM = JSON.stringify(liveIM);
				} catch (err) {
					console.error("UPDATE_PT_MAP mutations", err);
				}
			},
			UPDATE_PT_LIST(state, msg) {
				try {
					// 명령으로 내려오는 ptList - 관제점 변경분 반영
					// {
					//   command: "UPDATE_PT_MAP"
					//   now: "20201109101100"
					//   ptList:  // TODO: ptList가 object형식임. 이름 바꿔야 함.
					// }

					let liveAM = readStorage("liveAM");
					liveAM = Object.assign(liveAM, msg.ptList);

					let liveIM = {};
					Object.keys(liveAM).map((ptAddr) => {
						let pt = liveAM[ptAddr];
						liveIM[pt.ptIdx] = pt;
					});

					localStorage.liveAM = JSON.stringify(liveAM);
					localStorage.liveIM = JSON.stringify(liveIM);
				} catch (err) {
					console.error("UPDATE_PT_LIST mutations", err);
				}
			},
			// UPDATE_STRINGS(state, { strings }) {
			//   localStorage.strings = JSON.stringify(strings);
			// },
			UPDATE_TRANS(state, { strings }) {
				// 메뉴 다국어가 도착하면, 다국어문자열에 합친다.
				let localStrings = Object.assign(readStorage("strings"), strings);
				localStorage.strings = JSON.stringify(localStrings);
			},

			UPDATE_COST_INFO_LIVE_LIST(state) {
				let row = state.costInfoLiveList;
				let liveReals = JSON.parse(state.liveReal);
				// console.log(state.totalReal);
				// let totalReal = state.totalReal ? state.totalReal : localStorage.totalReal ? JSON.parse(localStorage.totalReal) : null;
				let liveKeys = Object.keys(liveReals);

				if (liveReals === null) return;

				for (let i = 0; i < liveKeys.length; i++) {
					let ptAddr = liveKeys[i];
					let regIdx = row.findIndex((v) => v.regDt === state.liveRealLastRegDt);
					// console.log(ptAddr, liveReals[ptAddr].ptVal ?? 'nona', totalReal[ptAddr].ptVal ?? 'nona');
					if (regIdx === -1) {
						row.push({ regDt: state.liveRealLastRegDt, datas: [] });

						row[row.length - 1].datas.push({ ptAddr: ptAddr, ptVal: liveReals[ptAddr] ? liveReals[ptAddr].ptVal : 0 });
					} else {
						// continue;
						row[regIdx].datas.push({ ptAddr: ptAddr, ptVal: liveReals[ptAddr] ? liveReals[ptAddr].ptVal : 0 });
					}
				}

				if (row.length > 10) {
					row.shift();
				}
				state.costInfoLiveList = row;
			},

			RUN_AUTO_LOGOUT_TIMER(state) {
				if (state.autoLogoutState === false) return;

				// let time;
				state.autoLogoutTimer -= 1000;
				// console.log(state.autoLogoutTimer);

				if (state.autoLogoutTimer < 1000) {
					this.dispatch("LOGOUT", state.loginUser);
					clearInterval(state.autoLogoutInterval);
					this.commit("SET_AUTO_LOGOUT_TRIGGER", true);
					// element.innerHTML = "Time is up!";
				}
			},

			UPDATE_NATIONS(state, value) {
				state.nations = value;
			},
		},
		actions: {
			// 자동 로그아웃 10분
			UPDATE_AUTO_LOGOUT_INTERVAL({ commit, state }) {
				let minutes = state.startTimer.minutes;
				let seconds = state.startTimer.seconds;

				state.autoLogoutTimer = 1000 * (60 * minutes + seconds);

				state.autoLogoutInterval = setInterval(() => {
					commit("RUN_AUTO_LOGOUT_TIMER");
				}, 1000);
			},
			UPDATE_AUTO_LOGOUT_STATE({ dispatch, commit, state }, value) {
				if (state.autoLogoutState === value) return;

				commit("SET_AUTO_LOGOUT_STATE", value);

				// console.log(state.autoLogoutState);
				if (state.autoLogoutState === false) {
					clearInterval(state.autoLogoutInterval);
				} else {
					dispatch("UPDATE_AUTO_LOGOUT_INTERVAL");
				}
			},
			//페이지 새로고침시 헤더에 accessToken 토큰 설정
			UPDATE_TOKEN({ commit }) {
				const accessToken = getAccessToken();
				//backEndApi 헤더 토큰이 비어 있으면 로컬 스토리지에 있는 accessToken을 설정
				if (!isEmpty(accessToken)) return;

				setAccessToken(localStorage.accessToken);
				commit("UPDATE_ACCESS_TOKEN", localStorage.accessToken);

				console.log(`★★★ UPDATE_TOKEN ==> ${accessToken}, LocalStorage StateToken => ${localStorage.accessToken}, Store StateToken ${this.state.accessToken}`);
			},
			CREATE_WEB_SOCKET() {
				wsc.createWsc(this);
			},
			UPDATE_MASTER({ commit }, selectMaster) {
				if (selectMaster) {
					commit("UPDATE_MASTER", selectMaster);
				} else {
					// if (isEmpty(axios.defaults.headers.common["Authorization"]))
					//   return;

					backEndApi.master
						.getMaster()
						.then(({ data }) => {
							commit("UPDATE_MASTER", data);
						})
						.catch((err) => {
							console.log(`★★★ UPDATE_MASTER Error => ${err} ★★★`);
						});
				}
			},
			UPDATE_MASTER_GROUP({ commit }) {
				backEndApi.login.getMasterGroup().then(({ data }) => {
					commit("UPDATE_MASTER_GROUP", data);
				});
			},
			LOGIN({ commit }, { masterCode, userId, password }) {
				return new Promise(async (resolve, reject) => {
					// password = await hash(password);

					let targetClient = global.xe.getTargetClient();

					backEndApi.login
						.signin({ masterCode, userId, password, targetClient })
						.then(({ data }) => {
							console.log(data);
							commit("LOGIN", data);
							resolve();
						})
						.catch((err, res) => {
							reject(err, res);
						});
				});
			},
			LOGOUT({ commit }, { userId }) {
				commit("LOGOUT");

				backEndApi.login.signout(userId).catch((err) => {
					console.debug("로그아웃 실패", err);
				});
			},
			UPDATE_LOGIN_INFO({ getters, commit }) {
				if (!getters.isAuthenticated) return;
				if (!getters.loginUser.userId) return;

				let targetClient = global.xe.getTargetClient();

				backEndApi.user.getLoginInfo(getters.loginUser.userId, targetClient).then(({ data }) => {
					// console.debug("UPDATE_LOGIN_INFO actions=", data);

					commit("UPDATE_LOGIN_INFO", data);
				});
			},
			UPDATE_MENUS({ getters, commit }) {
				if (!getters.isAuthenticated) return;
				if (!getters.loginUser.userId) return;

				let targetClient = global.xe.getTargetClient();

				backEndApi.menu.searchMenuLoginUser(getters.loginUser.userId, targetClient).then(({ data }) => {
					//console.debug("UPDATE_MENUS actions", data);
					commit("UPDATE_MENUS", data);
				});
			},
			UPDATE_WEATHER({ getters, commit }) {
				if (!getters.isAuthenticated) return;

				let { countryCode, cityCode } = getters.master;

				let sDt = new Date().format("yyyyMMdd0000");
				let eDt = new Date().format("yyyyMMdd2359");
				backEndApi.weather.searchWeatherByPeriod(countryCode, cityCode, sDt, eDt).then(({ data }) => {
					commit("UPDATE_WEATHER", data);
				});
			},
			UPDATE_PT_MAP({ getters, commit }) {
				if (!getters.isAuthenticated) return;

				//backEndApi.pointInfo.searchPointAM().then(({ data }) => {
				backEndApi.pointInfo.searchPointAM().then(({ data }) => {
					if (!isEmpty(data)) {
						commit("UPDATE_PT_MAP", data);
					} else {
						console.log("UPDATE_PT_MAP actions data is abnormal. ", data);
					}
				});
			},
			// UPDATE_STRINGS({ getters, commit }) {
			//   if (!getters.isAuthenticated) return;

			//   backEndApi.engineering.searchStrings().then(({ data }) => {
			//     commit("UPDATE_STRINGS", data);
			//   });
			// },
			UPDATE_TRANS({ getters, commit }) {
				if (!getters.isAuthenticated) return;

				backEndApi.login.searchTrans().then(({ data }) => {
					commit("UPDATE_TRANS", data);
				});
			},
		},
	});

	export default store;
</script>

<template>
  <div class="colorpicker-box" @click="onTogglePicker">
    <div class="colorpicker-color" :style="{ background: current }"></div>

    <div v-if="isShowPicker" class="xe-color-picker">
      <ul role="listbox" class="dropdown-menu">
        <li v-for="(color, i) in colorSet" :key="i" role="option" @click="onSelectColor(color)">
          <div class="colorpicker-color" :style="{ background: color }"></div>
        </li>
        <li role="option" @click="onSelectRandomColor()">
          <div class="colorpicker-color text-center" style="border: 1px dashed #bcbcbc;"><i class="fa fa-question"></i></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style>
.colorpicker-box {
  background: #d7dfe6;
  padding: 7px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.colorpicker-color {
  width: 21px;
  height: 21px;
}

.xe-color-picker > ul {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 400px;
  display: block;
}
.xe-color-picker > ul > li {
  float: left;
  width: 25px;
  cursor: pointer;
  margin: 2px;
}
</style>

<script>
import Resource from "../Resource.js";

export default {
  name: "colorPicker",
  props: ["idx", "current", "change-color"],
  data() {
    return {
      isShowPicker: false,
    };
  },
  computed: {
    colorSet() {
      return Resource.colorSet;
    },
  },
  methods: {
    onSelectColor(color) {
      this.$emit("change-color", this.idx, color);
    },
    onTogglePicker(e) {
      e.stopPropagation(); // this will stop propagation of this event to upper level
      this.isShowPicker = !this.isShowPicker;
      if (this.isShowPicker) {
        window.addEventListener("click", () => {
          this.isShowPicker = false;
        });
      } else {
        window.removeEventListener("click", () => {
          this.isShowPicker = false;
        });
      }
    },
    onSelectRandomColor() {
      let randomColor = this.getRandomColor();

      this.$emit("change-color", this.idx, randomColor);
    },
  },
};
</script>

<template>
	<div>
		<div class="row form-group justify-content-content" v-if="rowsData && selectViewGroup !== 'workflow'">
			<div class="col-3">
				<div class="row">
					<div class="col-3 m-auto">
						<span class="m-auto"> {{ $t("그룹") }}: </span>
					</div>
					<div class="col-9">
						<select class="form-control" v-model="selectWorkflowGroup">
							<option value="All"> {{ $t("전체") }} </option>
							<option v-for="(group, i) in workflowGroups" :key="i">{{ group.name }}</option>
						</select>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="row form-group" v-if="calcPointData">
			<div class="col-12">
				<table class="table table-bordered m-b-0 text-center">
					<thead class="bg-warning">
						<tr>
							<th :style="{ color: '#ffffff' }">
								기록시간
							</th>
						</tr>
						<tr>
							<th v-for="(head, i) in calcPointList" :key="`head${i}`" :style="{ color: '#ffffff' }">
								{{ head.ptAddr }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td v-for="(data, i) in calcPointList" :key="`originvalue${i}`">
								{{ data.ptVal }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div> -->

		<!-- <b-tabs> </b-tabs> -->
		<!-- <b-tabs> -->
		<!-- <b-tab title="선택 계산식 관제점 값" v-if="calcPointList.length !== 0 && selectViewGroup !== 'group'" active> -->
		<div class="row form-group" v-if="calcPointList.length !== 0 && selectViewGroup !== 'group'">
			<div class="col-12">
				<table class="table table-bordered m-b-0 text-center">
					<thead class="bg-warning">
						<tr>
							<th :style="{ color: '#ffffff', minWidth: '150px', verticalAlign: 'middle' }">
								{{ $t("기록 시간") }}
							</th>
							<th v-for="(ptAddr, i) in calcPointList" :key="`head${i}`" :style="{ color: '#ffffff', minWidth: '120px', verticalAlign: 'middle' }">
								{{ `${ptAddr}(${!pointList.find((v) => v.ptAddr === ptAddr) ? "-" : pointList.find((v) => v.ptAddr === ptAddr).ptName})` }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(pointLive, j) in stackedLiveData" :key="`livePoint${j}`">
							<td :style="{ verticalAlign: 'middle' }">
								{{ moment(pointLive.regDt, "YYYYMMDDHHmm").format("YYYY-MM-DD HH:mm") }}
							</td>
							<td v-for="(data, i) in pointLive.datas" :key="`originvalue${i}`" :style="{ verticalAlign: 'middle' }">
								<!-- {{ data }} -->
								<a v-if="!data">{{ "-" }}</a>
								<a v-else>{{ data.ptVal === null ? "-" : parseFloat(data.ptVal.toFixed(3)) }}</a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<!-- </b-tab> -->
		<!-- <b-tab title="그룹 계산식 적용 값" v-if="rowsData && selectViewGroup !== 'workflow'"> -->
		<div class="row form-group" v-if="rowsData && selectViewGroup !== 'workflow'">
			<div class="col-lg-12">
				<div>
					<table class="table table-bordered m-b-0">
						<thead>
							<tr :style="{ textAlign: 'center' }">
								<th class="bg-primary width-30" :style="{ verticalAlign: 'middle', minWidth: '150px', color: '#fff' }">{{ $t("기록시간") }}</th>
								<th
									class="cursor-pointer bg-primary"
									v-for="(text, i) in headerColumns.columns"
									:key="i"
									:style="{ verticalAlign: 'middle', minWidth: '120px', color: '#fff' }"
									data-toggle="tooltip"
									title="Some tooltip text!"
								>
									{{ text }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(rows, i) in rowsData" :key="i" class="cursor-pointer" :style="{ textAlign: 'center' }">
								<td :style="{ verticalAlign: 'middle' }">
									{{ moment(rows.regDt, "YYYYMMDDHHmm").format("YYYY-MM-DD HH:mm") }}
								</td>

								<td v-for="(row, j) in rows.datas" :key="j" :class="[isSelect(row) ? 'bg-orange' : 'bg-white']" :style="{ verticalAlign: 'middle' }">
									<!-- {{ row }} -->
									{{ Number(row.ptVal).toFixed(3) ?? "-" }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<!-- </b-tab> -->
		<!-- </b-tabs> -->
		<!-- <div class="p-t-5" v-if="calcPointList.length !== 0 && selectViewGroup !== 'group'">
			<b-button class="bg-warning border-warning" v-b-toggle:calcPointData squared>
				선택 계산식 관제점 값
				<span class="when-open">
					<b-icon icon="caret-up-fill"></b-icon>
				</span>
				<span class="when-closed">
					<b-icon icon="caret-down-fill"></b-icon>
				</span>
			</b-button>

			<b-collapse id="calcPointData">
				<div class="row form-group">
					<div class="col-12">
						<table class="table table-bordered m-b-0 text-center">
							<thead class="bg-warning">
								<tr>
									<th :style="{ color: '#ffffff', minWidth: '100px' }">
										기록시간
									</th>
									<th v-for="(ptAddr, i) in calcPointList" :key="`head${i}`" :style="{ color: '#ffffff', minWidth: '120px' }">
										{{ ptAddr }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(pointLive, j) in stackedLiveData" :key="`livePoint${j}`">
									<td :style="{ verticalAlign: 'middle' }">
										{{ moment(pointLive.regDt, "YYYYMMDDHHmm").format("YYYY-MM-DD HH:mm") }}
									</td>
									<td v-for="(data, i) in pointLive.datas" :key="`originvalue${i}`" :style="{ verticalAlign: 'middle' }">
										{{ data.ptVal === null ? "-" : parseFloat(data.ptVal.toFixed(3)) }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</b-collapse>
		</div>


		<div class="p-t-5" v-if="rowsData && selectViewGroup !== 'workflow'">
			<b-button class="bg-primary border-primary" v-b-toggle:inRowData squared>
				그룹 계산식 적용 값

				<span class="when-open">
					<b-icon icon="caret-up-fill"></b-icon>
				</span>
				<span class="when-closed">
					<b-icon icon="caret-down-fill"></b-icon>
				</span>
			</b-button>
			<b-collapse id="inRowData">
				<div class="row form-group">
					<div class="col-lg-12">
						<div>
							<table class="table table-bordered m-b-0">
								<thead>
									<tr :style="{ textAlign: 'center' }">
										<th class="bg-primary width-30" :style="{ verticalAlign: 'middle', minWidth: '100px', color: '#fff' }">{{ $t("기록시간") }}</th>
										<th
											class="cursor-pointer bg-primary"
											v-for="(text, i) in headerColumns.columns"
											:key="i"
											:style="{ verticalAlign: 'middle', minWidth: '120px', color: '#fff' }"
											data-toggle="tooltip"
											title="Some tooltip text!"
										>
											{{ text }}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(rows, i) in rowsData" :key="i" class="cursor-pointer" :style="{ textAlign: 'center' }">
										<td :style="{ verticalAlign: 'middle' }">
											{{ moment(rows.regDt, "YYYYMMDDHHmm").format("YYYY-MM-DD HH:mm") }}
										</td>

										<td v-for="(row, j) in rows.datas" :key="j" :class="[isSelect(row) ? 'bg-orange' : 'bg-white']" :style="{ verticalAlign: 'middle' }">
											{{ Number(row.ptVal).toFixed(3) }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</b-collapse>
		</div> -->

		<!-- <b-tooltip target="tooltip-target-1" triggers="hover"> 툴팁 </b-tooltip> -->
	</div>
</template>

<style>
	.collapsed > .when-open,
	.not-collapsed > .when-closed {
		display: none;
	}
</style>

<script>
	import moment from "moment";

	export default {
		props: ["selectViewGroup", "currentWorkflow", "currentUpl", "liveReals", "currentLiveDate", "select-row", "calcPointData", "pointList", "ptMapData", "commonCodeList", "calcPointList"],
		data() {
			return {
				moment,
				etcSelectInfoList: [],
				tooltipData: {},
				selectWorkflowGroup: "ALL",
			};
		},
		mounted() {
			console.log(this.pointList);

			this.etcSelectInfoList.push({ label: "관제점 단위", code: "unitSmallCodeName" });
			this.etcSelectInfoList.push({ label: "사용 에너지원", code: "energySourceCode" });
			this.etcSelectInfoList.push({ label: "에너지공급구분코드", code: "energySplyTypeCode" });
			this.etcSelectInfoList.push({ label: "관제점 용도", code: "ItemUseageCode" });
			this.etcSelectInfoList.push({ label: "적용구역", code: "applyZoneIdx" });
		},
		computed: {
			workflowGroups() {
				if (this.currentWorkflow && this.currentWorkflow.workflow && Array.isArray(this.currentWorkflow.workflow.children)) return this.currentWorkflow.workflow.children;
				else return [];
			},

			headerColumns() {
				let columns = [];
				let ptAddrs = [];
				let filterGroups = [];

				if (this.selectWorkflowGroup == "All") filterGroups = this.workflowGroups.filter((group) => group);
				else filterGroups = this.workflowGroups.filter((group) => group.name == this.selectWorkflowGroup);

				filterGroups.map((group) => {
					columns = columns.concat(group.children.map((v) => v.name));
					ptAddrs = ptAddrs.concat(group.children.map((v) => v.ptAddr));
				});
				this.$store.commit("SET_COST_INFO_COLUMN_LIST", ptAddrs);

				return { columns, ptAddrs };
			},
			isHighlight() {
				return (text) => {
					return this.isEmpty(this.currentUpl) || this.currentUpl.name != text ? false : true;
				};
			},

			// fullColumns() {
			//   // 해당 workflow의 모든 계산식 이름과 주소값
			//   let columns = [];
			//   let ptAddrs = [];

			//   this.workflowGroups.map((group) => {
			//     columns = columns.concat(group.children.map((v) => v.name));
			//     ptAddrs = ptAddrs.concat(group.children.map((v) => v.ptAddr));
			//   });

			//   return { columns, ptAddrs };
			// },
			isSelect() {
				return (row) => {
					return this.currentLiveDate == row.ptVal;
				};
			},
			rowsData() {
				let a = this.$store.getters.FILTER_COST_INFO_LIVE_LIST;
				return a.reverse();
			},

			stackedLiveData() {
				let point = this.$store.getters.GET_WORKFLOW_SELECT_DATA;
				return point.reverse();
			},
		},
		watch: {
			currentUpl: function(newV) {
				if (newV.uplType == "Start") this.selectWorkflowGroup = "All";
				else if (newV.uplType == "Group") this.selectWorkflowGroup = newV.name;
				else {
					this.workflowGroups.map((group) => {
						let parent = group.children.find((v) => v.name == newV.name);
						if (parent) this.selectWorkflowGroup = group.name;
					});
				}
			},
			liveReals() {
				console.log("live update ::::::");
				// this.rowsData();
				this.$store.commit("UPDATE_COST_INFO_LIVE_LIST", this.headerColumns);
			},
			calcPointList(value) {
				this.$store.commit("SET_COST_INFO_SELECT_POINT_LIST", value);
			},
		},
		methods: {
			onRowClick(rows) {
				this.$emit("select-row", rows[0].ptVal);
			},
			getTooltipData() {},
			// rowsData() {
			// 	let row = [];

			// 	let liveKeys = Object.keys(this.liveReals);

			// 	for (let i = 0; i < liveKeys.length; i++) {
			// 		for (let j = 0; j < this.headerColumns.ptAddrs.length; j++) {
			// 			let ptAddr = this.headerColumns.ptAddrs[j];
			// 			if (liveKeys[i] === ptAddr) {
			// 				let regIdx = row.findIndex((v) => v.regDt === this.liveReals[ptAddr].regDt);

			// 				if (regIdx === -1) {
			// 					row.push({ regDt: this.liveReals[ptAddr].regDt
			//             , datas: [] });
			// 					row[row.length - 1].datas.push({ ptAddr: ptAddr, ptVal: this.liveReals[ptAddr].ptVal });
			// 					break;
			// 				}
			// 				row[regIdx].datas.push({ ptAddr: ptAddr, ptVal: this.liveReals[ptAddr].ptVal });
			// 				break;
			// 			}
			// 		}
			// 	}

			// 	return row;
			// },
		},
	};
</script>

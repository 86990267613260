<template>
  <div v-xe-pm.E>
    <!-- <div class="d-flex flex-row"> -->
      <header-box :title="'데이터 관리 > 계산/집계'" :type="'engineering'" @bindConfig="bindConfig" />
    <!-- </div> -->
    <hr style="margin: 10px 0px 10px 0px" />
    <!-- <div class="row ">
      <div class="col-11">
        <title-icon :title="$t('엔지니어링')" icon="fa-wrench" />
      </div>
      <div class="col-1">
        <button class="btn btn-default pull-right" @click="bindConfig()" v-b-tooltip.hover :title="$t('새로고침')"> <i class="fa fa-redo"></i> {{ $t("새로고침") }} </button>
      </div>
    </div> -->
    <div class="row form-group">
      <div class="col-12">
        <b-tabs nav-class="nav-tabs-inverse" nav-wrapper-class="b-radius-1" active-nav-item-class="text-inverse">
          <!-- 샘플링 -->
          <b-tab active class="p-l-10 bg-white">
            <template slot="title">
              <span class="d-sm-none"> <trans>샘플링</trans> </span>
              <span class="d-sm-block d-none"> <trans>샘플링</trans> </span>
            </template>
            <div class="row form-group">
              <div class="col-12 m-t-10">
                <h5>{{ $t("LAST보간") }}</h5>
              </div>
              <div class="col-12">
                <div class="form-inline">
                  <div class="form-group m-r-10">
                    <label class="m-r-5"><trans>시작일</trans></label>
                    <div style="position: relative">
                      <date-picker class="form-control" :value="condInter.sDt" :config="{ format: 'YYYY-MM-DD HH' }" :placeholder="$t('시작일')" @input="condInter.sDt = $event.parseDate()"></date-picker>
                    </div>
                  </div>
                  <div class="form-group m-r-10">
                    <label class="m-r-5"><trans>종료일</trans></label>
                    <div style="position: relative">
                      <date-picker class="form-control" :value="condInter.eDt" :config="{ format: 'YYYY-MM-DD HH' }" :placeholder="$t('종료일')" @input="condInter.eDt = $event.parseDate()"></date-picker>
                    </div>
                  </div>
                  <div class="form-group m-r-10">
                    <label class="m-r-5"><trans>관제점</trans></label>
                    <input type="text" class="form-control" v-model="condInter.points" style="min-width:200px;" />
                  </div>
                  <button class="btn btn-sm btn-indigo" @click="onRunInterpolationLast()" v-b-tooltip.hover :title="$t('LAST보간 실행')"><i class="fa fa-play m-r-2"></i> {{ $t("LAST보간 실행") }}</button>
                </div>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-12">
                <h5>{{ $t("재샘플링") }}</h5>
              </div>
              <div class="col-12">
                <div class="form-inline">
                  <div class="form-group m-r-10">
                    <label class="m-r-5"><trans>시작일</trans></label>
                    <div style="position: relative">
                      <date-picker class="form-control" :value="condSam.sDt" :config="dpSet" :placeholder="$t('시작일')" @input="condSam.sDt = $event.parseDate()"></date-picker>
                    </div>
                  </div>
                  <div class="form-group m-r-10">
                    <label class="m-r-5"><trans>종료일</trans></label>
                    <div style="position: relative">
                      <date-picker class="form-control" :value="condSam.eDt" :config="dpSet" :placeholder="$t('종료일')" @input="condSam.eDt = $event.parseDate()"></date-picker>
                    </div>
                  </div>
                  <div class="form-group m-r-10">
                    <label class="m-r-5"><trans>관제점</trans></label>
                    <input type="text" class="form-control" v-model="condSam.points" style="min-width:200px;" />
                  </div>
                  <div class="form-group m-r-10">
                    <label class="m-r-5"><trans>디버깅사용</trans></label>
                    <input type="checkbox" v-model="condSam.isDebugWatch" />
                  </div>
                  <button class="btn btn-sm btn-purple m-r-10" @click="onRunSampling()" v-b-tooltip.hover :title="$t('재샘플링 실행')"><i class="fa fa-play m-r-2"></i> {{ $t("재샘플링 실행") }}</button>
                  <button class="btn btn-sm btn-indigo" @click="onRunSummary()" v-b-tooltip.hover :title="$t('재취합 실행')"><i class="fa fa-play m-r-2"></i> {{ $t("재취합 실행") }}</button>
                </div>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-12">
                <h5>{{ $t("로그삭제") }}</h5>
              </div>
              <div class="col-12">
                <div class="form-inline">
                  <div class="form-group m-r-10">
                    <label class="m-r-5"><trans>시작일</trans></label>
                    <div style="position: relative">
                      <date-picker class="form-control" :value="condLog.sDt" :config="dpSet" :placeholder="$t('시작일')" @input="condLog.sDt = $event.parseDate()"></date-picker>
                    </div>
                  </div>
                  <div class="form-group m-r-10">
                    <label class="m-r-5"><trans>종료일</trans></label>
                    <div style="position: relative">
                      <date-picker class="form-control" :value="condLog.eDt" :config="dpSet" :placeholder="$t('종료일')" @input="condLog.eDt = $event.parseDate()"></date-picker>
                    </div>
                  </div>
                  <button class="btn btn-sm btn-purple m-r-10" @click="onDeleteEventLog()" v-b-tooltip.hover :title="$t('이벤트로그 삭제')"><i class="fa fa-play m-r-2"></i> {{ $t("이벤트로그 삭제") }}</button>
                  <button class="btn btn-sm btn-indigo" @click="onDeleteServerLog()" v-b-tooltip.hover :title="$t('서버로그 삭제')"><i class="fa fa-play m-r-2"></i> {{ $t("서버로그 삭제") }}</button>
                </div>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-12">
                <h5>{{ $t("기타") }}</h5>
              </div>
              <div class="col-12 m-b-10">
                <div class="form-inline">
                  <!-- TODO: 전체 삭제임.. summary만 따로 한다던지 해야 함. 그룹명을 정의 해야 함. -->
                  <button class="btn btn-sm btn-purple m-r-10" @click="onClearCache()" v-b-tooltip.hover :title="$t('캐시 지우기')"><i class="fa fa-play m-r-2"></i> {{ $t("캐시 지우기") }}</button>
                </div>
              </div>
            </div>
          </b-tab>
          <!-- 전체백업 -->
          <!-- 
            <b-tab >
            <template slot="title">
              <span class="d-sm-none"> <trans>백업</trans> </span>
              <span class="d-sm-block d-none"> <trans>DB백업</trans> </span>
            </template>
            <div class="row form-group m-t-10">
              <div class="col-6">
                <label> 테이블 </label>
                <select class="form-control" v-model="backupTable">
                  <option v-for="tableName in tableNames" :key="tableName" > {{ tableName }} </option>
                </select>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-6 m-t-10">
                <label> 경로 </label>
                <input type="text" class="form-control" v-model="backupPath" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-6 m-t-10">
                <button class="btn btn-sm btn-purple m-r-10" @click="onBackupDatabase()" v-b-tooltip.hover :title="$t('DB백업')"><i class="fa fa-play m-r-2"></i> {{ $t('DB백업') }}</button>          
              </div>
            </div>
          </b-tab>
          <b-tab >
            <template slot="title">
              <span class="d-sm-none"> <trans>복원</trans> </span>
              <span class="d-sm-block d-none"> <trans>DB복원</trans> </span>
            </template>
            <div class="row form-group">
              <div class="col-6 m-t-10">
                <label> 백업목록 </label>
                <select class="form-control" v-model="restorePath">
                  <option v-for="path in (serverConfig.db && serverConfig.db.dumpFiles) ? serverConfig.db.dumpFiles : []" :key="path" > {{ path }} </option>
                </select>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-6 m-t-10">
                <label> 테이블 </label>
                <select class="form-control" v-model="restoreTable">
                  <option v-for="tableName in tableNames" :key="tableName" > {{ tableName }} </option>
                </select>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-6 m-t-10">
                <button class="btn btn-sm btn-purple m-r-10" @click="onRestoreDatabase()" v-b-tooltip.hover :title="$t('DB복원')"><i class="fa fa-play m-r-2"></i> {{ $t('DB복원') }}</button>          
              </div>
            </div>
          </b-tab> -->
          <!-- DB상태 -->
          <b-tab class="p-l-10 bg-white">
            <template slot="title">
              <span class="d-sm-none"> <trans>DB상태</trans> </span>
              <span class="d-sm-block d-none"> <trans>DB상태</trans> </span>
            </template>
            <div class="row form-group">
              <div class="col-3 m-t-10">
                <button class="btn btn-sm btn-purple m-r-10" @click="onRefreshDb()"><i class="fa fa-play m-r-2"></i> {{ $t("새로고침") }}</button>
              </div>
            </div>
            <div class="row form-group m-t-10">
              <div class="col-3">
                <label> wait_timeout </label>
              </div>
              <div class="col-3">
                <input type="number" min="1" max="10000" class="form-control" v-model="waitTimeout" />
              </div>
              <div class="col-3">
                <button class="btn btn-sm btn-purple m-r-10" @click="onSetWaitTimeout()"><i class="fa fa-play m-r-2"></i> {{ $t("변경") }}</button>
              </div>
            </div>
            <div class="row form-group m-t-10">
              <div class="col-3">
                <label> innodb_lock_wait_timeout </label>
              </div>
              <div class="col-3">
                <input type="number" min="1" max="10000" class="form-control" v-model="lockTimeout" />
              </div>
              <div class="col-3">
                <button class="btn btn-sm btn-purple m-r-10" @click="onSetLockTimeout()"><i class="fa fa-play m-r-2"></i> {{ $t("변경") }}</button>
              </div>
            </div>
            <div class="row form-group m-t-10">
              <div class="col-3">
                <label> max_connections </label>
              </div>
              <div class="col-3">
                <input type="number" min="1" max="10000" class="form-control" v-model="maxConnection" />
              </div>
              <div class="col-3">
                <button class="btn btn-sm btn-purple m-r-10" @click="onSetMaxConnection()"><i class="fa fa-play m-r-2"></i> {{ $t("변경") }}</button>
              </div>
            </div>

            <div class="row form-group m-t-10" v-if="dbVariables">
              <div class="col-6 ">
                Variables: {{ Object.keys(dbVariables).length }} <br />
                <table>
                  <tr v-for="(item, i) in dbVariables" :key="'dbVariables' + i">
                    <td>{{ item.Variable_name }}</td>
                    <td>{{ item.Value }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="row form-group m-t-10" v-if="dbStatus">
              <div class="col-6">
                Status: {{ Object.keys(dbStatus).length }} <br />

                <table>
                  <tr>
                    <td style="width:200px;">Cache Miss Rate(%) = {{ ((dbStatus.Threads_created / dbStatus.Connections) * 100) | filterComma(1) }}</td>
                    <td>{{ dbStatus.Threads_created }}</td>
                    <td>/</td>
                    <td>{{ dbStatus.Connections }}</td>
                    <td>* 100</td>
                    <td>Threads_created / Connections * 100</td>
                  </tr>
                  <tr>
                    <td>Connection Miss Rate(%) = {{ ((dbStatus.Aborted_connects / dbStatus.Connections) * 100) | filterComma(1) }}</td>
                    <td>{{ dbStatus.Aborted_connects }}</td>
                    <td>/</td>
                    <td>{{ dbStatus.Connections }}</td>
                    <td>* 100</td>
                    <td>Aborted_connects / Connections * 100</td>
                  </tr>
                  <tr>
                    <td>Connection Usage(%) = {{ ((dbStatus.Threads_connected / dbStatus.Max_used_connections) * 100) | filterComma(1) }}</td>
                    <td>{{ dbStatus.Threads_connected }}</td>
                    <td>/</td>
                    <td>{{ dbStatus.Max_used_connections }}</td>
                    <td>* 100</td>
                    <td>Threads_connected / max_connections * 100</td>
                  </tr>
                </table>

                <br />
                <br />
                <table>
                  <tr>
                    <td>
                      <pre>
Connection Usage(%)가 100%라면 max_connections 수를 증가시켜야 한다. 
Connection 수가 부족할 경우 Too Many Connection Error가 발생한다.

DB서버의 접속이 많은 경우 wait_timeout을 최대한 적게 설정하여 불필요한 연결을 빨리 정리하는 것이 좋다. 
그러나 Connection Miss Rate(%)가 1% 이상이면 wait_timeout을 좀 더 길게 설정한다.

MySQL 서버는 외부로부터 접속 요청을 받을 경우 인증을 위해 IP 주소를 호스트네임으로 바꾸는 과정을 수행하여 접속 시에 불필요한 부하가 발생한다. 
skip-name-resolve를 설정하고 접속시 IP기반으로 접속을 하게 되면 hostname lookup 과정을 생략하게 되어 좀 더 빠르게 접속할 수 있다.

https://m.blog.naver.com/PostView.nhn?blogId=jevida&logNo=220370360735&proxyReferer=https:%2F%2Fwww.google.com%2F
</pre
                      >
                    </td>
                  </tr>
                </table>

                <!-- <table>
                  <tr v-for="(item, i) in dbStatus" :key="'dbStatus'+i">
                    <td> {{ item.Variable_name }} </td>
                    <td> {{ item.Value }} </td>
                  </tr>
                </table> -->
              </div>
            </div>
          </b-tab>
          <!-- 기본데이터 -->
          <b-tab class="p-l-10 bg-white">
            <template slot="title">
              <span class="d-sm-none"> <trans>기본데이터</trans> </span>
              <span class="d-sm-block d-none"> <trans>기본데이터</trans> </span>
            </template>
            <div class="row form-group">
              <div class="col-6 m-t-10">
                <!-- <label><trans>마스터코드</trans></label> -->
                <h5>{{ $t("마스터코드") }}</h5>
                <input type="text" class="form-control" :placeholder="$t('마스터코드를 입력하세요.')" v-model="masterCode" />
              </div>
            </div>
            <div class="row form-group m-t-10">
              <div class="col-6">
                <div class="form-inline m-b-10">
                  <button class="btn btn-sm btn-purple m-r-10" @click="onInitGroup()" v-b-tooltip.hover :title="$t('메뉴,그룹,사용자,서버')"><i class="fa fa-play m-r-2"></i> {{ $t("기본데이터 초기화") }}</button>
                  <div class="form-group m-r-10">
                    <label class="m-r-5"><trans>전체 삭제 후 입력</trans></label>
                    <input type="checkbox" v-model="isGroupBeforeClear" />
                  </div>
                  <div class="form-group m-r-10">
                    메뉴,그룹,사용자,서버
                  </div>
                </div>
              </div>
            </div>
            <div class="row form-group m-t-10">
              <div class="col-6">
                <div class="form-inline m-b-10">
                  <button class="btn btn-sm btn-purple m-r-10" @click="onInitDriver()" v-b-tooltip.hover :title="$t('드라이버')"><i class="fa fa-play m-r-2"></i> {{ $t("드라이버 초기화") }}</button>
                  <div class="form-group m-r-10">
                    <label class="m-r-5"><trans>전체 삭제 후 입력</trans></label>
                    <input type="checkbox" v-model="isDriverBeforeClear" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row form-group m-t-10">
              <div class="col-6">
                <div class="form-inline m-b-10">
                  <button class="btn btn-sm btn-purple m-r-10" @click="onInitPoint()" v-b-tooltip.hover :title="$t('기본관제점')"><i class="fa fa-play m-r-2"></i> {{ $t("기본관제점 초기화") }}</button>
                  <div class="form-group m-r-10">
                    <label class="m-r-5"><trans>전체 삭제 후 입력</trans></label>
                    <input type="checkbox" v-model="isPointBeforeClear" />
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="row form-group m-t-10">
              <div class="col-6 m-t-10">
                <button class="btn btn-sm btn-purple m-r-10" @click="onRegistDriver()" v-b-tooltip.hover :title="$t('에이전트 드라이버 재등록')"><i class="fa fa-play m-r-2"></i> {{ $t('드라이버 등록') }}</button>
              </div>
            </div> -->

            <div class="row form-group" v-if="apiResult">
              <div class="col-6 m-t-10">
                Rows: {{ Object.keys(apiResult).length }} <br />
                <pre>
                  {{ apiResult }}
                </pre>
              </div>
            </div>
          </b-tab>
          <!-- 기타 -->
          <b-tab class="p-l-10 bg-white">
            <template slot="title">
              <span class="d-sm-none"> <trans>기타</trans> </span>
              <span class="d-sm-block d-none"> <trans>기타</trans> </span>
            </template>
            <div class="row form-group">
              <div class="col-6 m-t-10">
                데이터 이상분석 해야 함. <br />
                <br />
                tb_point에 없는데 tb_total_real등에는 존재하는 주소들.. <br />
                tb_point에 있는데 tb_total_real에 초기값이 없는 것.. <br />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-6">
                <button class="btn btn-sm btn-purple m-r-10" @click="onDispatchStrings()" v-b-tooltip.hover :title="$t('다국어문자열 가져오기')"><i class="fa fa-play m-r-2"></i> {{ $t("다국어문자열 가져오기") }}</button>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-6">
                <button class="btn btn-sm btn-purple m-r-10" @click="onDispatchTrans()" v-b-tooltip.hover :title="$t('메뉴다국어 가져오기')"><i class="fa fa-play m-r-2"></i> {{ $t("메뉴다국어 가져오기") }}</button>
              </div>
            </div>

            <div class="row form-group">
              <div class="col-6">
                <input type="text" class="form-control" v-model="testStr" />
                
              </div>
            </div>

            <div class="row form-group">
              <div class="col-6">
                <h6>Result : {{ $t(testStr) }}</h6>
                <h6>Trans : <trans> {{ testStr }} </trans></h6>
                <h6>
                  <a href="javascript:;" @click="toggleStr.strings = !toggleStr.strings">
                    Strings: {{ Object.keys(strings).length }}
                  </a>
                  <pre v-if="toggleStr.strings">
                    {{ strings }}
                  </pre>
                </h6>
                <h6>
                  <a href="javascript:;" @click="toggleStr.strErrors = !toggleStr.strErrors">
                    Error Strings: {{ Object.keys(strErrors).length }}
                  </a>
                  <pre v-if="toggleStr.strErrors">
                    {{ strErrors }}
                  </pre>
                </h6>
              </div>
            </div>

            <div class="row form-group">
              <div class="col-6">
                
              </div>
            </div>

          </b-tab>
          <!-- 단위변환 테스트 -->
          <b-tab class="p-l-10 bg-white">
            <template slot="title">
              <span class="d-sm-none"> 
                <trans>단위</trans> 
              </span>
              <span class="d-sm-block d-none"> <trans>단위변환 테스트</trans> </span>
            </template>
            <div class="row form-group">
              <div class="col-md-6 m-t-10">
                <!-- TODO: 단위에 W 가 있는데    W (unknown)  으로 표시됨 -->
                <!-- <label><trans>단위</trans></label> -->
                <h5>{{ $t("단위") }}</h5> 
                <tree-select
                  v-model="testPoint.unit"
                  :load-options="loadOptions"
                  :placeholder="controlMessages.UNIT_TREE_PLACEHOLDER_MESSAGE"
                  :noChildrenText="controlMessages.UNIT_TREE_NOCHILDERN_MESSAGE"
                  :noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)"
                  :multiple="false"
                  :options="unitOptions"
                  :disable-branch-nodes="true"                  
                  />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-6">
                <!-- <label><trans>원본단위</trans></label> -->
                <h5>{{ $t("원본단위") }}</h5> 
                <tree-select
                  v-model="testPoint.rawUnit"
                  :load-options="loadOptions"
                  :placeholder="controlMessages.ORG_UNIT_TREE_PLACEHOLDER_MESSAGE"
                  :noChildrenText="controlMessages.ORG_UNIT_TREE_NOCHILDERN_MESSAGE"
                  :noResultsText="$t(controlMessages.COMMON_TREE_NOSEARCH_MESSAGE)"
                  :multiple="false"
                  :options="unitOptions"
                  :disable-branch-nodes="true"
                  />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-6">
                <div class="bg-white p-30 p-l-50 b-1 m-b-10" style="height:100px;">
                  <div class="form-inline m-b-10">
                    <span class="m-l-30 m-r-10"><trans>테스트값</trans></span> <input type="number" class="form-control m-r-10" :placeholder="$t('테스트값을 입력하세요.')" v-model="testPoint.testVal" />
                    <button class="btn btn-success" @click="onConvertUnit()"><i class="fa fa-bullhorn"></i> {{ $t("실행") }}</button>
                  </div>
                </div>
                <div class="m-b-10">
                  <!-- 변환결과 : {{ testPoint.convertVal }} -->
                  <h6>{{ $t("변환결과 : ") }} {{ testPoint.convertVal }}</h6> 
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<style>

/* 
TODO: 달력 좌,우 아이콘 사라진거 복구해 볼라 했는데.. 안되었음.
.bootstrap-datetimepicker-widget table {
  background-color: blue !important;
  height: 40px !important;
  margin: 0px !important;
  padding: 0px !important;
  border: 0 !important;
}

.bootstrap-datetimepicker-widget table th {
  background-color: aqua !important;
  height: 40px !important;
  margin: 0px !important;
  padding: 0px !important;
  border: 0 !important;
}

.bootstrap-datetimepicker-widget table th.prev {
  background-color: red !important;
  width: 40px !important;
  height: 40px !important;
  margin: 0px !important;
  padding: 0px !important;
  border: 0 !important;
}

.bootstrap-datetimepicker-widget table th.prev i {
  background-color: yellow !important;
  width: 40px !important;
  height: 40px !important;
  margin: 0px !important;
  padding: 0px !important;
  border: 0 !important;
} */
</style>

<script>
import backEndApi from "@api/backEndApi";
import HeaderBox from "../../component/headerBox/sys/Header.vue";
import xelib from "xelib";
import * as popupMessages from "@src/consts/popupMessageConsts";
import * as controlMessages from "@src/consts/controlMessageConsts";

const { Unit } = xelib.PointBox;

// Unit 라이브러리에서 type을 키로 잡아서 tree-select option을 생성함.
const unitOptions = (function() {
  return Object.keys(Unit.types).map((type, i) => {
    let children = Object.keys(Unit.types[type]).map((v) => {
      // return { id: i}-${j, label: v };
      return { id: v, label: v };
    });

    return { id: i, label: type, children: children };
  });
})();



export default {
  props: [],
  data() {
    return {
      unitOptions: unitOptions,
      controlMessages,
      testPoint: {
        unit: "kW",
        rawUnit: "W",
        testVal: 1000.123,
        convertVal: -1,
      },

      serverConfig: { status: "empty" },

      dpSet: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
        showClear: true,
        showClose: true,
        minDate: new Date(2018, 1, 1),
        maxDate: false,
      },

      condInter: {
        sDt: new Date().format("yyyy-MM-dd 00").parseDate(),
        eDt: new Date().format("yyyy-MM-dd 23").parseDate(),
        // sDt: "2020-01-01 00:00".parseDate(),
        // eDt: "2020-01-01 01:00".parseDate(),
        points: "",
      },

      condSam: {
        sDt: new Date().format("yyyy-MM-dd 00:00").parseDate(),
        eDt: new Date().format("yyyy-MM-dd 23:59").parseDate(),
        // sDt: "2020-01-01 00:00".parseDate(),
        // eDt: "2020-01-01 00:14".parseDate(),
        points: "",
        isDebugWatch: false,
      },
      condLog: {
        sDt: new Date().format("yyyy-MM-dd 00:00").parseDate(),
        eDt: new Date().format("yyyy-MM-dd 23:59").parseDate(),
      },

      //tableNames: [ 'tb_server', 'tb_total_log', 'tb_summary_1min', 'tb_summary_15min', 'tb_summary_hour', 'tb_summary_day', 'tb_summary_month', 'tb_summary_year', 'tb_event_log' ],
      //backupPath: "",
      //backupTable:"",
      //restorePath:"",
      //restoreTable: "",

      // 데이터 초기화
      masterCode: "gtwill",
      isGroupBeforeClear: false,
      isDriverBeforeClear: false,
      isPointBeforeClear: false,

      apiResult: "",

      // DB상태
      dbVariables: [],
      dbStatus: [],
      maxConnection: 0, // 6000,
      lockTimeout: 0, // 300,
      waitTimeout: 0, // 86400,

      testStr: "",
      toggleStr:{ strings: false, strErrors: false },
    };
  },
  components:{
    HeaderBox
  },
  computed: {
    wssUrl() {
      return this.$store.getters.wssUrl;
    },
    dbUrl() {
      if (this.isEmpty(this.serverConfig.xemsmst)) return "";
      return `${this.serverConfig.xemsmst.dbType}://${this.serverConfig.xemsmst.host}:${this.serverConfig.xemsmst.port}/${this.serverConfig.xemsmst.database}`;
    },
    simulatorUrl() {
      if (this.isEmpty(this.serverConfig.simulator)) return "";
      return `http://${this.serverConfig.simulator.host}:${this.serverConfig.simulator.port}/`;
    },
    strings() {
      return this.readStorage("strings");
    },
    strErrors(){
      return global.xe.strErrors;
    },
  },
  watch: {
    // backupTable(){
    //   this.backupPath = this.serverConfig.db.dumpPath}/backup_${this.backupTable}_full_${new Date().format('yyyyMMdd_HHmmss')}.csv`;
    // },
  },
  mounted() {
    this.initSetting();
  },
  methods: {
    initSetting() {
      this.$store.dispatch("UPDATE_PT_MAP");

      this.bindConfig();

      this.onRefreshDb();
    },
    onConvertUnit() {
      this.testPoint.convertVal = Unit.convert(this.testPoint.testVal, this.testPoint.rawUnit, this.testPoint.unit);
    },
    bindConfig() {
      backEndApi.engineering.getConfig().then(({ data }) => {
        if (this.$err(data)) return;

        this.serverConfig = data;

        // this.serverConfig.db.dumpFiles.push("D:/Database/Dump/xemsv20/backup_tb_server_full_20201030_130308.csv");
      });
    },
    onClearCache() {
      this.alertConfirmWarning(popupMessages.DATA_BACKUP_CASH_DELETE_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        backEndApi.engineering.clearCache().then(({ data }) => {
          if (this.$err(data)) return;
        });
      });
    },

    onRegistDriver() {
      this.alertQuestion(popupMessages.DATA_BACKUP_DRIVER_REGIST_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        let isSync = true;

        backEndApi.driver.registDriver(isSync).then(({ data }) => {
          if (this.$err(data)) return;

          this.apiResult = data;
          this.alertNoti(popupMessages.DATA_BACKUP_COMMON_SYNC_POPUP_MESSAGE);
        });
      });
    },
    // DB 데이터 초기화
    onInitGroup() {
      let masterCode = this.masterCode;
      let msg = this.isGroupBeforeClear ? popupMessages.DATA_BACKUP_COMMON_RUN_AFTER_DELETE_POPUP_MESSAGE : popupMessages.DATA_BACKUP_COMMON_RUN_POPUP_MESSAGE;

      this.alertConfirmWarning(msg).then((result) => {
        if (!result.value) return;

        backEndApi.engineering.initGroup(masterCode, this.isGroupBeforeClear).then(({ data }) => {
          if (this.$err(data)) return;

          this.apiResult = data;
          this.alertNoti(popupMessages.DATA_BACKUP_COMMON_SYNC_POPUP_MESSAGE);
        });
      });
    },
    onInitDriver() {
      let msg = this.isGroupBeforeClear ? popupMessages.DATA_BACKUP_COMMON_RUN_AFTER_DELETE_POPUP_MESSAGE : popupMessages.DATA_BACKUP_COMMON_RUN_POPUP_MESSAGE;

      this.alertConfirmWarning(msg).then((result) => {
        if (!result.value) return;

        backEndApi.engineering.initDriver(this.isDriverBeforeClear).then(({ data }) => {
          if (this.$err(data)) return;

          this.apiResult = data;
          this.alertNoti(popupMessages.DATA_BACKUP_COMMON_SYNC_POPUP_MESSAGE);
        });
      });
    },
    onInitPoint() {
      let masterCode = this.masterCode;
      let msg = this.isPointBeforeClear ? popupMessages.DATA_BACKUP_COMMON_RUN_AFTER_DELETE_POPUP_MESSAGE : popupMessages.DATA_BACKUP_COMMON_RUN_POPUP_MESSAGE;

      this.alertConfirmWarning(msg).then((result) => {
        if (!result.value) return;

        backEndApi.engineering.initPoint(masterCode, this.isPointBeforeClear).then(({ data }) => {
          if (this.$err(data)) return;

          this.apiResult = data;
          this.alertNoti(popupMessages.DATA_BACKUP_COMMON_SYNC_POPUP_MESSAGE);
        });
      });
    },

    // onBackupDatabase() {

    //   let filePath = this.backupPath;
    //   let tableName = this.backupTable;
    //   let isOverwrite = true;

    //   this.alertQuestion("진행하시겠습니까?", "DB백업", tableName).then((result) => {
    //     if (!result.value) return;

    //     backEndApi.engineering.backup(tableName, filePath, isOverwrite).then(({ data }) => {
    //       if (this.$err(data)) return;

    //       this.alertNoti(`데이터 동기화를 요청하였습니다.`);
    //     });

    //   });
    // },
    // onRestoreDatabase() {

    //   let filePath = this.restorePath;
    //   let tableName = this.restoreTable;

    //   this.alertQuestion("진행하시겠습니까?", "DB복원", tableName).then((result) => {
    //     if (!result.value) return;

    //     backEndApi.engineering.restore(tableName, filePath).then(({ data }) => {
    //       if (this.$err(data)) return;

    //       this.alertNoti(`데이터 동기화를 요청하였습니다.`);
    //     });

    //   });
    // },
    onRunInterpolationLast() {
      this.alertQuestion(popupMessages.DATA_BACKUP_LAST_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        let { sDt, eDt, points } = this.condInter;

        backEndApi.live.interpolationLast(sDt.format("yyyyMMddHHmmss"), eDt.format("yyyyMMddHHmmss"), points).then(({ data }) => {
          if (this.$err(data)) return;

          // this.alertNoti(`데이터 동기화를 요청하였습니다.`);
        });
      });
    },
    onRunSampling() {
      this.alertQuestion(popupMessages.DATA_BACKUP_RESAMPLING_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        let { sDt, eDt, points, isDebugWatch } = this.condSam;

        backEndApi.live.reSampling(sDt.format("yyyyMMddHHmmss"), eDt.format("yyyyMMddHHmmss"), points, { isDebugWatch }).then(({ data }) => {
          if (this.$err(data)) return;

          // this.alertNoti(`데이터 동기화를 요청하였습니다.`);
        });
      });
    },
    onRunSummary() {
      this.alertQuestion(popupMessages.DATA_BACKUP_RECOLLECTION_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        let { sDt, eDt, points } = this.condSam;

        backEndApi.live.runSummary(5, sDt.format("yyyyMMddHHmmss"), eDt.format("yyyyMMddHHmmss"), points).then(({ data }) => {
          if (this.$err(data)) return;

          // this.alertNoti(`데이터 동기화를 요청하였습니다.`);
        });
      });
    },
    onDeleteEventLog() {
      this.alertConfirmWarning(popupMessages.DATA_BACKUP_EVENTLOG_DELETE_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        let { sDt, eDt } = this.condLog;

        backEndApi.eventLog.deleteEventLogByPeriod(sDt, eDt).then(({ data }) => {
          if (this.$err(data)) return;

          // this.alertNoti(`이벤트로그 ${data}건 삭제했습니다.`);
        });
      });
    },
    onDeleteServerLog() {
      this.alertConfirmWarning(popupMessages.DATA_BACKUP_SERVERLOG_DELETE_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        let { sDt, eDt } = this.condLog;

        backEndApi.server.deleteServerLogByPeriod(sDt, eDt).then(({ data }) => {
          if (this.$err(data)) return;

          // this.alertNoti(`서버로그 ${data}건 삭제했습니다.`);
        });
      });
    },
    onDispatchStrings() {
      this.alertQuestion(popupMessages.DATA_BACKUP_MULTILINGUAL_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        this.$store.dispatch("UPDATE_STRINGS");
      });
    },
    onDispatchTrans() {
      this.alertQuestion(popupMessages.DATA_BACKUP_MENU_MULTILINGUAL_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        this.$store.dispatch("UPDATE_TRANS");
      });
    },

    onRefreshDb() {
      this.bindDbVariables();
      this.bindDbStatus();
    },
    bindDbVariables() {
      backEndApi.engineering.getDbVariables().then(({ data }) => {
        if (this.$err(data)) return;

        this.dbVariables.range(data);

        this.maxConnection = parseInt(data.find((v) => v.Variable_name == "max_connections").Value);
        this.lockTimeout = parseInt(data.find((v) => v.Variable_name == "innodb_lock_wait_timeout").Value);
        this.waitTimeout = parseInt(data.find((v) => v.Variable_name == "wait_timeout").Value);
      });
    },

    bindDbStatus() {
      backEndApi.engineering.getDbStatus().then(({ data }) => {
        if (this.$err(data)) return;

        //this.dbStatus.range(data);

        let obj = {};
        data.forEach((v) => {
          obj[v.Variable_name] = parseFloat(v.Value);
        });
        this.dbStatus = obj;
      });
    },
    onSetMaxConnection() {
      this.alertQuestion(popupMessages.DATA_BACKUP_MAX_CONNECTION_UPDATE_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        backEndApi.engineering.setMaxConnection(this.maxConnection).then(({ data }) => {
          if (this.$err(data)) return;

          // this.alertNoti(`데이터를 수정하였습니다.`);
        });
      });
    },
    onSetLockTimeout() {
      this.alertQuestion(popupMessages.DATA_BACKUP_LOCKDOWN_UPDATE_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        backEndApi.engineering.setLockTimeout(this.lockTimeout).then(({ data }) => {
          if (this.$err(data)) return;

          // this.alertNoti(`데이터를 수정하였습니다.`);
        });
      });
    },
    onSetWaitTimeout() {
      this.alertQuestion(popupMessages.DATA_BACKUP_CALL_WAIT_UPDATE_POPUP_MESSAGE).then((result) => {
        if (!result.value) return;

        backEndApi.engineering.setWaitTimeout(this.waitTimeout).then(({ data }) => {
          if (this.$err(data)) return;

          // this.alertNoti(`데이터를 수정하였습니다.`);
        });
      });
    },
    loadOptions({callback}){
      callback();
    }
  },
};
</script>

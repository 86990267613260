<template>
	<div>
		<div class="alert alert-info fade show">'{{ changeUser.userId }}' {{ $t("사용자의 비밀번호를 변경합니다.") }}</div>
		<vvo v-slot="v" ref="changePwdVal">
			<div class="row form-group">
				<div class="col-md-12">
					<label><trans>현재 비밀번호</trans></label>
					<valid-input :vid="'현재 비밀번호'" :inputType="'password'" :inputValue.sync="current" :rules="''" :placeholder="$t('현재 비밀번호 입력')"></valid-input>
				</div>
			</div>
			<div class="row form-group">
				<div class="col-md-12">
					<label><trans>새 비밀번호</trans></label>
					<valid-input
						:vid="'새 비밀번호'"
						:inputType="'password'"
						:inputValue.sync="newPwd"
						:rules="rules.PASSWORD_CHANGE_NEW(changeUser.userId, current)"
						:placeholder="$t('새 비밀번호 입력')"
						:errorMessage="$t(validMessage.PASSWORD_CHANGE_VALID_MESSAGE)"
					></valid-input>
				</div>
			</div>
			<div class="row form-group">
				<div class="col-md-12">
					<label><trans>새 비밀번호 (확인용)</trans></label>
					<valid-input
						:vid="'새 비밀번호 (확인용)'"
						:inputType="'password'"
						:inputValue.sync="confirmPwd"
						:rules="rules.PASSWORD_CHANGE_CONFIRM('새 비밀번호')"
						:placeholder="$t('새 비밀번호 입력 (확인용)')"
						:errorMessage="$t(validMessage.PASSWORD_CHANGE_CONFIRM_VALID_MESSAGE)"
					></valid-input>

					<!-- <valid-input :inputType="'password'" :inputValue.sync="confirmPwd" :rules="`same-new-password:${newPwd" :placeholder="$t('밀번호 재입력')"></valid-input> -->
				</div>
			</div>
			<legend />
			<div slot="modal-footer" class="w-100">
				<button class="btn btn-sm btn-primary m-r-5 pull-right" @click="onValidate(v, onSavePwd)"><i class="fa fa-save"></i> {{ $t("저장") }}</button>
			</div>
		</vvo>
	</div>
</template>

<script>
	import backEndApi from "../../../../api/backEndApi.js";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import AppErrorHandler from "@src/appErrorHandler.js";
	import { StatusCodes } from "http-status-codes";

	export default {
		props: ["changeUser", "save-done"],
		data() {
			return {
				current: "",
				newPwd: "",
				confirmPwd: "",
				rules,
				validMessage,
			};
		},
		methods: {
		
			async onSavePwd() {
				// if (this.isEmpty(this.current) || this.isEmpty(this.newPwd) || this.isEmpty(this.confirmPwd)) {
				// 	this.alertConfirmWarning(popupMessages.PERMISSION_USER_RESET_EMPTY_PASSWORD_POPUP_MESSAGE);
				// 	return;
				// }

				// changeUserPwd 함수내에서 현재 로그인한 사용자의 비번 current가 맞는지 where절에서 확인하고 있음.

				let obj = { userId: this.changeUser.userId, current: this.current, newPwd: this.newPwd };

				const popupResult = await this.alertConfirmWarning(popupMessages.PERMISSION_USER_RESET_PASSWORD_POPUP_MESSAGE);
				if (!popupResult.value) return;

				try {
					await backEndApi.user.changeUserPwd(obj);
					this.alertNoti(popupMessages.PERMISSION_USER_RESET_PASSWORD_SUCCESS_POPUP_MESSAGE);
					this.$emit("save-done");
				} catch (err) {
          console.log(err);
					new AppErrorHandler(err)
						.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
						.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR, popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
						.createBizHandle(this.ERR_KIND.UNKNOWN_ERROR, popupMessages.PERMISSION_USER_WRONG_ORIGIN_PASSWORD_POPUP_MESSAGE, this.alertDanger)
						.createBizHandle(this.ERR_KIND.INVALID_ARGUMENT, popupMessages.PERMISSION_USER_WRONG_PASSWORD_POPUP_MESSAGE, this.alertDanger)
						.errHandling();
				}
			},
		},
	};
</script>

<template>
	<div class="d-flex">
		<div class="media-left">
			<a href="javascript:;" @click="onToggleAlarmHold(alarm, $event)" v-b-tooltip.hover :title="isHold ? $t('클릭하면 알림을 재개합니다.') : $t('클릭하면 알림을 일시중지합니다.')">
				<i class="fa media-object bg-silver-darker" :class="{ 'fa-bell-slash': isHold, 'fa-bell': !isHold }"></i>
			</a>
		</div>

		<div class="media-body" @click="openAlarmModal(alarm)" v-b-tooltip.hover :title="$t(alarm.description)">
			<h6 class="media-heading">[{{ $t(alarm.alarmGroup) }}]</h6>
      <h6>{{ alarm.ptAddr }}</h6>
			<h6>
				{{ $t(alarm.description) | codeChangelengthText(20) }}
			</h6>
			<div class="text-muted f-s-11 ">{{ $t(alarm.solution) | codeChangelengthText(20) }}</div>
			<div class="media-messaging m-auto">
				<!-- <span class="f-s-12">{{ alarm.regDt.passedTime() }}</span> -->
				<span class="f-s-12 pull-right">{{ alarm.regDt.format("MM-dd hh:mm") }}</span>
			</div>
		</div>
		<div class="media-right">
			<span class="badge">{{ alarm.status }}</span>
		</div>

		<b-modal ref="alarmViewModal" :hide-footer="true" :title="$t('알람로그 상세')">
			<alarm-view :alarmLogIdx="paramAlarmLogIdx" :readonly="true" />
		</b-modal>
	</div>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";
	import AlarmView from "@src/views/sys/alarm/AlarmView.vue";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import AppErrorHandler from "@src/appErrorHandler";
	import { StatusCodes } from "http-status-codes";

	const { parseBool } = global.xe;

	export default {
		props: ["alarm"], // alarmLog 임..
		components: {
			AlarmView,
		},
		data() {
			return {
				isHold: 0,
				paramAlarmLogIdx: 0, // 모달에 전달할 alarm 정보
				popupMessages,
			};
		},
		mounted() {
			this.isHold = this.alarm.isHold;
		},
		methods: {
			onToggleAlarmHold(alarm, evt) {
				evt.stopPropagation();
				evt.preventDefault();

				this.isHold = !parseBool(this.isHold);
				console.log(this.isHold);
				let alertMsg = this.isHold ? (popupMessages.ALARM_STOP_POPUP_MESSAGE) : popupMessages.ALARM_RESUME_POPUP_MESSAGE;

				let holdNumber = this.isHold === true ? 1 : 0;

				this.alertQuestion(alertMsg).then((result) => {
					if (!result.value) return;

					try {
						backEndApi.alarm.updateAlarmHold(alarm.alarmGroup, alarm.ptAddr, holdNumber).then(({ data }) => {
							if (this.$err(data)) return;

							this.alertNoti(this.isHold ? popupMessages.ALARM_STOP_SUCCESS_POPUP_MESSAGE : popupMessages.ALARM_RESUME_SUCCESS_POPUP_MESSAGE);
							this.isHold === true ? false : true;
							this.$emit("holdCallback");
						});
					} catch (err) {
						new AppErrorHandler(err)
							.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
							.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
							// .createBizHandle(this.ERR_KIND.MISSING_DATA, popupMessages.COMMON_ANALYSIS_NO_DATA_POPUP_MESSAGE, this.alertNoti)
							.errHandling(true);
					}
				});
			},
			openAlarmModal(alarm) {
				this.paramAlarmLogIdx = alarm.alarmLogIdx;
				this.$refs.alarmViewModal.show();
			},
		},
	};
</script>

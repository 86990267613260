

<template>
  <div class="NewPointLogModal">
    <div class="row" v-if="ptInfo !== null">
      <div class="col-xl-6 col-sm-12" v-for="(data, i) in ptInfoKeyList" :key="`개요${i}`">
        <label :style="{fontSize: '13px', fontWeight: 'bold'}">{{ data.text }}</label>
        <p :style="{paddingLeft: '10px'}">{{ ptInfo[data.key] === null ? "" : ptInfo[data.key] }}</p>
      </div>
      
    </div>
    
  </div>
</template>

<script>
import backEndApi from '@src/api/backEndApi';

export default {
  props: ['ptAddr'],
  data(){
    return {
      ptInfo: null,
      ptInfoKeyList: [{
        text: global.xe.$t("관제점 유형"),
        key: 'isVirtualName',
      }, {
        text: global.xe.$t("집계 유형"),
        key: "ptTypeName",
      }, {
        text: global.xe.$t("관제점 그룹"),
        key: "ptGroup",
      }, {
        text: global.xe.$t("관제점 주소"),
        key: "ptAddr",
      }, {
        text: global.xe.$t("관제점명"),
        key: "ptName",
      }, {
        text: global.xe.$t("용도"),
        key: "itemUsageName",
      }, {
        text: global.xe.$t("구역"),
        key: "zoneName",
      }, {
        text: global.xe.$t("사용여부"),
        key: "enabledName",
      }, {
        text: global.xe.$t("에너지원"),
        key: "energySourceName",
      }]
    }
  },
  async created() {
    console.log('point log modal on created ------------------------')
    const result = await backEndApi.pointInfo.getPointInfoVw(this.ptAddr);
    this.ptInfo = result.data;
  },
}
</script>
function init() {  
  // 브라우저 체크
  global.xe.checkMobile = function () {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    }
    return false;
  }
  
  global.xe.getTargetClient = function () {
    return global.xe.checkMobile() ? "Mobile" : "PC";
  }
}

export { init };
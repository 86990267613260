<template>
  <image v-if="obj" :x="obj.x" :y="obj.y" :width="obj.w" :height="obj.h" @click="onClick" v-bind:src="path + src" v-bind:xlink:href="path + src" @error="onError" v-b-tooltip.hover :title="title" />
</template>

<script>
export default {
  name: "ActionIcon",
  props: ["obj", "path", "title"],
  data() {
    return {
      //to_calc_interval: null,
      to_src_interval: null,
      src: "",
    };
  },
  watch: {
    "obj.ptVal": function() {
      this.bindImage();
    },
  },
  mounted() {
    this.initSetting();
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.to_src_interval);
    next();
  },
  beforeDestroy() {
    clearInterval(this.to_src_interval);
  },
  methods: {
    initSetting() {
      this.bindImage();

      // TODO: 나중에 보여질 이미지를 미리 로드해 놓아야 함..  image lazy load
    },
    bindImage() {
      // 이 함수는 ptVal이 변경될때마다 호출됨..

      // 단독이미지인 경우, "이미지"가 문자열경로임.
      if (typeof this.obj.image === "string") {
        this.setSrc(this.obj.image);
        return;
      }

      if (Array.isArray(this.obj.image) && this.obj.image.length > 0) {
        //"이미지"가 배열이면..
        let images = this.obj.image;

        // calc 연산에 따라, 현재조건에 맞는 이미지를 찾음..
        let cur = images.find((image) => {
          try {
            if (!this.isEmpty(image.calc)) {
              let result = new Function("ptVal", "return " + image.calc)(this.obj.ptVal || 0);
              if (result) return true;
            }
          } catch (err) {
            console.warn(`Image 표시조건을 연산할 수 없습니다.`, this.obj.propertyName, this.obj.ptAddr, image.calc);
          }
          return false;
        });

        // calc 설정되지 않았거나, 조건에 맞는 이미지가 없으면, 첫번째 이미지로..
        if (this.isEmpty(cur)) cur = images[0];

        this.setIntervalImage(cur);
      }
    },
    setIntervalImage(cur) {
      if (this.isEmpty(cur)) {
        console.warn("표시하려는 이미지가 존재하지 않습니다.");
        return;
      }
      if (this.isEmpty(cur.src)) {
        console.warn("표시하려는 이미지경로가 존재하지 않습니다.");
        return;
      }

      // 조건에 따라 표시할 이미지소스가 배열인 경우.. intervalSec에 따라 주기적으로 변경한다.
      if (Array.isArray(cur.src)) {
        // 표시하려는 이미지소스가 배열이면, intervalSec에 따라 주기적으로 이미지를 교체한다.
        clearInterval(this.to_src_interval);

        let cnt = 0;
        this.to_src_interval = setInterval(() => {
          this.setSrc(cur.src[cnt++ % cur.src.length]);
        }, (cur.intervalSec || 1) * 1000);
      } else {
        this.setSrc(cur.src);
      }
    },
    setSrc(src) {
      if (this.isEmpty(src)) {
        console.warn("표시하려는 이미지경로가 존재하지 않습니다.");
        return;
      }
      this.src = src;
    },
    onError() {
      console.warn("설정된 이미지경로가 존재하지 않습니다.");
      this.avatarUrl = "/assets/xems/common/product/noListBg.png";
    },
    onClick(...args) {
      this.$emit("click", args);
    },
  },
};
</script>

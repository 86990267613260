import backEndApi from "@api/backEndApi.js";
import xelib from "xelib";
const { WorkflowCalculator } = xelib.PointBox;

const WorkflowMgr = function(watchPt) {
  let that = this;

  that.runCalc = function(targetDt) {
    run(targetDt);
  };

  that.pre = function(preNow) {
    run(preNow);
  };

  that.next = function(nextNow) {
    that.clock.next(nextNow);
  };

  // 실행 ///////////////////////////////////////////////////////////////////////////////////////////////

  that.on_change = null;
  that.workgroup = null;

  // 화면에 통신으로 전송
  function sendUI(liveDate, liveReal) {
    if (liveReal) {
      if (liveReal[watchPt]) console.log("Calculator 2. sendUI liveReal=", JSON.stringify(liveReal[watchPt]));
    } else {
      console.warn("Calculator 2. sendUI liveReal=", liveReal);
    }

    if (that.on_change) that.on_change(liveDate, liveReal);
  }

  // 계산식 연산 실행 /////////////////////////////////////////////////////////////////////////////
  async function run(now) {
    let liveDate = now;

    let logtitle = `WorkflowMgr ${now}`;

    try {
      let liveReal = {
        // "_0001":{ ptVal }, "_0002":{ ptVal },
      };

      // 현재 시간과 차이 계산하여, History 모드를 이용한다??? 삭제해도 될 듯...
      let timeDiff = new Date().timeDiff(now);
      if (timeDiff > 0) {
        // 이력조회
        if (watchPt) console.log(logtitle, "HISTORY searchSummary1MinNow");

        let arr = await backEndApi.summary.searchSummary1MinNow(liveDate).then(({ data }) => data);

        let watchs = await backEndApi.summary.searchSummaryWatch(liveDate).then(({ data }) => data);

        if (watchPt) console.log("HISTORY arr =", arr);

        arr.forEach((v) => {
          let watch = null;

          if (Array.isArray(watchs)) {
            // 오류일 수 있음.
            watch = watchs.find((obj) => (obj.ptAddr = v.ptAddr));
          }

          liveReal[v.ptAddr] = {
            // for summary1min
            ptVal: v.ptVal,
            // 부가참조
            ptIdx: v.ptIdx,
            ptAddr: v.ptAddr,

            ptPre: v.ptPre,
            ptOrg: v.ptOrg,
            calc: watch && watch.calc ? watch.calc : v.calc,
            plan: watch && watch.plan ? watch.plan : v.plan,
            errorInfo: v.errorInfo,
          };
        });
      } else {
        // 실 계산
        if (watchPt) console.log(logtitle, "MANUAL getSamplingLog");

        let trList = await backEndApi.totalLog.getSamplingLog(liveDate).then(({ data }) => data);

        liveReal = await calulate(liveDate, trList);
      }

      sendUI(liveDate, liveReal);
    } catch (err) {
      console.error(logtitle, " error", err);
    }
  }

  let _ptMap = null;

  (async function() {
    _ptMap = await backEndApi.pointInfo.searchPointAM().then(({ data }) => {
    // _ptMap = await backEndApi.pointInfo.searchPointAM().then(({ data }) => {
      return data;
    });
  })();

  async function calulate(liveDate, trList) {
    let sampler = new WorkflowCalculator("UPL", [watchPt]);
    let liveReal = await sampler.sampling(liveDate, that.workgroup.constants, that.workgroup.workflows, _ptMap, trList, `${that.calcuateMode} 샘플링`);
    // if(watchPt) console.log("calulate liveReal", liveDate.format("HH:mm"), "  ", liveReal);
    return liveReal;
  }
};

export default WorkflowMgr;

const ERR_KIND = Object.freeze({
  /**
   * 인자값이 제공되지 않았습니다.
   */
  MISSING_ARGUMENT: '100',

  /**
   * 인자값이 올바르지 않습니다.
   */
  INVALID_ARGUMENT: '101',

  /**
   * 처리할 데이터가 존재하지 않습니다.
   */
  MISSING_DATA: '104',

  /**
   * 데이터가 중복되었습니다.
   */
  DUPLICATE_DATA: '105',

  /**
   * 다른곳에서 사용중인 정보입니다. 삭제할 수 없습니다.
   */
  IN_USE: '106',

  /**
   * 오류 - 지정된 메세지 그대로 사용
   */
  ERROR_OCCURRED: '200',

  /**
   * 처리도중 알 수 없는 오류가 발생하였습니다.
   */
  UNKNOWN_ERROR: '600',

  /**
   * 처리 권한이 없습니다.
   */
  INSUFFICIENT_PERMISSION: '666',
});

export default {
  ERR_KIND  
};
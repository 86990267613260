<template>
  <div class="d-flex flex-row">
      <div class="col-xl-11">
        <div class="pull-left">
          <h3>
            {{$t('시스템 관리')}} > {{ title }}
          </h3>
        </div>
      </div>
      <button v-if="type == 'engineering'" class="btn btn-default pull-right m-l-auto" @click="bindConfig()" v-b-tooltip.hover :title="$t('새로고침')"> <i class="fa fa-redo"></i> {{ $t("새로고침") }} </button>
      <div v-else-if="type == 'pointLogs'" class="btn-group m-r-20">
      <button class="btn btn-white" :class="[tableInfo.isShow ? 'active' : '']" v-for="(tableInfo, i) in tableInfos" :key="'tbl' + i" @click="tableInfo.isShow = !tableInfo.isShow">
        {{ tableInfo.tableType }}
      </button>
      </div>
    </div>
</template>

<script>
// import backEndApi from "../../../../../api/backEndApi";

export default {
  components: {},
  props: ["title", "type", "tableInfos"],
  watch: {},
  data() {
    return {
      
    };
  },
  computed: {
   
  },
  created() {
  
  },
  methods: {
    bindConfig(){
      this.$emit("bindConfig");
    }
  },
};
</script>

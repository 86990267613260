// "gtwill"  : "vue-cli-service serve --port 9100 --master:gtwill",
// "vietnam" : "vue-cli-service serve --port 9600 --master:vietnam",
// "dongwon" : "vue-cli-service serve --port 9700 --master:dongwon",
// "homeplus": "vue-cli-service serve --port 9800 --master:homeplus",
// "kbet"    : "vue-cli-service serve --port 9900 --master:kbet",
// "test"    : "vue-cli-service serve --port 19100 --master:test",

// let masterCode = "gtwill";
// console.log(`process.env.NODE_ENV: `, process.env);
// console.log(`masterCode: `, masterCode);
// console.log(`process.env.NODE_ENV: `, process.env.NODE_ENV);

console.log(`NODE_ENV : ${process.env.NODE_ENV}`);
console.log(`appTitle : ${process.env.VUE_APP_TITLE}`);
console.log(`masterCode : ${process.env.VUE_APP_masterCode}`);
console.log(`apiPort : ${process.env.VUE_APP_apiPort}`);
console.log(`expressWorkers : ${process.env.VUE_APP_expressWorkers}`);
console.log(`backEndWssPort : ${process.env.VUE_APP_backEndWssPort}`);

//xems backend의 여러 Port에 대한 정보는 Frontend에서 지정하는 것은 좋은 방법이 아님
//현재 front를 배포한 Backend Server에 현재 구성정보를 질의하여 적용할 수 있도록 변경해야 함.
//window.location.hostname, window.location.port
//GET server/info/

//const config = subscribe[masterCode];

//window.location.hostname

const config = {}

if (process.env.NODE_ENV === 'production' || global.xe.isEmpty(process.env.VUE_APP_backEndHost)  || global.xe.isEmpty(process.env.VUE_APP_apiPort)) {
  //backEndHost와 apiPort가 정의되지 않았다면 현재 접속된 서버를 기준으로 설정함.
  console.log(".env에 BackEnd의 host와 apiPort가 지정되지 않았으므로 현재 접속된 서버를 기준으로 Backend 접속정보를 설정함.");

  config.protocol = window.location.protocol || "http";
  config.host = window.location.hostname || "localhost";

  // Added by badblock.2023.06.21
  // EMS 접속 포트가 지정되지 않고 호출되는 경우 WebServer 또는 ReverseProxy를 경유한 것으로 간주함.
  if (window.location.port > 0) {
    config.apiPort = window.location.port * 1;
    config.backEndWssPort = config.apiPort + 10;      // WebSocket Port
  } else {
    config.apiPort = 0;
    config.backEndWssPort = 0;
    config.wssForwardPath = 'wssforward-front/';
  }

  // NOTI : 더 이상 사용하지 않음
  config.expressWorkers = process.env.VUE_APP_expressWorkers || 1;

} else {
  console.log(".env으로 Backend 접속정보를 설정함.");
  config.protocol = process.env.VUE_APP_protocol || "http";
  config.host =process.env.VUE_APP_backEndHost || "localhost";
  config.apiPort = process.env.VUE_APP_apiPort * 1;
  config.expressWorkers = process.env.VUE_APP_expressWorkers || 1;
  config.backEndWssPort = process.env.VUE_APP_backEndWssPort * 1; //for typecasting
}

// window.location.protocol : 'http:' 으로 값을 반환하여
// 표준화하기 위해서는 ':'을 제거하고 url을 생성한다.
config.protocol = config.protocol.replace(':', '');

/*
if (config == null || config == undefined) {
  console.error(`Config is abnormal. Check your master code.`);
}
*/
if (config.apiPort == 0) {
  console.warn(`Proxy를 경유할 경우 WebSocket Forwarding URL(${config.wssForwardPath})을 반드시 설정해야 함.`)

  config.apiUrl = `${config.protocol}://${config.host}`;
  config.wssUrl = `${config.protocol=='https'? 'wss://' : 'ws://'}${config.host}/${config.wssForwardPath}`;
} else {
  config.apiUrl = `${config.protocol}://${config.host}:${config.apiPort}`;
  config.wssUrl = `${config.protocol=='https'? 'wss://' : 'ws://'}${config.host}:${config.backEndWssPort}`
}

console.log("config : " + JSON.stringify(config));

export default {
  backend: {
    host: config.host,
    protocol: config.protocol,
    apiPort: config.apiPort,
    expressWorkers: config.expressWorkers,
    apiUrl: config.apiUrl,
    wssUrl: config.wssUrl,
  },
  // TODO: 에너지원 설정값 서버설정으로?
  resourcesAvailable: [
    { type: "elec", name: "전기" },
    { type: "gas", name: "가스" },
    { type: "heat", name: "난방" },
    { type: "water", name: "수도" }
  ]
};

<template>
	<div>
		<vvo v-slot="v">
			<div class="row form-group">
				<div class="col-12">
					<label><trans>데이터 수집기명</trans></label>
					<valid-input
						:vid="'데이터 수집기명'"
						:inputType="'text'"
						:inputValue.sync="newServer.serverName"
						:rules="rules.COLLECT_DATA_EQUIP_NAME_RULE"
						:errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 15)"
						:placeholder="$t('데이터 수집기명을 입력하세요.')"
					></valid-input>
				</div>
			</div>
			<!-- 데이터 수집기 연결 -->
			<div v-xe-pm.E class="row form-group" v-if="isUpdate">
				<div class="col-12">
					<label><trans>데이터 수집기 연결</trans></label>
				</div>
				<div class="col-9">
					<select class="form-control" v-model="newClientIdx" @change="onChangeAgent">
						<option value="" selected> 없음 </option>
						<template v-for="(client, i) in clients">
							<option :key="i" :value="client.clientIdx"> {{ client.ip }} / {{ client.mac }} / {{ client.hostname }} </option>
						</template>
					</select>
				</div>
				<div class="col-3">
					<!-- <button class="btn btn-lime m-l-5" @click="onRemoveAgent()" v-b-tooltip.hover :title="$t('에이전트 제거')">
          <i class="fa fa-times"> </i>
        </button> -->
					<!-- <button class="btn btn-primary m-l-5" @click="onSaveAgent()" v-b-tooltip.hover :title="$t('에이전트 변경')">
          <i class="fa fa-times"> </i>
          {{$t('저장')}}
        </button> -->
					<button :disabled="isEmpty(client) || isEmpty(client.clientIdx)" class="btn btn-yellow" @click="onRestartAgent()" v-b-tooltip.hover :title="$t('에이전트 재시작')">
						<i class="fa fa-sync"></i>
						{{ $t("재시작") }}
					</button>
				</div>
			</div>

			<!-- 서버 상태
    { 
      "cpu": { "total": 100, "cpuUsed": 1.94, "cpuAvrg": 4.082 }, 
      "ram": { "ramTotal": 31.93, "ramUsed": 16.7, "ramFree": 15.24 }, 
      "hdd": { "hddTotal": 4172.12, "hddUsed": 609.02, "hddFree": 3563.1 }, 
      "keepAlive": "20201021200821" 
    } -->
			<div class="p-10">
				<div class="row" v-if="isUpdate">
					<div class="col-12">
						<label><trans>NIC</trans></label>
						<p>
							IP: {{ newServer.ip }} <br />
							MAC: {{ newServer.mac }}
						</p>
					</div>
				</div>
				<div class="row" v-if="isUpdate && status">
					<div class="col-6">
						<label><trans>메모리</trans></label>
						<p>
							<trans>전체</trans>: {{ status.ram.total }} <br />
							<trans>사용</trans>: {{ status.ram.used }} <br />
							<trans>여유</trans>: {{ status.ram.free }} <br />
							<span v-if="status.ram.isWarn" class="text-red"><trans>메모리사용량이 과도합니다.</trans> </span>
						</p>
					</div>
					<div class="col-6">
						<label><trans>저장소</trans></label>
						<p>
							<trans>전체</trans>: {{ status.hdd.total }} <br />
							<trans>사용</trans>: {{ status.hdd.used }} <br />
							<trans>여유</trans>: {{ status.hdd.free }} <br />
							<span v-if="status.hdd.isWarn" class="text-red"><trans>디스크공간이 얼마남지 않았습니다.</trans></span>
						</p>
					</div>
				</div>
				<div class="row form-group" v-if="isUpdate && status">
					<div class="col-lg-12">
						<label> <trans>최종실행일</trans> </label>
						<div class="form-control" readonly v-if="keepAlive">
							<span class="pull-left"> {{ keepAlive.passedTime() }} </span>
							<span class="pull-right"> {{ keepAlive.format("yyyy-MM-dd HH:mm") }} </span>
						</div>
						<div class="form-control" readonly v-else>Empty</div>
					</div>
				</div>
			</div>

			<div slot="modal-footer" class="w-100">
				<button v-xe-pm.E class="btn btn-danger" v-if="isUpdate && (isEmpty(childrens) || childrens == 0)" @click="onDeleteServer()"><i class="fa fa-times"></i> {{ $t("삭제") }}</button>
				<button class="btn btn-primary pull-right" @click="onValidate(v, onSaveServer, newServer)">
					<i class="fa fa-save"></i> {{ $t("저장") }}
				</button>
			</div>
		</vvo>
	</div>
</template>

<script>
	import backEndApi from "../../../../api/backEndApi.js";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	// TODO: serverLog 테이블에 ip, mac 있어야 함...
	// TODO: 저장소 부족체크  20%이하 경고, 서버에서 설정으로 관리해야 하고, 서버에서 로그삭제시 체크하여 경고알람을 발생시켜야 함...

	// TODO: 에이전트 변경시, 다른 서버에서 사용중인 에인전트로 변경하게되면, 알려주어야 함.

	// TODO: 서버로그에 IP, MAC 이 없어서, 어떤 서버의 내용인지 불명확함.   서버상태 업데이트가 1번만 되고 있음.. 에이전트에서 올라와야 함....
	// 전반적으로 장비의 상태정보가 불명확함......

	// TODO: 에이전트 재시작도 이상함..  에이전트가 접속중인지.. 재시작을 잘했는지에 대한 이벤트 로그확인이 어려움...

	const config = {
		warningLimit: 20,
	};

	export default {
		props: ["server", "childrens", "save-done"],
		data() {
			return {
				newServer: {},

				oldClientIdx: "",
				newClientIdx: "",
				clients: [],
				client: null,
				rules,
				validMessage,
				popupMessages,
			};
		},
		computed: {
			isUpdate() {
				return !this.isEmpty(this.server.serverIdx);
			},
			cpu() {
				return this.newServer && !this.isEmpty(this.newServer.serverStatus) && !this.isEmpty(this.newServer.serverStatus.cpu) ? this.newServer.serverStatus.cpu : {};
			},
			ram() {
				return this.newServer && !this.isEmpty(this.newServer.serverStatus) && !this.isEmpty(this.newServer.serverStatus.ram) ? this.newServer.serverStatus.ram : {};
			},
			hdd() {
				return this.newServer && !this.isEmpty(this.newServer.serverStatus) && !this.isEmpty(this.newServer.serverStatus.hdd) ? this.newServer.serverStatus.hdd : {};
			},
			status() {
				if (this.isEmptyObj(this.newServer.serverStatus)) return null;

				let ramFree = this.percentage(this.ram.ramFree, this.ram.ramTotal); // -1 반환은 계산할 수 없음임.
				let hddFree = this.percentage(this.hdd.hddFree, this.hdd.hddTotal);
				return {
					ram: {
						total: this.storageSize(this.ram.ramTotal),
						used: this.storageSize(this.ram.ramUsed),
						free: ramFree > -1 ? ramFree + "%" : "",
						isWarn: ramFree > -1 && ramFree <= config.warningLimit,
					},
					hdd: {
						total: this.storageSize(this.hdd.hddTotal),
						used: this.storageSize(this.hdd.hddUsed),
						free: hddFree > -1 ? hddFree + "%" : "",
						isWarn: hddFree > -1 && hddFree <= config.warningLimit,
					},
				};
			},
			keepAlive() {
				return this.newServer && !this.isEmpty(this.newServer.serverStatus) && !this.isEmpty(this.newServer.serverStatus.keepAlive) ? this.newServer.serverStatus.keepAlive : null;
			},
		},
		watch: {
			"server.serverIdx": function() {
				this.initSetting();
			},
		},
		mounted() {
			this.initSetting();
		},
		methods: {
			initSetting() {
				this.newServer = {};

				if (this.isUpdate) {
					backEndApi.server.getServer(this.server.serverIdx).then(({ data }) => {
						if (this.$err(data)) return;

						this.newServer = data;
					});

					// 에이전트 연결
					this.bindAgent();
				}
			},
			//#region 서버정보
			onSaveServer() {
				this.isUpdate ? this.updateServer() : this.insertServer();
			},
			insertServer() {
				this.alertQuestion(popupMessages.COMMON_SAVE_CONFIRM_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.server.insertServer(this.newServer).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
						this.$emit("save-done");
					});
				});
			},
			updateServer() {
				this.alertQuestion(popupMessages.COMMON_UPDATE_CONFIRM_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.server.updateServer(this.newServer).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.COMMON_UPDATE_POPUP_MESSAGE);
						this.$emit("save-done");
					});
				});
			},
			onDeleteServer() {
				// TODO: 하위 정보가 있으면 삭제할 수 없습니다.

				this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.server.deleteServer(this.newServer.serverIdx).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
						this.$emit("save-done");
					});
				});
			},
			//#endregion

			//#region 에이전트

			bindAgent() {
				this.clients.clear();
				backEndApi.client.searchClient().then(({ data }) => {
					if (this.$err(data)) return;

					for (let item of data.sort((a, b) => (a.ip < b.ip ? -1 : a.ip < b.ip ? 1 : 0))) {
						this.clients.push(item);
					}
				});

				this.client = null;
				this.oldClientIdx = "";
				this.newClientIdx = "";

				if (this.isUpdate) {
					backEndApi.client.getClientByServerIdx(this.server.serverIdx).then(({ data }) => {
						if (this.$err(data)) return;

						this.client = data;
						this.oldClientIdx = data.clientIdx;
						this.newClientIdx = data.clientIdx;
					});
				}
			},
			// onRemoveAgent() {
			//   this.newClientIdx = "";
			// },
			// onSaveAgent(){
			//   this.alertConfirmWarning("저장하시겠습니까?", "에이전트").then((result) => {
			//     if (!result.value) return;

			//     backEndApi.client.applyClient({ serverIdx: this.newServer.serverIdx, oldClientIdx: this.oldClientIdx, newClientIdx: this.newClientIdx });

			//   });
			// },
			onChangeAgent() {
				const that = this;

				this.alertQuestion(popupMessages.POINT_DATA_COLLECTION_AGENT_CHANGE_POPUP_MESSAGE).then((result) => {
					if (!result.value) {
						that.newClientIdx = that.oldClientIdx;
						return;
					}

					backEndApi.client.applyClient({ serverIdx: this.server.serverIdx, oldClientIdx: this.oldClientIdx, newClientIdx: this.newClientIdx }).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.COMMON_UPDATE_POPUP_MESSAGE);
						this.bindAgent();
					});
				});
			},
			onRestartAgent() {
				if (this.isEmpty(this.client) || this.isEmpty(this.client.clientIdx)) {
					this.alertNoti(popupMessages.POINT_DATA_COLLECTION_AGENT_RESTART_ERROR_POPUP_MESSAGE); // TODO: 오류메세지로 바꿔야 함.
					return;
				}

				this.alertQuestion(popupMessages.POINT_DATA_COLLECTION_AGENT_RESTART_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					backEndApi.client.restartAgent(this.client.clientIdx).then(({ data }) => {
						if (this.$err(data)) return;

						this.alertNoti(popupMessages.POINT_DATA_COLLECTION_AGENT_RESTART_SEND_POPUP_MESSAGE);
					});
				});
			},
			//#endregion
		},
	};
</script>

<template>
	<div class="login login-with-news-feed">
		<div class="news-feed">
			<div class="news-image" :style="'background-image: url(' + (master.loginBg ? master.loginBg : '') + ')'"></div>
			<div class="news-caption">
				<h4 class="caption-title">
					<b> {{ master.brand }} </b>
					<!-- <b>{{ 'CxEMS v2.1' }}</b> -->
				</h4>
			</div>
		</div>
		<div class="right-content">
			<div class="login-header">
				<div class="brand">
					<b>{{ master.brand }}</b>
					<!-- <b>{{ 'CxEMS v2.1' }}</b> -->

					<small>{{ master.description }}</small>
					<!-- <small>{{ 'Carbon Free Energy Management Solution' }}</small> -->
				</div>
				<div class="icon">
					<i class="fa fa-sign-in-alt"></i>
				</div>
			</div>
			<!-- 로그인폼 -->
			<div class="login-content">
				<form v-on:submit="checkForm" method="POST" class="margin-bottom-0">
					<div class="form-group m-b-15" v-show="masterGroup && masterGroup.length > 1">
						<select class="form-control form-control-lg" v-model="masterCode">
							<option value="" disabled> {{ $t("소속선택") }} </option>
							<option v-for="(m, i) in masterGroup" :key="i" :value="m.masterCode"> {{ m.brand }} </option>
						</select>
					</div>
					<div class="form-group m-b-15">
						<valid-input :inputType="'text'" :inputValue.sync="userId" :rules="rules.LOGIN_ID_RULE" :placeholder="$t('아이디')" :NonStatus="true"></valid-input>

						<!-- <input type="text" class="form-control form-control-lg" placeholder="아이디" required v-model="userId" maxlength="50" /> -->
					</div>
					<div class="form-group m-b-15">
						<valid-input :inputType="'password'" :inputValue.sync="password" :rules="rules.LOGIN_PASSWORD_RULE" :placeholder="$t('비밀번호')" :NonStatus="true"></valid-input>
					</div>
					<div class="login-buttons">
						<button type="submit" class="btn btn-success btn-block btn-lg" style="border-color: #2e579c;background-color:#2e579c;">{{ $t("로그인") }}</button>
					</div>
					<div class="m-t-20 m-b-40 p-b-40 text-inverse" v-if="master.policy && master.policy.UserRegister">
						Not a member yet? Click
						<a :href="'/#/register?masterCode=' + masterCode" class="text-success">here</a> to register.
					</div>
					<hr />
					<p class="text-center text-grey-darker">&copy; {{ master.copy }}</p>

					<!-- <p class="text-center text-grey-darker">{{'Copyright 2022. The KIE All right reserved.' }}</p> -->
				</form>
			</div>

			<b-modal ref="changePwdModal" :title="$t('비밀번호 재설정')" :hide-footer="true">
				<change-pwd-modal :changeUser="$store.state.loginUser" @save-done="() => $refs.changePwdModal.hide()" />
			</b-modal>
		</div>
	</div>
</template>

<style scoped>
	.login .login-header .brand {
		font-size: 35px;
	}
</style>

<script>
	import * as rules from "../consts/ruleConsts";
	import * as validMessage from "../consts/validMessageConsts";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	//import * as messages from '../consts/messageConsts';
	//import PageOptions from "../config/PageOptions.vue";

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// 안드로이드 사용자 접속 정보 조회 함수
	// vue-cli 에서 미사용 함수에 대한 빌드 실패를 막기위해 Function 오브젝트 사용
	// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function

	// eslint-disable-next-line
	let getAndroidAutoLogin = new Function("if (typeof Android !== 'undefined' && Android) { return Android.getAutoLogin(); } console.log ('Android 미 정의로 로그인 정보를 읽지 않음'); return;");
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	import ChangePwdModal from "../views/sys/menu/user/ChangePwdModal.vue";

	export default {
		components: {
			ChangePwdModal,
		},
		data() {
			return {
				masterCode: "",
				userId: "",
				password: "",
				msg: "",
				rules,
				validMessage,
				to_master_group: null,
				popupMessages
			};
		},
		computed: {
			masterGroup() {
				return this.$store.getters.masterGroup;
			},
			master() {
				if (this.masterCode && this.masterGroup && Array.isArray(this.masterGroup)) {
					let match = this.masterGroup.find((v) => v.masterCode == this.masterCode);
					if (match) return match;
				}
				return { masterCode: "", desc: "", theme: "", version: "", logo: "", loginBg: "", brand: "", description: "", copy: "" };
			},
			isAuthenticated() {
				//return this.$store ? this.$store.getters.isAuthenticated : false;
				return this.$store.getters.isAuthenticated;
			},
			loginUser() {
				//return this.$store ? this.$store.getters.loginUser : null;
				return this.$store.getters.loginUser;
			},
		},
		created() {
			this.pageOptions.pageEmpty = true;
			document.body.className = "bg-white";
		},
		beforeRouteLeave(to, from, next) {
			this.pageOptions.pageEmpty = false;
			document.body.className = "";
			clearInterval(this.to_master_group);
			next();
		},
		beforeDestroy() {
			clearInterval(this.to_master_group);
		},
		mounted() {
			this.initSetting();
		},
		methods: {
			initSetting() {
				this.to_master_group = setInterval(() => {
					// 마스터코드가 마스터그룹이 로딩되는 것을 기다려야 함.
					if (this.masterCode === "") {
						if (this.masterGroup && this.masterGroup.length > 0) {
							this.masterCode = this.masterGroup[0].masterCode;

							// 안드로이드 자동로그인 구간.
							let json = getAndroidAutoLogin();
							if (json) {
								try {
									let user = JSON.parse(json);
									// 로그인 정보를 확인
									if ("masterCode" in user && "userId" in user && "password" in user) {
										if (this.isEmpty(user.masterCode) || this.isEmpty(user.userId) || this.isEmpty(user.password)) {
											return;
										}

										let param = { masterCode: user.masterCode, userId: user.userId, password: user.password };

										this.$store
											.dispatch("LOGIN", param)
											.then(() => {
												this.redirect();
											})
											.catch((err) => {
												this.msg = err.message;
												this.alertDanger(popupMessages.LOGIN_FAILURE_POPUP_MESSAGE);
											});
									}
								} catch (err) {
									console.error(`안드로이드 사용자 정보 읽기 오류 발생(${err.message})`);
								}
							}
						}
					} else {
						// 마스터그룹이 로딩되어 마스터코드에 값이 들어오면, 마스터그룹로딩을 중단한다.
						clearInterval(this.to_master_group);
					}
				}, 100);
			},
			checkForm: function(e) {
				if (e) e.preventDefault();

				// 마스터코드가 선택되지 않으면 로그인 할 수 없다.
				if (this.isEmpty(this.masterCode)) {
					this.alertNoti(popupMessages.LOGIN_MASTER_CODE_SELECT_POPUP_MESSAGE);
					return;
				}

				let param = { masterCode: this.masterCode, userId: this.userId, password: this.password };

				// 로그인은 함수를 직접호출하지 않고, store를 통해 동기화 로직을 거친다.
				this.$store
					.dispatch("LOGIN", param)
					.then(() => {
						this.redirect();
            
						// 로그인 버튼 눌렀을 시 해당 유저정보를 안드로이드로 전달
						if (global.xe.getTargetClient() == "Mobile") {
							window.Android.setPreference(this.masterCode, this.userId, this.password);
						}
					})
					.catch((err, res) => {
						this.msg = err.message;
						console.log(err.message, err.response, res);
						if (err.message === "first login") {
							this.alertQuestion(popupMessages.LOGIN_FIRST_ACCESS_POPUP_MESSAGE).then((result) => {

                if (!result.value) return;

								this.$refs.changePwdModal.show();
							});
						} else {
							if (err.response.data.errno === "200") {
								this.alertNoti(err.response.data.message);
							} else {
								this.alertDanger(popupMessages.LOGIN_FAILURE_POPUP_MESSAGE);
							}
						}
					});
			},
			redirect() {
				// 리다이렉트 하기 전에, 현재 마스터의 테마로 로딩한다.
				let master = this.$store.getters.master;
				console.log(this.$store.state.loginUser);
				if (this.$store.state.loginUser.pwdChgRequired === true) {
					throw Error("first login");
				}

				console.log(master);

				localStorage.theme = master.theme; // 전체 테마
				localStorage.labelTheme = master.labelTheme; // 헤더 색상 테마
				this.pageOptions.pageHeaderInverseMode = master.headerInverse; // 반전 모드

				// 테마 css 는 동적으로 변경되어야 하기 때문에, lazy loading함.
				import(`../assets/css/${master.theme}/app.min.css`)
					.then(() => {
						return import(`../assets/css/${master.theme}/theme/${master.labelTheme}.min.css`);
					})
					.then(() => {
						return import(`../assets/css/v-${master.theme}.css`);
					})
					.then(() => {
						this.redirectReturnPath();
					});
			},
			redirectReturnPath() {
				// 원래 접근하려던 페이지의 URL 파라미터 토큰을 재 생성하여, 리다이렉트 한다.
				const { search } = window.location;
				const tokens = search.replace(/^\?/, "").split("&");
				const { returnPath } = tokens.reduce((qs, tkn) => {
					const pair = tkn.split("=");
					qs[pair[0]] = decodeURIComponent(pair[1]);
					return qs;
				}, {});

				// 리다이렉트 처리
				if (returnPath || returnPath == "") {
					this.$router.push(returnPath);
				} else {
					this.$router.push({ path: "/" });
				}
			},
		},
	};
</script>

<template>
  <!-- begin #footer -->
  <div id="footer" class="footer">&copy; {{ master.copy }}</div>
  <!-- end #footer -->
</template>

<script>
//import PageOptions from "../../config/PageOptions.vue";

export default {
  name: "Footer",
  data() {
    return {
      //pageOptions: PageOptions,
    };
  },
  computed: {
    master() {
      return this.$store.getters.master;
    },
  },
};
</script>

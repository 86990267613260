import PanelComponent from "./Panel.vue";

const Panel = {
  install(Vue) {
    //Vue.component(PanelComponent.name, PanelComponent);
    Vue.component(PanelComponent.name, () => import("./Panel.vue"));
  },
};

export default Panel;

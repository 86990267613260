<template>
	<b-container>
		<div class="row searchArea">
			<div class="col-md-12 innerBox">
				<div class="row p-10">
					<!-- 조회조건 -->
					<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
						<div class="pt-2 p-l-5">
							<span>{{$t('조회조건')}}</span>
						</div>
						<div class="p-l-5 p-r-5">
							<div class="btn-group">
								<button class="btn btn-white" :class="{ active: timeDsvn === 'Log' }" @click="onClick('Log')">{{$t('로그')}}</button>
								<button class="btn btn-white" :class="{ active: timeDsvn === 'Min' }" @click="onClick('Min')">{{$t('1분')}}</button>
								<button class="btn btn-white" :class="{ active: timeDsvn === 'Quarter' }" @click="onClick('Quarter')">{{$t('15분')}}</button>
								<button class="btn btn-white" :class="{ active: timeDsvn === 'Hour' }" @click="onClick('Hour')">{{$t('시간')}}</button>
								<button class="btn btn-white" :class="{ active: timeDsvn === 'Day' }" @click="onClick('Day')">{{$t('일간')}}</button>
								<button class="btn btn-white" :class="{ active: timeDsvn === 'Month' }" @click="onClick('Month')">{{$t('월간')}}</button>
								<button class="btn btn-white" :class="{ active: timeDsvn === 'Year' }" @click="onClick('Year')">{{$t('연간')}}</button>
							</div>
						</div>
					</div>
					<!-- 조회기간 -->
					<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
						<div class="pt-2 p-l-25">
							<span>{{$t('조회기간')}}</span>
						</div>
						<div class="p-l-5">
							<div class="d-flex flex-row">
								<div>
									<date-picker
										placeholder="Start Date"
										v-model="fromDate"
										:style="{ width: '160px' }"
										:config="{ format: this.formatter, viewMode: this.viewMode, locale: 'ko' }"
										@focus.prevent="onDatePickerShow"
									>
									</date-picker>
								</div>
								<div class="pt-2 p-l-5 p-r-5">
									<span> ~ </span>
								</div>
								<div class="p-r-5">
									<date-picker
										placeholder="End Date"
										:style="{ width: '160px' }"
										v-model="toDate"
										:config="{ format: this.formatter, viewMode: this.viewMode, locale: 'ko' }"
										@focus.prevent="onDatePickerShow"
									>
									</date-picker>
								</div>
							</div>
						</div>
					</div>
					<div class="text-right">
						<button type="button" class="ml-3 btn btn-info" @click="setItems()">{{$t('조회')}}</button>
						<button type="button" class="ml-3 btn btn-light" @click="onExcelDownload()">{{$t('다운로드')}}</button>
					</div>
				</div>
			</div>
		</div>

		<!-- 그리드 -->
		<b-table
			hover
			no-border-collapse
			show-empty
			sticky-header
			ref="pointLogTalbe"
			:id="'point-log-table'"
			:class="'text-center'"
			:items="items"
			:fields="fields"
			:busy="isBusy"
			:empty-text="$t('조회된 결과가 없습니다.')"
			:select-mode="'single'"
			selectable
		>
			<template #empty="scope">
				<h4>{{ scope.emptyText }}</h4>
			</template>

			<template #table-busy>
				<div class="text-center my-2">
					<b-spinner class="align-middle"> </b-spinner>
					<strong>Loading... </strong>
				</div>
			</template>

			<template #cell(selected)="{ rowSelected }">
				<template v-if="rowSelected">
					<span>&check;</span>
					<span class="sr-only">Selected</span>
				</template>
				<template v-else>
					<span>&nbsp;</span>
					<span class="sr-only">Not selected</span>
				</template>
			</template>

			<template #cell(index)="data">
				{{ data.index + 1 }}
			</template>
		</b-table>
	</b-container>
</template>

<script>
	import SearchUtils from "@src/views/component/searchBox/analysis/SearchUtils";
	import backEndApi from "../../../../api/backEndApi";
	import XLSX from "xlsx";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		components: {},
		props: ["ptAddr"],
		watch: {},
		data() {
			return {
				formatter: "YYYY-MM-DD HH:mm",
				timeDsvn: "Log",
				viewMode: "days",
				fromDate: new Date(),
				toDate: new Date(),
				fields: null,
				items: null,

				isBusy: false,
			};
		},
		computed: {},
		created() {
			this.setFields();
		},
		methods: {
			onClick(type) {
				// this.fromDate = null;
				// this.toDate = null;

				this.timeDsvn = type;

				this.changeViewMode();
				this.onDatePickerShow();
			},
			changeViewMode() {
				let viewMode = "";
				switch (this.timeDsvn) {
					case "Month":
						viewMode = "months";
						break;
					case "Year":
						viewMode = "years";
						break;
					case "Yearly":
						viewMode = "years";
						break;
					default:
						viewMode = "days";
						break;
				}
				this.viewMode = viewMode;
			},
			onDatePickerShow() {
				switch (this.timeDsvn) {
					case "Log":
						this.formatter = "YYYY-MM-DD HH:mm";
						break;
					case "Min":
						this.formatter = "YYYY-MM-DD HH:mm";
						break;
					case "Quarter":
						this.formatter = "YYYY-MM-DD HH:mm";
						break;
					case "Hour":
						this.formatter = "YYYY-MM-DD HH";
						break;
					case "Day":
						this.formatter = "YYYY-MM-DD";
						break;
					case "Month":
						this.formatter = "YYYY-MM";
						break;
					case "Year":
						this.formatter = "YYYY";
						break;
					case "Yearly":
						this.formatter = "YYYY";
						break;
				}
			},
			setFields() {
				this.fields = [
					// { key: 'ptAddr', label: "관제점 주소" },
					{ key: "regDt", label: global.xe.$t("기준 일시") },
					{ key: "ptVal", label: global.xe.$t("계측(집계) 값") },
				];
			},
			setItems() {
				let param = {
					ptAddr: this.ptAddr,
					timeDsvn: this.timeDsvn,
					fromDate: this.fromDate,
					toDate: this.toDate,
				};

				if (!this.checkDateRange(param)) {
					return;
				}

				this.isBusy = true;

				backEndApi.pointInfo.getPointLog(param).then(({ data }) => {
					data.forEach((result) => {
						result.ptAddr = this.ptAddr;
					});
					this.isBusy = false;
					this.items = data;
				});
			},
			checkDateRange(param) {
				let message = SearchUtils.searchParamValCheck(param);
				if (message !== "") {
					this.alertNoti(message);
					return false;
				}

				let diffInfo = SearchUtils.dateLengthCheck(param.timeDsvn, param.fromDate, param.toDate);

				message = SearchUtils.wrongDateMessage(param.timeDsvn, diffInfo.diffDay, diffInfo.from);
				if (message !== "") {
					this.alertNoti(message);
					return false;
				}

				return true;
			},
			onExcelDownload() {
				this.alertQuestion(popupMessages.COMMON_EXCEL_DOWNLOAD_POPUP_MESSAGE).then((result) => {
					if (!result.value) return;

					var excelData = XLSX.utils.table_to_sheet(document.getElementById("point-log-table")); // table id를 넣어주면된다
					var workBook = XLSX.utils.book_new(); // 새 시트 생성

					XLSX.utils.book_append_sheet(workBook, excelData, "계측이력 조회"); // 시트 명명, 데이터 지정
					XLSX.writeFile(workBook, "계측이력 조회" + ".xlsx"); // 엑셀파일 만
				});
			},
		},
	};
</script>

<template>
  <!-- begin sidebar nav -->
  <ul class="nav" v-if="navigation">
    <li class="nav-header">Navigation</li>
    <template v-for="nav in navigation">
      <sidebar-nav-list
        ref="sidebarNavList"
        v-bind:menu="nav"
        v-bind:scrollTop="scrollTop"
        v-bind:key="nav.menuIdx"
        v-bind:status="nav.status"
        v-on:collapse-other="handleCollapseOther(nav)"
        v-on:showFloatSubmenu="handleShowFloatSubmenu"
        v-on:hideFloatSubmenu="handleHideFloatSubmenu"
      ></sidebar-nav-list>
    </template>
    <!-- begin sidebar minify button -->
    <li>
      <a
        href="javascript:;"
        class="sidebar-minify-btn"
        v-on:click="handleSidebarMinify()"
        ><i class="fa fa-angle-double-left"></i
      ></a>
    </li>
    <!-- end sidebar minify button -->
  </ul>
  <!-- end sidebar nav -->
</template>

<style>
/* 메뉴글자 폭인 좁아서 늘림 */
/* .sidebar .nav {
  padding-left: 15px;
  padding-right: 10px;
}
.sidebar .nav > li > a {
  padding: 6px 6px !important;
} */
</style>

<script>
import Vue from "vue";
import Router from "vue-router";
//import PageOptions from "../../config/PageOptions.vue";
import backEndApi from "@api/backEndApi.js";
import SidebarNavList from "./SidebarNavList.vue";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {

  if (this.currentRoute.path !== location) {
    return originalPush.call(this, location);
  }
};

export default {
  name: "SidebarNav",
  props: ["scrollTop"],
  components: {
    SidebarNavList,
  },
  data() {
    return {
      //pageOptions: PageOptions,
      navigation: [],

      lastCheckTime: new Date(),
    };
  },
  computed: {
    navs() {
      //return this.$store.getters.navs;
      //return this.readStorage("navs");
      let menus = this.readStorage("menus");
      return Vue.convertNavList(menus);
    },
  },
  watch: {
    "$store.getters.navs": function() {
      // 서버로부터 사용자메뉴를 받아 변경된 경우..
      this.initCurrentMenu();
      this.showUserRecommendBadge();
    },
    "$store.getters.currentMenu": function(currentMenu) {
      // 메뉴를 클릭한 경우
      this.setCurrentMenu(currentMenu);
    },
    $route: function() {
      // $route를 이용하여 URL주소가 변경된 경우..
      this.initCurrentMenu();
      this.showUserRecommendBadge();
    },
  },
  created() {
    this.initCurrentMenu();
  },
  mounted() {
    this.initSetting();
  },
  methods: {
    initSetting() {
      if (this.checkMobile()) this.pageOptions.pageDualMenu = false;
    },
    initCurrentMenu() {
      let that = this;

      // 루트 path 이면 첫번째 메뉴로 이동..
      if (this.onRootNavigation()) return;

      // 현재 path에 맞는 nav를 찾아서 바인딩
      if (this.navs && Array.isArray(this.navs) && this.navs.length > 0) {
        let isSetCurrentMenu = false;

        if (this.$route.fullPath == "/") {
          let firstParentNav = this.navs.forTree((nav) => {
            let firstChilldNav = nav.children.first();
            if (firstChilldNav && !this.isEmpty(firstChilldNav.path))
              return true;
          });

          if (firstParentNav) {
            that.setCurrentMenu(firstParentNav);
            isSetCurrentMenu = true;
          }
        }

        if (!isSetCurrentMenu) {
          this.navs.forTree((nav) => {
            if (nav.path == this.$route.fullPath) {
              that.setCurrentMenu(nav);
              isSetCurrentMenu = true;
              return true;
            }
          });
        }

        if (!isSetCurrentMenu) {
          this.setCurrentMenu(this.navs[0]);
        }
      }
    },
    showUserRecommendBadge() {
      // Settings 메뉴에 신규사용자 숫자를 배지로 표시한다.
      if (
        !this.isEmpty(this.$store.getters.master.policy) &&
        !this.isEmpty(this.$store.getters.master.policy.UserRegister)
      ) {
        if (
          Array.isArray(this.navs) &&
          this.navs.length &&
          this.lastCheckTime.passedMin() > 30
        ) {
          this.lastCheckTime = new Date();

          this.navs.forTree((nav) => {
            if (nav.path !== "/management/settings") return;

            backEndApi.userRecommend
              .searchUserRecommend("", "")
              .then(({ data }) => {
                nav.badge = data.length;
                this.$forceUpdate();
              });
          });
        }
      }
    },
    setCurrentMenu(currentMenu) {
      try {
        if (!this.isEmpty(currentMenu.parent)) {
          this.setCurrentMenu(currentMenu.parent);
          return;
        }

        // 최상위메뉴에 경로가 있다면, location을 이동시키고
        if (!this.isEmpty(currentMenu.path)) {
          this.routerPush(currentMenu.path); // path 중복처리 GlobalPlugin 함수로 대체
          this.navigation = [];
          return;
        }
      } catch (err) {
        console.log("setCurrentMenu 1 Error ", err);
      }

      try {
        if (this.pageOptions.pageDualMenu) {
          // PC모드에서 최상위메뉴의 자식들을 표시한다.
          this.navs.forTree((nav) => {
            if (nav.menuIdx === currentMenu.menuIdx) {
              this.navigation = nav.children;
              return true;
            }
          });
        } else {
          this.navigation = this.navs;
        }
      } catch (err) {
        console.log("setCurrentMenu 2 Error ", err);
      }
    },
    onRootNavigation() {
      // 주소가 "/"로 들어오는 경우, 가장 첫번재 Path로 이동시킨다.

      if (this.$route.fullPath === "/") {
        // 루트메뉴에 접근하였을때 store 갱신해야 하는 것들..
        //this.$store.dispatch("UPDATE_STRINGS"); // 리소스 문자열
        //this.$store.dispatch("UPDATE_TRANS"); // DB 다국어 문자열
        //if (this.isAuthenticated) this.$store.commit("UPDATE_TARGET_CLIENT", this.isMobile ? "Mobile" : "PC");
        //if (this.isAuthenticated) this.$store.dispatch("UPDATE_MENUS");
        this.$store.dispatch("UPDATE_LOGIN_INFO");

        // 루트메뉴에 접근하였을때, 메뉴에서 첫번째 페이지를 찾아 라우팅을 변경한다.
        let navs = this.navs;
        if (navs && Array.isArray(navs) && navs.length > 0) {
          let firstNav = navs.forTree((nav) => {
            if (!this.isEmpty(nav.path)) return true;
          });

          if (firstNav) {
            this.$router.push(firstNav.path);
            return true;
          }
        }
      }
      return false;
    },
    handleShowFloatSubmenu: function(menu, offset) {
      this.$emit("showFloatSubmenu", menu, offset);
    },
    handleHideFloatSubmenu: function() {
      this.$emit("hideFloatSubmenu");
    },
    handleCollapseOther: function(nav) {
      for (var i = 0; i < this.navigation.length; i++) {
        this.$refs.sidebarNavList[i].collapse(nav);
      }
    },
    handleSidebarMinify: function() {
      this.pageOptions.pageSidebarMinified = !this.pageOptions
        .pageSidebarMinified;
    },
  },
};
</script>
